import * as React from 'react';
import {
	Grid,
	FormHelperText,
	MenuItem,
	Select,
	Typography,
	InputLabel,
	FormControl
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CustomSelectProps {
	name: string;
	header?: string;
	subtitle?: string;
	placeholder?: string;
	children: React.ReactNode;
	error: Record<string, boolean>;
	[rest: string]: unknown;
}

export function CustomSelect(props: CustomSelectProps) {
	const { t } = useTranslation();
	const { name, header, subtitle, placeholder, children, error, ...rest } = props;
	return (
		<Grid item xs={12}>
			<Typography variant="h6">{header || ''}</Typography>
			<Typography variant="subtitle2" color="textSecondary">
				{subtitle || ''}
			</Typography>
			<br />
			<Select
				variant="standard"
				fullWidth
				displayEmpty
				error={error[name]}
				sx={{ color: error[name] ? '#ff333a' : undefined }}
				{...rest}
			>
				<MenuItem disabled value="">
					<em>{placeholder || ''}</em>
				</MenuItem>
				{children}
			</Select>
			{error[name] && <FormHelperText error>{t('general:required_message')}</FormHelperText>}
		</Grid>
	);
}

export function CustomSelectV2(props: CustomSelectProps) {
	const { name, header, subtitle, placeholder, children, error, sx, ...rest } = props;
	return (
		<Grid item xs={12}>
			<Typography variant="h3">{header || ''}</Typography>
			<Typography variant="body1" color="textSecondary">
				{subtitle || ''}
			</Typography>
			<br />
			<FormControlStandard
				name={name}
				header={header}
				placeholder={placeholder}
				error={error}
				sx={sx}
				children={children}
				rest={rest}
			/>
		</Grid>
	);
}

export function CustomSelectV3(props: CustomSelectProps) {
	const { name, header, placeholder, children, error, sx, ...rest } = props;
	return (
		<Grid item xs={12}>
			<FormControlStandard
				name={name}
				header={header}
				placeholder={placeholder}
				error={error}
				sx={sx}
				children={children}
				rest={rest}
			/>
		</Grid>
	);
}

const FormControlStandard = (props) => {
	const { t } = useTranslation();
	const { name, header, placeholder, children, error, sx, ...rest } = props;
	return (
		<FormControl variant="outlined" sx={sx}>
			<InputLabel id={header + 'label'}>{header}</InputLabel>
			<Select
				variant="outlined"
				error={error[name]}
				sx={{ color: error[name] ? '#ff333a' : undefined }}
				labelId={header + 'label'}
				label={header}
				{...rest}
			>
				<MenuItem disabled value="">
					<em>{placeholder || ''}</em>
				</MenuItem>
				{children}
			</Select>
			{error[name] && <FormHelperText error>{t('general:required_message')}</FormHelperText>}
		</FormControl>
	);
};
