import * as React from 'react';
import { OverviewApp } from './OverviewSection';
import { AdoptionSection } from './AdoptionSection';
import makeStyles from '@mui/styles/makeStyles';
import { ChartSection } from './Chartsection';
import { GeographicalSection } from './GeographicalSection';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
		overflow: 'auto'
	},
	section: {
		margin: theme.spacing(0, 0, 4)
	}
}));

export function DashboardApp() {
	const classes = useStyles({});
	return (
		<div className={classes.root}>
			<div className={classes.section}>
				<OverviewApp />
			</div>
			<div className={classes.section}>
				<AdoptionSection />
			</div>
			<div className={classes.section}>
				<ChartSection />
			</div>
			<div className={classes.section}>
				<GeographicalSection />
			</div>
		</div>
	);
}
