import { Badge, Button, Tooltip, Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Blockquote } from '../../components/Blockquote';
import { LoadingScreen } from '../../components/LoadingScreen';
import { State } from '../reducers';
import { AcceptingDialog } from './AcceptingDialog';
import {
	openAddDialog,
	loadMoreProperties,
	applySearch,
	openAcceptAllDialog,
	loadProperties
} from './actions';
import { PropertyEditDialog } from './EditDialog';
import { RejectingDialog } from './RejectingDialog';
import { AddDialog } from './AddDialog';
import { AcceptAllDialog } from './AcceptAllDialog';
import { PropertiesTable } from './Table';
import { usePermission } from '../acl/permissions';
import { OwnerExclusivePermission } from '../../../server/permissions/permission';
import { i18n } from '../../i18n';
import { Link } from 'react-router-dom';
import {
	useFeature,
	Feature,
	useAccountSpecificFeature,
	AccountSpecificFeature
} from '../acl/features';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchBar as PropertySearchBar } from '../../components/SearchBar';
import { useTranslation } from 'react-i18next';
import { NoResultsForSearchScreen } from '../../components/NoResultsForSearchScreen';
import { InfiniteLoaderEndMessage } from '../../components/InfiniteLoaderEndMessage';

const useStyles = makeStyles((theme) => ({
	buttonBar: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: theme.spacing(1)
	},
	margin: {
		margin: theme.spacing(0, 4)
	},
	link: {
		textDecoration: 'none'
	},
	blockquote: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		width: 'auto',
		alignSelf: 'stretch',
		fontSize: '0.875rem'
	},
	fab: {
		position: 'sticky',
		left: '100%',
		bottom: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(3),
		minHeight: '56px'
	},
	flex: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	outer: {
		overflowY: 'hidden',
		overflowX: 'hidden',
		width: '100%',
		height: '100%'
	},
	outerTable: {
		overflowY: 'auto',
		width: '100%',
		padding: theme.spacing(1, 2)
	},
	outerSearchBar: {
		margin: theme.spacing(1, 1, 0),
		overflow: 'visible',
		zIndex: 1
	}
}));

export function PropertiesList(props: {}) {
	const { data, maxAcceptedCount, currentAcceptedCount, hasMore, isFetching } = useSelector(
		(state: State) => ({
			data: state.properties?.list?.data,
			maxAcceptedCount: state.properties?.list?.metaData?.maxAcceptedCount,
			currentAcceptedCount: state.properties?.list?.metaData?.currentAcceptedCount,
			hasMore: state.properties?.list?.metaData?.hasMore,
			isFetching: state.properties?.list?.metaData?.isFetching
		})
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(loadProperties());
	}, []);
	const loadMore = () => dispatch(loadMoreProperties());
	const classes = useStyles(props);
	const hasAccountView = usePermission(OwnerExclusivePermission.VIEW_ACCOUNT);
	const hasChargebeeCheckout = useFeature(Feature.ChargebeeCheckout);
	const [searchInput, setSearchInput] = useState('');
	const { t } = useTranslation();
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);

	const UpgradeElement = hasAccountView ? (
		<Link to="/app/settings/subscription" className={classes.link}>
			<Button variant="contained" color="primary">
				{i18n.t('admin:licenses_upgrade_button')}
			</Button>
		</Link>
	) : (
		<Tooltip title={i18n.t('properties:contact_account_owner')}>
			<span>
				<Button variant="contained" color="primary" disabled>
					{i18n.t('admin:licenses_upgrade_button')}
				</Button>
			</span>
		</Tooltip>
	);

	return (
		<div className={classes.outer}>
			<div className={classes.flex}>
				<div className={classes.outerSearchBar}>
					{!hasPricingModelV2 && (
						<Blockquote className={classes.blockquote}>
							<span>
								<Trans
									i18nKey="properties:licenses_blockquote"
									values={{
										maxAcceptedCount
									}}
								/>
								<Badge
									className={classes.margin}
									color={currentAcceptedCount >= maxAcceptedCount ? 'error' : 'primary'}
									badgeContent={`${currentAcceptedCount}/${
										maxAcceptedCount > 0 ? '*' : maxAcceptedCount
									}`}
								/>
							</span>
							{hasChargebeeCheckout && UpgradeElement}
						</Blockquote>
					)}
					<PropertySearchBar
						searchInput={searchInput}
						setSearchInput={setSearchInput}
						applySearch={applySearch}
						placeholder={'properties:search_placeholder'}
					/>
					<div className={classes.buttonBar}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => dispatch(openAcceptAllDialog())}
						>
							<Trans i18nKey="properties:action_button_accept_all" />
						</Button>
					</div>
				</div>
				{data && data.length ? (
					<div className={classes.outerTable} id="properties-list-scroll-container">
						<InfiniteScroll
							next={loadMore}
							hasMore={hasMore}
							loader={<LoadingScreen />}
							dataLength={data.length}
							scrollableTarget="properties-list-scroll-container"
							endMessage={<InfiniteLoaderEndMessage itemName="properties" />}
						>
							<PropertiesTable />
							{isFetching && <LoadingScreen />}
							<AcceptingDialog />
							<AcceptAllDialog />
							<RejectingDialog />
							<PropertyEditDialog />
						</InfiniteScroll>
					</div>
				) : data ? (
					<NoResultsForSearchScreen
						message={
							searchInput
								? t('properties:nothing_found_on_search')
								: t('properties:nothing_available_for_this_account')
						}
					/>
				) : (
					<LoadingScreen />
				)}
				<AddDialog />
			</div>
			<Fab color="primary" onClick={() => dispatch(openAddDialog())} className={classes.fab}>
				<AddIcon />
			</Fab>
		</div>
	);
}
