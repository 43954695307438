import { Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	breakWord: {
		wordBreak: 'break-word',
		hyphens: 'manual',
		whiteSpace: 'pre-line'
	}
}));

interface DetailFieldProps {
	name: string;
	content: string;
}

export const DetailField = ({ name, content }: DetailFieldProps) => {
	const classes = useStyles();
	const checkContent = isDateString(content);
	if (checkContent && !DateTime.fromISO(content).invalid) {
		content = createDateTimeContent(content);
	}
	return (
		<Grid container className={classes.root} spacing={1}>
			<Grid item sm={3}>
				<Typography className={classes.breakWord} variant="body2">
					{name}
				</Typography>
			</Grid>
			<Grid item sm={9}>
				<Typography className={classes.breakWord} variant="body2">
					{content}
				</Typography>
			</Grid>
		</Grid>
	);
};

function createDateTimeContent(content: string): string {
	content = DateTime.fromISO(content).toFormat('dd LLL yyyy (H:mm)');
	if (content.endsWith('(0:00)')) {
		content = content.slice(0, content.length - 6);
	}
	return content;
}

function isDateString(content) {
	return (
		(typeof content === 'string' || content instanceof String) &&
		!!content.match(
			'^(?:[1-9]\\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)T(?:[01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d(?:\\.\\d{1,9})?(?:Z|[+-][01]\\d:[0-5]\\d)$'
		)
	);
}
