import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { RouteLeavingGuard } from '../../components/RouteLeavingGuard';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import { submitEmailSettings } from './actions';
import { Permission } from '../../../server/permissions/permission';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router';
import Page from '../../mui-custom/Page/Page';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';
import { Headline3 } from '../../mui-custom/Headline3';

const emailTopics = [
	Permission.NEW_ORDER_EMAIL,
	Permission.REMINDER_ORDER_EMAIL,
	Permission.REMINDER_OFFER_REQUEST_EMAIL,
	Permission.CHANGED_ORDER_EMAIL,
	Permission.CHANGED_INTERNAL_ORDER_EMAIL
];

export function Email() {
	const { permissions } = useSelector((state: State) => state.auth.user);
	const maxActiveUsers = useSelector((state: State) => state.auth.account?.maxActiveUsers ?? 0);
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [selectedPermissions, setSelectedPermissions] = useState({ ...permissions });

	const [receiveEmails, setReceiveEmails] = useState(emailTopics.some((p) => permissions[p]));
	const [allTopic, setAllTopic] = useState(!emailTopics.some((p) => !permissions[p]));
	const [isEditing, setIsEditing] = useState(false);

	const handleAllTopicsChange = (e) => {
		setAllTopic(e.target.checked);
		const newPermissions = {};
		emailTopics.forEach((p) => (newPermissions[p] = true));
		setSelectedPermissions({ ...selectedPermissions, ...newPermissions });
	};

	const handleCheckboxChange = (e) => {
		const newPermissions = { ...selectedPermissions, [e.target.value]: e.target.checked };
		setAllTopic(!emailTopics.some((p) => !newPermissions[p]));
		setSelectedPermissions(newPermissions);
	};

	const handleSubmit = () => {
		const changedPermissions = {};
		if (!receiveEmails) {
			emailTopics.forEach((permission) => {
				changedPermissions[permission] = false;
			});
		} else if (allTopic) {
			emailTopics.forEach((permission) => {
				changedPermissions[permission] = true;
			});
		}
		const newPermissions = { ...selectedPermissions, ...changedPermissions };
		setSelectedPermissions(newPermissions);
		dispatch(submitEmailSettings(newPermissions));
		setIsEditing(false);
	};

	const SwitchLabel = (value: Permission, label = '') => {
		const help = label + '_help';
		return (
			<Stack>
				<FormControlLabel
					control={
						<Switch
							value={value}
							checked={receiveEmails && selectedPermissions[value]}
							onChange={handleCheckboxChange}
							color="primary"
							disabled={!receiveEmails}
						/>
					}
					sx={{ justifyContent: 'space-between', m: 0 }}
					labelPlacement="start"
					label={<Typography variant="body1">{t(label)}</Typography>}
				/>
				{i18n.exists(help) && (
					<Typography variant="caption" color="text.secondary">
						{t(help)}
					</Typography>
				)}
			</Stack>
		);
	};

	return (
		<ValidatorForm onSubmit={handleSubmit} onFocus={() => setIsEditing(true)}>
			<Page
				actionBar={
					isEditing && (
						<ActionBar
							primary={{
								children: t('general:cta_save_edit'),
								type: 'submit'
							}}
							secondary={{
								children: t('general:cta_discard'),
								onClick: () => {
									setIsEditing(false);
									setSelectedPermissions(permissions);
									setReceiveEmails(emailTopics.some((p) => permissions[p]));
								}
							}}
						/>
					)
				}
				headline={t('general:email_notifications')}
				text={t('profile:email_notifications_subtitle')}
			>
				<FormControlLabel
					control={
						<Switch
							value={receiveEmails}
							checked={receiveEmails}
							onChange={(e) => setReceiveEmails(e.target.checked)}
							color="primary"
						/>
					}
					sx={{ justifyContent: 'space-between', m: 0 }}
					labelPlacement="start"
					label={t('profile:email_settings_receive_emails')}
				/>
				<FormControlLabel
					control={
						<Switch
							value={Permission.ONLY_ASSIGNED_ORDER_EMAIL}
							checked={receiveEmails && selectedPermissions[Permission.ONLY_ASSIGNED_ORDER_EMAIL]}
							onChange={handleCheckboxChange}
							color="primary"
							disabled={!receiveEmails}
						/>
					}
					sx={{ justifyContent: 'space-between', m: 0 }}
					labelPlacement="start"
					label={t('profile:email_settings_receive_emails_for_assigned_orders')}
				/>

				<Headline3>{t('profile:email_settings_receive_emails_topic')}</Headline3>
				{t('profile:email_settings_receive_emails_topic_subtitle')}

				<FormControlLabel
					control={
						<Switch
							value={allTopic}
							checked={allTopic}
							onChange={handleAllTopicsChange}
							color="primary"
							disabled={!receiveEmails}
						/>
					}
					sx={{ justifyContent: 'space-between', m: 0 }}
					labelPlacement="start"
					label={t('profile:email_settings_receive_emails_topic_receive_all')}
				/>

				{SwitchLabel(Permission.NEW_ORDER_EMAIL, 'profile:email_settings_new_order_email')}
				{SwitchLabel(
					Permission.REMINDER_ORDER_EMAIL,
					'profile:email_settings_reminder_order_email'
				)}
				{SwitchLabel(
					Permission.REMINDER_OFFER_REQUEST_EMAIL,
					'profile:email_settings_reminder_open_offer_request_email'
				)}
				{SwitchLabel(Permission.CHANGED_ORDER_EMAIL, 'profile:email_settings_changed_order_email')}
				{maxActiveUsers > 1 &&
					SwitchLabel(
						Permission.CHANGED_INTERNAL_ORDER_EMAIL,
						'profile:email_settings_changed_internal_order_email'
					)}
			</Page>
			<RouteLeavingGuard
				navigate={(path) => history.push(path)}
				shouldBlockNavigation={(_location) => isEditing}
				title={t('profile:discard_changes')}
				confirmButtonText={t('general:cta_discard_edit')}
			/>
		</ValidatorForm>
	);
}
