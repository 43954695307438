import { Grid } from '@mui/material';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../core/reducers';
import { loadUserStatistics, loadCustomerStatistics } from './actions';
import { DashboardTile } from './Tile';
import { LoadingScreen } from '../../components/LoadingScreen';
import { DashboardHeader } from './Header';

export function OverviewApp() {
	const { users, customers, registration } = useSelector(
		(state: State) => state.sysadmin.dashboard
	);
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(loadUserStatistics());
		dispatch(loadCustomerStatistics());
	}, []);

	return (
		<div>
			<DashboardHeader>
				<Trans i18nKey="sysadmin:dashboard_overview_header" />
			</DashboardHeader>
			<Grid container spacing={2}>
				<DashboardTile
					header={<Trans i18nKey="sysadmin:dashboard_overview_widget_accounts" />}
					content={registration ? String(registration.registrationCount) : <LoadingScreen />}
				/>
				<DashboardTile
					header={<Trans i18nKey="sysadmin:dashboard_overview_widget_users" />}
					content={users ? String(users.totalUsers) : <LoadingScreen />}
				/>
				<DashboardTile
					header={<Trans i18nKey="sysadmin:dashboard_overview_widget_average_users_per_account" />}
					content={users ? (users.averageUsers || 0).toFixed(2) : <LoadingScreen />}
				/>
				<DashboardTile
					header={
						<Trans i18nKey="sysadmin:dashboard_overview_widget_average_customers_per_account" />
					}
					content={customers ? (customers.averageCustomers || 0).toFixed(2) : <LoadingScreen />}
				/>
			</Grid>
		</div>
	);
}
