import React from 'react';
import { Card, Stack } from '@mui/material';
import PrimaryButton, { PrimaryButtonProps } from '../Button/PrimaryButton';
import SecondaryButton, { ButtonColor, SecondaryButtonProps } from '../Button/SecondaryButton';

export interface ActionBarProps {
	primary?: PrimaryButtonProps | SecondaryButtonProps;
	primaryAsSecondary?: boolean;
	secondary?: SecondaryButtonProps;
	alternate?: SecondaryButtonProps;
	children?: React.ReactChild;
	sx?: object;
	[x: string]: unknown;
}

const ActionBar = function ActionBar({
	primary,
	primaryAsSecondary = false,
	secondary,
	alternate,
	children,
	sx,
	...rest
}: ActionBarProps) {
	return (
		<Card sx={{ p: 2, borderWidth: 0, ...sx }} {...rest}>
			<Stack
				direction="row-reverse"
				justifyContent="space-between"
				rowGap={2}
				columnGap={5}
				flexWrap={'wrap'}
				sx={{
					width: '100%'
				}}
			>
				<Stack direction="row-reverse" spacing={3}>
					{!!primary &&
						(!primaryAsSecondary ? (
							<PrimaryButton {...primary} />
						) : (
							<SecondaryButton
								{...{
									color: ButtonColor.Creative,
									...primary
								}}
							/>
						))}
					{!!secondary && <SecondaryButton {...secondary} />}
				</Stack>
				<Stack flexGrow={1} direction="row" spacing={3}>
					{!!alternate && (
						<SecondaryButton
							{...{
								color: ButtonColor.Creative,
								...alternate
							}}
						/>
					)}
					<Stack sx={{ alignSelf: 'center' }}>{children}</Stack>
				</Stack>
			</Stack>
		</Card>
	);
};

export type ActionBar = typeof ActionBar;

// eslint-disable-next-line import/no-default-export
export default ActionBar;
