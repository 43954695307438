import { DocumentPostBody } from '@api/v1';
import { DefaultOptionType } from '../../components/AsyncSelect';
import {
	createAddFileAction,
	createChangeFileAction,
	createRemoveFileAction
} from '../../components/Dropzone';
import { api } from '../../lib/api';
import { createConstants } from '../../lib/createConstants';
import { State } from '../reducers';
import { createAction, createStandardAction } from 'typesafe-actions';
import { createApiAction } from '../../redux/createApiAction';
import { createAsyncConstants } from '../../lib/createConstants';
import { showNotification } from '../actions';
import { i18n } from '../../../app/i18n';
import { debounce } from 'lodash';

export const constants = createConstants(
	[
		'ADD_FILE_TO_CREATION_DIALOG',
		'REMOVE_FILE_FROM_CREATION_DIALOG',
		'CHANGE_FILE_IN_CREATION_DIALOG',
		'SET_IS_FETCHING'
	],
	{
		prefix: 'DOCUMENTS'
	}
);

export const asyncConstants = createAsyncConstants(['FETCH', 'FETCH_MORE'], {
	prefix: 'DOCUMENTS'
});

export const fetchDocuments = () => (dispatch, getState: () => State) => {
	return dispatch({
		type: asyncConstants.FETCH.TYPE,
		payload: api.get('/api/v1/documents')
	});
};

export const fetchMoreDocuments = () => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_IS_FETCHING
	});
	return dispatch(debounce(appendDocumentsToList(), 1000));
};

const appendDocumentsToList = () => (dispatch, getState: () => State) => {
	const metaData = getState().documents?.list?.metaData;
	let lastId: number;
	if (metaData) {
		lastId = metaData.lastId;
	}
	return dispatch({
		type: asyncConstants.FETCH_MORE.TYPE,
		payload: api.get('/api/v1/documents', { params: { afterId: lastId } })
	});
};

export const openCreationDialog = createAction('documents/open_creation_dialog');
export const closeCreationDialog = createAction('documents/close_creation_dialog');
export const addFileToCreationDialog = createAddFileAction(constants.ADD_FILE_TO_CREATION_DIALOG);
export const removeFileFromCreationDialog = createRemoveFileAction(
	constants.REMOVE_FILE_FROM_CREATION_DIALOG
);
export const changeFileInCreationDialog = createChangeFileAction(
	constants.CHANGE_FILE_IN_CREATION_DIALOG
);
export const changeTitle = createAction('documents/change_title', (action) => (payload: string) =>
	action(payload)
);
export const changeDescription = createStandardAction('documents/change_description')<string>();
export const changeProperty = createStandardAction('documents/change_property')<
	DefaultOptionType
>();
export const createDocument = createApiAction(
	'documents/create',
	async (a: void, dispatch, getState: () => State) => {
		try {
			const newDocument = await api.post<DocumentPostBody, number>('/api/v1/documents', {
				document: {
					title: getState().documents.creationDialog.title,
					propertyId: getState().documents.creationDialog.property.key
				},
				attachments: getState().documents.creationDialog.files.map((file) => ({
					name: file.fileName,
					type: file.fileType,
					size: 0,
					s3Key: file.s3Key,
					thumbnailS3Key: file.thumbnailS3Key
				}))
			});
			dispatch(showNotification(i18n.t('documents:success_message'), 'success'));
			dispatch(fetchDocuments());
			return newDocument;
		} catch (err) {
			dispatch(showNotification(i18n.t('general:default_error'), 'error'));
			throw err;
		}
	}
);
