import { UIDocument } from '@api/v1';
import { Card, CardHeader, CardContent, Hidden, Typography, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AttachmentIcon from '@mui/icons-material/Attachment';
import * as React from 'react';
import { Avatar } from '../../components/Avatar';
import { dateTime } from '../../lib/dateHelper';
import { useTranslation } from 'react-i18next';
import { getFirstLetterOfCompanyForIcon } from '../../lib/nameHelper';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2)
	},
	content: {
		display: 'flex'
	},
	title: {
		flex: '1 1 100%'
	}
}));

export const DocumentListItem: React.FC<{ item: UIDocument }> = ({ item: document }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { id, title, customer, property, createdAt, attachmentCount } = document;
	return (
		<Card className={classes.root}>
			<CardHeader
				title={customer.name}
				subheader={property?.name || '\u200A'}
				avatar={<Avatar>{getFirstLetterOfCompanyForIcon(customer)}</Avatar>}
				action={
					<Hidden smDown>
						<Typography variant="body2">{dateTime(createdAt).toFormat('T')}</Typography>
					</Hidden>
				}
			/>
			<CardContent className={classes.content}>
				<Typography className={classes.title}>
					{t('documents:list_item_content', { id, title })}
				</Typography>
				<Chip icon={<AttachmentIcon />} label={String(attachmentCount)} variant="outlined" />
			</CardContent>
		</Card>
	);
};
