import {
	BConfirmEmail,
	BConfirmUnsubscription,
	BLogin,
	BPasswordChoose,
	BPasswordReset,
	UIUser,
	UINotification
} from '@api/v1';
import qs from 'qs';
import { i18n } from '../../i18n';
import { api } from '../../lib/api';
import { createConstants } from '../../lib/createConstants';
import { intercomIdentify } from '../../lib/intercom';
import { Callback } from '../../lib/types';
import { showNotification } from '../actions';
import { history } from '../history';
import { State } from '../reducers';
import { closeSocket, connectSocket } from '../socket';
import moment from 'moment-with-locales-es6';
import { initializePendo } from '../../lib/initializePendo';
import { all, select, take } from 'redux-saga/effects';
import { constants as OrderConstants } from '../orders/actions';
import { asyncConstants as CustomerConstants } from '../customers/actions';
import { asyncConstants as PropertiesConstants } from '../properties/actions';

export const constants = createConstants([
	'LOGIN',
	'LOGOUT',
	'GET_USER',
	'RESET_PASSWORD',
	'CHOOSE_PASSWORD',
	'GET_ACCOUNT',
	'PATCH_USER',
	'PATCH_ACCOUNT',
	'SET_PASSWORD',
	'SET_PASSWORD_CONFIRM',
	'CONFIRM_EMAIL_ADDRESS',
	'CONFIRM_UNSUBSCRIPTION',
	'SET_AUTH_EMAIL_ADDRESS',
	'SET_EMAIL_CONFIRMED',
	'CHECK_URL_ON_CHOOSE_PASSWORD_PAGE',
	'GET_NOTIFICATIONS',
	'REDEEM_COUPON'
]);

export const setPassword = (password: string) => ({
	type: constants.SET_PASSWORD,
	payload: password
});
export const setPasswordConfirm = (passwordConfirm: string) => ({
	type: constants.SET_PASSWORD_CONFIRM,
	payload: passwordConfirm
});
export const setAuthEmailAddress = (emailAddress: string) => ({
	type: constants.SET_AUTH_EMAIL_ADDRESS,
	payload: emailAddress
});
export const setEmailConfirmed = (emailConfirmed: boolean) => ({
	type: constants.SET_EMAIL_CONFIRMED,
	payload: emailConfirmed
});
export const confirmEmailAddress = (token: string) => (dispatch) =>
	dispatch({
		type: constants.CONFIRM_EMAIL_ADDRESS,
		payload: api
			.post<BConfirmEmail>('/api/v1/registration/actions/confirm_email_address', { token })
			.then((data) => {
				dispatch(showNotification(i18n.t('registration:email_confirmed'), 'success'));
				history.replace('/app');
				return data;
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
				throw err;
			})
	});
export const confirmUnsubscription = (email: string) => (dispatch) =>
	dispatch({
		type: constants.CONFIRM_UNSUBSCRIPTION,
		payload: api
			.post<BConfirmUnsubscription>('/api/v1/user/unsubscribe', { email })
			.then((data) => {
				dispatch(showNotification(i18n.t('general:unsubscription_confirmed'), 'success'));
				return data;
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
				throw err;
			})
	});
export const login = (body: BLogin) => (dispatch) =>
	dispatch({
		type: constants.LOGIN,
		payload: api
			.post<BLogin>('/api/v1/auth/login', body)
			.then((data) => {
				window.localStorage.setItem('jwt', data.token);
				const historyState = history.location.state;
				if (historyState && historyState.nextPath) {
					history.push(historyState.nextPath, {});
				} else {
					history.push('/app');
				}
				handleLogin(data.user, dispatch);
				return data.user;
			})
			.catch((err) => {
				if (
					err.response?.status === 429 ||
					err.status === 429 ||
					err.message === 'Status code: 429'
				) {
					dispatch(
						showNotification(
							i18n.t('general:login_failed_too_many_requests', {
								minutes: 1 + moment(err.response.data.resetAt).diff(moment(), 'minutes')
							}),
							'error'
						)
					);
				} else {
					dispatch(
						showNotification(
							err.response.data.remainingAttempts
								? i18n.t('general:login_failed', {
										remainingAttempts: err?.response?.data?.remainingAttempts
								  })
								: i18n.t('general:login_failed_too_many_requests', {
										minutes: 1 + moment(err?.response?.data?.resetAt).diff(moment(), 'minutes')
								  }),
							'error'
						)
					);
				}
				throw err;
			})
	});
export const logout =
	(callback: Callback = () => null) =>
	(dispatch) =>
		dispatch({
			type: constants.LOGOUT,
			payload: api
				.post('/api/v1/auth/logout')
				.then(() => {
					window.localStorage.removeItem('jwt');
					window.localStorage.removeItem('sysadmin_logged_in_as_user');
					window.location.href = '/login';
					handleLogout(dispatch);
					return callback;
				})
				.catch(callback)
		});
export const checkToken =
	(callback: Callback = () => null) =>
	(dispatch) =>
		dispatch({
			type: constants.GET_USER,
			payload: api
				.get<UIUser>('/api/v1/user')
				.then((data) => {
					if (!window.localStorage.getItem('jwt')) {
						throw new Error('Jwt does not exist');
					}
					handleLogin(data, dispatch);
					callback(null, data);
					return data;
				})
				.catch((err) => {
					callback(err);
					throw err;
				})
		});
export const getNotifications =
	(callback: Callback = () => null) =>
	(dispatch) =>
		dispatch({
			type: constants.GET_NOTIFICATIONS,
			payload: api
				.get<UINotification[]>('/api/v1/notifications', { params: { limit: 10 } })
				.then((notifications) => {
					callback(null, notifications);
					return notifications;
				})
				.catch((err) => {
					callback(err);
					throw err;
				})
		});
export const getAccount =
	(_callback: Callback = () => null) =>
	(dispatch) =>
		dispatch({
			type: constants.GET_ACCOUNT,
			payload: api
				.get('/api/v1/account')
				.then((data) => {
					return data;
				})
				.catch((err) => {
					throw err;
				})
		});

export function* loadStatisticsAndIninitalizePendoSaga() {
	yield all([
		take(constants.GET_USER + '_FULFILLED'),
		take(constants.GET_ACCOUNT + '_FULFILLED'),
		take(OrderConstants.FETCH_ORDER_STATISTICS + '_FULFILLED'),
		take(CustomerConstants.FETCH_STATISTICS.FULFILLED),
		take(PropertiesConstants.FETCH_STATISTICS.FULFILLED)
	]);
	const state = yield select();
	initializePendo(
		state.auth.user,
		state.auth.account,
		state.orders.statistics,
		state.customers.statistics,
		state.properties.statistics
	);
}

export const resetPassword = (body: BPasswordReset, callback: Callback) => (dispatch) => ({
	type: constants.RESET_PASSWORD,
	payload: api
		.post<BPasswordReset>('/api/v1/auth/reset_password', body)
		.then((data) => {
			callback(null, data);
			return data;
		})
		.catch((err) => {
			callback(err);
			dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
			throw err;
		})
});
export const choosePassword = () => (dispatch, getState: () => State) => ({
	type: constants.CHOOSE_PASSWORD,
	payload: api
		.post<BPasswordChoose>('/api/v1/auth/choose_password', {
			password: getState().auth.choosePassword.password,
			token: getState().auth.choosePassword.token
		})
		.then((data) => {
			window.localStorage.setItem('jwt', data.token);
			history.push('/app');
			handleLogin(data.user, dispatch);
			window.location.reload();
			return data;
		})
		.catch((err) => {
			if (err.response.status === 403) {
				dispatch(showNotification(i18n.t('notifications.reset_password_invalid_token'), 'error'));
			} else {
				dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
			}
			throw err;
		})
});
export const checkUrlOnChoosePasswordPage = (query: string) => {
	const queryContent = qs.parse(query, {
		ignoreQueryPrefix: true
	});
	return {
		type: constants.CHECK_URL_ON_CHOOSE_PASSWORD_PAGE,
		payload: {
			token: queryContent.token || null,
			fromActivation: queryContent.from_activation === 'true',
			accountName: queryContent.account_name || null,
			emailAddress: queryContent.email_address || null
		}
	};
};

export const redeemCoupon = (couponCode: string) => (dispatch, getState: () => State) => {
	return dispatch({
		type: constants.REDEEM_COUPON,
		payload: api
			.post('/api/v1/chargebee/redeem_coupon', { couponCode })
			.then((data) => {
				dispatch(showNotification('Coupon wurde erfolgreich eingelöst.', 'success'));
				return data;
			})
			.catch((err) => {
				dispatch(showNotification('Coupon konnte nicht eingelöst werden.', 'error'));
				throw err;
			})
	});
};

async function handleLogin(user: UIUser, dispatch) {
	dispatch(connectSocket());
	await intercomIdentify();
	/*
	 * We have to save this globally because our tracking system uses redux
	 * and we cannot retrieve the state from a reducer.
	 */
	(window as any).account = user
		? {
				id: user.accountId,
				usage: user.accountUsage
		  }
		: null;
}
function handleLogout(dispatch) {
	dispatch(closeSocket());
	(window as any).account = null;
}
