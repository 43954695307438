import React from 'react';
import { Typography } from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';

export interface Headline3Props {
	variant?: '' | 'isMobile' | 'isDesktop'; // option for overriding breakpoint changes
	children?: React.ReactNode;
	[key: string]: unknown;
}

export function Headline3({ variant, children, ...rest }: Headline3Props) {
	const isMobile = useMobile();
	const mobileMode = variant === 'isMobile' || isMobile;
	return (
		<Typography variant={mobileMode ? 'h5' : 'h3'} {...rest}>
			{children}
		</Typography>
	);
}

export type Headline3 = ReturnType<typeof Headline3>;
