import { FormControlProps } from '@mui/material/FormControl';
import { registerLocale, isValid, getNames } from 'i18n-iso-countries';
import * as de from 'i18n-iso-countries/langs/de.json';
import * as React from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { SelectValidator } from './SelectValidator';

export const supportedCountryCodes = ['DE', 'AT', 'CH', 'LI', 'BE', 'IT', 'LU'];

registerLocale(de);

ValidatorForm.addValidationRule('country_iso_code', (value) => {
	return isValid(value);
});

interface CountrySelectProps {
	name: string;
	value: string;
	onChange: (value: string) => void;
	validators: string[];
	errorMessages: string[];
	label: React.ReactNode;
	disabled?: boolean;
	fullWidth?: FormControlProps['fullWidth'];
	margin?: FormControlProps['margin'];
	className?: string;
}

export const CountrySelect = ({
	name,
	value,
	onChange,
	validators,
	errorMessages,
	label,
	disabled,
	fullWidth,
	margin,
	className
}: CountrySelectProps) => {
	return (
		<SelectValidator
			name={name}
			label={label}
			value={value}
			disabled={disabled}
			onChange={(e) => onChange(e.currentTarget.value)}
			options={[{ value: null, label: '' }, ...getAvailableCountries()]}
			validators={validators}
			errorMessages={errorMessages}
			fullWidth={fullWidth}
			margin={margin}
			className={className}
		/>
	);
};

function getAvailableCountries() {
	const countryNames = getNames('de');
	return (
		Object.keys(countryNames)
			// eslint-disable-next-line @typescript-eslint/prefer-includes
			.filter((value) => supportedCountryCodes.indexOf(value) !== -1)
			.map((value) => ({
				value,
				label: countryNames[value]
			}))
	);
}
