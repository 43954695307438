import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Prompt } from 'react-router-dom';
interface Props {
	when?: boolean | undefined;
	navigate: (path: string) => void;
	shouldBlockNavigation: (location: Location) => boolean;
	title?: string;
	content?: string;
	confirmButtonText: string;
}

const useStyles = makeStyles((theme) => ({
	dialogContainer: {
		textAlign: 'center'
	}
}));

export const RouteLeavingGuard = ({
	when,
	navigate,
	shouldBlockNavigation,
	title,
	content,
	confirmButtonText
}: Props) => {
	const [modalVisible, setModalVisible] = useState(false);
	const [lastLocation, setLastLocation] = useState<Location | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const closeModal = () => {
		setModalVisible(false);
	};
	const classes = useStyles({});

	const handleBlockedNavigation = (nextLocation: Location): boolean => {
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
			setModalVisible(true);
			setLastLocation(nextLocation);
			return false;
		}
		return true;
	};
	const handleConfirmNavigationClick = () => {
		setModalVisible(false);
		setConfirmedNavigation(true);
	};
	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			// Navigate to the previous blocked location with your navigate function
			navigate(lastLocation.pathname);
		}
	}, [confirmedNavigation, lastLocation]);
	return (
		<>
			<Prompt when={when} message={handleBlockedNavigation} />
			<Dialog open={modalVisible} maxWidth="xs" className={classes.dialogContainer}>
				<br />
				{title && <DialogTitle>{title}</DialogTitle>}
				<br />
				{content && <DialogContent>{content}</DialogContent>}
				<DialogActions>
					<Button onClick={closeModal}>
						<Typography color="textSecondary" variant="subtitle2">
							<Trans i18nKey="general:cta_go_back" />
						</Typography>
					</Button>
					<Button color="secondary" variant="contained" onClick={handleConfirmNavigationClick}>
						{confirmButtonText}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
