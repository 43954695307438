import { createAsyncConstants } from '../../lib/createConstants';
import { api } from '../api';

const asyncConstants = createAsyncConstants(['GET_QUEUES_COOKIE'], {
	prefix: 'SYSADMIN'
});

export const getQueuesCookie = () => ({
	type: asyncConstants.GET_QUEUES_COOKIE.TYPE,
	payload: api
		.get('/api/sysadmin/queues/auth')
		.then(() => window.open(`${window.location.origin}/sysadmin/app/queues`, '_blank'))
});
