import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { State } from '../../reducers';
import { Check, Print, Close, LocationOn, Person, DoDisturbAlt } from '@mui/icons-material';
import {
	Box,
	Button,
	ButtonBase,
	Card,
	CardActions,
	CardContent,
	Chip,
	Divider,
	Grid,
	Theme,
	Tooltip,
	Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { getItemChipLabel, scrollToElementWithOffset } from '../helpers';
import { IconicText } from '../../../components/IconicText';
import {
	getFirstLetterOfCompanyForIcon,
	getInitialsForPerson,
	getName
} from '../../../lib/nameHelper';
import { Avatar } from '../../../components/Avatar';
import { getAddress, getBillingAddress, getMapAddress } from '../../../lib/address';
import { HoverBase } from '../../../components/HoverBase';
import { EmployeeSearch } from '../../../components/EmployeeSearch';
import { dateTime } from '../../../lib/dateHelper';
import { CommentContainer } from './CommentContainer';
import { CommentCompose } from './CommentCompose';
import { UIEmployee } from '@api/v1';

import { AttachmentList, AttachmentThumbnail } from '../../../components/Attachment';
import {
	assignOfferRequest,
	fetchSingleOfferRequest,
	removeAllSubmitDialogFiles
} from '../actions';
import { DeclineDialog } from './DeclineDialog';
import { SubmitDialog } from './SubmitDialog';
import { OfferRequestCloseComment } from './Comment';
import { OfferCard } from './OfferCard';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		'@media print': {
			display: 'block'
		}
	},
	scrollContainer: {
		overflowY: 'auto'
	},
	details: {
		margin: theme.spacing(1),
		overflow: 'unset',
		'@media print': {
			margin: 0,
			boxShadow: 'none',
			pageBreakAfter: 'always'
		},
		paddingBottom: theme.spacing(1)
	},
	compose: {
		flex: '0 0 auto',
		margin: theme.spacing(1)
	},
	hideOnPrint: {},
	'@media print': {
		details: {
			visibility: 'visible'
		},
		hideOnPrint: {
			display: 'none'
		}
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`
	},
	headerButton: {
		padding: theme.spacing(1),
		marginLeft: 'auto'
	},
	link: {
		color: 'inherit'
	},
	statusBar: {
		height: theme.spacing(1),
		border: 0,
		margin: 0,
		background: theme.palette.grey[300]
	},
	iconizedDetails: {
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5)
	},
	cardActions: {
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		'@media print': {
			visibility: 'hidden'
		},
		marginBottom: theme.spacing(1)
	},
	breakWord: {
		wordBreak: 'break-word',
		hyphens: 'auto',
		whiteSpace: 'pre-line'
	},
	detailField: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	inactiveDeclineButtonContainer: {
		textAlign: 'right',
		height: theme.spacing(5)
	},
	doDisturbIcon: {
		marginLeft: '10px',
		marginRight: '5px',
		fontSize: 'smaller'
	},
	closeRequestInfo: {
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '12px',
		lineHeight: '14px',
		display: 'inline'
	},
	closeOfferRequestBlock: {
		display: 'flex',
		alignItems: 'center'
	},
	isActive: {
		display: 'none'
	}
}));

export const OfferRequestDetailView = ({ match }) => {
	const offerRefs = useRef([]);
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const { offerRequest, isAddingComment } = useSelector((state: State) => ({
		offerRequest: state.offerRequests.single.offerRequest,
		isAddingComment: state.offerRequests.single.commentCompose.isAdding
	}));

	const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);
	const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);

	const [employeeMenuElement, setEmployeeMenuElement] = useState<HTMLElement>(null);

	function onAssignmentSelect(employee: UIEmployee) {
		dispatch(assignOfferRequest(employee));
		window.setTimeout(() => setEmployeeMenuElement(null), 50);
	}

	function onCloseEmployeeAssignment(event: React.SyntheticEvent) {
		setEmployeeMenuElement(null);
		if (event) {
			event.stopPropagation();
		}
	}

	useEffect(() => {
		dispatch(fetchSingleOfferRequest(match.params.offerRequestId));
	}, [match.params.offerRequestId]);

	useEffect(() => {
		offerRefs.current = offerRefs.current.slice(0, offerRequest?.offers?.length);
	}, [offerRequest?.offers]);

	const assignOfferRef = (el: HTMLElement, index: number) => {
		offerRefs.current[index] = el;
	};

	const scrollToOfferRef = (idx: number) => () => {
		const element = offerRefs.current[idx];
		scrollToElementWithOffset(element, 120, 'scrollContainer');
	};

	if (!offerRequest) return <LoadingScreen />;
	const addressText = offerRequest.address && getAddress(offerRequest.address);
	const billingAddressText =
		offerRequest.billingAddress && getBillingAddress(offerRequest.billingAddress);
	const mapAddress = offerRequest.address && getMapAddress(offerRequest.address);
	return (
		<div className={classes.container}>
			<div className={classes.scrollContainer} id="scrollContainer">
				<Card className={classes.details}>
					<div className={classes.hideOnPrint}>
						<div className={classes.header}>
							<Chip
								size="small"
								label={getItemChipLabel(offerRequest.status, t)}
								variant="outlined"
							/>
							{offerRequest.status === 'ARCHIVE' && (
								<div className={classes.closeOfferRequestBlock}>
									<DoDisturbAlt fontSize="smaller" className={classes.doDisturbIcon} />

									<div className={classes.closeRequestInfo}>
										{t('offerRequest:status_ARCHIVED')}{' '}
										{dateTime(offerRequest.statusUpdatedAt).toFormat('dd.MM.yyyy, HH:mm')}
									</div>
								</div>
							)}
							<Tooltip title={t('offers:print')} placement="left">
								<ButtonBase onClick={window.print} className={classes.headerButton}>
									<Print />
								</ButtonBase>
							</Tooltip>
							<Link
								className={classes.link}
								to={{
									pathname: '/app/offer-requests',
									search: location.search
								}}
							>
								<ButtonBase className={classes.headerButton}>
									<Close />
								</ButtonBase>
							</Link>
						</div>
						<div>
							<hr className={classes.statusBar} />
						</div>
					</div>
					<CardContent>
						<Typography variant="body1" align="left" fontSize="1.25rem">
							{offerRequest.title}
						</Typography>
					</CardContent>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={4}>
								<Typography variant="subtitle2">{t('offers:label_customer')}</Typography>
								<IconicText
									className={classes.iconizedDetails}
									icon={
										<Avatar size="small">
											{getFirstLetterOfCompanyForIcon(offerRequest.customer)}
										</Avatar>
									}
									text={offerRequest?.customer?.name}
								/>
							</Grid>
							<Grid item sm={4}>
								<Typography variant="subtitle2">{t('offers:label_address')}</Typography>
								<IconicText
									className={classes.iconizedDetails}
									icon={<LocationOn />}
									text={
										<a href={`https://maps.google.com/?q=${mapAddress}`} target="_blank">
											{addressText}
										</a>
									}
								/>
							</Grid>
							<Grid item sm={4}>
								<Typography variant="subtitle2">{t('offers:label_assignee')}</Typography>
								<HoverBase
									color="primary"
									onClick={(event) => setEmployeeMenuElement(event.currentTarget)}
								>
									<IconicText
										className={classes.iconizedDetails}
										icon={
											<Avatar size="small" src={offerRequest?.assignee?.avatar}>
												{offerRequest?.assignee ? (
													getInitialsForPerson(offerRequest.assignee)
												) : (
													<Person />
												)}
											</Avatar>
										}
										text={
											offerRequest.assignee
												? getName(offerRequest.assignee)
												: t('offers:assign_employee')
										}
									/>
									<EmployeeSearch
										anchorEl={employeeMenuElement}
										onClose={onCloseEmployeeAssignment}
										onSelect={onAssignmentSelect}
										resetOption={{ text: t('offers:unassign'), value: null }}
										disableCreation={false}
									/>
								</HoverBase>
							</Grid>
						</Grid>
						{offerRequest.description && (
							<>
								<Divider />
								<Grid container className={classes.detailField} spacing={1}>
									<Grid item sm={3}>
										<Typography className={classes.breakWord} variant="body2">
											{t('offers:label_description')}
										</Typography>
									</Grid>
									<Grid item sm={9}>
										<Typography className={classes.breakWord} variant="body2">
											{offerRequest.description}
										</Typography>
									</Grid>
								</Grid>
							</>
						)}
						{offerRequest.billingAddress && (
							<>
								<Divider />
								<Grid container className={classes.detailField} spacing={1}>
									<Grid item sm={3}>
										<Typography className={classes.breakWord} variant="body2">
											{t('offers:label_billing_address')}
										</Typography>
									</Grid>
									<Grid item sm={9}>
										<Typography className={classes.breakWord} variant="body2">
											{billingAddressText}
										</Typography>
									</Grid>
								</Grid>
							</>
						)}
						{offerRequest.desiredExecutionAt && (
							<>
								<Divider />
								<Grid container className={classes.detailField} spacing={1}>
									<Grid item sm={3}>
										<Typography className={classes.breakWord} variant="body2">
											{t('offers:label_execution_date')}
										</Typography>
									</Grid>
									<Grid item sm={9}>
										<Typography className={classes.breakWord} variant="body2">
											{dateTime(offerRequest.desiredExecutionAt).toFormat('dd.MM.yyyy')}
										</Typography>
									</Grid>
								</Grid>
							</>
						)}
						{!!offerRequest?.offers?.length && (
							<>
								<Divider />
								<Grid container className={classes.detailField} spacing={1}>
									<Grid item sm={3}>
										<Typography className={classes.breakWord} variant="body2">
											{t('offers:label_offers')}
										</Typography>
									</Grid>
									<Grid container item sm={9}>
										{offerRequest.offers.map((offer, idx) => (
											<OfferCard offer={offer} handleOnClick={scrollToOfferRef(idx)} />
										))}
									</Grid>
								</Grid>
							</>
						)}
						{!!offerRequest.attachments?.length && (
							<div>
								<br />
								<Typography variant="subtitle2">{t('offers:label_attachments')}</Typography>
								<AttachmentList>
									{offerRequest.attachments.map((attachment) => (
										<AttachmentThumbnail
											key={attachment.id}
											name={attachment.name}
											link={attachment.url}
											thumbnail={attachment.thumbnailS3Key}
										/>
									))}
								</AttachmentList>
							</div>
						)}
					</CardContent>
					<CardActions className={classes.cardActions}>
						{['DECLINED', 'SUBMITTED'].includes(offerRequest.status) && (
							<Box display="flex" flex="1">
								{offerRequest.status === 'DECLINED' && <Close fontSize="small" color="disabled" />}
								{offerRequest.status === 'SUBMITTED' && <Check fontSize="small" color="disabled" />}
								<Typography variant="caption" color="textSecondary">
									{t(
										offerRequest.status === 'DECLINED'
											? 'offers:status_DECLINED'
											: 'offers:status_SUBMITTED'
									)}{' '}
									{dateTime(offerRequest.statusUpdatedAt).toFormat('dd.MM.yyyy HH:mm')}
								</Typography>
							</Box>
						)}
						<div className={classes.inactiveDeclineButtonContainer}>
							<Button
								className={offerRequest.status === 'ARCHIVE' ? classes.isActive : ''}
								color="secondary"
								variant="contained"
								onClick={() => setIsDeclineDialogOpen(true)}
								disabled={['DECLINED', 'SUBMITTED'].includes(offerRequest.status)}
							>
								{t('offers:cta_decline')}
							</Button>

							<Button
								className={offerRequest.status === 'ARCHIVE' ? classes.isActive : ''}
								sx={{ ml: 1 }}
								color="primary"
								variant="contained"
								disabled={offerRequest.status === 'DECLINED'}
								onClick={() => setIsSubmitDialogOpen(true)}
							>
								{t(
									offerRequest.status === 'SUBMITTED' ? 'offers:cta_make_again' : 'offers:cta_make'
								)}
							</Button>
						</div>
					</CardActions>
					<CommentCompose name={offerRequest.customer?.name} />
					{offerRequest.status === 'ARCHIVE' && (
						<OfferRequestCloseComment
							statusUpdatedBy={offerRequest.statusUpdatedBy}
							statusUpdatedAt={offerRequest.statusUpdatedAt}
							closeDescription={offerRequest.closeDescription}
						/>
					)}
					{isAddingComment && <LoadingScreen />}
					<CommentContainer
						comments={offerRequest.comments}
						offers={offerRequest.offers}
						assignRef={assignOfferRef}
					/>
					<div className={classes.compose}>
						{isDeclineDialogOpen && (
							<DeclineDialog open onClose={() => setIsDeclineDialogOpen(false)} />
						)}
						{isSubmitDialogOpen && (
							<SubmitDialog
								open
								onClose={() => {
									setIsSubmitDialogOpen(false);
									dispatch(removeAllSubmitDialogFiles());
								}}
							/>
						)}
					</div>
				</Card>
			</div>
		</div>
	);
};
