import React from 'react';
import { useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export function addDefaultProps<Props extends React.ComponentPropsWithoutRef<any>>(
	Component: React.ComponentType<Props>,
	defaultProps = {}
) {
	return (props: Props) => <Component {...defaultProps} {...props} />;
}

export function addDefaultPropsWithTheme<Props extends React.ComponentPropsWithoutRef<any>>(
	Component: React.ComponentType<Props>,
	defaultPropsFunction: (theme: Theme) => Props
) {
	return (props: Props) => {
		const theme = useTheme();
		const defaultProps = defaultPropsFunction(theme);
		return <Component {...defaultProps} {...props} />;
	};
}

export function formatPrice(price) {
	return price.toFixed(2).replace('.', ',');
}
