import { Dispatch, AnyAction } from 'redux';
import { createAsyncAction, EmptyAC, PayloadAC } from 'typesafe-actions';
import { ThunkDispatch } from 'redux-thunk';
import { i18n } from '../i18n';
import { showNotification } from '../core/actions';

interface ApiActionOptions {
	errorMessage?: React.ReactNode;
}

export function createApiAction<T extends string, TPayload, TState = any, TArg = undefined>(
	type: T,
	createHandler: (
		arg: TArg,
		dispatch: ThunkDispatch<any, undefined, AnyAction>,
		getState: () => TState
	) => Promise<TPayload>,
	options?: ApiActionOptions
): StandardAsyncActionReturnType<TArg, TState> & ApiActions<TPayload> {
	const apiActions = createAsyncAction(`${type}.request`, `${type}.success`, `${type}.failure`)<
		undefined,
		TPayload,
		Error
	>();
	const promiseHandler = (arg: TArg) => async (
		dispatch: ThunkDispatch<any, undefined, AnyAction>,
		getState: () => TState
	) => {
		dispatch(apiActions.request());
		try {
			const payload = await createHandler(arg, dispatch, getState);
			dispatch(apiActions.success(payload));
		} catch (err) {
			const errorMessage = options?.errorMessage || i18n.t('general:generic_error');
			dispatch(apiActions.failure(err));
			dispatch(showNotification(errorMessage, 'error'));
		}
	};
	const func = promiseHandler as StandardAsyncActionReturnType<TArg, TState>;
	return Object.assign(func, {
		request: apiActions.request,
		success: apiActions.success,
		failure: apiActions.failure
	});
}

type StandardAsyncActionReturnType<TArg, TState> = TArg extends undefined
	? () => (dispatch: Dispatch, getState: () => TState) => Promise<void>
	: (arg: TArg) => (dispatch: Dispatch, getState: () => TState) => Promise<void>;

interface ApiActions<TPayload> {
	request: EmptyAC<string>;
	success: [TPayload] extends [undefined]
		? unknown extends TPayload
			? PayloadAC<string, TPayload>
			: EmptyAC<string>
		: PayloadAC<string, TPayload>;
	failure: PayloadAC<string, Error>;
}
