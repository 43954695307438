import { Chat, Description } from '@mui/icons-material';
import { Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { useMobile } from '../../../hooks/useBreakpoints';
import ListOrTabNav from '../../../mui-custom/ListOrTabNav/ListOrTabNav';
import { State } from '../../reducers';
import { loadTicketDetails } from '../actions';
import { DetailSubPage } from './DetailSubPage';
import { CommentCompose } from './CommentCompose';

enum MOBILE {
	DETAIL,
	CHAT
}
const useStyles = makeStyles(({ spacing }: Theme) => ({
	hideOnPrint: {
		'@media print': {
			display: 'none'
		}
	},
	fullWidthOnPrint: {
		'@media print': {
			flexDirection: 'column',
			margin: 0
		}
	}
}));
export function TicketDetailView() {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const classes = useStyles();
	const { ticketDetails } = useSelector((state: State) => state.tickets);
	const [mobileActive, setMobileActive] = useState(0);
	const isMobile = useMobile();

	useEffect(() => {
		const ticketId = Number(pathname.split('/').pop());
		dispatch(loadTicketDetails(ticketId));
	}, []);

	if (ticketDetails?.isLoading) {
		return <LoadingScreen />;
	}

	return (
		<>
			{isMobile && (
				<ListOrTabNav
					variant="tab"
					navItems={[
						{ icon: Description, label: t('tickets:detail_page_mobile_nav_ticket') },
						{ icon: Chat, label: t('tickets:detail_page_mobile_nav_chat') }
					]}
					setActiveId={setMobileActive}
					activeId={mobileActive}
					className={classes.hideOnPrint}
				/>
			)}
			<Stack direction="row" spacing={2} className={classes.fullWidthOnPrint}>
				{(!isMobile || mobileActive === MOBILE.DETAIL) && (
					<Stack sx={{ flex: '1 1 67%' }}>
						{/* // use Grid for DetailSubPage here */}
						<DetailSubPage />
						{/* // ChatComponent here */}
					</Stack>
				)}
				{(!isMobile || mobileActive === MOBILE.CHAT) && (
					<Stack sx={{ flex: '1 1 33%' }}>
						{/* // use Grid for DetailSubPage here */}
						<CommentCompose sx={{ width: 'unset' }} />
						{/* // ChatComponent here */}
					</Stack>
				)}
			</Stack>
		</>
	);
}
