export enum AdminExclusivePermission {
	CREATE_USER = 'create_user',
	MODIFY_USER = 'modify_user',
	DELETE_USER = 'delete_user',
	INVITE_USER = 'invite_user',
	VIEW_ADMIN = 'view_admin'
}

export enum OwnerExclusivePermission {
	VIEW_ACCOUNT = 'view_account',
	MODIFY_ACCOUNT = 'modify_account',
	MODIFY_SUBSCRIPTION = 'modify_subscription',
	MODIFY_PAYMENT_SOURCE = 'modify_payment_source'
}

export enum Permission {
	NEW_ORDER_EMAIL = 'new_order_email',
	CHANGED_INTERNAL_ORDER_EMAIL = 'changed_internal_order_email',
	CHANGED_ORDER_EMAIL = 'changed_order_email',
	REMINDER_ORDER_EMAIL = 'reminder_order_email',
	REMINDER_OFFER_REQUEST_EMAIL = 'reminder_offer_request_email',
	ONLY_ASSIGNED_ORDER_EMAIL = 'only_assigned_order_email',
	SUBSCRIBED_TO_NEWSLETTER = 'subscribed_to_newsletter'
}

export type PermissionType = Permission | OwnerExclusivePermission | AdminExclusivePermission;
