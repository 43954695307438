import * as React from 'react';
import { CardContent, TextField, CardActions, Button, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { bindActionCreators } from 'redux';
import { resetPassword } from './actions';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const styles = createStyles({
	link: {
		textDecoration: 'none'
	}
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			resetPassword
		},
		dispatch
	);

interface DemandPasswordProps
	extends WithStyles<typeof styles>,
		ReturnType<typeof mapDispatchToProps> {}
interface DemandPasswordState {
	email: string;
	submitted: boolean;
}

class DemandPassword extends React.Component<DemandPasswordProps, DemandPasswordState> {
	state = {
		email: '',
		submitted: false
	};
	onSubmit() {
		this.props.resetPassword({ email: this.state.email }, (err, data) => {
			if (!err) {
				this.setState({ submitted: true });
			}
		});
	}
	render() {
		const { classes } = this.props;
		if (this.state.submitted) {
			return [
				<CardContent key="content">
					<Typography variant="h5" gutterBottom>
						<Trans i18nKey="general:after_reset_password_header" />
					</Typography>
					<Trans
						i18nKey="general:after_reset_password_description"
						components={[
							<Typography key="0" gutterBottom>
								0
							</Typography>,
							<Typography key="1">1</Typography>
						]}
					/>
				</CardContent>,
				<CardActions key="actions">
					<Link to="/sysadmin/login" className={classes.link}>
						<Button size="small" color="primary">
							<Trans i18nKey="general:back_to_login" />
						</Button>
					</Link>
				</CardActions>
			];
		}
		return [
			<CardContent key="content">
				<TextField
					label={<Trans i18nKey="general:email" />}
					value={this.state.email}
					onChange={(e) => this.setState({ email: e.target.value })}
					margin="normal"
					fullWidth
					variant="standard"
				/>
			</CardContent>,
			<CardActions key="actions">
				<Button variant="contained" disabled={!this.state.email} onClick={() => this.onSubmit()}>
					<Trans i18nKey="general:reset_password" />
				</Button>
			</CardActions>
		];
	}
}

const wrapped = withStyles(styles)(connect(null, mapDispatchToProps)(DemandPassword));
export { wrapped as DemandPassword };
