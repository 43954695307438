import { UIEmployee } from '@api/v1';
import { Divider, InputAdornment, Menu, MenuItem, TextField, Theme, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/api';
import { EmployeeCreationDialog } from '../EmployeeCreationDialog';
import { prefillEmployeeCreationForm } from '../EmployeeCreationDialog/actions';
import { Highlight } from '../Highlight';

const useStyles = makeStyles((theme: Theme) => ({
	popover: {
		width: theme.spacing(30),
		maxWidth: theme.spacing(30)
	},
	menuItem: {
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	menuButton: {
		width: '100%',
		justifyContent: 'flex-start',
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`
	},
	searchField: {
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
		'&:focus': {
			outline: 'none'
		}
	},
	results: {
		maxHeight: theme.spacing(27),
		overflow: 'auto'
	}
}));

interface EmployeeSearchProps {
	anchorEl: HTMLElement;
	onClose: (event?: React.SyntheticEvent) => void;
	onSelect: (employee: UIEmployee) => void;
	disableCreation: boolean;
	resetOption?: {
		text: string;
		value: any;
	};
}

export const EmployeeSearch = ({
	anchorEl,
	onClose,
	onSelect,
	disableCreation,
	resetOption
}: EmployeeSearchProps) => {
	let searchField: HTMLInputElement;
	const [searchText, setSearchText] = React.useState('');
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [results, setResults] = React.useState([]);

	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();

	const handleEmployeeCreation = (employee?: UIEmployee) => {
		setSearchText('');
		setDialogOpen(false);
		if (employee) {
			handleSelect(employee);
		}
		searchEmployees();
	};
	React.useEffect(() => {
		searchEmployees(searchText);
	}, [anchorEl]);

	const onChangeInput = (event: React.SyntheticEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const inputText = event.currentTarget.value;
		// filter and replace special characters for names, implement this also for validating name input of new employee?
		const escapeText = inputText?.replace(/[!$%&/()=?<>*"'[\]¡“¶¢|{}≠¿]/g, '');
		const [firstName, lastName] = escapeText ? escapeText.replace(/\s+/, '\n').split('\n') : ['', ''];
		dispatch(prefillEmployeeCreationForm({ firstName, lastName }));
		setSearchText(escapeText);
		searchEmployees(escapeText);
	};
	const searchEmployees = debounce((search = '') => {
		api
			.get<UIEmployee[]>('/api/v1/employees', { params: { search } })
			.then((data) => setResults(data));
	});
	const handleSelect = (employee: UIEmployee) => {
		onSelect(employee);
		onClose();
	};

	const textCreateEmployee = searchText
		? t('employees:new_one', { name: searchText })
		: t('employees:label_create');
	return (
		<>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				onClose={onClose}
				disableAutoFocusItem
				classes={{ paper: classes.popover }}
				TransitionProps={{
					onEntered: () => (searchField ? searchField.focus() : null)
				}}
			>
				<TextField
					className={classes.searchField}
					value={searchText}
					onChange={onChangeInput}
					placeholder={t('employees:search')}
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						inputRef: (r) => (searchField = r)
					}}
					variant="standard"
				/>
				{results.length
					? [
							<Divider key="divider" />,
							<div key="list" className={classes.results}>
								{results.map((employee) => (
									<MenuItem key={employee.id} button onClick={() => handleSelect(employee)}>
										<div className={classes.menuItem}>
											<Highlight highlight={searchText}>
												{`${employee.firstName} ${employee.lastName}`}
											</Highlight>
										</div>
									</MenuItem>
								))}
							</div>
					  ]
					: null}
				{resetOption || !disableCreation
					? [
							<Divider key="reset-divider" />,
							resetOption ? (
								<li key="reset">
									<Button
										color="primary"
										className={classes.menuButton}
										onClick={() => handleSelect(resetOption.value)}
									>
										<div className={classes.menuItem}>{resetOption.text}</div>
									</Button>
								</li>
							) : null,
							!disableCreation ? (
								<li key="create">
									<Button
										color="primary"
										className={classes.menuButton}
										onClick={() => setDialogOpen(true)}
									>
										<div className={classes.menuItem}>{textCreateEmployee}</div>
									</Button>
								</li>
							) : null
					  ]
					: null}
			</Menu>
			<EmployeeCreationDialog open={dialogOpen} onClose={() => handleEmployeeCreation()} />
		</>
	);
};
