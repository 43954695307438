import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.notification.main,
		display: 'flex',
		width: theme.spacing(1),
		height: theme.spacing(1),
		borderRadius: theme.spacing(2)
	}
}));

export function ActivityIndicator(props: {}) {
	const classes = useStyles({});
	return <span className={classes.root} />;
}
