import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UIRegistrationSteps } from '../../data/enums';
import { Stepper, Step, StepLabel } from '@mui/material';
import i18next from 'i18next';
import ReactGA from 'react-ga';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';

interface RegistrationProgressProps {
	status: UIRegistrationSteps;
	isSelfRegistration: boolean;
}

const allRegistrationSteps = Object.values(UIRegistrationSteps);
/* selfRegistrationSteps equal allRegistrationSteps without first step for insert token */
const selfRegistrationSteps = allRegistrationSteps.slice(1);
const steps: UIRegistrationSteps[] = [UIRegistrationSteps.INSERT_TOKEN, ...selfRegistrationSteps];

export const RegistrationProgress: React.FC<RegistrationProgressProps> = ({
	status,
	isSelfRegistration = false
}) => {
	const { t } = useTranslation();
	const propsPerStep = getPropsForSteps(status, t, isSelfRegistration);
	const activeStep = allRegistrationSteps.indexOf(status) - Number(isSelfRegistration);

	return (
		<Stepper sx={{ padding: 3 }} activeStep={activeStep} orientation="horizontal">
			{propsPerStep.map((stepProps, index) => (
				<Step key={index} completed={stepProps.completed}>
					<StepLabel>{stepProps.content}</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};

function getPropsForSteps(
	currentStatus: UIRegistrationSteps,
	t: i18next.TFunction,
	isSelfRegistration: boolean
): Array<{ completed: boolean; content: string }> {
	const usedSteps = isSelfRegistration ? selfRegistrationSteps : steps;
	return usedSteps.map((status) => ({
		completed: allRegistrationSteps.indexOf(currentStatus) >= allRegistrationSteps.indexOf(status),
		content: currentStatus === status ? translateStep(status, t) : null
	}));
}
function translateStep(status: UIRegistrationSteps, t: i18next.TFunction): string {
	// eslint-disable-next-line default-case
	switch (status) {
		case UIRegistrationSteps.INSERT_TOKEN:
			return t('registration:steps_insert_token');
		case UIRegistrationSteps.INSERT_ACCOUNT_DATA:
			return t('registration:steps_insert_account_data');
		case UIRegistrationSteps.INSERT_USER_DATA:
			return t('registration:steps_insert_user_data');
		case UIRegistrationSteps.INSERT_PLAN:
			return t('registration:steps_insert_plan');
		case UIRegistrationSteps.REGISTRATION_FINISHED:
			return t('registration:steps_finished');
	}
}

export const constructQueryString = (options: Record<string, string>) => {
	return `?${new URLSearchParams(options).toString()}`;
};

export function useRegSteps(stepName: UIRegistrationSteps) {
	const history = useHistory();
	const location = useLocation();
	const query = qs.parse(location.search, { ignoreQueryPrefix: true });
	/**
	 * **!!! *Currying function* !!!**
	 ** step=-1 to move backward 1 step,
	 ** step=1 by default or wrong type/ different than 'number' and move forward 1 step,
	 ** step=2 to jump 2 step(s) forward and so on */
	const onSubmitSuccess = (step?: unknown) => () => {
		const _step = typeof step === 'number' ? step : 1; // get the value if it's number otherwise take 1 by default and move 1 step forward
		const next = allRegistrationSteps.indexOf(stepName) + _step;
		ReactGA.event({
			category: 'User',
			action: (step === -1 ? 'Navigate back from ' : 'Create Account step ') + stepName
		});
		history.push({
			pathname: location.pathname,
			search: constructQueryString({
				...(query?.selfRegistration && { selfRegistration: 'true' }),
				step: allRegistrationSteps[next]
			})
		});
	};
	return { onSubmitSuccess, query, history, location };
}
