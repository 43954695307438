import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';

export const DateDivider = ({ dateString }: { dateString: string }) => {
	const theme = useTheme();
	return (
		<Stack direction="row">
			<Box
				component="hr"
				sx={{
					flex: 1,
					borderTop: `1px ridge ${theme.palette.grey[300]}`,
					margin: 'auto'
				}}
			/>
			<Typography variant="caption" sx={{ px: 1 }}>
				{DateTime.fromFormat(dateString, 'yyyyMMdd').toFormat('dd.MM.yyyy')}
			</Typography>
			<Box
				component="hr"
				sx={{
					flex: 1,
					borderTop: `1px ridge ${theme.palette.grey[300]}`,
					margin: 'auto'
				}}
			/>
		</Stack>
	);
};
