import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HomeApp } from '../home/App';
import { OrderApp } from '../orders/App';
import { OfferRequestsApp } from '../offer-requests/App';
import { AppointmentApp } from '../appointments/App';
import { DocumentApp } from '../documents/App';
import { AdminApp } from '../admin/App';
import { NotificationApp } from '../notifications/App';
import { ProfileApp } from '../profile/App';
import { CustomersList } from '../customers/App';
import { PropertiesList } from '../properties/App';
import { InsurancesList } from '../insurances/App';
import {
	AccountSpecificFeature,
	Feature,
	useAccountSpecificFeature,
	useFeature
} from '../acl/features';
import { TicketsApp } from '../tickets/App';

export const SideRoute = ({ hasAdminView = false }) => {
	const showHome = useFeature(Feature.DashBoard);
	const hasTicketCreation = useAccountSpecificFeature(AccountSpecificFeature.TicketCreation);
	return (
		<Switch>
			{showHome && <Route path="/app/home" component={HomeApp} />}
			<Route path="/app/orders" component={OrderApp} />
			{hasTicketCreation && <Route path="/app/tickets" component={TicketsApp} />}
			<Route path="/app/offer-requests" component={OfferRequestsApp} />
			<Route path="/app/appointments" component={AppointmentApp} />
			<Route path="/app/documents" component={DocumentApp} />
			{hasAdminView && <Route path="/app/admin" component={AdminApp} />}
			<Route path="/app/notifications" component={NotificationApp} />
			<Route path="/app/settings/:section" component={ProfileApp} />
			<Route path="/app/properties" component={PropertiesList} />
			<Route path="/app/customers" component={CustomersList} />
			<Route path="/app/insurances" component={InsurancesList} />
			<Redirect to={showHome ? '/app/home' : '/app/orders'} />
			<Redirect to={hasTicketCreation ? '/app/tickets' : '/app/orders'} />
		</Switch>
	);
};
