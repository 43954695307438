import * as React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		margin: theme.spacing(1, 0)
	},
	text: {
		padding: theme.spacing(0, 1)
	},
	line: {
		flex: 1,
		borderTop: `1px ridge ${theme.palette.grey[300]}`,
		margin: 'auto'
	}
}));

interface SectionHeaderProps {
	children: React.ReactNode;
	className?: string;
}

export function SectionHeader(props: SectionHeaderProps) {
	const classes = useStyles({});
	const { className } = props;
	return (
		<div className={clsx(classes.root, className)}>
			<hr className={classes.line} />
			<Typography className={classes.text}>{props.children}</Typography>
			<hr className={classes.line} />
		</div>
	);
}
