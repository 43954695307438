export const mapStatusToLabel = {
	ALL: 'general:status_ALL',
	OPEN: 'general:status_OPEN',
	IN_PROGRESS: 'general:status_IN_PROGRESS',
	INPROGRESS: 'general:status_IN_PROGRESS',
	FINISHED: 'general:status_FINISHED',
	DECLINED: 'general:status_DECLINED'
};

export const mapStatusToLabelTicket = {
	OPEN: 'tickets:status_open',
	INPROGRESS: 'tickets:status_inprogress',
	POSTPONED: 'tickets:status_postponed',
	CLOSED: 'tickets:status_closed'
};
