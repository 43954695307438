import { api } from '../../lib/api';
import { State } from '../reducers';
import { createConstants, createAsyncConstants } from '../../lib/createConstants';
import { debounce } from 'lodash';

export const constants = createConstants(['SET_IS_FETCHING'], {
	prefix: 'APPOINTMENTS'
});

export const asyncConstants = createAsyncConstants(['FETCH', 'FETCH_MORE'], {
	prefix: 'APPOINTMENTS'
});

export const fetchAppointments = () => (dispatch, getState: () => State) => {
	return dispatch({
		type: asyncConstants.FETCH.TYPE,
		payload: api.get('/api/v1/appointments')
	});
};

export const fetchMoreAppointments = () => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_IS_FETCHING
	});
	return dispatch(debounce(appendAppointmentsToList(), 1000));
};

const appendAppointmentsToList = () => (dispatch, getState: () => State) => {
	const metaData = getState().appointments?.list?.metaData;
	let lastId: number;
	if (metaData) {
		lastId = metaData.lastId;
	}
	return dispatch({
		type: asyncConstants.FETCH_MORE.TYPE,
		payload: api.get('/api/v1/appointments', { params: { afterId: lastId } })
	});
};
