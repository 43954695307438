import { Grid, Stack, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Headline5 } from '../../../mui-custom/Headline5';
import { IconListedItem } from '../../../mui-custom/IconListedItem';
import {
	createPricingV2Benefits,
	usePlanPriceCalculator,
	useVisiblePlans
} from '../../../components/PricingDetails';
import Check from '@mui/icons-material/Check';
import { RELAY_SMALL } from '../../../../server/chargebee/chargebee.enums';

export const AbonnementsSection: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const { palette, spacing } = useTheme();
	const planNames = useVisiblePlans(true);
	const [firstBenefit, ...restBenefits] = createPricingV2Benefits(t);
	console.log(':::restBenefits: ', { restBenefits });
	const planPrices = usePlanPriceCalculator();

	return (
		<Stack sx={{ alignItems: 'center', gap: 4, py: 4, backgroundColor: palette.background.paper }}>
			<Stack sx={{ alignItems: 'center', gap: 1 }}>
				<Typography color={palette.primary.main}>
					{t('registration:starter_page_abo').toUpperCase()}
				</Typography>
				<Headline5>{t('registration:starter_page_abo_headline')}</Headline5>
			</Stack>
			<Grid container>
				<Grid item xs={12} md={6} sx={{ mx: 3, mb: 3 }}>
					{planNames.map((planName) => (
						<IconListedItem
							key={planName}
							small
							headline={planName}
							icon={
								<Headline5
									sx={{ color: palette.text.primary, width: spacing(8), mx: 1 }}
								>{`€ ${planPrices[planName]}`}</Headline5>
							}
							text={firstBenefit[planName].replace('<br>', ' ')}
							border
							sx={{ mb: 1 }}
						/>
					))}
					<Typography>{t('registration:starter_page_abo_benefits_left_column_note')}</Typography>
				</Grid>
				<Grid item xs={12} md={5} sx={{ mx: 3 }}>
					<Typography>
						{t('registration:starter_page_abo_benefits_right_column').toUpperCase()}
					</Typography>
					{restBenefits.map((benefit, id) => (
						<IconListedItem
							key={benefit[RELAY_SMALL]}
							small
							icon={<Check />}
							text={benefit[RELAY_SMALL]}
						/>
					))}
				</Grid>
			</Grid>
		</Stack>
	);
};
