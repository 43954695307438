import * as React from 'react';
import { Collapse } from '@mui/material';
import { useLocation } from 'react-router';

interface SubListProps {
	path: string;
	children: React.ReactNode;
}

export function SubList(props: SubListProps) {
	const location = useLocation();
	const isVisible = location.pathname.includes(props.path);
	return <Collapse in={isVisible}>{props.children}</Collapse>;
}
