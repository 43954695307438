import React from 'react';
import { Typography } from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';

export interface Headline1Props {
	variant?: '' | 'isMobile' | 'isDesktop'; // option for overriding breakpoint changes
	children?: React.ReactNode;
	[key: string]: unknown;
}

export function Headline1({ variant, children, ...rest }: Headline1Props) {
	const isMobile = useMobile();
	const mobileMode = variant === 'isMobile' || isMobile;
	return (
		<Typography overflow="hidden" variant={mobileMode ? 'h3' : 'h1'} {...rest}>
			{children}
		</Typography>
	);
}

export type Headline1 = ReturnType<typeof Headline1>;
