import React, { useEffect } from 'react';
import { CardContent, Typography, Button, Theme, CardActions } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { setToken, checkToken } from './actions';
import { UIRegistrationSteps } from '../../data/enums';
import { State } from '../reducers';
import ReactGA from 'react-ga';
import { useRegSteps } from './Progress';

const GATrackID = import.meta.env.MODE === 'production' ? 'UA-55860842-9' : 'UA-55860842-7';
ReactGA.initialize(GATrackID);

const useStyles = makeStyles((_theme: Theme) => ({
	actions: {
		justifyContent: 'space-between'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '420px'
	},
	content: {
		flexGrow: 1
	}
}));

export const InsertTokenForm = () => {
	ReactGA.pageview(window.location.pathname + window.location.search);
	useEffect(() => {
		ValidatorForm.addValidationRule('isTokenValid', async (value) => {
			try {
				// eslint-disable-next-line @typescript-eslint/await-thenable
				await dispatch(checkToken(value));
				return true;
			} catch (err) {
				return false;
			}
		});
	}, []);

	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { token, propertyManager } = useSelector((state: State) => state.registration);
	const { history, onSubmitSuccess } = useRegSteps(UIRegistrationSteps.INSERT_TOKEN);

	return (
		<ValidatorForm
			className={classes.container}
			instantValidate={false}
			onSubmit={onSubmitSuccess()}
		>
			<CardContent className={classes.content}>
				<Typography color="inherit" variant="h5">
					{t('registration:description_insert_token')}:
				</Typography>
				<TextValidator
					id="token"
					placeholder={t('registration:label_insert_token')}
					value={token}
					validators={['isTokenValid']}
					errorMessages={[t('registration:token_invalid', { propertyManager })]}
					onChange={(e) => dispatch(setToken(e.target.value))}
					margin="dense"
					fullWidth
					name="tokenFormTextValidator"
					variant="standard"
				/>
			</CardContent>
			<CardActions className={classes.actions}>
				<Button
					color="primary"
					onClick={() => {
						ReactGA.event({
							category: 'User',
							action: 'Connect Account'
						});
						history.push(`/app/customers?&token=${token}`);
					}}
				>
					{t('registration:button_connect_account')}
				</Button>
				<Button variant="contained" type="submit" color="primary">
					{t('registration:button_create_account')}
				</Button>
			</CardActions>
		</ValidatorForm>
	);
};
