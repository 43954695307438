import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { State } from '../../reducers';
import { closeMakeExternalDialog, convertOrderToExternal } from '../actions';
import { useDispatch, useSelector } from 'react-redux';

export function MakeExternalDialog() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { makeExternalDialog, order } = useSelector((state: State) => state.orders.single);
	return (
		<Dialog open={makeExternalDialog.open} maxWidth="sm">
			<DialogTitle>{t('orders:make_external_dialog_header')}</DialogTitle>
			<DialogContent>
				<Typography
					dangerouslySetInnerHTML={{
						__html: t('orders:make_external_dialog_content', {
							email: order.customer?.emailAddress || ''
						})
					}}
				></Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => dispatch(closeMakeExternalDialog())}>
					{t('general:cta_cancel')}
				</Button>
				<Button color="primary" onClick={() => dispatch(convertOrderToExternal())}>
					{t('orders:cta_send_order')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
