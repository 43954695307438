import * as React from 'react';
import {
	Dialog,
	DialogTitle,
	TextField,
	DialogContent,
	DialogActions,
	Button,
	Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeInternalIdDialog, changeInternalId, saveInternalId } from '../actions';
import { State } from '../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
	width: {
		width: theme.spacing(50)
	}
}));

export const InternalIdDialog = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { open } = useSelector((state: State) => state?.orders?.single?.internalIdDialog);
	const { order } = useSelector((state: State) => state?.orders?.single);
	const dispatch = useDispatch();
	return (
		<Dialog open={open} classes={{ paper: classes.width }}>
			<DialogTitle>{t('orders:dialog_internal_id_title')}</DialogTitle>
			<DialogContent>
				<TextField
					defaultValue={order.internalId}
					onChange={(e) => dispatch(changeInternalId(e.target.value))}
					label={t('orders:internal_id')}
					id="order_details_dialog_field_text_internal_order_number"
					fullWidth
					autoFocus
					variant="standard"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => dispatch(closeInternalIdDialog())}>{t('general:cta_cancel')}</Button>
				<Button
					color="primary"
					id="order_details_save_button_dialog_internal_order_number"
					onClick={() => dispatch(saveInternalId())}
				>
					{t('orders:cta_save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
