import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { MaterialDropzone, DropzoneProps } from '../../../components/Dropzone';
import { DialogProps } from '@mui/material/Dialog';
import { withTranslation, WithTranslation } from 'react-i18next';

interface DropzoneDialogProps extends WithTranslation {
	open: boolean;
	onClose: DialogProps['onClose'];
	title: string;
	dropzoneProps: DropzoneProps;
}

class DropzoneDialog extends React.Component<DropzoneDialogProps> {
	render() {
		const { open, onClose, title, dropzoneProps, t } = this.props;
		return (
			<Dialog open={open}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<MaterialDropzone id="file_upload_dialog_dropzone" {...dropzoneProps} />
				</DialogContent>
				<DialogActions>
					<Button id="file_upload_dialog_button_close" onClick={onClose}>
						{t('general:cta_close')}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const wrapped = withTranslation()(DropzoneDialog);

export { wrapped as DropzoneDialog };
