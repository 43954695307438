import i18next from 'i18next';
import { memoize } from 'lodash';
import { DamageType } from '@api/v1';

export const transformDamageType = (
	damageTypes: DamageType[],
	t: i18next.TFunction
): Array<{ value: DamageType; label: string }> => {
	const translationMap = getDamageTypeTranslationMapper(t);
	return damageTypes.map((damageType) => ({
		value: damageType,
		label: translationMap[damageType]
	}));
};
const getDamageTypeTranslationMapper = memoize((t: i18next.TFunction) => ({
	FIRE: t('insurances:add_dialog_damage_type_fire'),
	EXPLOSION: t('insurances:add_dialog_damage_type_explosion'),
	MAINS_WATER: t('insurances:add_dialog_damage_type_mains_water'),
	ELEMENTARY_LOSS: t('insurances:add_dialog_damage_type_elementary_loss'),
	GLASS_BREAKAGE: t('insurances:add_dialog_damage_type_elementary_glass_breakage'),
	STORM_DAMAGE: t('insurances:add_dialog_damage_type_elementary_storm_damage'),
	HAIL_DAMAGE: t('insurances:add_dialog_damage_type_elementary_hail_damage'),
	LIGHTNING_DAMAGE: t('insurances:add_dialog_damage_type_elementary_lightning_damage'),
	FLOOD_DAMAGE: t('insurances:add_dialog_damage_type_elementary_flood_damage'),
	OTHER: t('insurances:add_dialog_damage_type_elementary_other')
}));
