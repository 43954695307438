import * as React from 'react';
import { Switch, Route } from 'react-router';
import { LoginForm } from './LoginForm';
import { DemandPassword } from './DemandPassword';
import { ChoosePassword } from './ChoosePassword';
import { LoggedOutView } from '../../components/LoggedOutView';
import { CardMedia, Typography, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from 'react-i18next';

const styles = (theme: Theme) =>
	createStyles({
		header: {
			background: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			padding: theme.spacing(2)
		}
	});

interface AuthAppProps extends WithStyles<typeof styles> {}

class AuthApp extends React.Component<AuthAppProps> {
	render() {
		const { classes } = this.props;
		return (
			<LoggedOutView>
				<CardMedia className={classes.header}>
					<Typography variant="h3" align="center" color="inherit">
						<Trans i18nKey="sysadmin:login_screen.header" />
					</Typography>
				</CardMedia>
				<Switch>
					<Route path="/sysadmin/login" component={LoginForm} />
					<Route path="/sysadmin/demand_password" component={DemandPassword} />
					<Route path="/sysadmin/choose_password" component={ChoosePassword} />
				</Switch>
			</LoggedOutView>
		);
	}
}

const wrapped = withStyles(styles)(AuthApp);
export { wrapped as AuthApp };
