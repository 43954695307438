import { FormControl, FormHelperText } from '@mui/material';
import * as React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { MaterialDropzone } from './Dropzone';

export class DropzoneValidator extends ValidatorComponent {
	errorText() {
		const { isValid } = this.state;
		if (isValid) return null;
		return <FormHelperText>{this.getErrorMessage()}</FormHelperText>;
	}
	renderValidatorComponent() {
		const { margin, value, fullWidth, ...props } = this.props;
		return (
			<FormControl
				fullWidth={fullWidth}
				margin={this.props.margin}
				error={!this.state.isValid}
				variant="standard"
			>
				<MaterialDropzone files={value} {...props} />
				{this.errorText()}
			</FormControl>
		);
	}
}
