import { SRAccount } from '@api/sysadmin';
import { asyncConstants } from './actions';

export type AccountsState = SRAccount[];

export const reducers = (state: AccountsState = null, action) => {
	switch (action.type) {
		case asyncConstants.GET_ACCOUNTS.FULFILLED:
			return action.payload;
		case asyncConstants.DELETE_ACCOUNT.FULFILLED:
			return state.filter((account) => account.id !== action.payload);
		default:
			return state;
	}
};
