import { combineReducers } from 'redux';
import { SRSysadminUser } from '@api/sysadmin';
import { constants } from './actions';

const FULFILLED = '_FULFILLED';

export interface AuthState {
	user: SRSysadminUser;
	password: string;
	passwordConfirm: string;
}

export const reducers = combineReducers<AuthState>({
	user: (state = null, action) => {
		switch (action.type) {
			case constants.LOGIN + FULFILLED:
			case constants.GET_USER + FULFILLED: {
				return action.payload;
			}
			default:
				return state;
		}
	},
	password: (state = null, action) => {
		switch (action.type) {
			case constants.SET_PASSWORD:
				return action.payload;
			default:
				return state;
		}
	},
	passwordConfirm: (state = null, action) => {
		switch (action.type) {
			case constants.SET_PASSWORD_CONFIRM:
				return action.payload;
			default:
				return state;
		}
	}
});
