import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper, Step, StepLabel, StepConnector, StepIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { State } from '../../reducers';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { CheckCircle } from '@mui/icons-material';
import { Cancel } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
	stepper: {
		flex: '1 1 auto',
		color: theme.palette.grey['200'],
		'& .MuiStepLabel-alternativeLabel': {
			color: theme.palette.grey['300'],
			fontWeight: 'normal'
		},
		padding: '1rem 0'
	},
	stepOpen: {
		'& .MuiStepLabel-iconContainer > div': {
			border: `3px solid ${theme.orderStatus.open.main}`,
			background: 'white'
		}
	},
	stepInProgress: {
		'& .MuiStepLabel-iconContainer > div': {
			border: `3px solid ${theme.palette.primary.main}`,
			background: theme.palette.primary.main,
			'&.is-active': {
				background: 'white'
			}
		}
	},
	stepFinished: {
		'& .MuiStepLabel-iconContainer > div': {
			border: `3px solid ${theme.orderStatus.finished.main}`,
			background: theme.orderStatus.finished.main
		}
	},
	stepDeclined: {
		'& .MuiStepLabel-iconContainer > div': {
			border: theme.palette.grey['400'],
			background: theme.palette.grey['400']
		}
	},
	stepIcon: {
		borderRadius: theme.spacing(2),
		width: theme.spacing(2),
		height: theme.spacing(2),
		background: theme.palette.grey['200'],
		border: `2px solid ${theme.palette.grey['200']}`,
		zIndex: 1
	},
	checkIcon: {
		color: theme.orderStatus.finished.main,
		width: '18px',
		height: '18px',
		zIndex: 1
	},
	cancelIcon: {
		width: '20px',
		height: '20px',
		zIndex: 1,
		color: theme.palette.grey['400'],
		background: 'white'
	}
}));
export function OrderProgress(props: {}) {
	const classes = useStyles(props);
	const { status } = useSelector((state: State) => ({
		status: state.orders.single.order.status
	}));
	const propsPerStep = () => {
		if (status === 'OPEN') {
			return [
				{ completed: false, disabled: true },
				{ completed: false, disabled: true },
				{ completed: false, disabled: false },
				{ completed: false, disabled: false }
			];
		} else if (status === 'IN_PROGRESS') {
			return [
				{ completed: false, disabled: true },
				{ completed: true, disabled: true },
				{ completed: false, active: true, disabled: false },
				{ completed: false, disabled: false }
			];
		} else if (status === 'FINISHED') {
			return [
				{ completed: false, disabled: true },
				{ completed: true, disabled: true },
				{ completed: true, disabled: false },
				{ completed: true, disabled: false, active: true }
			];
		} else {
			return [
				{ completed: true },
				{ active: true, completed: false },
				{ completed: false, disabled: false },
				{ completed: false, disabled: false }
			];
		}
	};
	const { t } = useTranslation();
	const getActiveStep = () => {
		if (status === 'OPEN') return 0;
		if (status === 'IN_PROGRESS') return 1;
		if (status === 'DECLINED') return -1;
		if (status === 'FINISHED') return 2;
	};

	const StepIconComponent = (_props) => {
		if (_props.icon === 4 && _props.active) {
			return <StepIcon icon={<CheckCircle className={classes.checkIcon} />} />;
		}
		if (_props.icon === 1 && _props.completed) {
			return <StepIcon icon={<Cancel className={classes.cancelIcon} />} />;
		}
		return <div className={clsx(classes.stepIcon, { 'is-active': _props.active })}></div>;
	};

	const Connector = withStyles((theme) => {
		return {
			alternativeLabel: {
				top: 8,
				left: '-50%',
				right: 'calc(50% + 6px)',
				zIndex: 0
			},
			active: {
				'& $line': {
					borderColor: theme.palette.primary.main
				},
				'&.finished $line': {
					borderColor: theme.orderStatus.finished.main
				},
				'&.declined $line': {
					borderColor: theme.palette.grey['400']
				}
			},
			completed: {
				'& $line': {
					borderColor: theme.orderStatus.finished.main
				}
			},
			disabled: {
				'& $line': {
					borderTopStyle: 'dashed',
					borderColor: theme.palette.grey['200']
				}
			},
			line: {
				borderColor: theme.palette.grey['200'],
				borderTopWidth: 2,
				borderRadius: 1
			}
		};
	})(StepConnector);

	const activeStep = getActiveStep();
	return (
		<Stepper
			className={classes.stepper}
			activeStep={activeStep}
			orientation="horizontal"
			alternativeLabel
			connector={
				<Connector className={activeStep === -1 ? 'declined' : activeStep > 1 ? 'finished' : ''} />
			}
		>
			<Step {...propsPerStep()[0]}>
				<StepLabel
					className={activeStep === -1 ? classes.stepDeclined : ''}
					StepIconComponent={StepIconComponent}
				>
					{t('orders:steps_decline')}
				</StepLabel>
			</Step>
			<Step {...propsPerStep()[1]}>
				<StepLabel
					className={
						activeStep === -1
							? classes.stepDeclined
							: activeStep > 1
							? classes.stepFinished
							: activeStep > 0
							? classes.stepInProgress
							: classes.stepOpen
					}
					StepIconComponent={StepIconComponent}
				>
					{t('orders:steps_accept')}
				</StepLabel>
			</Step>
			<Step
				{...propsPerStep()[2]}
				className={
					activeStep > 1 ? classes.stepFinished : activeStep > 0 ? classes.stepInProgress : ''
				}
			>
				<StepLabel StepIconComponent={StepIconComponent}>{t('orders:steps_appointment')}</StepLabel>
			</Step>
			<Step {...propsPerStep()[3]} className={activeStep > 1 ? classes.stepFinished : ''}>
				<StepLabel StepIconComponent={StepIconComponent}>{t('orders:steps_finish')}</StepLabel>
			</Step>
		</Stepper>
	);
}
