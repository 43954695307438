import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ActivityIndicator } from '../../components/ActivityIndicator';
import {
	getNotificationIcon,
	getNotificationHeadline,
	getTimeSinceCreation,
	getNotificationType,
	getNotificationLink,
	getNotificationContent
} from '../notifications/utils';

interface NotificationItemProps {
	notification;
}

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.grey[400],
		minWidth: theme.spacing(6)
	},
	root: {
		minWidth: 275
	},
	listItemContent: {
		maxWidth: theme.spacing(22),
		marginRight: theme.spacing(2),
		'& > p, span': {
			display: 'block',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		}
	},
	link: {
		textDecoration: 'none',
		color: 'inherit'
	}
}));

export function NotificationItem(props: NotificationItemProps) {
	const { notification } = props;
	const { createdAt, notifiedAt } = notification;
	const classes = useStyles(props);
	const { t } = useTranslation();
	const location = useLocation();
	const type = useMemo(() => getNotificationType(notification), [notification]);
	const pathname = useMemo(() => getNotificationLink(notification), [notification]);
	const notificationContent = useMemo(() => getNotificationContent(notification), [notification]);
	const notificationIcon = useMemo(() => getNotificationIcon(type), [type]);
	const notificationHeadline = useMemo(() => getNotificationHeadline(notification, t), [
		notification,
		t
	]);
	const timeSinceCreation = useMemo(() => getTimeSinceCreation(createdAt), [createdAt]);

	return (
		<NavLink
			className={classes.link}
			to={{
				pathname,
				search: location.search
			}}
		>
			<ListItem button divider className={classes.root}>
				<ListItemAvatar className={classes.icon}>{notificationIcon}</ListItemAvatar>
				<ListItemText
					className={classes.listItemContent}
					primary={notificationHeadline}
					secondary={
						<React.Fragment>
							{notificationContent}
							<br />
							{timeSinceCreation}
						</React.Fragment>
					}
				/>
				{notifiedAt ? null : <ActivityIndicator />}
			</ListItem>
		</NavLink>
	);
}
