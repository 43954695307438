import { UIAccount, UIUser, Notifications } from '@api/v1';
import { combineReducers } from 'redux';
import { constants as profileEditConstants } from '../../core/profile/actions';
import { constants } from './actions';
import { constants as orderConstants } from '../../core/orders/actions';
import { constants as notificationConstants } from '../../core/notifications/actions';

const FULFILLED = '_FULFILLED';

export interface AuthState {
	user: UIUserState;
	account: UIAccount;
	choosePassword: AuthChoosePasswordState;
}

export interface AuthChoosePasswordState {
	password: string;
	passwordConfirm: string;
	token: string;
	fromActivation: boolean;
	accountName: string;
	emailAddress: string;
}

export type UIUserState = Partial<UIUser> & {
	notifications: NotificationsState;
};

export type NotificationsState = Notifications & {
	isLoading: boolean;
};

export const reducers = combineReducers<AuthState>({
	user: (state = null, action) => {
		switch (action.type) {
			case constants.CHOOSE_PASSWORD + FULFILLED:
			case constants.PATCH_USER + FULFILLED: {
				return action.payload;
			}
			case constants.GET_USER + FULFILLED: {
				if (!state?.notifications) return action.payload;
				return { ...action.payload, notifications: state.notifications };
			}
			case constants.GET_NOTIFICATIONS + FULFILLED: {
				return { ...state, notifications: action.payload };
			}
			case constants.LOGIN + FULFILLED:
				return action.payload;
			case constants.LOGOUT + FULFILLED:
				return null;
			case constants.SET_AUTH_EMAIL_ADDRESS:
				return { ...state, email: action.payload };
			case constants.SET_EMAIL_CONFIRMED:
				return { ...state, emailConfirmed: action.payload };
			case profileEditConstants.HANDLE_SUBMIT + FULFILLED:
				return action.payload;
			case profileEditConstants.SET_EMAIL_PERMISSIONS:
				return { ...state, permissions: action.payload };
			case orderConstants.FETCH_UNREAD_NOTIFICATIONS_COUNT + FULFILLED:
				return { ...state, notifications: { ...state.notifications, unreadCount: action.payload } };
			case orderConstants.FETCH_ORDER + FULFILLED:
				if (!state) return state;
				const { notifications } = state;
				const modifiedDataNewNotifiedAt = notifications?.data?.map((notification) =>
					notification.activityLog?.order?.id === action?.payload?.id
						? { ...notification, notifiedAt: new Date() }
						: notification
				);
				return { ...state, notifications: { ...notifications, data: modifiedDataNewNotifiedAt } };
			case notificationConstants.GET_NOTIFICATIONS + FULFILLED:
				return { ...state, notifications: action.payload };
			case notificationConstants.GET_MORE_NOTIFICATIONS + FULFILLED:
				return {
					...state,
					notifications: {
						...action.payload,
						data: [...state.notifications.data, ...action.payload.data],
						isLoading: false
					}
				};
			case notificationConstants.SET_IS_LOADING:
				return {
					...state,
					notifications: {
						...state?.notifications,
						isLoading: action.payload
					}
				};
			default:
				return state;
		}
	},
	account: (state = null, action) => {
		switch (action.type) {
			case constants.GET_ACCOUNT + FULFILLED:
			case constants.PATCH_ACCOUNT + FULFILLED: {
				return { ...action.payload };
			}
			case constants.REDEEM_COUPON + FULFILLED: {
				return { ...state, coupon: action.payload };
			}
			case constants.REDEEM_COUPON + '_REJECTED': {
				return { ...state, coupon: { error: 'coupon_invalid' } };
			}
			default:
				return state;
		}
	},
	choosePassword: combineReducers<AuthChoosePasswordState>({
		password: (state = '', action) => {
			switch (action.type) {
				case constants.SET_PASSWORD: {
					return action.payload;
				}
				default:
					return state;
			}
		},
		passwordConfirm: (state = '', action) => {
			switch (action.type) {
				case constants.SET_PASSWORD_CONFIRM: {
					return action.payload;
				}
				default:
					return state;
			}
		},
		token: (state = null, action) => {
			switch (action.type) {
				case constants.CHECK_URL_ON_CHOOSE_PASSWORD_PAGE:
					return action.payload.token;
				default:
					return state;
			}
		},
		fromActivation: (state = false, action) => {
			switch (action.type) {
				case constants.CHECK_URL_ON_CHOOSE_PASSWORD_PAGE:
					return action.payload.fromActivation;
				default:
					return state;
			}
		},
		emailAddress: (state = null, action) => {
			switch (action.type) {
				case constants.CHECK_URL_ON_CHOOSE_PASSWORD_PAGE:
					return action.payload.emailAddress;
				default:
					return state;
			}
		},
		accountName: (state = null, action) => {
			switch (action.type) {
				case constants.CHECK_URL_ON_CHOOSE_PASSWORD_PAGE:
					return action.payload.accountName;
				default:
					return state;
			}
		}
	})
});
