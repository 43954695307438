import { Button, CardActions, CardContent, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { confirmEmailAddress, confirmUnsubscription, login } from './actions';

const useStyles = makeStyles(() => ({
	actions: {
		justifyContent: 'flex-end'
	},
	loginButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	link: {
		textDecoration: 'none'
	}
}));

interface LoginFormProps extends RouteComponentProps<{}> {}

export const LoginForm: React.FC<LoginFormProps> = ({ history, location }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();
	const onSubmit = (event) => {
		event.preventDefault();
		dispatch(login({ email, password }));
	};

	useEffect(() => {
		checkIfUnsubscribe();
		checkIfEmailConfirm();
	}, []);

	const checkIfUnsubscribe = () => {
		if (location.pathname.includes('unsubscribe')) {
			const { email: locationEmail } = qs.parse(location.search, {
				ignoreQueryPrefix: true
			});
			dispatch(confirmUnsubscription(locationEmail));
		}
	};
	const checkIfEmailConfirm = () => {
		if (location.pathname.includes('confirm_email')) {
			const { token } = qs.parse(location.search, {
				ignoreQueryPrefix: true
			});
			dispatch(confirmEmailAddress(token));
		}
	};
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<form onSubmit={onSubmit}>
			<CardContent>
				<TextField
					id="login_field_email"
					label={t('general:email')}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					margin="normal"
					fullWidth
					type="email"
					variant="standard"
				/>
				<TextField
					id="login_field_password"
					label={t('general:password')}
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					margin="normal"
					fullWidth
					variant="standard"
				/>
			</CardContent>
			<CardActions className={classes.loginButtonContainer}>
				<Link to="/registration" className={classes.link}>
					<Button color="primary">{t('general:cta_register')}</Button>
				</Link>
				<Button id="login_button_submit" type="submit" color="primary" variant="contained">
					{t('general:cta_login')}
				</Button>
			</CardActions>
			<CardActions className={classes.actions}>
				<Link id="login_link_password_forgotten" to="/demand_password" className={classes.link}>
					<Button size="small" color="primary">
						{t('general:cta_forgot_password')}
					</Button>
				</Link>
			</CardActions>
		</form>
	);
};
