import { State } from 'app/core/reducers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAccounts } from '../actions';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	Stack,
	Typography
} from '@mui/material';
import { MergeAccountConfirmDialog } from './MergeAccountConfirmDialog';

export const MergeAccountDialog = ({
	isOpen,
	onClose
}: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	const { t } = useTranslation();
	const [selectedAccount, setSelectedAccount] = React.useState(null);
	const { accounts } = useSelector((state: State) => state.sysadmin);
	const [isMergeAccountConfirmOpen, setIsMergeAccountConfirmOpen] = React.useState(false);

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(getAccounts());
	}, []);

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>{t('sysadmin:account_merge_account_dialog_title')}</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Typography>{t('sysadmin:account_merge_account_dialog_content')}</Typography>
					<Select
						value={selectedAccount}
						onChange={(event) => setSelectedAccount(event.target.value)}
					>
						{accounts?.map((account) => (
							<MenuItem key={account.id} value={account.id}>
								{`${account.name} (${account.id})`}
							</MenuItem>
						))}
					</Select>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					color="primary"
					onClick={() => setIsMergeAccountConfirmOpen(true)}
					disabled={!selectedAccount}
				>
					{t('sysadmin:account_merge_account_dialog_submit')}
				</Button>
				<Button onClick={onClose} color="secondary">
					{t('sysadmin:account_merge_account_dialog_cancel')}
				</Button>
			</DialogActions>
			<MergeAccountConfirmDialog
				isOpen={isMergeAccountConfirmOpen}
				onClose={() => {
					setIsMergeAccountConfirmOpen(false);
					onClose();
				}}
				selectedAccount={selectedAccount}
			/>
		</Dialog>
	);
};
