import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PilotPaywall } from './PilotPaywall';
import { State } from '../reducers';
import { AccountSpecificFeature } from '../acl/features';
import { TrialExceededPaywall } from './TrialExceededPaywall';
import { ChargebeeSubscriptionStatus } from '../../../server/chargebee/chargebee.enums';
import { loadPaymentSources, setPaywallClose, setPaywallOpen } from '../admin/account/actions';
import { PaywallState } from '../admin/account/reducers';

export const Paywall = () => {
	const { user } = useSelector((state: State) => state.auth);
	const { account, paymentSources, paywallActive } = useSelector(
		(state: State) => state.admin.account
	);
	const [hasConfirmedPlanSelection, setHasConfirmedPlanSelection] = useState(false);
	const [trialExceededPaywall, setTrialExceededPaywall] = useState(false);
	const [usePaywallActive, setPaywallActive] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		if (usePaywallActive) dispatch(loadPaymentSources());
	}, [usePaywallActive]);
	useEffect(() => {
		if (!user || !account) return;

		const isPaywallActive =
			user?.accountSpecificFeatures?.includes(AccountSpecificFeature.PricingModelV2) &&
			!location.pathname.includes('/app/settings') &&
			!user?.hasActivePlan &&
			account?.deactivatedAt === null &&
			!hasConfirmedPlanSelection;

		const isTrialExceeded =
			isPaywallActive &&
			user?.subscriptionStatus === ChargebeeSubscriptionStatus.IN_TRIAL &&
			(paymentSources?.length === 0 || paymentSources === null);

		setTrialExceededPaywall(isTrialExceeded);
		if (paywallActive === PaywallState.FORCED_OPEN) return;

		setPaywallActive(isPaywallActive);
		dispatch(isPaywallActive ? setPaywallOpen() : setPaywallClose());
	}, [user, location.pathname, account]);

	const handleClosePaywall = () => {
		setHasConfirmedPlanSelection(true);
		dispatch(setPaywallClose());
	};

	const handleCloseTrialPaywallDialog = () => {
		setTrialExceededPaywall(false);
		dispatch(setPaywallClose());
	};

	if (trialExceededPaywall) {
		return <TrialExceededPaywall open={true} close={handleCloseTrialPaywallDialog} />;
	}
	return <PilotPaywall paywallState={paywallActive} close={handleClosePaywall} />;
};
