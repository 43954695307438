import React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { AddTicketPage } from './single/AddTicketPage';
import { AddCustomFieldsPage } from './single/AddCustomFieldsPage';
import { ListPage } from './list/ListPage';
import { Stack, Typography } from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';
import { NAVBAR_HEIGHT } from '../navbar/App';
import { AccountSpecificFeature, useAccountSpecificFeature } from '../acl/features';
import { TicketDetailView } from './single/DetailView';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

enum PATH {
	LIST_PAGE = '/app/tickets',
	ADDING_PAGE = '/app/tickets/add',
	DETAIL_PAGE = '/app/tickets/detail'
}

const TITLE = {
	[PATH.LIST_PAGE]: 'tickets:filter_switch_label',
	[PATH.ADDING_PAGE]: 'tickets:create_new_ticket',
	[PATH.DETAIL_PAGE]: 'tickets:ticket_details_title'
};

export const TicketsApp = () => {
	const isMobile = useMobile();
	const { t } = useTranslation();
	const history = useHistory();

	const { pathname } = useLocation();
	const hasTicketsCreation = useAccountSpecificFeature(AccountSpecificFeature.TicketCreation);
	if (!hasTicketsCreation) return null;

	return (
		<Stack sx={{ width: isMobile ? '100vw' : 1 }}>
			<ActionBar
				alternate={
					pathname !== PATH.LIST_PAGE && {
						children: <ArrowBack />,
						onClick: () => history.push({ pathname: '/app/tickets' })
					}
				}
				primary={
					pathname !== PATH.ADDING_PAGE && {
						children: t(TITLE[PATH.ADDING_PAGE]),
						onClick: () => history.push({ pathname: '/app/tickets/add' })
					}
				}
				sx={{ borderRadius: 0, borderBottomWidth: 1 }}
			>
				<Typography variant="h6">{t(TITLE[pathname] || TITLE[PATH.DETAIL_PAGE])}</Typography>
			</ActionBar>
			{/* End of PageNav */}

			<Stack
				sx={{
					m: isMobile ? 0 : 2,
					height: isMobile ? `calc(100vh - ${NAVBAR_HEIGHT})` : '100%',
					overflow: 'auto'
				}}
			>
				<Route exact path={PATH.LIST_PAGE} component={ListPage} />
				<Route exact path={PATH.ADDING_PAGE} component={AddTicketPage} />
				<Route
					exact={isMobile}
					path={`${PATH.ADDING_PAGE}/custom-fields`}
					component={AddCustomFieldsPage}
				/>
				<Route exact path={`${PATH.DETAIL_PAGE}/:id`} component={TicketDetailView} />
			</Stack>
		</Stack>
	);
};
