import React from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { Headline3 } from '../Headline3';
import { ActionBar } from '../ActionBar/ActionBar';

export interface ContentCardProps {
	headline: string;
	text: string | React.ReactNode;
	children?: React.ReactNode;
	actionBar?: React.ReactElement<ActionBar>;
	actionBarPosition?: 'top' | 'bottom';
	sx?: Object;
}

const ContentCard = function ContentCard({
	headline,
	text,
	children,
	actionBar,
	actionBarPosition = 'bottom',
	...props
}: ContentCardProps) {
	return (
		<Paper variant="outlined">
			<Stack padding={3} spacing={actionBarPosition === 'top' ? 0 : 5} {...props}>
				<Stack spacing={2} {...props}>
					<Headline3>{headline}</Headline3>
					<Typography variant="body1">{text}</Typography>
				</Stack>
				{actionBarPosition === 'top' && actionBar}
				{children}
				{actionBarPosition === 'bottom' && actionBar}
			</Stack>
		</Paper>
	);
};

export type ContentCard = typeof ContentCard;

// eslint-disable-next-line import/no-default-export
export default ContentCard;
