import { DropzoneFile } from 'app/components/Dropzone/types';
export const pendingFiles = (files: Partial<DropzoneFile[]>) => {
	if (files.length < 1) {
		return false;
	}
	const pendingFiles = files.filter((file) => file.progress < 100);
	if (pendingFiles.length < 1) {
		return false;
	}
	return pendingFiles;
};
