import { DateTime } from 'luxon';

export type ItemType = Partial<unknown> & { createdAt: Date | string };

export function sortGroupByDate<T extends ItemType>(items: T[], option = 'desc') {
	const groups: Record<string, T[]> = {};
	for (const item of items) {
		const createdDate = dateTime(item.createdAt).toFormat('yyyyMMdd');
		(groups[createdDate] = groups[createdDate] ?? []).push(item);
	}
	const dateGroupPairs = Object.entries(groups);
	const compareByDateAsFirstValue = compareBy('0', option);
	return dateGroupPairs.sort(compareByDateAsFirstValue);
}

export function compareBy(key: string, option = 'asc') {
	const order = option.toLowerCase() === 'asc' ? 1 : -1;
	return (a: unknown, b: unknown) => (a[key] > b[key] ? order : -order);
}

export function dateTime(dateOrString: Date | string) {
	return DateTime.fromJSDate(new Date(dateOrString));
}
