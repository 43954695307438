import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const useStyles = makeStyles((theme) => ({
	outer: {
		overflowY: 'auto',
		width: '100%',
		padding: theme.spacing(1, 2)
	},
	card: {
		'& > div': {
			paddingTop: theme.spacing(3)
		}
	}
}));

export const NoResultsForSearchScreen: React.FC<{
	message?: i18next.TFunction;
}> = ({ message }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<div className={classes.outer}>
			<Card className={classes.card}>
				<CardContent>
					<Typography variant="body2">{message || t('general:nothing_found_on_search')}</Typography>
				</CardContent>
			</Card>
		</div>
	);
};
