import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'app/core/reducers';
import { loadAccountCategoryStatistics, loadTaskareaStatistics } from './actions';
import { DashboardTile } from './Tile';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Chart from 'chart.js/auto';
import { translateAccountCategory } from '../../components/AccountCategorySelect';
import { Tableau20 } from 'hw-chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';
import { SetThree12 } from 'hw-chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer';
import { DashboardHeader } from './Header';

export function ChartSection() {
	const { accounts, taskareas } = useSelector((state: State) => ({
		accounts: state.sysadmin.dashboard.accounts,
		taskareas: state.sysadmin.dashboard.taskareas
	}));
	const dispatch = useDispatch();
	const { t } = useTranslation();
	React.useEffect(() => {
		dispatch(loadAccountCategoryStatistics());
		dispatch(loadTaskareaStatistics());
	}, []);

	React.useEffect(() => {
		if (!accounts) {
			return;
		}
		const labels = accounts.map((account) => {
			return translateAccountCategory(account.category, t);
		});
		const counts = accounts.map((account) => {
			return account.count;
		});
		const data = {
			labels,
			datasets: [
				{
					data: counts,
					backgroundColor: Tableau20
				}
			]
		};
		const config = {
			type: 'pie',
			data,
			options: {
				responsive: true
			}
		};
		const chart = new Chart(document.getElementById('category-pie'), config);

		return () => {
			chart.destroy();
		};
	}, [accounts]);

	React.useEffect(() => {
		if (!taskareas) {
			return;
		}
		const sortedTaskArea = taskareas.sort((a, b) => b.taskAreaCount - a.taskAreaCount);
		const labels = sortedTaskArea.map((taskarea) => {
			return taskarea.name;
		});
		const counts = sortedTaskArea.map((taskarea) => {
			return taskarea.taskAreaCount;
		});
		const data = {
			labels,
			datasets: [
				{
					axis: 'x',
					data: counts,
					backgroundColor: SetThree12
				}
			]
		};
		const config = {
			type: 'bar',
			data,
			options: {
				plugins: { legend: { display: false } },
				responsive: false,
				indexAxis: 'x'
			}
		};
		const chart = new Chart(document.getElementById('taskarea-bar'), config);

		return () => {
			chart.destroy();
		};
	}, [taskareas]);
	return (
		<div>
			<DashboardHeader>
				<Trans i18nKey="sysadmin:dashboard_charts_header" />
			</DashboardHeader>
			<Grid container spacing={2}>
				<DashboardTile
					header="Categories"
					content={<canvas id="category-pie" width="400" height="400"></canvas>}
				/>
				<DashboardTile header="Task areas" content={<ScrollChart />} />
			</Grid>
		</div>
	);
}

function ScrollChart(props) {
	const useStyles = makeStyles((theme) => ({
		chartWrapper: {
			width: '400px',
			overflowX: 'scroll'
		}
	}));
	const classes = useStyles(props);
	return (
		<div className={classes.chartWrapper}>
			<canvas className={classes.chart} id="taskarea-bar" width="1000" height="400"></canvas>
		</div>
	);
}
