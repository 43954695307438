import { Rating, Stack, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconListedItem } from '../../../mui-custom/IconListedItem';

export const ReferencesSection: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const { palette } = useTheme();
	return (
		<Stack sx={{ alignItems: 'center', gap: 4, py: 4 }}>
			<Stack sx={{ alignItems: 'center', gap: 1 }}>
				<Typography color={palette.primary.main}>
					{t('registration:starter_page_references').toUpperCase()}
				</Typography>
				{/* <Headline5>{t('registration:starter_page_references_headline')}</Headline5> */}
			</Stack>

			<IconListedItem
				// icon={
				// 	<Avatar
				// 		alt="listed-card-avatar"
				// 		src="./../../static/images/MaximilianOffizier_Testimonials.png"
				// 		sx={{ height: '10rem', width: '10rem' }}
				// 	/>
				// }
				// headline={t('registration:starter_page_product_references_headline')}
				// text={t('registration:starter_page_product_references_text')}
			>
				<Rating name="read-only" size="large" readOnly value={5} sx={{ my: 1, mx: 'auto' }} />
				<Typography variant="h6">
					{t('registration:starter_page_product_references_headline')}
				</Typography>
				<Typography sx={{ mx: 'auto' }}>
					{t('registration:starter_page_product_references_text')}
				</Typography>
			</IconListedItem>
		</Stack>
	);
};
