import React from 'react';
import { Typography } from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';

export interface Headline5Props {
	variant?: '' | 'isMobile' | 'isDesktop'; // option for overriding breakpoint changes
	children?: React.ReactNode;
	[key: string]: unknown;
}

export function Headline5({ variant, children, ...rest }: Headline5Props) {
	const isMobile = useMobile();
	const mobileMode = variant === 'isMobile' || isMobile;
	return (
		<Typography variant={mobileMode ? 'h6' : 'h5'} {...rest}>
			{children}
		</Typography>
	);
}

export type Headline5 = ReturnType<typeof Headline5>;
