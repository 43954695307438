import * as React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

interface TileProps {
	header: React.ReactNode;
	content: React.ReactNode;
}

export function DashboardTile(props: TileProps) {
	return (
		<Grid item>
			<Card>
				<CardContent>
					<Typography variant="h6" align="center">
						{props.header}
					</Typography>
					<Typography variant="h2" align="center">
						{props.content}
					</Typography>
				</CardContent>
			</Card>
		</Grid>
	);
}
