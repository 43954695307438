import i18next from 'i18next';
import { hideNotification, showNotification } from '../../core/actions';
import {
	getActiveSubscription,
	loadAccountDetails,
	loadPaymentSources,
	reactivateAccount,
	setPaywallForcedOpen,
} from '../../core/admin/account/actions';
import { State } from '../../core/reducers';
import { getSocket } from '../../core/socket';
import { i18n } from '../../i18n';
import { api } from '../../lib/api';
import { createAsyncConstants } from '../../lib/createConstants';

export const asyncConstants = createAsyncConstants(['OPEN_CHECKOUT', 'SUBSCRIPTION_CHANGED'], {
	prefix: 'UPGRADE_BUTTON'
});
let chargebeeInstance;
declare let Chargebee: any;

export const openPortalSession =
	(hasPricingModelV2: boolean) =>
	(dispatch, getState: () => State) => {
		if (!chargebeeInstance) {
			chargebeeInstance = Chargebee.init({
				site: import.meta.env.MODE === 'production' ? 'relay' : 'relay-test'
			});
		}
		const { activeSubscription, paymentSources } = getState().admin.account;
		chargebeeInstance.setPortalSession(() => api.get('/api/v1/chargebee/portal_session'));
		const chargebeePortalInstance = chargebeeInstance.createChargebeePortal();
		if (hasPricingModelV2) {
			chargebeePortalInstance.openSection(
				{
					sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
					subscriptionId: activeSubscription?.id
				},
				{
					paymentSourceAdd: () => {
						dispatch(
							showNotification(i18next.t('general:payment_method_add_successful'), 'success')
						);
						dispatch(loadPaymentSources());
						dispatch(reactivateAccount());
						chargebeeInstance.closeAll();
						dispatch(setPaywallForcedOpen());
					},
					paymentSourceUpdate: () => {
						dispatch(
							showNotification(i18next.t('general:payment_method_update_successful'), 'success')
						);
						dispatch(loadPaymentSources());
						chargebeeInstance.closeAll();
					}
				}
			);
			return;
		}
		const hasPaymentSources = paymentSources?.length > 0;
		const options = getOptionsForChargebeePortal(hasPaymentSources, dispatch);
		const forwardOptions = getForwardOptionsForChargebeePortal(
			activeSubscription?.id,
			hasPaymentSources
		);
		const close = () => {
			dispatch(getActiveSubscription());
			dispatch(loadAccountDetails());
		};
		chargebeeInstance.createChargebeePortal().open({ ...options, close }, forwardOptions);
	};

export const startListeningForSubscriptionChanges = () => (dispatch) => {
	const socket = getSocket();
	socket.emit('users.list.entered');
	socket.on('subscription.changed', (subscription) => {
		dispatch(hideNotification());
		dispatch(showNotification(i18n.t('general:changes_successful'), 'success'));
		dispatch({
			type: asyncConstants.SUBSCRIPTION_CHANGED.FULFILLED,
			payload: subscription
		});
		chargebeeInstance.closeAll();
		dispatch(getActiveSubscription());
		dispatch(loadAccountDetails());
	});
};

export const stopListeningForSubscriptionChanges = () => {
	const socket = getSocket();
	socket.emit('users.list.exited');
	socket.off('subscription.changed');
};

export const getOptionsForChargebeePortal = (hasPaymentSources, dispatch) => {
	return hasPaymentSources
		? {
				subscriptionChanged(data) {
					dispatch(showNotification(i18n.t('general:changes_processing'), 'info', true));
					dispatch({
						type: asyncConstants.SUBSCRIPTION_CHANGED.PENDING,
						payload: data.subscription.id
					});
				}
		  }
		: {
				paymentSourceUpdate(result) {
					if (result === 'error') {
						return;
					}
					dispatch(loadPaymentSources());
					dispatch(showNotification(i18next.t('general:changes_successful'), 'success'));
				}
		  };
};

export const getForwardOptionsForChargebeePortal = (subscriptionId, hasPaymentSources) => {
	return hasPaymentSources && subscriptionId
		? {
				sectionType: Chargebee.getPortalSections().EDIT_SUBSCRIPTION,
				params: {
					subscriptionId
				}
		  }
		: {
				sectionType: Chargebee.getPortalSections().EDIT_PAYMENT_SOURCE
		  };
};
