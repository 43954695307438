import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { State } from 'app/core/reducers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../../core/actions';
import { mergeAccount } from './actions';

export const MergeAccountConfirmDialog = ({
	isOpen,
	onClose,
	selectedAccount
}: {
	isOpen: boolean;
	onClose: () => void;
	selectedAccount: number;
}) => {
	const { t } = useTranslation();
	const { account } = useSelector((state: State) => state.sysadmin.users);
	const { accounts } = useSelector((state: State) => state.sysadmin);
	const dispatch = useDispatch();

	const mergeAccounts = async () => {
		try {
			// eslint-disable-next-line @typescript-eslint/await-thenable
			await dispatch(mergeAccount(account.id, selectedAccount));
			dispatch(showNotification(t('sysadmin:account_merge_account_success'), 'success'));
		} catch (e) {
			dispatch(showNotification(e, 'error'));
		}
		onClose();
	};
	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>{t('sysadmin:account_merge_account_confirm_title')}</DialogTitle>
			<DialogContent>
				<Alert severity="warning">
					{t('sysadmin:account_merge_account_confirm_content', {
						selectedAccount: accounts?.find((a) => a.id === selectedAccount)?.name,
						account: account.name
					})}
				</Alert>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => mergeAccounts()} color="primary">
					{t('sysadmin:account_merge_account_dialog_submit')}
				</Button>
				<Button onClick={onClose} color="secondary">
					{t('sysadmin:account_merge_account_dialog_cancel')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
