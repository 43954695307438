import { UIEmployee, UIOrder, DamageType } from '@api/v1';
import {
	Card,
	Divider,
	Grid,
	Typography,
	Box,
	DialogContent,
	Button,
	CircularProgress,
	CardHeader,
	Stack
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClockIcon from '@mui/icons-material/AccessAlarm';
import LocationIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment-with-locales-es6';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentList, AttachmentThumbnail } from '../../../components/Attachment';
import { Avatar } from '../../../components/Avatar';
import { EmployeeSearch } from '../../../components/EmployeeSearch';
import { IconicText } from '../../../components/IconicText';
import { getName, getFirstLetterOfCompanyForIcon } from '../../../lib/nameHelper';
import { getAddress, getMapAddress } from '../../../lib/address';
import { ContactPersonEntry } from './ContactPersonEntry';
import { DetailField } from './DetailField';
import { DateDialog } from '../../../components/DateTime';
import { State } from '../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import {
	setDueDateDialogOpen,
	changeDueDate,
	changeFollowUpDate,
	getSignedUrlForZippedAttachments
} from '../actions';
import {
	useFeature,
	Feature,
	useAccountSpecificFeature,
	AccountSpecificFeature
} from '../../acl/features';
import { HoverBase } from '../../../components/HoverBase';
import { transformDamageType } from '../../insurances/utils';
import { Link } from 'react-router-dom';
import CustomInput from '../../../mui-custom/Input';
import { DefinitionList } from '../../../mui-custom/DefinitionList';
import { useAccountDeactivatedCheck } from '../../../hooks/useAccountDeactivatedCheck';

const useStyles = makeStyles((theme) => ({
	iconizedDetails: {
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5)
	},
	dueDateDialogInfo: {
		color: theme.palette.grey[500],
		background: theme.palette.grey[200]
	},
	downloadAttachmentProgress: {
		marginLeft: theme.spacing(0.5)
	},
	insuranceContainer: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	}
}));

interface OrderContentProps {
	order: UIOrder;
	assign: (employee: UIEmployee) => void;
	children?: unknown;
}

export function OrderContent(props: OrderContentProps) {
	const { order, children } = props;
	const { address } = order;
	const [employeeMenuElement, setEmployeeMenuElement] = useState<HTMLElement>(null);
	const dueDateDialogOpen = useSelector((state: State) => state.orders.single.dueDateDialog.open);
	const { accountDeactivated } = useAccountDeactivatedCheck();
	const { account } = useSelector((state: State) => state.admin.account);
	const [inputsDisabled, setInputsDisabled] = useState(false);
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	useEffect(() => {
		if (
			(accountDeactivated ||
				new Date(account?.activePlanUntil) < new Date() ||
				order.status !== 'IN_PROGRESS') &&
			hasPricingModelV2
		) {
			setInputsDisabled(true);
		} else {
			setInputsDisabled(false);
		}
	}, [order, accountDeactivated, account]);
	const dispatch = useDispatch();
	const canEditDueDate = useFeature(Feature.DueDateEditing) && order.status === 'IN_PROGRESS';
	function onAssignmentSelect(employee: UIEmployee) {
		props.assign(employee);
		window.setTimeout(() => setEmployeeMenuElement(null), 50);
	}
	function onCloseEmployeeAssignment(event: React.SyntheticEvent) {
		setEmployeeMenuElement(null);
		if (event) {
			event.stopPropagation();
		}
	}
	const { t } = useTranslation();
	const classes = useStyles(props);
	const addressText = getAddress(address);
	const mapAddress = getMapAddress(address);

	const [followUpDateDialogOpen, setFollowUpDateDialogOpen] = useState(false);

	return (
		<>
			<Card>
				<CardHeader title={t('orders:detail_page_processing')} />
				{children}
				<Grid container spacing={2} sx={{ p: 2, pt: 0 }}>
					<CustomInput
						sm={6}
						id="order_details_button_followup"
						label={t('orders:label_follow_up')}
						placeholder={
							order.followUpDate
								? moment(order.followUpDate).format('L')
								: t('orders:label_no_follow_up')
						}
						onClick={() => !inputsDisabled && setFollowUpDateDialogOpen(true)}
						disabled={inputsDisabled}
					>
						{!order.isInternal && order.followUpDate && (
							<Box
								sx={{ mt: '10px', ml: -4, zIndex: 1 }}
								onClick={() => dispatch(changeFollowUpDate(null))}
							>
								<CloseIcon color="primary" />
							</Box>
						)}
					</CustomInput>

					<CustomInput
						sm={6}
						label={t('orders:label_assignee')}
						onClick={(event) => !inputsDisabled && setEmployeeMenuElement(event.currentTarget)}
						placeholder={order.assignee ? getName(order.assignee) : t('orders:assign_employee')}
						disabled={inputsDisabled}
					></CustomInput>
				</Grid>
				<DateDialog
					open={followUpDateDialogOpen}
					onClose={() => setFollowUpDateDialogOpen(false)}
					onSubmit={(date) => {
						setFollowUpDateDialogOpen(false);
						dispatch(changeFollowUpDate(date));
					}}
					initialValue={moment(order.followUpDate || new Date())}
					dialogTitle={t('orders:follow_up_date_dialog_header')}
					submitLabel={t('orders:follow_up_date_dialog_button_save')}
					disablePast
				/>

				<EmployeeSearch
					anchorEl={employeeMenuElement}
					onClose={onCloseEmployeeAssignment}
					onSelect={onAssignmentSelect}
					resetOption={{ text: t('orders:unassign'), value: null }}
				/>
			</Card>

			<Card>
				<CardHeader title={t('orders:detail_page_property')} />
				<DefinitionList
					sx={{ px: 2 }}
					items={[
						{
							key: t('orders:label_orderer'),
							value: (
								<IconicText
									className={classes.iconizedDetails}
									icon={
										<Avatar size="small">{getFirstLetterOfCompanyForIcon(order.customer)}</Avatar>
									}
									text={order?.customer?.name}
								/>
							)
						},
						{
							key: t('orders:label_address'),
							value: (
								<IconicText
									className={classes.iconizedDetails}
									icon={<LocationIcon />}
									text={
										<a href={`https://maps.google.com/?q=${mapAddress}`} target="_blank">
											{addressText}
										</a>
									}
								/>
							)
						}
					]}
				/>
			</Card>

			<Card sx={{ p: 2 }}>
				{!!order.dueDate && (
					<Grid item xs={12}>
						<Typography variant="subtitle2">{t('orders:label_due_date')}</Typography>
						{canEditDueDate ? (
							<React.Fragment>
								<HoverBase color="primary" onClick={() => dispatch(setDueDateDialogOpen(true))}>
									<Box display="flex" alignItems="center">
										<Box pr={1}>
											<ClockIcon fontSize="small" />
										</Box>
										<Typography variant="body2">{moment(order.dueDate).format('L')}</Typography>
										<Box pl={1}>
											<EditIcon fontSize="small" color="primary" />
										</Box>
									</Box>
								</HoverBase>
								<DateDialog
									open={dueDateDialogOpen}
									onClose={() => dispatch(setDueDateDialogOpen(false))}
									onSubmit={(date) => {
										dispatch(setDueDateDialogOpen(false));
										dispatch(changeDueDate(date));
									}}
									initialValue={moment(order.dueDate)}
									dialogTitle={t('orders:due_date_dialog_header')}
									submitLabel={t('orders:due_date_dialog_button_save')}
									disablePast
									content={
										<DialogContent className={classes.dueDateDialogInfo}>
											<Typography variant="body2">{t('orders:due_date_dialog_note')}</Typography>
										</DialogContent>
									}
								/>
							</React.Fragment>
						) : (
							<IconicText
								className={classes.iconizedDetails}
								icon={<ClockIcon />}
								text={`${t('orders:label_due_date')}: ${moment(order.dueDate).format('L')}`}
							/>
						)}
					</Grid>
				)}
				{!!order.fields?.length && (
					<>
						<br />
						<Typography variant="subtitle2">{t('orders:label_details')}</Typography>

						{order.fields
							.filter(({ name }) => name !== 'Kommentar')
							.map((field, i) => [
								<Divider key={`divider-${i}`} />,
								<DetailField key={field.name} name={field.name} content={field.content} />
							])}
					</>
				)}
				{!!order.insurance && (
					<>
						<br />
						<Typography variant="subtitle2">{t('orders:label_insurance')}</Typography>
						<Divider />
						<Grid container spacing={2} className={classes.insuranceContainer}>
							<Grid item xs={4}>
								<Link to="/app/customers_and_properties/insurances">
									{order.insurance.externalId}
								</Link>
							</Grid>
							<Grid item xs={4}>
								{order.insurance.description}
							</Grid>
							<Grid item xs={4}>
								{transformDamageType(order.insurance.damageType as DamageType[], t)
									.map((type) => type.label)
									.join(', ')}
							</Grid>
						</Grid>
					</>
				)}
				{!!order.attachments?.length && (
					<div>
						<br />
						<Typography variant="subtitle2">{t('orders:label_attachments')}</Typography>
						<AttachmentList>
							{order.attachments.map((attachment) => (
								<AttachmentThumbnail
									key={attachment.id}
									name={attachment.name}
									link={attachment.url}
									thumbnail={attachment.thumbnailS3Key}
								/>
							))}
						</AttachmentList>
						<Grid container justifyContent="flex-start" alignItems="center">
							<Button
								color="primary"
								id="order_details_download_attachments_button"
								variant="outlined"
								onClick={() => dispatch(getSignedUrlForZippedAttachments())}
							>
								{t('orders:download_attachments_button')}
							</Button>
							{order.zippedAttachments && order.zippedAttachments.isProcessing && (
								<CircularProgress
									className={classes.downloadAttachmentProgress}
									size={20}
									color="primary"
								/>
							)}
						</Grid>
					</div>
				)}
				{!!order.contactPersons?.length && (
					<>
						<br />
						<Typography variant="subtitle2">{t('orders:label_contactable_people')}</Typography>

						<div>
							{order.contactPersons.map((person, i) => (
								<div key={`contactContainer-${i}`}>
									<Divider key={`divider-${i}`} />
									<ContactPersonEntry key={`contactEntry-${i}`} contact={person} />
								</div>
							))}
						</div>
					</>
				)}
			</Card>
			<Stack>
				<Typography variant="h6">{t('orders:label_description')}</Typography>
				{order.fields.find(({ name }) => name === 'Kommentar')?.content}
			</Stack>
		</>
	);
}
