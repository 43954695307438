import React from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MarkEmailUnreadOutlined, CreditCardOutlined } from '@mui/icons-material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import { AdminAccountPage } from '../admin/account/App';
import { AdminUsersApp } from '../admin/users/App';
import { AdminUserDeactivationDialogV2 } from '../admin/users/DeactivationDialog';
import { AdminUserInvitationDialogV2 } from '../admin/users/InvitationDialog';
import { AdminUserDeletionDialogV2 } from '../admin/users/DeletionDialog';
import { useMobile } from '../../hooks/useBreakpoints';
import { Email } from './Email';
import { ProfileForm } from './ProfileForm';
import Page from '../../mui-custom/Page/Page';


const SUBSCRIPTION = 'subscription';
const PROFILE = 'profile';
const USER_LICENSE = 'user-license';
const EMAIL_NOTIFICATIONS = 'e-mail-notifications';

const getNavigationItems = (translate: i18next.TFunction) => [
	{
		icon: CreditCardOutlined,
		label: translate('general:payment_details'),
		link: '/app/settings/subscription'
	},
	{
		icon: AccountCircleOutlinedIcon,
		label: translate('general:profile'),
		link: '/app/settings/profile'
	},
	{
		icon: GroupOutlinedIcon,
		label: translate('general:account_users'),
		link: '/app/settings/user-license'
	},
	{
		icon: MarkEmailUnreadOutlined,
		label: translate('general:email_notifications'),
		link: '/app/settings/e-mail-notifications'
	}
];

const getActiveTab = (section: string) => {
	const sectionMap = {
		[SUBSCRIPTION]: 0,
		[PROFILE]: 1,
		[USER_LICENSE]: 2,
		[EMAIL_NOTIFICATIONS]: 3
	};

	return sectionMap[section] ?? 0;
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		overflowY: 'scroll',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#fff'
	},
	header: {
		backgroundColor: theme.palette.grey[400],
		zIndex: 1
	},
	link: {
		textDecoration: 'none',
		color: 'inherit'
	},
	indicator: {
		backgroundColor: '#fff'
	},
	container: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginTop: '10vh'
	}
}));

export function ProfileApp(props) {
	const { section } = useParams();
	const location = useLocation();
	const { t: translate } = useTranslation();
	const classes = useStyles(props);
	const selectedTab = getActiveTab(section);

	const isMobile = useMobile();

	return (
		<Grid className={classes.root} sx={{ m: isMobile ? 0 : 2, mb: 0 }}>
			<Container disableGutters={true} maxWidth="lg">
				<Page
					activePageId={selectedTab}
					navItems={getNavigationItems(translate)}
					innerPages={[
						<AdminAccountPage key={0} />,
						<ProfileForm key={1} />,
						<AdminUsersApp key={2} />,
						<Email key={3} />
					]}
					location={location}
				/>
				<AdminUserDeactivationDialogV2 />
				<AdminUserInvitationDialogV2 />
				<AdminUserDeletionDialogV2 />
			</Container>
		</Grid>
	);
}
