import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'app/core/reducers';
import { loadRegistrationStatistics, loadOrderStatistics } from './actions';
import { DashboardTile } from './Tile';
import { DashboardHeader } from './Header';
import { Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import moment from 'moment-with-locales-es6';

export function AdoptionSection() {
	const { registration, orders } = useSelector((state: State) => state.sysadmin.dashboard);
	const dispatch = useDispatch();
	const currentDate = moment();
	const lastMonth = currentDate.subtract(1, 'months').format('MMMM');
	const twoMonthsAgo = currentDate.subtract(1, 'months').format('MMMM');
	React.useEffect(() => {
		dispatch(loadRegistrationStatistics());
		dispatch(loadOrderStatistics());
	}, []);
	return (
		<div>
			<DashboardHeader>
				<Trans i18nKey="sysadmin:dashboard_adoption_header" />
			</DashboardHeader>
			<Grid container spacing={2}>
				<DashboardTile
					header={<Trans i18nKey="sysadmin:dashboard_adoption_widget_signup_rate" />}
					content={registration ? `${(registration.signupRate * 100 || 0).toFixed(2)}%` : null}
				/>
				<DashboardTile
					header={<Trans i18nKey="sysadmin:dashboard_adoption_widget_invitations" />}
					content={registration ? String(registration.invitationCount) : null}
				/>
				<DashboardTile
					header={<Trans i18nKey="sysadmin:dashboard_adoption_widget_registrations" />}
					content={registration ? String(registration.registrationCount) : null}
				/>
				<DashboardTile
					header={
						<Trans
							i18nKey="sysadmin:dashboard_adoption_widget_created_orders_last"
							values={{ month: lastMonth }}
						/>
					}
					content={orders ? String(orders.createdOrdersLastMonth) : null}
				/>
				<DashboardTile
					header={
						<Trans
							i18nKey="sysadmin:dashboard_adoption_widget_created_orders_last"
							values={{ month: twoMonthsAgo }}
						/>
					}
					content={orders ? String(orders.createdOrdersTwoMonthsAgo) : null}
				/>
				<DashboardTile
					header={
						<Trans
							i18nKey="sysadmin:dashboard_adoption_widget_closed_orders_last"
							values={{ month: lastMonth }}
						/>
					}
					content={orders ? String(orders.closedOrdersLastMonth) : null}
				/>
				<DashboardTile
					header={
						<Trans
							i18nKey="sysadmin:dashboard_adoption_widget_closed_orders_last"
							values={{ month: twoMonthsAgo }}
						/>
					}
					content={orders ? String(orders.closedOrdersTwoMonthsAgo) : null}
				/>
			</Grid>
		</div>
	);
}
