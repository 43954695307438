import { combineReducers } from 'redux';
import { reducers as auth, AuthState } from './auth/reducers';
import { reducers as accounts, AccountsState } from './accounts/reducers';
import { reducers as users, UsersState } from './accounts/users/reducers';
import { reducers as dashboard, DashboardState } from './dashboard/reducers';
import { reducers as scheduledTasks, ScheduledTasksState } from './scheduled-tasks/reducers';

export interface SysadminState {
	auth: AuthState;
	accounts: AccountsState;
	users: UsersState;
	dashboard: DashboardState;
	scheduledTasks: ScheduledTasksState;
}

export const reducers = combineReducers<SysadminState>({
	auth,
	accounts,
	users,
	dashboard,
	scheduledTasks
});
