import React, { useEffect, useState } from 'react';
import { Theme, Button, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { openAcceptDialog, openDeclineDialog } from '../actions';
import PrimaryButton, { ButtonColor } from '../../../mui-custom/Button/PrimaryButton';
import { State } from '../../reducers';
import { AccountSpecificFeature, useAccountSpecificFeature } from '../../acl/features';
import { BuyCreditsModal } from './BuyCreditsModal';
import { useAccountDeactivatedCheck } from '../../../hooks/useAccountDeactivatedCheck';

const useStyles = makeStyles((theme: Theme) => ({
	buttonBar: {
		display: 'flex',
		justifyContent: 'flex-end',
		color: theme.orderStatus.open.contrastText
	},
	button: {
		margin: theme.spacing(1)
	},
	rejectButton: {
		background: theme.palette.grey[200],
		'&:hover': { background: theme.palette.grey[300] },
		color: '#000'
	}
}));

export const InitialActionsV1 = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	return (
		<div className={classes.buttonBar}>
			<Button
				id="order_details_button_decline"
				variant="contained"
				color="secondary"
				className={cx(classes.button)}
				onClick={() => dispatch(openDeclineDialog())}
			>
				{t('orders:cta_decline')}
			</Button>
			<Button
				id="order_details_button_accept"
				variant="contained"
				color="primary"
				className={classes.button}
				onClick={() => dispatch(openAcceptDialog())}
			>
				{t('orders:cta_accept')}
			</Button>
		</div>
	);
};

export function useCheckCredits() {
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	const { account } = useSelector((state: State) => state.admin.account);
	const { usedOrderCredits, orderCreditsPlan, orderCreditsAddon } = account || {};
	const isOutOfCredits =
		hasPricingModelV2 && orderCreditsPlan + orderCreditsAddon <= usedOrderCredits;
	return { isOutOfCredits };
}

export const InitialActions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { accountDeactivated } = useAccountDeactivatedCheck();

	const { isOutOfCredits } = useCheckCredits();

	const [isAcceptBtnClicked, setIsAcceptBtnClicked] = useState(false);
	const [openBuyCredits, setOpenBuyCredits] = useState(false);

	const onClose = () => {
		setIsAcceptBtnClicked(false);
		setOpenBuyCredits(false);
	};

	useEffect(() => {
		if (!isAcceptBtnClicked) return;
		if (isOutOfCredits) return setOpenBuyCredits(true);
		dispatch(openAcceptDialog());
		setIsAcceptBtnClicked(false);
	}, [isAcceptBtnClicked, isOutOfCredits]);

	return (
		<>
			<Stack direction="row" spacing={2}>
				<PrimaryButton
					color={ButtonColor.Destructive}
					id="order_details_button_decline"
					onClick={() => dispatch(openDeclineDialog())}
					disabled={accountDeactivated}
				>
					{t('orders:cta_decline')}
				</PrimaryButton>
				<PrimaryButton
					id="order_details_button_accept"
					onClick={() => setIsAcceptBtnClicked(true)}
					disabled={accountDeactivated}
				>
					{t('orders:cta_accept')}
				</PrimaryButton>
			</Stack>
			<BuyCreditsModal open={openBuyCredits} onClose={onClose} />
		</>
	);
};
