import * as React from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import { Trans } from 'react-i18next';

interface LegalLinkProps extends Omit<LinkProps, 'href' | 'target'> {}

export const TermsLink = (props: LegalLinkProps) => {
	return (
		<Link {...props} target="_blank" href="https://relay.immo/agb/">
			{props.children || <Trans i18nKey="general:footer_terms_and_conditions" />}
		</Link>
	);
};

export const ImprintLink = (props: LegalLinkProps) => {
	return (
		<Link {...props} target="_blank" href="https://relay.immo/impressum/">
			<Trans i18nKey="general:footer_imprint" />
		</Link>
	);
};

export const PrivacyLink = (props: LegalLinkProps) => {
	return (
		<Link {...props} target="_blank" href="https://relay.immo/datenschutz/">
			{props.children || <Trans i18nKey="general:footer_privacy" />}
		</Link>
	);
};

export const LicenseLink = (props: LegalLinkProps) => {
	return (
		<Link {...props} target="_blank" href="https://app.relay.immo/static/files/licenses.txt">
			<Trans i18nKey="general:footer_licenses" />
		</Link>
	);
};
