import { SRTask } from '@api/sysadmin';
import { asyncConstants } from './actions';

export type ScheduledTasksState = SRTask[];

export const reducers = (state: ScheduledTasksState = null, action) => {
	switch (action.type) {
		case asyncConstants.GET_SCHEDULED_TASKS.FULFILLED:
			return action.payload;
		default:
			return state;
	}
};
