import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeAddDialog, createCustomer } from './actions';
import { useTranslation } from 'react-i18next';
import { CreateCustomerBody } from '@api/v1';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';
import Page from '../../mui-custom/Page/Page';
import Input from '../../mui-custom/Input';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const initialState: CreateCustomerBody = {
	address: {
		streetAddress: '',
		locality: '',
		postalCode: '',
		country: 'DE'
	},
	name: '',
	emailAddress: '',
	telephone: '',
	origin: 'relay',
	status: 'ACCEPTED'
};

export const AddingPage = ({ navBack = null }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [customer, setCustomer] = useState(initialState);

	const setField = (field: string) => (e) => setCustomer({ ...customer, [field]: e.target.value });

	const setAddressField = (field: string) => (e) =>
		setCustomer({
			...customer,
			address: {
				...customer.address,
				[field]: e.target.value
			}
		});

	return (
		<ValidatorForm
			onSubmit={() => {
				dispatch(createCustomer(customer));
				navBack();
			}}
		>
			<Page
				style={{ maxWidth: '50rem' }}
				headline={t('contact:add_dialog_title')}
				actionBar={
					<ActionBar
						primary={{
							children: t('customers:edit_dialog_save'),
							type: 'submit'
						}}
						secondary={{
							children: t('customers:edit_dialog_cancel'),
							onClick: () => {
								dispatch(closeAddDialog());
								navBack();
							}
						}}
					/>
				}
			>
				{!!customer && (
					<Grid container spacing={2}>
						<Input
							label={t('customers:add_dialog_internal_id')}
							onChange={setField('internalId')}
							value={customer.internalId}
						/>
						<Grid item xs={12}>
							<TextValidator
								fullWidth
								name="name"
								label={t('customers:add_dialog_name')}
								onChange={setField('name')}
								value={customer.name}
								validators={['required']}
								errorMessages={[t('general:required_message')]}
							/>
						</Grid>
						<Input
							sm={6}
							label={t('contact:add_dialog_street_address')}
							onChange={setAddressField('streetAddress')}
							value={customer.address.streetAddress}
						/>
						<Input
							sm={6}
							label={t('contact:add_dialog_postal_code')}
							onChange={setAddressField('postalCode')}
							value={customer.address.postalCode}
						/>
						<Input
							label={t('customers:add_dialog_locality')}
							onChange={setAddressField('locality')}
							value={customer.address.locality}
						/>
						<Grid item sm={6} xs={12}>
							<TextValidator
								fullWidth
								name="email"
								label={t('contact:add_dialog_email_address')}
								onChange={setField('emailAddress')}
								value={customer.emailAddress}
								validators={['required', 'isEmail']}
								errorMessages={[
									t('general:required_message'),
									t('validation:invalid_email_address')
								]}
							/>
						</Grid>
						<Input
							sm={6}
							label={t('contact:add_dialog_telephone')}
							onChange={setField('telephone')}
							value={customer.telephone}
						/>
					</Grid>
				)}
			</Page>
		</ValidatorForm>
	);
};
