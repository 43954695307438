import React from 'react';
import { Grid, Stack, TableCell, Typography, useTheme } from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';
import { Headline1 } from '../Headline1';
import { Headline3 } from '../Headline3';
import makeStyles from '@mui/styles/makeStyles';
import { formatPrice } from '../helpers';

const useStyles = makeStyles((theme) => ({
	strikePrice: {
		color: theme.palette.grey[400]
	}
}));

export function PriceTagCell({
	price = '',
	children = '',
	variant = '',
	sx = {},
	strikeThrough = ''
}) {
	const { t } = useTranslation();
	const { palette } = useTheme();
	const isMobile = useMobile();
	const mobileMode = variant === 'isMobile' || isMobile;
	const classes = useStyles();

	return (
		<TableCell sx={{ ...sx, px: 0 }}>
			<Grid
				sx={{
					backgroundColor: palette.infoShades4p,
					p: 2,
					pr: 4,
					borderRadius: 2,
					width: mobileMode ? '100%' : 'fit-content'
				}}
			>
				<Headline1 sx={{ p: 2 }}>
					<s className={classes.strikePrice}>{strikeThrough ? strikeThrough + ' ' : null}</s>
					{formatPrice(price)}€
				</Headline1>
				<Typography variant="body2" sx={{ p: 2, pt: 0, color: palette.text.secondary }}>
					{children || t('admin:account_pricing_monthly_mwst')}
				</Typography>
			</Grid>
		</TableCell>
	);
}

export const PriceTag = ({ price = 0, children = null }) => (
	<Stack direction="row" gap={1} sx={{ alignItems: 'baseline' }}>
		<Headline3 color="primary">€{price}</Headline3>
		{children}
	</Stack>
);

export type PriceTagCell = ReturnType<typeof PriceTagCell>;
