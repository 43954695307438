import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../reducers';
import { acceptProperty, closeAcceptingDialog } from './actions';

export const AcceptingDialog = () => {
	const { open, property } = useSelector((state: State) => state?.properties?.acceptingDialog);
	const dispatch = useDispatch();
	return (
		<Dialog open={open}>
			<DialogTitle>
				<Trans i18nKey="properties:accepting_dialog_header" />
			</DialogTitle>
			<DialogContent>
				<Trans
					i18nKey="properties:accepting_dialog_content"
					values={{
						propertyName: property ? property.name : '',
						customerName: property ? property.customer.name : ''
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => dispatch(closeAcceptingDialog())}>
					<Trans i18nKey="general:cta_cancel" />
				</Button>
				<Button color="primary" onClick={() => dispatch(acceptProperty())}>
					<Trans i18nKey="properties:action_button_accept" />
				</Button>
			</DialogActions>
		</Dialog>
	);
};
