import { Button, CardActions, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { login } from '../auth/actions';
import { State } from '../reducers';
import ReactGA from 'react-ga';

const useStyles = makeStyles(() => ({
	actions: {
		justifyContent: 'flex-end'
	},
	content: {
		flexGrow: 1,
		marginTop: '12px'
	},
	link: {
		textDecoration: 'none'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '420px'
	}
}));

export const RegistrationFinished: React.FC = (): JSX.Element => {
	ReactGA.pageview(window.location.pathname + window.location.search);
	const { email, password } = useSelector((state: State) => ({
		email: state.registration.email,
		password: state.registration.password
	}));
	const classes = useStyles({});
	const dispatch = useDispatch();
	const { t } = useTranslation();
	return (
		<CardContent className={classes.container}>
			<Typography color="inherit" variant="h5">
				{t('registration:description_finished')}
			</Typography>
			<br />
			<Typography
				color="inherit"
				dangerouslySetInnerHTML={{
					__html: t('registration:content_finished', { email })
				}}
			/>
			<Typography
				variant="body2"
				className={classes.content}
				dangerouslySetInnerHTML={{
					__html: t('registration:content_finished_caption')
				}}
			/>
			<CardActions className={classes.actions}>
				<Link
					to="/login"
					onClick={() => dispatch(login({ email, password }))}
					className={classes.link}
				>
					<Button variant="contained" color="primary">
						{t('registration:button_lets_go')}
					</Button>
				</Link>
			</CardActions>
		</CardContent>
	);
};
