export type Callback = (error?: Error, data?: any) => any;

export const mapOrderStatusToColor = {
	OPEN: 'warning',
	IN_PROGRESS: 'info',
	INPROGRESS: 'info',
	FINISHED: 'success',
	DECLINED: 'secondary'
};

export const mapTicketStatusToColor = {
	OPEN: 'warning',
	INPROGRESS: 'info',
	POSTPONED: 'secondary',
	CLOSED: 'success'
};
