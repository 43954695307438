import {
	ActiveRestrictionsCount,
	ChargebeeSubscription,
	PaymentSource,
	UIAccount,
	UIAddress
} from '@api/v1';
import { combineReducers } from 'redux';
import { createReducer } from '../../../redux/createReducer';
import {
	addExternalProfilLogo,
	buyAdditionalOrder,
	closeAccountEditDialog,
	getActiveRestrictions,
	getActiveSubscription,
	loadAccountDetails,
	loadAccountUsedOrderCredits,
	loadPaymentSources,
	openAccountEditDialog,
	removeExternalProfilLogo,
	saveAccountDetails,
	saveExternalProfileDetails,
	setCountry,
	setLocality,
	setName,
	setPaywallClose,
	setPaywallForcedOpen,
	setPaywallOpen,
	setPostalCode,
	setStreet
} from './actions';

export enum PaywallState {
	OPEN = 'open',
	CLOSE = 'close',
	FORCED_OPEN = 'forced_open'
}
export interface AdminAccountState {
	account: UIAccount;
	activeSubscription: ChargebeeSubscription;
	activeRestrictions: ActiveRestrictionsCount;
	paymentSources: PaymentSource[];
	dialog: AccountEditDialogState;
	paywallActive: PaywallState;
}
export interface AccountEditDialogState {
	open: boolean;
	fields: AccountEditDialogFieldsState;
}
export interface AccountEditDialogFieldsState {
	name: string;
	address: UIAddress;
}

export const adminAccountReducers = combineReducers<AdminAccountState>({
	account: createReducer(null)
		.handleAction(loadAccountUsedOrderCredits.success, (state, action) => ({
			...state,
			...action.payload
		}))
		.handleAction(buyAdditionalOrder.success, (state, action) => ({
			...state,
			...action.payload
		}))
		.handleAction(loadAccountDetails.success, (state, action) => action.payload)
		.handleAction(saveAccountDetails.success, (state, action) => action.payload)
		.handleAction(saveExternalProfileDetails.success, (state, action) => ({
			...state,
			externalProfile: action.payload
		}))
		.handleAction(addExternalProfilLogo.success, (state, action) => ({
			...state,
			externalProfile: { ...state.externalProfile, companyLogoUrl: action.payload }
		}))
		.handleAction(removeExternalProfilLogo.success, (state, action) => ({
			...state,
			externalProfile: { ...state.externalProfile, companyLogoUrl: '' }
		})),
	dialog: combineReducers<AccountEditDialogState>({
		open: createReducer(false)
			.handleAction(openAccountEditDialog, () => true)
			.handleAction(closeAccountEditDialog, () => false)
			.handleAction(saveAccountDetails.success, () => false),
		fields: combineReducers<AccountEditDialogFieldsState>({
			name: createReducer('')
				.handleAction(setName, (state, action) => action.payload)
				.handleAction(openAccountEditDialog, (state, action) => action.payload.name || ''),
			address: combineReducers<UIAddress>({
				country: createReducer('')
					.handleAction(setCountry, (state, action) => action.payload)
					.handleAction(
						openAccountEditDialog,
						(state, action) => action.payload.address.country || ''
					),
				locality: createReducer('')
					.handleAction(setLocality, (state, action) => action.payload)
					.handleAction(
						openAccountEditDialog,
						(state, action) => action.payload.address.locality || ''
					),
				postalCode: createReducer('')
					.handleAction(setPostalCode, (state, action) => action.payload)
					.handleAction(
						openAccountEditDialog,
						(state, action) => action.payload.address.postalCode || ''
					),
				streetAddress: createReducer('')
					.handleAction(setStreet, (state, action) => action.payload)
					.handleAction(
						openAccountEditDialog,
						(state, action) => action.payload.address.streetAddress || ''
					)
			})
		})
	}),
	activeSubscription: createReducer<ChargebeeSubscription>(null).handleAction(
		getActiveSubscription.success,
		(state, action) => action.payload
	),
	activeRestrictions: createReducer<ActiveRestrictionsCount>(null).handleAction(
		getActiveRestrictions.success,
		(state, action) => action.payload
	),
	paymentSources: createReducer(null).handleAction(
		loadPaymentSources.success,
		(state, action) => action.payload
	),
	paywallActive: createReducer(PaywallState.CLOSE)
		.handleAction(setPaywallOpen, () => PaywallState.OPEN)
		.handleAction(setPaywallClose, () => PaywallState.CLOSE)
		.handleAction(setPaywallForcedOpen, () => PaywallState.FORCED_OPEN)
});
