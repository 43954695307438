import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import * as React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';

export class CheckboxValidator extends ValidatorComponent {
	errorText() {
		const { isValid } = this.state;
		if (isValid) return null;
		return <FormHelperText>{this.getErrorMessage()}</FormHelperText>;
	}
	renderValidatorComponent() {
		const { value, onChange, label, color, classes } = this.props;
		return (
			<FormControl error={!this.state.isValid} variant="standard">
				<FormGroup>
					<FormControlLabel
						control={<Checkbox value={value} onChange={onChange} color={color} />}
						classes={{
							root: classes ? classes.group : null,
							label: classes ? classes.label : null
						}}
						label={label}
					/>
					{this.errorText()}
				</FormGroup>
			</FormControl>
		);
	}
}
