import { ListItem, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { History } from 'history';
import { useRouteMatch } from 'react-router';

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.grey[200],
		'&:hover': {
			background: theme.palette.grey[300]
		}
	},
	activeLink: {
		background: theme.palette.grey[400],
		color: theme.palette.grey[100],
		'&:hover': {
			background: theme.palette.grey[400]
		}
	},
	text: {
		paddingLeft: theme.spacing(5)
	},
	link: {
		color: 'inherit',
		textDecoration: 'none'
	}
}));

interface SubListItemProps {
	to: History.LocationDescriptor;
	children: React.ReactNode;
	isActive?: boolean;
	onClick?: () => void;
}

export function SubListItem(props: SubListItemProps) {
	const classes = useStyles(props);
	const match = useRouteMatch(typeof props.to === 'string' ? props.to : props.to.pathname);
	const isActive = typeof props.isActive === 'undefined' ? match : props.isActive;
	return (
		<NavLink to={props.to} className={classes.link}>
			<ListItem
				classes={{
					root: clsx([classes.root, { [classes.activeLink]: isActive }])
				}}
				onClick={isActive ? null : props.onClick}
				button
			>
				<ListItemText className={classes.text} primary={props.children} />
			</ListItem>
		</NavLink>
	);
}
