import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	width: {
		width: theme.spacing(50)
	}
}));
export function TitleDialog() {
	const { open, defaultValue } = useSelector((state: State) => ({
		open: state.orders.single.titleDialog.open,
		defaultValue: state.orders.single.order.title
	}));
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles({});
	return (
		<Dialog open={open} classes={{ paper: classes.width }}>
			<DialogTitle>{t('orders:dialog_title_header')}</DialogTitle>
			<DialogContent>
				<TextField
					id="order_title_dialog_field_title"
					defaultValue={defaultValue}
					onChange={(e) => dispatch(actions.changeTitle(e.target.value))}
					label={t('orders:label_title')}
					multiline
					fullWidth
					variant="standard"
				/>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					variant="text"
					onClick={() => dispatch(actions.closeTitleDialog())}
				>
					{t('general:cta_cancel')}
				</Button>
				<Button
					id="order_title_dialog_button_submit"
					color="primary"
					onClick={() => dispatch(actions.saveTitle())}
				>
					{t('orders:cta_save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
