import { UIOffer, OfferStatus } from '@api/v1';
import { Box, Card, Chip, Grid, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { dateTime } from '../../../lib/dateHelper';
import { NumberFormatter } from '../../../lib/numberFormater';
import { useTranslation } from 'react-i18next';

interface StyledCardProps {
	status?: OfferStatus;
}

const StyledCard = styled(Card, {
	shouldForwardProp: (prop) => prop !== 'status',
	name: 'StyledOfferCard',
	slot: 'Root'
})<StyledCardProps>(({ status, theme }) => ({
	padding: '0.5rem 0.75rem 0.5rem 0.75rem',
	marginRight: '0.75rem',
	marginBottom: '0.75rem',
	display: 'flex',
	flexDirection: 'column',
	gap: '0.5rem',
	borderRadius: '0.5rem',
	backgroundColor: '#F8F8F8',
	borderColor: status === 'ACCEPTED' ? '#32967E' : status === 'DECLINED' ? '#DB494F' : '#8F8FA3',
	[theme.breakpoints.down('xl')]: {
		minWidth: '11.563rem'
	},
	cursor: 'pointer'
}));

export const OfferCard = ({
	offer,
	handleOnClick
}: {
	offer: UIOffer;
	handleOnClick: () => void;
}) => {
	const { t } = useTranslation();
	const isDeclined = offer.status === 'DECLINED';
	return (
		<Grid item sm={12} md={6} lg={12} xl={6}>
			<StyledCard variant="outlined" status={offer.status} onClick={handleOnClick}>
				<Typography
					sx={{
						fontSize: '1rem',
						letterSpacing: '0.009rem',
						lineHeight: '1.188rem',
						textDecoration: isDeclined ? 'line-through' : ''
					}}
					variant="h6"
				>
					{offer.title}
				</Typography>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Typography sx={{ fontSize: '0.75rem' }}>{`${t(
						'offers:label_offer_price'
					)}:`}</Typography>
					<Chip
						size="small"
						label={`${NumberFormatter.currency().format(offer.price)}`}
						sx={{ textDecoration: isDeclined ? 'line-through' : '' }}
					/>
				</Box>

				<Typography
					sx={{
						fontSize: '0.75rem',
						opacity: '0.5',
						alignSelf: 'flex-end',
						color: isDeclined ? 'red' : 'auto'
					}}
				>
					{dateTime(offer.createdAt).toFormat('dd.MM.yyyy, HH:mm')}
				</Typography>
			</StyledCard>
		</Grid>
	);
};
