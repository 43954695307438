import { Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MergeAccountDialog } from './MergeAccountDialog';
export const MergeAccountButton = () => {
	const { t } = useTranslation();

	const [isMergeAccountDialogOpen, setIsMergeAccountDialogOpen] = React.useState(false);

	return (
		<>
			<Button variant="outlined" color="primary" onClick={() => setIsMergeAccountDialogOpen(true)}>
				{t('sysadmin:account_merge_account_button')}
			</Button>
			<MergeAccountDialog
				isOpen={isMergeAccountDialogOpen}
				onClose={() => setIsMergeAccountDialogOpen(false)}
			/>
		</>
	);
};
