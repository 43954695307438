import { UINotification } from '@api/v1';
import { api } from '../../lib/api';
import { createConstants } from '../../lib/createConstants';
import { State } from '../reducers';

let timeoutId;

export const constants = createConstants([
	'GET_NOTIFICATIONS',
	'SET_IS_LOADING',
	'GET_MORE_NOTIFICATIONS'
]);

export const getNotifications = () => (dispatch) => {
	dispatch({
		type: constants.GET_NOTIFICATIONS,
		payload: api.get<UINotification[]>('/api/v1/notifications?limit=10')
	});
};

export const fetchMoreNotifications = () => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_IS_LOADING,
		payload: true
	});
	const { lastId } = getState().auth.user.notifications?.metaData;
	timeoutId = setTimeout(() => dispatch(appendNotificationsToList(lastId)), 1000);
};

export const appendNotificationsToList = (afterId: number) => (dispatch) => {
	dispatch({
		type: constants.GET_MORE_NOTIFICATIONS,
		payload: api.get<UINotification[]>('/api/v1/notifications', { params: { afterId, limit: 10 } })
	});
	clearTimeout(timeoutId);
};
