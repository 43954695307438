import * as React from 'react';
import {
	Dialog,
	DialogProps,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogActionsProps
} from '@mui/material';
import { PrimaryButtonProps } from '../Button/PrimaryButton';
import { ButtonColor, SecondaryButtonProps } from '../Button/SecondaryButton';
import ActionBar from '../ActionBar/ActionBar';
import { addDefaultProps, addDefaultPropsWithTheme } from '../helpers';

const ConfirmDialogContainer = addDefaultProps<DialogProps>(Dialog, {
	fullWidth: true,
	maxWidth: 'xs'
});

const ConfirmDialogActions = addDefaultPropsWithTheme<DialogActionsProps>(
	DialogActions,
	(theme) => ({
		sx: {
			pb: '4px',
			[theme.breakpoints.up('md')]: {
				pb: '8px'
			}
		}
	})
);

type ConfirmDialogProps = DialogProps & {
	title: string;
	text?: string;
	primaryAction: PrimaryButtonProps;
	secondaryAction?: SecondaryButtonProps;
};

function ConfirmDialog(props: ConfirmDialogProps) {
	const { title, text, primaryAction = {}, secondaryAction = {}, ...dialogProps } = props;
	return (
		<ConfirmDialogContainer {...dialogProps}>
			<DialogTitle>{title}</DialogTitle>
			{!!text && <DialogContent>{text}</DialogContent>}
			<ConfirmDialogActions>
				<ActionBar
					primaryAsSecondary
					primary={{
						...primaryAction,
						color: primaryAction?.color || ButtonColor.Creative
					}}
					secondary={{
						...secondaryAction,
						children: secondaryAction?.children || 'Cancel', // TODO: what about i18n
						onClick: dialogProps?.onClose as React.MouseEventHandler<HTMLButtonElement>
					}}
				/>
			</ConfirmDialogActions>
		</ConfirmDialogContainer>
	);
}

export type ConfirmDialog = typeof ConfirmDialog;

export { ConfirmDialogContainer };
export { DialogTitle as ConfirmDialogTitle };
export { DialogContent as ConfirmDialogContent };
export { ConfirmDialogActions };

// eslint-disable-next-line import/no-default-export
export default ConfirmDialog;
