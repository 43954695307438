import { api } from '../../lib/api';

export const changeSubscription = async ({
	planId,
	coupon_code
}: {
	planId: string;
	coupon_code?: string;
}) =>
	api.post(
		`/api/v1/chargebee/change_subscription?planId=${planId}${
			coupon_code ? `&coupon_code=${coupon_code}` : ''
		}`
	);
