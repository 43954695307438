import { Grid, Typography, TextField, ButtonBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers';
import { setFilter } from '../actions';
import { IconicText } from '../../../components/IconicText';
import { Avatar } from '../../../components/Avatar';
import { EmployeeSearch } from '../../../components/EmployeeSearch';
import { CustomerFilter } from '../../../components/CustomerFilter';
import { getInitialsForPerson, getName } from '../../../lib/nameHelper';
import PersonIcon from '@mui/icons-material/Person';

const useStyles = makeStyles((theme) => ({
	distance: {
		marginTop: theme.spacing(1)
	}
}));

export const Filters = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles(props);
	const { street, postalCode, assignee, locality, customer } = useSelector(
		(state: State) => state.offerRequests.filters
	);

	const [assigneeMenuElement, setAssigneeMenuElement] = useState(null);

	return (
		<Grid container spacing={3}>
			<Grid item sm={6} xs={12}>
				<Grid container spacing={1}>
					<Grid item sm={6} xs={12}>
						<Typography variant="subtitle2" className={classes.distance}>
							{t('offers:label_assignee')}
						</Typography>
						<ButtonBase onClick={(e) => setAssigneeMenuElement(e.currentTarget)}>
							<IconicText
								icon={
									<Avatar size="small" src={assignee?.avatar || null}>
										{assignee ? getInitialsForPerson(assignee) : <PersonIcon />}
									</Avatar>
								}
								text={assignee ? getName(assignee) : t('offers:label_assignee_all')}
							/>
						</ButtonBase>
						<EmployeeSearch
							anchorEl={assigneeMenuElement}
							disableCreation
							onClose={() => setAssigneeMenuElement(null)}
							onSelect={(selectedAssignee) => dispatch(setFilter({ assignee: selectedAssignee }))}
							resetOption={{
								text: t('offers:reset_filter'),
								value: null
							}}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Typography variant="subtitle2" className={classes.distance}>
							{t('offers:label_customer')}
						</Typography>
						<CustomerFilter
							customer={customer}
							onSelect={(selectedCusomter) => dispatch(setFilter({ customer: selectedCusomter }))}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item sm={6} xs={12}>
				<Typography variant="subtitle2" className={classes.distance}>
					{t('offers:label_address')}
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							id="offer_filters_field_street_address"
							placeholder={t('offers:label_street')}
							fullWidth
							value={street}
							onChange={(e) => dispatch(setFilter({ street: e.currentTarget.value }))}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="offer_filters_field_postal_code"
							placeholder={t('offers:postal_code')}
							fullWidth
							value={postalCode}
							onChange={(e) => dispatch(setFilter({ postalCode: e.currentTarget.value }))}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							id="offer_filters_field_locality"
							placeholder={t('offers:label_locality')}
							fullWidth
							value={locality}
							onChange={(e) => dispatch(setFilter({ locality: e.currentTarget.value }))}
							variant="standard"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
