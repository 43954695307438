import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AccountSpecificFeature, useAccountSpecificFeature } from '../../core/acl/features';
import PrimaryButton from '../../mui-custom/Button/PrimaryButton';
import { State } from '../../core/reducers';
import { useMobile } from '../../hooks/useBreakpoints';
import { Plan } from '../UpgradeButton/App';
import { SecondaryButton } from '../../mui-custom/Button';
import { getPrettyPlanName } from '../../core/admin/account/utils';

export const UpgradeDialog = ({
	open,
	onClose,
	selectedPlan,
	onConfirm,
	confirmIsLoading
}: {
	open: boolean;
	onClose: () => void;
	selectedPlan: Plan;
	onConfirm: () => void;
	confirmIsLoading: boolean;
}) => {
	const { t } = useTranslation();
	const { user } = useSelector((state: State) => state.auth);
	const isMobile = useMobile();
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	const isPilotGroup1Account =
		hasPricingModelV2 && user.subscriptionStatus === null && user.activePlanUntil === null;

	return selectedPlan ? (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{t('admin:change_subscription_header')}</DialogTitle>
			<DialogContent dividers={false}>
				<Typography>
					{t('admin:confirm_subscription_change_message', {
						planName: getPrettyPlanName(selectedPlan.name),
						price: selectedPlan.price,
						credits: selectedPlan.credits
					})}
				</Typography>
			</DialogContent>
			<DialogActions sx={{ flexDirection: isMobile ? 'column-reverse' : 'row', gap: 1 }}>
				<SecondaryButton onClick={onClose} disabled={confirmIsLoading}>
					{t('general:cta_cancel')}
				</SecondaryButton>
				<PrimaryButton onClick={onConfirm} isLoadingButton loading={confirmIsLoading}>
					{isPilotGroup1Account
						? t('admin:change_subscription_confirm_start_trial')
						: t('admin:change_subscription_confirm')}
				</PrimaryButton>
			</DialogActions>
		</Dialog>
	) : null;
};
