import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { openFinishDialog, openDeclineDialog } from '../actions';
import { useDispatch } from 'react-redux';
import PrimaryButton, { ButtonColor } from '../../../mui-custom/Button/PrimaryButton';
import { useAccountDeactivatedCheck } from '../../../hooks/useAccountDeactivatedCheck';

export const InProgressActionsV1 = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<React.Fragment>
			<Button
				id="order_details_button_change_status"
				variant="contained"
				color="primary"
				onClick={handleOpen}
			>
				{t('orders:cta_change_status')}
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				getContentAnchorEl={null}
			>
				<MenuItem
					id="order_details_button_change_status_finish"
					onClick={() => dispatch(openFinishDialog())}
				>
					{t('orders:dialog_finish_cta')}
				</MenuItem>
				<MenuItem
					id="order_details_button_change_status_decline"
					onClick={() => dispatch(openDeclineDialog())}
				>
					{t('orders:cta_decline')}
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
};

export const InProgressActions = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { accountDeactivated } = useAccountDeactivatedCheck();

	return (
		<React.Fragment>
			<PrimaryButton
				id="order_details_button_change_status"
				onClick={handleOpen}
				disabled={accountDeactivated}
			>
				{t('orders:cta_change_status')}
			</PrimaryButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'center', vertical: 'top' }}
				getContentAnchorEl={null}
				sx={{ '& .MuiBackdrop-root': { background: 'transparent' } }}
			>
				<PrimaryButton
					id="order_details_button_change_status_finish"
					onClick={() => dispatch(openFinishDialog())}
				>
					{t('orders:dialog_finish_cta')}
				</PrimaryButton>
				<PrimaryButton
					color={ButtonColor.Destructive}
					id="order_details_button_change_status_decline"
					onClick={() => dispatch(openDeclineDialog())}
				>
					{t('orders:cta_decline')}
				</PrimaryButton>
			</Menu>
		</React.Fragment>
	);
};
