import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomConfirmDialog from '../../mui-custom/ConfirmDialog/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { submitRegistrationV2 } from './actions';
import { useRegSteps } from './Progress';
import { UIRegistrationSteps } from '../../data/enums';

export const ConfirmPlanDialog = ({
	open,
	plan,
	onClose
}: {
	open: boolean;
	plan: string;
	onClose: any;
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { onSubmitSuccess } = useRegSteps(UIRegistrationSteps.INSERT_PLAN);

	const onSubmitConfirmedPlan = () => {
		onClose();
		dispatch(submitRegistrationV2(plan, onSubmitSuccess()));
	};
	return (
		<CustomConfirmDialog
			open={open}
			title={t('registration:confirm_plan_dialog_title')}
			text={t('registration:confirm_plan_dialog_content', { plan })}
			primaryAction={{
				children: t('registration:confirm_plan_dialog_primary_action'),
				onClick: onSubmitConfirmedPlan
			}}
			secondaryAction={{
				children: t('registration:confirm_plan_dialog_secondary_action')
			}}
			onClose={onClose}
		/>
	);
};
