import { Alert, Badge, Button, Fab, Grid, Stack, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../../components/LoadingScreen';
import { i18n } from '../../i18n';
import { usePermission } from '../acl/permissions';
import { OwnerExclusivePermission } from '../../../server/permissions/permission';
import { State } from '../reducers';
import { loadCustomers, openAddDialog, openEditDialog, registerAndLoadCustomers } from './actions';
import { UpgradeCustomerDialog } from './UpgradeCustomerDialog';
import { EditingPage } from './EditDialog';
import { AddingPage } from './AddDialog';
import { CustomerTable } from './Table';
import { Link } from 'react-router-dom';
import {
	AccountSpecificFeature,
	Feature,
	useAccountSpecificFeature,
	useFeature
} from '../acl/features';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';
import { useMobile } from '../../hooks/useBreakpoints';
import { ArrowBack } from '@mui/icons-material';
import { ResponseCustomer } from '../../../server/customers/browser.types';
import { qs } from 'url-parse';

const useStyles = makeStyles((theme) => ({
	link: {
		textDecoration: 'none'
	},
	fab: {
		position: 'sticky',
		left: '100%',
		bottom: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(2)
	}
}));

enum NAV {
	LIST_PAGE = 'contact:list_page_title',
	ADDING_PAGE = 'contact:add_dialog_title',
	EDITING_PAGE = 'contact:edit_dialog_title'
}

/* this should be moved to components and have storybook examples */
interface PageNavProps<T> {
	onAction?: () => void;
	setActivePage: React.Dispatch<React.SetStateAction<T>>;
	activePage: T;
}
const PageNav = ({ onAction, setActivePage, activePage = NAV.LIST_PAGE }: PageNavProps<NAV>) => {
	const { t } = useTranslation();
	const isMobile = useMobile();
	const isNavigated = activePage !== NAV.LIST_PAGE;
	return (
		<ActionBar
			alternate={
				isNavigated
					? {
							onClick: () => setActivePage(NAV.LIST_PAGE),
							children: <ArrowBack />
					  }
					: null
			}
			primary={
				isMobile || isNavigated
					? null
					: { children: t('customers:page_nav_action_add_contact'), onClick: onAction }
			}
			sx={{ borderRadius: 0, borderBottomWidth: 1 }}
		>
			<Typography variant="h6">{t(activePage)}</Typography>
		</ActionBar>
	);
};

export function CustomersList(props: {}) {
	const isMobile = useMobile();
	const { metaData } = useSelector((state: State) => {
		return state.customers.list;
	});
	const { token } = qs.parse(location.search);

	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);

	const dispatch = useDispatch();
	const classes = useStyles(props);
	const hasAccountView = usePermission(OwnerExclusivePermission.VIEW_ACCOUNT);
	const hasChargebeeCheckout = useFeature(Feature.ChargebeeCheckout);
	const [page, setPage] = useState(NAV.LIST_PAGE);

	useEffect(() => {
		if (token) {
			dispatch(registerAndLoadCustomers(token));
		} else {
			dispatch(loadCustomers());
		}

		setPage(NAV.LIST_PAGE);
	}, []);
	if (!metaData) return <LoadingScreen />;

	const navToEditContact = (customer: ResponseCustomer) => () => {
		dispatch(openEditDialog(customer));
		setPage(NAV.EDITING_PAGE);
	};

	const navToAddContact = () => {
		dispatch(openAddDialog());
		setPage(NAV.ADDING_PAGE);
	};

	const { maxAcceptedCount, currentAcceptedCount } = metaData;
	return (
		<Grid sx={{ flexDirection: 'column', width: 1 }}>
			<PageNav setActivePage={setPage} activePage={page} onAction={navToAddContact} />

			<Grid
				sx={{
					display: 'grid',
					rowGap: 2.5,
					m: isMobile ? 0 : 2,
					/* 100% - Height of SubPAgeNav (ActionBar: 72px) - borderBottom (1px) - margin-bottom */
					height: `calc(100% - 72px - 1px - ${isMobile ? 0 : 16}px)`,
					overflow: 'auto'
				}}
			>
				{page === NAV.LIST_PAGE && (
					<Stack>
						{!hasPricingModelV2 && (
							<Alert severity="warning" sx={{ mb: 2 }}>
								<Grid sx={{ display: 'flex' }}>
									<Typography variant="body1">
										{i18n.t('customers:licenses_blockquote', {
											maxAcceptedCount: maxAcceptedCount || i18n.t('general:unlimited')
										})}
									</Typography>
									<Badge
										sx={{ my: 1, mx: 3 }}
										color={
											maxAcceptedCount && currentAcceptedCount >= maxAcceptedCount
												? 'error'
												: 'primary'
										}
										badgeContent={`${currentAcceptedCount}/${maxAcceptedCount || '*'}`}
									/>
								</Grid>
								{hasChargebeeCheckout &&
									(hasAccountView ? (
										<Link to="/app/settings/subscription" className={classes.link}>
											<Button sx={{ mt: 1 }} variant="contained" color="primary">
												{i18n.t('admin:licenses_upgrade_button')}
											</Button>
										</Link>
									) : (
										<Tooltip title={i18n.t('admin:contact_account_owner')}>
											<span>
												<Button sx={{ mt: 1 }} variant="contained" color="primary" disabled>
													{i18n.t('admin:licenses_upgrade_button')}
												</Button>
											</span>
										</Tooltip>
									))}
							</Alert>
						)}
						<CustomerTable
							addContact={() => setPage(NAV.ADDING_PAGE)}
							editContact={navToEditContact}
						/>
						{isMobile && (
							<Fab
								color="primary"
								variant="extended"
								sx={{ borderRadius: 2, ml: 'auto' }}
								onClick={() => setPage(NAV.ADDING_PAGE)}
								className={classes.fab}
							>
								<AddIcon />
								{i18n.t('customers:page_nav_action_add_contact_mobile')}
							</Fab>
						)}
					</Stack>
				)}
				{page === NAV.ADDING_PAGE && <AddingPage navBack={() => setPage(NAV.LIST_PAGE)} />}
				{page === NAV.EDITING_PAGE && <EditingPage navBack={() => setPage(NAV.LIST_PAGE)} />}
				<UpgradeCustomerDialog />
			</Grid>
		</Grid>
	);
}
