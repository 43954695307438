import { CircularProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.spacing(1)
	}
}));

interface LoadingScreenProps {
	className?: string;
}

export const LoadingScreen = ({ className }: LoadingScreenProps) => {
	const classes = useStyles();
	return (
		<div className={cx(classes.root, className)}>
			<CircularProgress />
		</div>
	);
};
