import { api } from '../api';
import { createApiAction } from '../../redux/createApiAction';
import {
	SysadminOrderStatistics,
	SysadminRegistrationStatistics,
	SysadminUsersStatistics,
	SysadminCustomersStatistics
} from '@api/sysadmin';

export const loadUserStatistics = createApiAction('sysadmin/load_user_statistics', () =>
	api.get<SysadminUsersStatistics>('/api/sysadmin/users/statistics')
);
export const loadRegistrationStatistics = createApiAction(
	'sysadmin/load_registration_statistics',
	() => api.get<SysadminRegistrationStatistics>('/api/sysadmin/registration/statistics')
);
export const loadCustomerStatistics = createApiAction('sysadmin/load_customer_statistics', () =>
	api.get<SysadminCustomersStatistics>('/api/sysadmin/customers/statistics')
);
export const loadOrderStatistics = createApiAction('sysadmin/load_order_statistics', () =>
	api.get<SysadminOrderStatistics>('/api/sysadmin/orders/statistics')
);
export const loadAccountCategoryStatistics = createApiAction(
	'sysadmin/load_account_category_statistics',
	() => api.get('/api/sysadmin/accounts/category/statistics')
);
export const loadTaskareaStatistics = createApiAction(
	'sysadmin/load_account_taskareas_statistics',
	() => api.get('/api/sysadmin/accounts/taskareas/statistics')
);
