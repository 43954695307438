import { createAsyncConstants } from '../../lib/createConstants';
import { api } from '../api';
import { State } from '../../core/reducers';
import { i18n } from '../../i18n';
import { showNotification } from '../../core/actions';

export const asyncConstants = createAsyncConstants(['GET_ACCOUNTS', 'DELETE_ACCOUNT'], {
	prefix: 'SYSADMIN'
});

export const getAccounts = () => ({
	type: asyncConstants.GET_ACCOUNTS.TYPE,
	payload: api.get('/api/sysadmin/accounts')
});
export const deleteAccount = (accountId: number) => (dispatch, getState: () => State) => {
	const account = getState().sysadmin.accounts.filter((a) => a.id === accountId)[0];
	const text = window.prompt(
		i18n.t('sysadmin:account_list_deletion_prompt', {
			accountName: account.name
		})
	);
	if (text === 'DELETE') {
		return dispatch({
			type: asyncConstants.DELETE_ACCOUNT.TYPE,
			payload: api
				.delete(`/api/sysadmin/accounts/${accountId}`)
				.then(() => {
					dispatch(
						showNotification(
							i18n.t('sysadmin:account_list_deletion_success', {
								accountName: account.name
							}),
							'success'
						)
					);
					return accountId;
				})
				.catch(() =>
					dispatch(showNotification(i18n.t('sysadmin:account_list_deletion_error'), 'error'))
				)
		});
	}
};
