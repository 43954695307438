import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography
} from '@mui/material';
import { PricingDetailsPaywall } from '../../components/PricingDetails';
import { PrimaryButton, SecondaryButton } from '../../mui-custom/Button';
import { Trans, useTranslation } from 'react-i18next';
import { useMobile } from '../../hooks/useBreakpoints';
import { AccountSpecificFeature, useAccountSpecificFeature } from '../acl/features';
import { State } from '../reducers';
import { useSelector } from 'react-redux';
import { PaywallState } from '../admin/account/reducers';

export const PilotPaywall = ({
	paywallState,
	close
}: {
	paywallState: PaywallState;
	close: () => void;
}) => {
	const { user } = useSelector((state: State) => state.auth);
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	const { t } = useTranslation();
	const isMobile = useMobile();
	const isPilotGroup1Account =
		hasPricingModelV2 && user.subscriptionStatus === null && user.activePlanUntil === null;

	return (
		<Dialog open={paywallState !== PaywallState.CLOSE} maxWidth={'lg'}>
			<DialogTitle>
				<Stack gap={2}>
					<Typography variant="h5">
						{isPilotGroup1Account ? t('general:paywall_pilot1_title') : t('general:paywall_title')}
					</Typography>
					{isPilotGroup1Account ? (
						<Typography
							variant="body1"
							dangerouslySetInnerHTML={{
								__html: t('general:paywall_pilot1_intro')
							}}
						/>
					) : (
						<Typography variant="body1">
							{t(
								paywallState !== PaywallState.FORCED_OPEN
									? 'general:paywall_intro'
									: 'general:paywall_intro_after_adding_payment'
							)}
						</Typography>
					)}
				</Stack>
			</DialogTitle>
			<DialogContent>
				<PricingDetailsPaywall onClose={close} />
			</DialogContent>
			<DialogActions sx={{ flexDirection: isMobile ? 'column-reverse' : 'row', gap: 1 }}>
				{paywallState !== PaywallState.FORCED_OPEN && (
					<PrimaryButton href="/app/settings/subscription">
						<Trans i18nKey="general:paywall_action" />
					</PrimaryButton>
				)}
				<SecondaryButton
					href="https://hilfe.relay.immo/de-form/articles/8967779-das-neue-relay-preismodell"
					target="_blank"
				>
					<Trans i18nKey="general:paywall_faq" />
				</SecondaryButton>
			</DialogActions>
		</Dialog>
	);
};
