import * as React from 'react';
import { Typography } from '@mui/material';

interface DashboardHeaderProps {
	children: React.ReactChild | string;
}

export function DashboardHeader(props: DashboardHeaderProps) {
	return (
		<Typography variant="h2" color="inherit" noWrap gutterBottom>
			{props.children}
		</Typography>
	);
}
