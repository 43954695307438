import { RefObject, useEffect, useMemo, useState } from 'react';

export function useCheckViewport(elementRef: RefObject<HTMLElement>) {
	const [isIntersecting, setIsIntersecting] = useState(false);

	const observer = useMemo(
		() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
		[]
	);

	useEffect(() => {
		if (elementRef.current) observer.observe(elementRef.current);
		return () => observer.disconnect();
	}, [elementRef?.current, observer]);

	return isIntersecting;
}
