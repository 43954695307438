import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';

export const api = {
	get<Response = any>(url: string, config?: AxiosRequestConfig): Promise<Response> {
		return axios
			.get(url, mergeConfig(_.merge(config, { params: { _: Date.now() } })))
			.then(({ data }) => data);
	},
	post<Body = any, Response = any>(
		url: string,
		body?: Body,
		config?: AxiosRequestConfig
	): Promise<Response> {
		return axios.post(url, body, mergeConfig(config)).then(({ data }) => data);
	},
	put<Body = any, Response = any>(
		url: string,
		body?: Body,
		config?: AxiosRequestConfig
	): Promise<Response> {
		return axios.put(url, body, mergeConfig(config)).then(({ data }) => data);
	},
	delete<Response = any>(url: string, config?: AxiosRequestConfig): Promise<Response> {
		return axios.delete(url, mergeConfig(config)).then(({ data }) => data);
	},
	patch<Body = any, Response = any>(
		url: string,
		body?: Body,
		config?: AxiosRequestConfig
	): Promise<Response> {
		return axios.patch(url, body, mergeConfig(config)).then(({ data }) => data);
	},
	uploadFile(file: File, config?: AxiosRequestConfig) {
		const data = new FormData();
		data.append('file', file);
		return axios.post('/api/v1/uploads', data, mergeConfig(config));
	}
};

function mergeConfig(config: AxiosRequestConfig): AxiosRequestConfig {
	const jwt = window.localStorage.getItem('sysadmin_jwt');
	const preconfig: AxiosRequestConfig = {
		headers: {
			Accept: 'application/json',
			Authorization: jwt ? `Bearer ${jwt}` : undefined
		}
	};
	return _.merge(preconfig, config);
}
