import { UIInsurance } from '@api/v1';
import { TableRow, TableCell, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { transformDamageType } from './utils';
import moment from 'moment-with-locales-es6';
import { createInsuranceStateKey } from '../../lib/i18keyGenerator';

interface InsuranceListEntryProps {
	insurance: UIInsurance;
	onEditClick: (insurance: UIInsurance) => void;
}

export function InsuranceListEntry({ insurance, onEditClick }: InsuranceListEntryProps) {
	const { t } = useTranslation();
	const damageTypes = insurance.damageType.map((type) => (type.value || type));
	const damageTypeLabels = transformDamageType(damageTypes, t).map(({ label }) => label);
	return (
		<TableRow sx={{ pr: 10 }}>
			<TableCell>{insurance.externalId}</TableCell>
			<TableCell>{!!insurance.status && t(createInsuranceStateKey(insurance.status))}</TableCell>
			<TableCell>{insurance.customer?.name}</TableCell>
			<TableCell>{insurance.properties?.map(({ name }) => name).join(', ')}</TableCell>
			<TableCell>{insurance.description}</TableCell>
			<TableCell>{damageTypeLabels.join(', ')}</TableCell>
			<TableCell>
				{insurance.expirationDate ? moment(insurance.expirationDate).format('DD.MM.YYYY') : ''}
			</TableCell>
			<TableCell>
				<IconButton color="primary" onClick={() => onEditClick(insurance)} size="large">
					<EditIcon />
				</IconButton>
			</TableCell>
		</TableRow>
	);
}
