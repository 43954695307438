import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../../components/LoadingScreen';
import { State } from '../reducers';
import { getNotifications, fetchMoreNotifications } from './actions';
import { NotificationListItemV1 } from './ListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UINotification } from '@api/v1';
import { compareBy } from '../../lib/dateHelper';
import { Container, Stack } from '@mui/material';
import Page from '../../mui-custom/Page/Page';
import { useTranslation } from 'react-i18next';
import { InfiniteLoaderEndMessage } from '../../components/InfiniteLoaderEndMessage';

export const NotificationApp = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { notificationsData, notificationsMetaData } = useSelector((state: State) => {
		return {
			notificationsData: state?.auth?.user?.notifications?.data,
			notificationsMetaData: state?.auth?.user?.notifications?.metaData
		};
	});
	useEffect(() => {
		dispatch(getNotifications());
	}, []);
	return (
		<Container
			disableGutters={true}
			maxWidth={false}
			sx={{ px: 2, pt: 3, pb: 2, height: '100%', overflowY: 'scroll' }}
			id="notifications-container"
		>
			<Page headline={t('notification:header')}>
				{!notificationsData ? (
					<LoadingScreen />
				) : (
					<InfiniteScroll
						next={() => dispatch(fetchMoreNotifications())}
						hasMore={notificationsMetaData.hasMore}
						dataLength={notificationsData?.length}
						loader={<LoadingScreen />}
						scrollThreshold="0px"
						scrollableTarget="notifications-container"
						endMessage={<InfiniteLoaderEndMessage itemName="notifications" />}
					>
						<Stack spacing={4}>
							{notificationsData
								.sort(compareBy('createdAt', 'desc'))
								.map((notification: UINotification) => (
									<NotificationListItemV1
										key={notification.activityLogId}
										notification={notification}
									/>
								))}
						</Stack>
					</InfiniteScroll>
				)}
			</Page>
		</Container>
	);
};
