import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Box, useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import { api } from '../../lib/api';
import { CustomerListResponse } from '@api/v1';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomerStatus } from '../../../server/customers/browser.types';
import { useMobile } from '../../hooks/useBreakpoints';
import { DashboardTileOrListedCard } from './App';

const StyledBadge = styled(Badge)(({ theme }) => ({
	alignSelf: 'center',
	'& .MuiBadge-badge': {
		width: theme.spacing(3),
		height: theme.spacing(3),
		border: '2px solid #fff',
		borderRadius: '50%',
		fontSize: theme.spacing(1.5),
		background: theme.palette.info.main
	}
}));

export const ConnectionRequestTile = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const history = useHistory();
	const isMobile = useMobile();
	const [customersCount, setCustomersCount] = useState(0);
	useEffect(() => {
		const loadPendingCustomersCount = async () => {
			const result = await api.get<CustomerListResponse>(
				`/api/v1/customers?status=${CustomerStatus.Pending}`
			);
			setCustomersCount(result.data.length || 0);
		};

		loadPendingCustomersCount().catch((e) =>
			console.error(`Fetching of customers with status PENDING failed with ${e.message}`)
		);
	}, []);

	const avatar = (
		<Avatar
			src="./../../static/images/Company.svg"
			alt="company vector"
			sx={{ width: '100%', height: '100%' }}
		/>
	);

	const header = (
		<>
			<Box component="span" sx={{ color: theme.palette.info.main }}>
				{`${customersCount} ${t('home:dashboard_connection_request_tile_header_color')}`}
			</Box>
			<span>
				{customersCount === 1
					? t('home:dashboard_connection_request_tile_header_without_color_singular')
					: t('home:dashboard_connection_request_tile_header_without_color_plural')}
			</span>
		</>
	);
	const media = (
		<StyledBadge badgeContent={customersCount} color="primary" overlap="circular">
			{isMobile ? avatar : <Box sx={{ width: '88px', height: '88px' }}>{avatar}</Box>}
		</StyledBadge>
	);

	if (customersCount <= 0) return null;
	return (
		<DashboardTileOrListedCard
			header={header}
			media={media}
			text={t('home:dashboard_connection_request_tile_text')}
			buttonText={t('home:dashboard_connection_request_tile_button')}
			onClick={() => history.push('/app/customers_and_properties/customers')}
		/>
	);
};
