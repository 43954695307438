import { Checkbox, Grid, ListItemText, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { UIExternalProfile, UploadToTempResults } from '@api/v1';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';
import { State } from '../../../../app/core/reducers';
import { radiusValues, taskAreaValues } from './constants';
import { fetchRatings } from '../../../../app/core/profile/actions';
import { saveExternalProfileDetails } from '../account/actions';
import { CustomSelectV2 } from '../../../components/CustomSelect';
import Page from '../../../mui-custom/Page/Page';
import ContentCard from '../../../mui-custom/ContentCard/ContentCard';
import ActionBar from '../../../mui-custom/ActionBar/ActionBar';

/*
this regex allows the following url pattern:
casavi.de
casavi.de/page1
www.casavi.de/page1
http://casavi.de
http://casavi.de/page1
http://www.casavi.de
http://www.casavi.de/page1
https://www.casavi.de
https://www.casavi.de/page1
*/
export const websiteRegex =
	'[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)';

export function AdminExternalProfileApp(props: {}) {
	const { user } = useSelector((state: State) => state.auth);
	const { account } = useSelector((state: State) => state.admin.account);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const scrollRef = useRef(null);

	const [isEditing, setIsEditing] = useState(false);
	const initialFormState: UIExternalProfile = {
		name: account?.externalProfile?.name || account?.name || '',
		email: account?.externalProfile?.email || user?.email || '',
		address: account?.externalProfile?.address || account?.address,
		companyDescription: account?.externalProfile?.companyDescription || '',
		taskAreas: account?.externalProfile?.taskAreas,
		radius: account?.externalProfile?.radius,
		isActive: account?.externalProfile?.isActive || false,
		companyWebsite: account?.externalProfile?.companyWebsite || ''
	};
	const [formData, setFormData] = useState(initialFormState);
	const [tmpImageData, setTmpImageData] = useState<UploadToTempResults>(null);

	const [error, setError] = React.useState({ taskAreas: false, radius: false });
	const touch =
		(name = '') =>
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setFormData({
				...formData,
				[name]: e.target.value
			});
			setError({ ...error, [name]: false });
		};

	const renderSelectedTaskAreas = (selectedValues: string[]) => {
		if (selectedValues.length !== 0) return selectedValues.join(', ');
		return <em>{t('admin:external_profile_taskarea_placeholder')}</em>;
	};

	const handleFormSubmit = (_ev) => {
		const _error = {
			taskAreas: !formData.taskAreas?.length,
			radius: !(formData.radius >= 0)
		};
		setError(_error);
		if (Object.values(_error).find(Boolean)) return;

		const { address: addressWithoutId } = formData;
		const formDataWithoutAddressId = formData;
		formDataWithoutAddressId.address = addressWithoutId;
		setIsEditing(false);
		dispatch(
			saveExternalProfileDetails({ externalProfile: formDataWithoutAddressId, tmpImageData })
		);
		setTmpImageData(null);
		setTmpImageURL(null);
	};

	useEffect(() => {
		setFormData(initialFormState);
		if (account?.externalProfile) dispatch(fetchRatings());
	}, [account]);

	return (
		<Page headline={t('general:company_data')} text={t('admin:external_profile_intro')}>
			<ContentCard
				headline={t('general:company_data')}
				text={t('admin:external_profile_intro')}
				actionBar={
					isEditing && (
						<ActionBar
							primary={{ children: t('general:cta_save_edit'), onClick: handleFormSubmit }}
							primaryAsSecondary
							secondary={{
								children: t('general:cta_discard'),
								onClick: () => {
									setIsEditing(false);
									setFormData(initialFormState);
								}
							}}
						/>
					)
				}
			>
				<ValidatorForm
					onSubmit={null}
					onFocus={() => setIsEditing(true)}
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 16
					}}
				>
					<TextValidator
						label={t('general:company_name')}
						validators={['required']}
						value={formData.name}
						onChange={(e) => setFormData({ ...formData, name: e.target.value })}
						errorMessages={[t('general:required_message')]}
						fullWidth
						variant="outlined"
					/>
					<TextValidator
						label={t('general:streetAddress')}
						validators={['required']}
						value={formData.address?.streetAddress || ''}
						onChange={(e) =>
							setFormData({
								...formData,
								address: {
									...formData.address,
									streetAddress: e.target.value
								}
							})
						}
						errorMessages={[t('general:required_message')]}
						fullWidth
						variant="outlined"
					/>
					<Grid sx={{ display: 'flex', gap: 2 }}>
						<TextValidator
							label={t('general:postal_code')}
							validators={['required']}
							value={formData.address?.postalCode || ''}
							onChange={(e) =>
								setFormData({
									...formData,
									address: {
										...formData.address,
										postalCode: e.target.value
									}
								})
							}
							errorMessages={[t('general:required_message')]}
							sx={{ width: '88px' }}
							variant="outlined"
						/>
						<Grid sx={{ flex: 1 }}>
							<TextValidator
								label={t('general:locality')}
								validators={['required']}
								value={formData.address?.locality || ''}
								onChange={(e) =>
									setFormData({
										...formData,
										address: {
											...formData.address,
											locality: e.target.value
										}
									})
								}
								errorMessages={[t('general:required_message')]}
								fullWidth
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<TextValidator
						label={t('general:email_address')}
						validators={['required', 'isEmail']}
						errorMessages={[t('general:required_message'), t('validation:invalid_email_address')]}
						value={formData.email}
						onChange={(e) => setFormData({ ...formData, email: e.target.value })}
						fullWidth
						variant="outlined"
					/>
					<TextValidator
						label={t('general:website')}
						validators={[`matchRegexp:${websiteRegex}`]}
						value={formData.companyWebsite}
						onChange={(e) =>
							setFormData({
								...formData,
								companyWebsite: e.target.value
							})
						}
						errorMessages={[t('general:no_valid_link')]}
						fullWidth
						variant="outlined"
					/>
					<TextField
						multiline
						rows={6}
						label={t('general:company_description')}
						value={formData.companyDescription}
						onChange={(e) =>
							setFormData({
								...formData,
								companyDescription: e.target.value
							})
						}
						fullWidth
						variant="outlined"
					/>
					<br />
					<CustomSelectV2
						name="taskAreas"
						id="external-profile-taskarea"
						header={t('admin:external_profile_taskarea')}
						subtitle={t('admin:external_profile_taskarea_subline')}
						placeholder={t('admin:external_profile_taskarea_placeholder')}
						multiple
						value={formData.taskAreas || []}
						onChange={touch('taskAreas')}
						renderValue={renderSelectedTaskAreas}
						error={error}
						sx={{ width: '100%' }}
						variant="outlined"
						ref={scrollRef}
					>
						{taskAreaValues.map((taskArea) => (
							<MenuItem key={taskArea} value={taskArea}>
								<Checkbox checked={!!formData.taskAreas?.includes(taskArea)} />
								<ListItemText primary={taskArea} />
							</MenuItem>
						))}
					</CustomSelectV2>
					<br />
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<CustomSelectV2
								name="radius"
								id="external-profile-radius"
								header={t('admin:external_profile_radius')}
								subtitle={t('admin:external_profile_radius_subline')}
								placeholder={t('admin:external_profile_radius_placeholder')}
								value={formData.radius >= 0 ? formData.radius : ''}
								onChange={touch('radius')}
								sx={{ width: '200px' }}
								error={error}
								variant="outlined"
							>
								{radiusValues.map((_radius) => (
									<MenuItem key={_radius.label} value={_radius.value}>
										{_radius.label}
									</MenuItem>
								))}
							</CustomSelectV2>
							{formData.radius === 0 && (
								<Typography variant="caption">
									{t('admin:external_profile_radius_unlimited_warning')}
								</Typography>
							)}
						</Grid>
					</Grid>
					<RouteLeavingGuard
						navigate={(path) => history.push(path)}
						shouldBlockNavigation={(_location) => isEditing}
						title={t('profile:discard_changes')}
						confirmButtonText={t('general:cta_discard_edit')}
					/>
				</ValidatorForm>
			</ContentCard>
		</Page>
	);
}
