import * as React from 'react';
import { CardContent, TextField, Button, CardActions } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { State } from '../../core/reducers';
import { bindActionCreators } from 'redux';
import { login } from './actions';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

const styles = createStyles({
	actions: {
		justifyContent: 'flex-end'
	},
	loginButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	link: {
		textDecoration: 'none'
	}
});

const mapStateToProps = (state: State) => ({});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			login
		},
		dispatch
	);

interface LoginFormProps
	extends WithStyles<typeof styles>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		RouteComponentProps<{}> {}
interface LoginFormState {
	email: string;
	password: string;
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
	state = {
		email: '',
		password: ''
	};
	onSubmit(event) {
		event.preventDefault();
		const { email, password } = this.state;
		this.props.login({ email, password }, (err) => {
			if (!err) {
				this.props.history.push('/sysadmin/app');
			}
		});
	}
	render() {
		const { classes } = this.props;
		return (
			<form onSubmit={this.onSubmit.bind(this)}>
				<CardContent>
					<TextField
						id="email"
						label={<Trans i18nKey="general:email" />}
						value={this.state.email}
						onChange={(e) => this.setState({ email: e.target.value })}
						margin="normal"
						fullWidth
						variant="standard"
					/>
					<TextField
						id="password"
						label={<Trans i18nKey="general:password" />}
						type="password"
						value={this.state.password}
						onChange={(e) => this.setState({ password: e.target.value })}
						margin="normal"
						fullWidth
						variant="standard"
					/>
					<div className={classes.loginButtonContainer}>
						<Button type="submit" color="primary" variant="contained">
							<Trans i18nKey="general:cta_login" />
						</Button>
					</div>
				</CardContent>
				<CardActions className={classes.actions}>
					<Link to="/sysadmin/demand_password" className={classes.link}>
						<Button size="small" color="primary">
							<Trans i18nKey="general:cta_forgot_password" />
						</Button>
					</Link>
				</CardActions>
			</form>
		);
	}
}

const wrapped = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
export { wrapped as LoginForm };
