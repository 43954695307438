import { UICustomer, UIEmployee, UIOfferRequestStatus, UIOfferRequestsList } from '@api/v1';
import { call, debounce, put, select } from 'redux-saga/effects';
import qs from 'qs';
import { History } from 'history';
import { pickBy, debounce as lodashDebounce } from 'lodash';
import { api } from '../../lib/api';
import { createAsyncConstants, createConstants } from '../../lib/createConstants';
import { State } from '../reducers';
import { i18n } from '../../i18n';
import {
	createAddFileAction,
	createChangeFileAction,
	createRemoveFileAction
} from '../../components/Dropzone';
import { FiltersState } from './reducers';
import { history } from '../history';
import { showNotification } from '../actions';
import { createApiAction } from '../../redux/createApiAction';
import { DropzoneFile } from 'app/components/Dropzone/types';
import { AnyAction } from 'redux';

export type OfferRequestStatus = UIOfferRequestStatus;

export interface LoadOfferRequestsOptions {
	limit?: number;
	afterId?: number;
	status?: OfferRequestStatus;
	assigneeId?: number;
	customerId?: number;
	assignee?: UIEmployee;
	customer?: UICustomer;
	postalCode?: string;
	searchString?: string;
	street?: string;
	locality?: string;
}

const [ALL, OPEN, SUBMITTED, ARCHIVE]: OfferRequestStatus[] = [
	'ALL',
	'OPEN',
	'SUBMITTED',
	'ARCHIVE'
];
const LIMIT_PER_PAGE = 15;

export const subMenuItems: OfferRequestStatus[] = [ALL, OPEN, SUBMITTED, ARCHIVE];

export const asyncConstants = createAsyncConstants(
	[
		'FETCH',
		'FETCH_MORE',
		'FETCH_STATISTICS',
		'FETCH_SINGLE',
		'CREATE_COMMENT',
		'ASSIGN_OFFER_REQUEST',
		'DECLINE_OFFER_REQUEST'
	],
	{
		prefix: 'OFFER_REQUESTS'
	}
);

export const constants = createConstants([
	'SET_STATUS_FILTER',
	'RESET_OFFER_REQUEST_LIST',
	'CLEAR_STATUS_FILTER',
	'CHANGE_COMMENT_TEXT',
	'ADD_COMMENT_FILE',
	'CHANGE_COMMENT_FILE',
	'REMOVE_COMMENT_FILE',
	'ADD_SUBMIT_DIALOG_FILE',
	'CHANGE_SUBMIT_DIALOG_FILE',
	'REMOVE_SUBMIT_DIALOG_FILE',
	'REMOVE_ALL_SUBMIT_DIALOG_FILES',
	'REMOVE_ALL_COMMENT_FILES',
	'OPEN_COMMENT_FILE_DIALOG',
	'CLOSE_COMMENT_FILE_DIALOG',
	'FETCH_OFFER_REQUEST_LIST',
	'SET_FILTER',
	'SET_IS_LOADING',
	'UPDATE'
]);

export const loadOfferRequests = (options?: LoadOfferRequestsOptions) => (dispatch) => {
	dispatch({ type: constants.RESET_OFFER_REQUEST_LIST });
	dispatch({
		type: asyncConstants.FETCH.TYPE,
		payload: api.get('/api/v1/offer-requests', {
			params: { ...options }
		})
	});
};

export function* loadOfferRequestsSaga() {
	yield debounce(500, constants.FETCH_OFFER_REQUEST_LIST + '_PENDING', _loadOfferRequests);
}

function* _loadOfferRequests(filters: AnyAction) {
	const state = yield select();
	const params = getLoadOfferRequestsParams(filters.payload, state);
	try {
		const results = yield call(api.get, '/api/v1/offer-requests', { params });
		yield put({
			type: constants.FETCH_OFFER_REQUEST_LIST + '_FULFILLED',
			payload: results
		});
	} catch (err) {
		yield put({
			type: constants.FETCH_OFFER_REQUEST_LIST + '_REJECTED',
			payload: err
		});
	}
}

function getLoadOfferRequestsParams(options: LoadOfferRequestsOptions, { offerRequests }: State) {
	const { assignee, customer, status, street, postalCode, locality, searchString } =
		offerRequests.filters;
	return {
		assigneeId: assignee?.id,
		customerId: customer?.id,
		status,
		street,
		postalCode,
		locality,
		searchString,
		limit: LIMIT_PER_PAGE,
		...options
	} as LoadOfferRequestsOptions;
}

export const fetchMoreOfferRequests = () => (dispatch) => {
	dispatch({
		type: constants.SET_IS_LOADING,
		payload: true
	});
	return dispatch(lodashDebounce(appendOfferRequestsToList({}), 1000));
};

export const appendOfferRequestsToList = createApiAction(
	'offer-requests/append_to_list',
	(options: LoadOfferRequestsOptions = {}, dispatch, getState: () => State) => {
		const params = getLoadOfferRequestsParams(
			{
				...options,
				afterId: getState().offerRequests.list.metaData.lastId
			},
			getState()
		);
		return api.get<UIOfferRequestsList>('/api/v1/offer-requests', { params });
	}
);

export const fetchSingleOfferRequest = (offerRequestId: number) => (dispatch) => {
	return dispatch({
		type: asyncConstants.FETCH_SINGLE.TYPE,
		payload: api.get(`/api/v1/offer-requests/${offerRequestId}`)
	});
};

export const loadOfferRequestStatistics = () => (dispatch) => {
	return dispatch({
		type: asyncConstants.FETCH_STATISTICS.TYPE,
		payload: api.get('/api/v1/offer-requests/statistics')
	});
};

export const setStatusFilter =
	(status: OfferRequestStatus, modifyUrl = false, navigateTo?: string) =>
	(dispatch, getState: () => State) => {
		dispatch({
			type: constants.SET_STATUS_FILTER,
			payload: status
		});
		if (!modifyUrl) return null;
		dispatch({ type: constants.RESET_OFFER_REQUEST_LIST });
		setFiltersInURL({ ...getState().offerRequests.filters, status }, { navigateTo });
	};

export const clearStatusFilter = () => (dispatch) => {
	dispatch({ type: constants.CLEAR_STATUS_FILTER });
	dispatch(loadOfferRequests());
};

export const loadArchivedOfferRequests = () => async (dispatch) => {
	const archiveStates = ['REJECTED', 'DECLINED', 'ARCHIVE'];
	const [rejectedOfferRequests, declinedOfferRequests, archivedOfferRequests] = await Promise.all(
		archiveStates.map((state) => {
			dispatch({ type: asyncConstants.FETCH.PENDING });
			return api.get('/api/v1/offer-requests', {
				params: { status: state }
			});
		})
	);
	dispatch({
		type: constants.UPDATE,
		payload: {
			data: [
				...rejectedOfferRequests?.data,
				...declinedOfferRequests?.data,
				...archivedOfferRequests?.data
			],
			metaData: null
		}
	});
};

export const assignOfferRequest = (employee: UIEmployee) => (dispatch, getState: () => State) => {
	const offerRequestId = getState().offerRequests.single.offerRequest.id;
	return dispatch({
		type: asyncConstants.ASSIGN_OFFER_REQUEST.TYPE,
		payload: api.patch(`/api/v1/offer-requests/${offerRequestId}`, {
			assigneeId: employee?.id || null,
			commentText: i18n.t('offers:comment_assignee_change', {
				name: `${getState().auth.user.firstName} ${getState().auth.user.lastName}`,
				assignee: employee ? `${employee.firstName} ${employee.lastName}` : null
			})
		})
	});
};

export const decline = (comment: string) => (dispatch, getState: () => State) => {
	const offerRequestId = getState().offerRequests.single.offerRequest.id;
	return dispatch({
		type: asyncConstants.DECLINE_OFFER_REQUEST.TYPE,
		payload: api.patch(`/api/v1/offer-requests/${offerRequestId}`, {
			status: 'DECLINED',
			comment,
			commentText: i18n.t('offers:comment_status_change_decline', {
				name: `${getState().auth.user.firstName} ${getState().auth.user.lastName}`
			})
		})
	});
};

interface OfferSubmitPayload {
	offerDescription: string;
	price: number;
	executionDate: string;
	files: DropzoneFile[];
}

export const submit = (payload: OfferSubmitPayload) => (dispatch, getState: () => State) => {
	const offerRequestId = getState().offerRequests.single.offerRequest.id;
	return dispatch({
		type: asyncConstants.ASSIGN_OFFER_REQUEST.TYPE,
		payload: api
			.patch(`/api/v1/offer-requests/${offerRequestId}`, {
				status: 'SUBMITTED',
				internalAuthorId: getState().auth.user.id,
				...payload
			})
			.then((res) => {
				const { status } = getState().offerRequests.filters;
				dispatch(loadOfferRequestStatistics());
				dispatch(loadOfferRequests({ status }));
				return res;
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			})
	});
};

export const openCommentFileDialog = () => ({
	type: constants.OPEN_COMMENT_FILE_DIALOG
});
export const closeCommentFileDialog = () => ({
	type: constants.CLOSE_COMMENT_FILE_DIALOG
});
export const addCommentFile = createAddFileAction(constants.ADD_COMMENT_FILE);
export const changeCommentFile = createChangeFileAction(constants.CHANGE_COMMENT_FILE);
export const removeCommentFile = createRemoveFileAction(constants.REMOVE_COMMENT_FILE);
export const addSubmitDialogFile = createAddFileAction(constants.ADD_SUBMIT_DIALOG_FILE);
export const changeSubmitDialogFile = createChangeFileAction(constants.CHANGE_SUBMIT_DIALOG_FILE);
export const removeSubmitDialogFile = createRemoveFileAction(constants.REMOVE_SUBMIT_DIALOG_FILE);
export const removeAllSubmitDialogFiles = () => ({
	type: constants.REMOVE_ALL_SUBMIT_DIALOG_FILES
});
export const removeAllCommentFiles = () => ({
	type: constants.REMOVE_ALL_COMMENT_FILES
});
export const changeCommentText = (text: string) => (dispatch, getState: () => State) =>
	dispatch({
		type: constants.CHANGE_COMMENT_TEXT,
		payload: text,
		meta: getState().offerRequests.single.offerRequest.id
	});

export const postComment = (text: string) => (dispatch, getState: () => State) =>
	dispatch({
		type: asyncConstants.CREATE_COMMENT.TYPE,
		payload: api.post(
			`/api/v1/offer-requests/${getState().offerRequests.single.offerRequest.id}/comments/`,
			{
				text,
				files: getState().offerRequests.single.commentCompose.files
			}
		),
		meta: getState().offerRequests.single.offerRequest.id
	});

export const setFilter =
	(param, isInitialFilter = false) =>
	(dispatch, getState: () => State) => {
		dispatch({
			type: constants.SET_FILTER,
			payload: param
		});
		dispatch(loadOfferRequestStatistics());
		if (param.assignee) {
			param.assigneeId = param.assignee.id;
			delete param.assignee;
		}
		if (param.customer) {
			param.customerId = param.customer.id;
			delete param.customer;
		}
		const { filters } = getState().offerRequests;
		if (!isInitialFilter) setFiltersInURL(filters);
		dispatch(loadOfferRequests({ ...filters, ...param }));
	};

// Returns true if filters other than status have been set
export function setFiltersInURL(filters: FiltersState, options?: { navigateTo?: string }): boolean {
	const pickedFilters = pickBy(
		filters,
		(_val, key) => !['collapsed', 'searchString', 'dialogOpen'].includes(key)
	);
	modifyHistory(history, {
		pathname: options?.navigateTo,
		search: { mode: 'merge', data: pickedFilters },
		mode: 'replace'
	});
	const { status, ...searchFilters } = pickedFilters;
	return Boolean(Object.keys(searchFilters).length);
}

type SearchModes = 'merge' | 'replace';
interface ModifySearchOptions {
	mode: SearchModes;
	data: object;
}

function modifySearch(_history: History, options: ModifySearchOptions): object {
	const currentSearch = qs.parse(_history.location.search, {
		ignoreQueryPrefix: true
	});
	if (options.mode === 'replace') return pickBy(options.data);
	return pickBy({ ...currentSearch, ...options.data });
}

type HistoryModes = 'replace' | 'push';
interface ModifyHistoryOptions {
	search: ModifySearchOptions;
	pathname?: string;
	mode?: HistoryModes;
}

function modifyHistory(_history: History, options: ModifyHistoryOptions): void {
	const search = modifySearch(_history, options.search);
	const location = {
		pathname: options.pathname,
		search: qs.stringify(search)
	};
	if (options.mode === 'push') return history.push(location);
	return history.replace(location);
}

export function getFiltersFromSearch(search: string): LoadOfferRequestsOptions {
	const { status, ...parsedSearch } = qs.parse(search, { ignoreQueryPrefix: true });
	return { ...parsedSearch, status: getStatusFromSearch(status) };
}

export function getStatusFromSearch(status) {
	if (status === undefined) return undefined;
	return subMenuItems.includes(status) ? status : ALL;
}
