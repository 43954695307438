import * as React from 'react';
import { ButtonBase, PropTypes } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonBaseProps } from '@mui/material/ButtonBase';
import clsx from 'clsx';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		transition: theme.transitions.create('background-color', {
			duration: theme.transitions.duration.shortest
		})
	},
	default: {
		'&:hover': {
			backgroundColor: alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity)
		}
	},
	primary: {
		'&:hover': {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)
		}
	},
	secondary: {
		'&:hover': {
			backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity)
		}
	}
}));

interface HoverBaseProps extends ButtonBaseProps {
	color?: PropTypes.Color;
}

export function HoverBase(props: HoverBaseProps) {
	const classes = useStyles({});
	const { color = 'default', ...buttonBaseProps } = props;
	return <ButtonBase className={clsx(classes.root, classes[color])} {...buttonBaseProps} />;
}
