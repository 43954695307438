/* eslint-disable curly */
import React from 'react';
import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	SvgIconTypeMap,
	Tab,
	Tabs
} from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';
import { OverridableComponent } from '@mui/types';

export type NavVariant = 'list' | 'tab' | '';
export interface ListOrTabNavProps {
	navItems: { icon: OverridableComponent<SvgIconTypeMap>; label: string; link?: string }[];
	variant?: NavVariant;
	width?: string | number;
	setActiveId?: (id: unknown) => void;
	activeId?: number;
	[x: string]: unknown;
}

function ListOrTabNav({
	navItems,
	variant = '',
	width = 300,
	setActiveId,
	activeId,
	...rest
}: ListOrTabNavProps) {
	const isMobile = useMobile();
	const mobileMode = variant === 'tab' || isMobile;

	return (
		<Paper sx={{ width: mobileMode ? '100%' : width, flex: 1, boxShadow: 'none' }} {...rest}>
			{mobileMode ? (
				<Tabs
					variant="scrollable"
					value={activeId}
					onChange={(_, id: number) => setActiveId(id)}
					sx={{ borderBottom: '1px solid #0000001f', borderRadius: 0 }}
				>
					{navItems.map((item, idx) => (
						<Tab key={idx} icon={<item.icon />} iconPosition="start" label={item.label} />
					))}
				</Tabs>
			) : (
				<List sx={{ p: 3 }}>
					{navItems.map((item, idx) => (
						<ListItem key={idx} disablePadding>
							<ListItemButton
								onClick={() => setActiveId(idx)}
								selected={idx === activeId}
								sx={{ borderRadius: 6 }}
							>
								<ListItemIcon>
									<item.icon />
								</ListItemIcon>
								<ListItemText primary={item.label} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)}
		</Paper>
	);
}

export type ListOrTabNav = typeof ListOrTabNav;

// eslint-disable-next-line import/no-default-export
export default ListOrTabNav;
