import { ValidatorForm } from 'react-material-ui-form-validator';
import { api } from './api';

export const phoneNumberRegex = /^(((\+|(00))((32)|(39)|(41)|(43)|(49)|(352)|(423)) ?)|(0[1-9]{1,3})|(\([0-9]+\)))((-|–| )?[0-9]+)+$/;
// + or 00 for country code: BE 32, IT 39, CH 41, AT 43, DE 49, Luxemburg 352, Liechtenstein 423. E.g. +49, 0049
// 0[1-9]{1,3} for mobile phone / some city code. E.g. 0172, 0835
// \([0-9]+\) for city code. E.g. (8888), (333)
// other regex (old) version for recheck existed phone number in DB? /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/;
export function isWhiteSpaceOnly(value: string) {
	return !/\S/.test(value);
}

export function initializeValidators() {
	ValidatorForm.addValidationRule('required', (value) => {
		if (value instanceof Array) {
			return value.length !== 0;
		} else if (typeof value === 'string') {
			return value !== '';
		}
		return Boolean(value);
	});
	ValidatorForm.addValidationRule('isEmailAvailable', async (value) => {
		const emailIsUsed = await checkEmail(value);
		return !emailIsUsed;
	});
	ValidatorForm.addValidationRule('isEmailChangedAndAvailable', async (value) => {
		const available = await checkEmailChangedAndAvailable(value);
		return !available;
	});
	ValidatorForm.addValidationRule('passwordLength', (value) => {
		return !(!value || value.length < 8);
	});
	ValidatorForm.addValidationRule('isValidPhoneNumber', (value) => {
		return phoneNumberRegex.test(value);
	});
	ValidatorForm.addValidationRule('isEmptyOrValidPhoneNumber', (value) => {
		return !value || phoneNumberRegex.test(value);
	});
	ValidatorForm.addValidationRule('filesUploaded', (files: any[]) => {
		return files.reduce((res, file) => {
			return res && file.progress === 100 && !file.error;
		}, true);
	});
	ValidatorForm.addValidationRule('isPrice', (price: string) => {
		return !!price.match('^[0-9]?[0-9]*[,]?[0-9]{0,2}$');
	});
}

export const checkEmail = (email: string) =>
	api.get(`/api/v1/registration/actions/check_email_address?email=${encodeURIComponent(email)}`);

const checkEmailChangedAndAvailable = (email: string) =>
	api.get(
		`/api/v1/user/check_email_address_changed_and_available?email=${encodeURIComponent(email)}`
	);
