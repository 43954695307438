import {
	UICustomer,
	UIEmployee,
	UIOfferRequest,
	UIOfferRequestsList,
	UIOfferRequestStatistics,
	UIOfferRequestStatus
} from '@api/v1';
import { combineReducers } from 'redux';
import { appendOfferRequestsToList, asyncConstants, constants } from './actions';
import { createReducer } from '../../redux/createReducer';
import { handleAddFile, handleChangeFile, handleRemoveFile } from '../../components/Dropzone';
import { DropzoneFile } from '../../components/Dropzone/types';
import _ from 'lodash';
import {
	CommentDrafts,
	initCommentDrafts,
	initDropzoneFiles,
	initOfferRequest
} from './initialState';

interface CommentComposeState {
	readonly files: DropzoneFile[];
	readonly fileDialogOpen: boolean;
	readonly isAdding: boolean;
}
interface SubmitDialogState {
	readonly files: DropzoneFile[];
}

export interface FiltersState {
	readonly status?: UIOfferRequestStatus;
	readonly assignee?: UIEmployee;
	readonly customer?: UICustomer;
	readonly postalCode?: string;
	readonly searchString?: string;
	readonly street?: string;
	readonly locality?: string;
}

interface SingleState {
	readonly offerRequest: UIOfferRequest;
	readonly commentCompose: CommentComposeState;
	readonly submitDialog: SubmitDialogState;
}
export interface OfferRequestsState {
	readonly list: UIOfferRequestsList;
	readonly hasMore?: boolean;
	readonly isLoading?: boolean;
	readonly statistics: UIOfferRequestStatistics;
	readonly filters: FiltersState;
	readonly commentDrafts: CommentDrafts;
	readonly single: SingleState;
}

const FULFILLED = '_FULFILLED';

export const reducers = combineReducers({
	isLoading: createReducer(false)
		.handleAction(appendOfferRequestsToList.request, () => true)
		.handleAction(appendOfferRequestsToList.success, () => false)
		.handleAction(appendOfferRequestsToList.failure, () => false)
		.handleAnyAction(constants.SET_IS_LOADING, () => true),
	list: createReducer<UIOfferRequestsList>({
		data: null,
		metaData: { lastId: null, hasMore: null }
	})
		.handleAction(appendOfferRequestsToList.success, (state, action) => {
			return {
				data: [...state.data, ...action.payload.data],
				metaData: action.payload.metaData
			};
		})
		.handleAnyAction(asyncConstants.FETCH.FULFILLED, (state, action) => {
			return action.payload;
		})
		.handleAnyAction(constants.UPDATE, (state, action) => {
			return { data: action.payload.data, metaData: action.payload.meta };
		})
		.handleAnyAction(constants.RESET_OFFER_REQUEST_LIST, (state, action) => {
			return {
				data: null,
				metaData: { lastId: null, hasMore: null }
			};
		}),
	statistics: createReducer<UIOfferRequestStatistics>({
		OPEN: 0,
		SUBMITTED: 0,
		ACCEPTED: 0,
		DECLINED: 0,
		REJECTED: 0,
		ALL: 0
	}).handleAnyAction(asyncConstants.FETCH_STATISTICS.FULFILLED, (state, action) => {
		return action.payload;
	}),
	filters: (state: FiltersState = {}, action) => {
		switch (action.type) {
			case constants.SET_STATUS_FILTER:
				return {
					...state,
					status: action.payload
				};
			case constants.CLEAR_STATUS_FILTER:
				return {
					...state,
					status: undefined
				};
			case constants.SET_FILTER:
				return {
					...state,
					...action.payload
				};
			default:
				return state;
		}
	},
	single: combineReducers<SingleState>({
		offerRequest: createReducer(initOfferRequest)
			.handleAnyAction(asyncConstants.FETCH_SINGLE.PENDING, () => null)
			.handleAnyAction(asyncConstants.FETCH_SINGLE.FULFILLED, (state, action) => action.payload)
			.handleAnyAction(
				asyncConstants.ASSIGN_OFFER_REQUEST.FULFILLED,
				(state, action) => action.payload
			)
			.handleAnyAction(
				asyncConstants.DECLINE_OFFER_REQUEST.FULFILLED,
				(state, action) => action.payload
			)
			.handleAnyAction(asyncConstants.CREATE_COMMENT.FULFILLED, (state, action) => {
				if (state.id === action.payload.offerRequestId) {
					return _.assign({}, state, {
						comments: _.concat([], state.comments, [action.payload])
					});
				}
				return state;
			}),
		commentCompose: combineReducers<CommentComposeState>({
			files: (state = initDropzoneFiles, action) => {
				switch (action.type) {
					case constants.ADD_COMMENT_FILE:
						return handleAddFile(state, action);
					case constants.CHANGE_COMMENT_FILE:
						return handleChangeFile(state, action);
					case constants.REMOVE_COMMENT_FILE:
						return handleRemoveFile(state, action);
					case asyncConstants.CREATE_COMMENT.PENDING:
					case constants.REMOVE_ALL_COMMENT_FILES:
						return [];
					default:
						return state;
				}
			},
			fileDialogOpen: (state = false, action) => {
				switch (action.type) {
					case constants.OPEN_COMMENT_FILE_DIALOG:
						return true;
					case constants.CLOSE_COMMENT_FILE_DIALOG:
						return false;
					default:
						return state;
				}
			},
			isAdding: (state = false, action) => {
				switch (action.type) {
					case asyncConstants.CREATE_COMMENT.PENDING:
						return true;
					case asyncConstants.CREATE_COMMENT.REJECTED:
					case asyncConstants.CREATE_COMMENT.FULFILLED:
						return false;
					default:
						return state;
				}
			}
		}),
		submitDialog: combineReducers<SubmitDialogState>({
			files: (state = initDropzoneFiles, action) => {
				switch (action.type) {
					case constants.ADD_SUBMIT_DIALOG_FILE:
						return handleAddFile(state, action);
					case constants.CHANGE_SUBMIT_DIALOG_FILE:
						return handleChangeFile(state, action);
					case constants.REMOVE_SUBMIT_DIALOG_FILE:
						return handleRemoveFile(state, action);
					case constants.REMOVE_ALL_SUBMIT_DIALOG_FILES:
						return [];
					default:
						return state;
				}
			}
		})
	}),
	commentDrafts: (state: CommentDrafts = initCommentDrafts, action) => {
		switch (action.type) {
			case constants.CHANGE_COMMENT_TEXT:
				return {
					...state,
					[action.meta]: action.payload
				};
			case asyncConstants.CREATE_COMMENT.PENDING:
				return {
					...state,
					[action.meta]: ''
				};
			default:
				return state;
		}
	}
});
