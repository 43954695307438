import {
	Button,
	ButtonBase,
	Divider,
	InputAdornment,
	Menu,
	MenuItem,
	TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from './Avatar';
import { IconicText } from './IconicText';
import { UICustomer } from '@api/v1';
import { Highlight } from './Highlight';
import { api } from '../../app/lib/api';

const useStyles = makeStyles((theme) => ({
	popover: {
		width: theme.spacing(30),
		maxWidth: theme.spacing(30)
	},
	menuItem: {
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	menuButton: {
		width: '100%',
		justifyContent: 'flex-start',
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`
	},
	searchField: {
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
		'&:focus': {
			outline: 'none'
		}
	},
	results: {
		maxHeight: theme.spacing(27),
		overflow: 'auto'
	}
}));

interface ResultsState {
	data: Array<UICustomer>;
}

export const CustomerFilter = (props) => {
	let searchField: HTMLInputElement;
	const { customer, onSelect } = props;
	const classes = useStyles({});
	const { t } = useTranslation();
	const [searchText, setSearchText] = useState('');
	const [menuEl, setMenuEl] = useState(null);
	const [results, setResults] = useState<ResultsState>({
		data: []
	});

	const searchCustomers = (search = '') => {
		api
			.get('/api/v1/customers', { params: { search } })
			.then((response) => setResults({ data: response.data }));
	};

	const onChangeInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const { value } = event.currentTarget;
		setSearchText(value);
		searchCustomers(value);
	};

	const handleSelect = (val: UICustomer) => {
		onSelect(val);
		setMenuEl(null);
	};

	useEffect(() => {
		searchCustomers();
	}, [menuEl]);

	return (
		<>
			<ButtonBase onClick={(e) => setMenuEl(e.currentTarget)}>
				<IconicText
					icon={
						<Avatar size="small">
							<PersonIcon />
						</Avatar>
					}
					text={customer ? customer.name : t('customers:label_customer_all')}
				/>
			</ButtonBase>
			<Menu
				open={Boolean(menuEl)}
				anchorEl={menuEl}
				getContentAnchorEl={null}
				onClose={() => setMenuEl(null)}
				disableAutoFocusItem
				classes={{ paper: classes.popover }}
				TransitionProps={{
					onEntered: () => (searchField ? searchField.focus() : null)
				}}
			>
				<TextField
					className={classes.searchField}
					value={searchText}
					onChange={onChangeInput}
					placeholder={t('customers:search')}
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						inputRef: (r) => (searchField = r)
					}}
					variant="standard"
				/>
				{results?.data?.length
					? [
							<Divider key="divider" />,
							<div key="list" className={classes.results}>
								{results.data.map((result) => (
									<MenuItem key={result.id} button onClick={() => handleSelect(result)}>
										<div className={classes.menuItem}>
											<Highlight highlight={searchText}>{result.name}</Highlight>
										</div>
									</MenuItem>
								))}
							</div>
					  ]
					: null}
				<Divider key="reset-divider" />
				<li key="reset">
					<Button color="primary" className={classes.menuButton} onClick={() => handleSelect(null)}>
						<div className={classes.menuItem}>{t('customers:reset_filter')}</div>
					</Button>
				</li>
			</Menu>
		</>
	);
};
