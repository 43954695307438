import { UIAppointment } from '@api/v1';
import { combineReducers } from 'redux';
import { constants, asyncConstants } from './actions';
import { MetaList } from 'server/browser.types';

export interface UIBrowserLazyLoadingMetaData {
	lastId: number;
	hasMore: boolean;
}

export interface UILazyLoadingMetaData extends UIBrowserLazyLoadingMetaData {
	isFetching: boolean;
}

export interface UIAppointmentList extends MetaList<UIAppointment, UILazyLoadingMetaData> {}

export interface AppointmentState {
	list: UIAppointmentList;
}

export const reducers = combineReducers<AppointmentState>({
	list: (state = null, action) => {
		switch (action.type) {
			case asyncConstants.FETCH.FULFILLED:
				return action.payload;
			case constants.SET_IS_FETCHING:
				return { ...state, metaData: { ...state.metaData, isFetching: true } };
			case asyncConstants.FETCH_MORE.FULFILLED:
				return {
					...action.payload,
					data: [...state.data, ...action.payload.data]
				};
			default:
				return state;
		}
	}
});
