import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import { fetchAppointments, fetchMoreAppointments } from './actions';
import { AppointmentListItem } from './ListItem';
import { useFeature, Feature } from '../acl/features';
import { LazyList, useStyles } from '../../components/LazyList';

export function AppointmentApp() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchAppointments());
	}, []);
	const classes = useStyles();
	const { list } = useSelector((state: State) => state.appointments);
	if (!useFeature(Feature.AppointmentView)) return null;

	return (
		<div className={classes.root} id="appointments">
			<div className={classes.controlBar}>
				<Button color="secondary" variant="contained">
					<Trans i18nKey="appointments:list_button_create" />
				</Button>
			</div>
			<LazyList
				next={fetchMoreAppointments}
				metaList={list}
				i18TextNoList="appointments:list_none_here"
				ListItem={AppointmentListItem}
			/>
		</div>
	);
}
