import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';

interface IconListedItemProps {
	icon?: React.ReactNode | string;
	headline?: React.ReactNode;
	text?: React.ReactNode;
	children?: React.ReactNode;
	primary?: boolean;
	border?: boolean;
	small?: boolean;
	sx?: object;
}

export function IconListedItem({
	icon,
	headline,
	text,
	children,
	primary = false,
	border = false,
	small = false,
	sx
}: IconListedItemProps) {
	const { palette } = useTheme();

	return (
		<Stack
			direction="row"
			spacing={0}
			sx={{
				borderRadius: 0.5,
				border: border ? '1px solid #E0E0E0' : 0,
				...sx
			}}
		>
			{!!icon && (
				<Stack
					sx={{
						boxSizing: 'content-box',
						alignItems: 'flex-end',
						justifyContent: 'center',
						flexGrow: 0,
						py: 1,
						px: headline ? 1 : 0,
						color: palette.text.secondary
					}}
				>
					{icon}
				</Stack>
			)}
			<Stack
				sx={{
					boxSizing: 'content-box',
					justifyContent: 'center',
					p: 1
				}}
			>
				<Stack sx={{ mt: children ? 'auto' : 0, gap: 0.5 }}>
					{children}
					<Typography sx={{ color: palette.text.primary }} variant="h6">
						{headline}
					</Typography>
					<Typography
						sx={{ color: primary ? palette.primary.main : palette.text.primary }}
						variant={small ? 'body2' : 'body1'}
					>
						{text}
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
}
