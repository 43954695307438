import { CardContent, Stack } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Headline5 } from '../../../mui-custom/Headline5';
import { ChargebeePlansV2 } from '../../../../server/chargebee/chargebee.enums';
import { ChangePlanButton } from '../ChangePlanButton';
import Check from '@mui/icons-material/Check';
import { useMobile } from '../../../hooks/useBreakpoints';
import { IconListedItem } from '../../../mui-custom/IconListedItem';

export const HeaderSection: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const isMobile = useMobile();
	return (
		<CardContent
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center',
				gap: 2,
				mb: 4
			}}
		>
			<Headline5>{t('registration:starter_page_header_headline')}</Headline5>
			<Stack sx={{ flexDirection: isMobile ? 'column' : 'row', gap: 1 }}>
				<IconListedItem
					icon={<Check />}
					text={t('registration:starter_page_header_benefit_1')}
					sx={{ mx: 'auto' }}
				/>
				<IconListedItem
					icon={<Check />}
					text={t('registration:starter_page_header_benefit_2')}
					sx={{ mx: 'auto' }}
				/>
				<IconListedItem
					icon={<Check />}
					text={t('registration:starter_page_header_benefit_3')}
					sx={{ mx: 'auto' }}
				/>
			</Stack>
			<ChangePlanButton
				buttonText={t('registration:starter_page_cta_button')}
				planName={ChargebeePlansV2.RELAY_STARTER}
				hasConfirmation={false}
			/>
		</CardContent>
	);
};
