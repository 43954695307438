import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	message: {
		textAlign: 'center',
		margin: '1.5em'
	}
}));

export const InfiniteLoaderEndMessage = ({ itemName }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return <Typography className={classes.message}>{t(getTranslationKey(itemName))}</Typography>;
};

const getTranslationKey = (module: string) => {
	const _messages = {
		orders: 'orders:infinite_loader_endmessage',
		offers: 'offers:infinite_loader_endmessage',
		appointments: 'appointments:infinite_loader_endmessage',
		documents: 'documents:infinite_loader_endmessage',
		notifications: 'notification:infinite_loader_endmessage',
		insurances: 'insurances:infinite_loader_endmessage',
		properties: 'properties:infinite_loader_endmessage'
	};
	return _messages[module] ?? 'general:infinite_loader_endmessage';
};
