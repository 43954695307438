import { UIOrderContactPerson } from '@api/v1';
import { Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	outer: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		alignItems: 'center'
	},
	emailAddressField: {
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	}
}));

interface ContactPersonEntryProps {
	contact: UIOrderContactPerson;
}

export const ContactPersonEntry = ({ contact }: ContactPersonEntryProps) => {
	const classes = useStyles();
	return (
		<Grid container className={classes.outer}>
			<Grid container spacing={1} item md={6}>
				<Grid item md={6} sm={3} xs={12}>
					<Typography variant="body2">{contact.type}</Typography>
				</Grid>
				<Grid item md={6} sm={9} xs={12}>
					<Typography variant="body2">{contact.name}</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={1} item md={6}>
				<Grid item sm={5} xs={12}>
					{[contact.telephone, contact.mobile]
						.filter((v) => v)
						.map((phone, i) => (
							<div key={`phone-${i}`}>
								<a href={`tel:${phone}`}>{phone}</a>
							</div>
						))}
				</Grid>
				<Grid item sm={7} xs={12} className={classes.emailAddressField}>
					{contact.emailAddress ? (
						<a target="_blank" title={contact.emailAddress} href={`mailto:${contact.emailAddress}`}>
							{contact.emailAddress}
						</a>
					) : null}
				</Grid>
			</Grid>
		</Grid>
	);
};
