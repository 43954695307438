import {
	CardContent,
	Divider,
	Typography,
	DialogContent,
	Button,
	Card,
	InputBase,
	ButtonBase,
	Hidden,
	Collapse,
	Dialog,
	DialogTitle,
	DialogActions
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { State } from '../../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from '../actions';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/Tune';
import classNames from 'classnames';
import { Filters } from './Filters';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1, 1, 0),
		overflow: 'visible',
		zIndex: 1
	},
	cardContent: {
		padding: `0 ${theme.spacing(1)} !important`
	},
	search: {
		position: 'relative',
		marginLeft: 0,
		width: '100%'
	},
	searchIcon: {
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	input: {
		width: '100%',
		'margin-left': theme.spacing(4)
	},
	searchContainer: {
		display: 'flex',
		alignItems: 'center',
		'& > label': {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			},
			'& > span': {
				color: theme.palette.grey[600],
				fontSize: 'small'
			}
		}
	},
	filterButton: {
		marginRight: theme.spacing(-1),
		padding: theme.spacing(1)
	},
	filterButtonActive: {
		color: theme.palette.primary.main
	},
	filterText: {
		marginLeft: theme.spacing(1)
	},
	filterContainer: {
		padding: theme.spacing(1),
		display: 'flex',
		justifyContent: 'space-between',
		flex: '0 0 auto',
		marginBottom: theme.spacing(1),
		'& > input': {
			color: theme.palette.primary.main
		}
	}
}));

export function Searchbar(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles(props);

	const { searchString, ...restFilters } = useSelector(
		(state: State) => state.offerRequests.filters
	);
	const { assignee, customer, street, postalCode, locality } = restFilters;
	const hasActiveFilter = [assignee, customer, street, postalCode, locality].some(Boolean);

	const [isFilterCollapsed, setFilterCollapse] = useState(true);
	const [isFilterDialogOpen, setFilterDialogOpen] = useState(false);

	return (
		<Card className={classes.root} elevation={2}>
			<CardContent className={classes.cardContent}>
				<div className={classes.searchContainer}>
					<div className={classes.search}>
						<div className={classes.searchIcon}>
							<SearchIcon />
						</div>
						<InputBase
							id="offer_filters_field_general_search"
							onChange={(e) => dispatch(setFilter({ searchString: e.currentTarget.value }))}
							placeholder={t('offers:search_placeholder')}
							value={searchString}
							className={classes.input}
							inputProps={{ 'aria-label': 'Search' }}
						/>
					</div>
					<ButtonBase
						onClick={() => {
							setFilterCollapse(!isFilterCollapsed);
							setFilterDialogOpen(!isFilterDialogOpen);
						}}
						className={classNames(classes.filterButton, {
							[classes.filterButtonActive]: hasActiveFilter
						})}
					>
						<FilterIcon />
						<Hidden mdDown>
							<Typography className={classes.filterText}>
								{t('offers:filter_switch_label')}
							</Typography>
						</Hidden>
					</ButtonBase>
				</div>
				<Hidden smDown>
					<Collapse in={isFilterCollapsed}>
						<Divider light />
						<div className={classes.filterContainer}>
							<Filters />
						</div>
					</Collapse>
				</Hidden>
				<Hidden smUp>
					<Dialog key="dialog" open={isFilterDialogOpen}>
						<DialogTitle>{t('offers:filters_mobile_dialog_header')}</DialogTitle>
						<DialogContent>
							<Filters />
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setFilterDialogOpen(false)}>{t('general:cta_close')}</Button>
						</DialogActions>
					</Dialog>
				</Hidden>
			</CardContent>
		</Card>
	);
}
