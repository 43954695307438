import { MetaList, UIMetaData } from '@api/v1';
import * as React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingScreen } from './LoadingScreen';
import { NoResultsForSearchScreen } from './NoResultsForSearchScreen';
import { InfiniteLoaderEndMessage } from './InfiniteLoaderEndMessage';
import { GroupCard } from './GroupCard';
import { ItemType, sortGroupByDate } from '../lib/dateHelper';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		height: '100%',
		overflowY: 'scroll'
	},
	outer: {
		overflowY: 'auto',
		width: '100%',
		padding: theme.spacing(1, 2)
	},
	listLayout: {
		flex: '1 1 100%',
		height: '100%'
	},
	card: {
		'& > div': {
			paddingTop: theme.spacing(3)
		}
	},
	controlBar: {
		margin: theme.spacing(2)
	}
}));

interface LazyListProps<T> {
	metaList: MetaList<T, UIMetaData>;
	next: () => void;
	i18TextNoList: string;
	ListItem: React.FC<{ item: T }>;
}
export const LazyList = <T extends ItemType>({
	metaList,
	next,
	i18TextNoList,
	ListItem
}: LazyListProps<T>) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	if (!metaList || !metaList.data) return <LoadingScreen />;

	const { data, metaData } = metaList;
	if (!data.length) return <NoResultsForSearchScreen message={t(i18TextNoList)} />;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [itemName, _] = i18TextNoList.split(':'); // get key name for i18n translation and scrollable area target id
	const orderGroups = sortGroupByDate(data);
	return (
		<InfiniteScroll
			next={() => dispatch(next())}
			hasMore={metaData?.hasMore}
			loader={<LoadingScreen />}
			dataLength={data.length}
			scrollableTarget={itemName}
			scrollThreshold="0px"
			endMessage={<InfiniteLoaderEndMessage itemName={itemName} />}
		>
			{orderGroups.map((group) => (
				<GroupCard key={group[0]} group={group} Item={ListItem} />
			))}
		</InfiniteScroll>
	);
};
