import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { TextGroupHeadline } from './TextGroupHeadline';
import { TextGroupText } from './TextGroupText';

export interface TextGroupSmallProps {
	overline?: string;
	headline?: string;
	text?: string;
}

export function TextGroupSmall({ overline, headline, text }: TextGroupSmallProps) {
	const theme = useTheme();
	return (
		<Stack>
			<Typography sx={{ color: theme.palette.text.primary }} variant="overline">
				{overline || '\u00A0'}
			</Typography>
			<TextGroupHeadline>{headline || '\u00A0'}</TextGroupHeadline>
			<TextGroupText>{text || '\u00A0'}</TextGroupText>
		</Stack>
	);
}

export type TextGroupSmall = ReturnType<typeof TextGroupSmall>;
