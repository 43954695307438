import { constants, asyncConstants, setAccountCategory } from './actions';
import { combineReducers } from 'redux';
import { createReducer } from '../../redux/createReducer';
import { AccountCategory, TaskArea } from '@api/v1';
export interface RegistrationState {
	propertyManager: string;
	token: string;
	firstName: string;
	lastName: string;
	companyName: string;
	category: AccountCategory;
	email: string;
	telephone: string;
	street: string;
	postalCode: string;
	locality: string;
	country: string;
	password: string;
	confirmPassword: string;
	agreedToTerms: boolean;
	subscribedToNewsletter: boolean;
	isProcessing: boolean;
	companyDescription: string;
	taskAreas: TaskArea[];
	radius: number;
	companyWebsite: string;
	hasNewPricing?: boolean;
}

export const reducers = combineReducers<RegistrationState>({
	token: (state = '', action) => {
		switch (action.type) {
			case constants.SET_TOKEN:
				return action.payload;
			case asyncConstants.CHECK_REGISTRATION_JWT.FULFILLED:
				return action.payload.token || '';
			default:
				return state;
		}
	},
	propertyManager: (state = '', action) =>
		action.type === constants.SET_TOKEN_ISSUER ? action.payload : state,
	firstName: (state = null, action) => {
		switch (action.type) {
			case constants.SET_FIRST_NAME:
				return action.payload;
			case asyncConstants.CHECK_TOKEN.FULFILLED:
				return action.payload.user.firstName || null;
			default:
				return state;
		}
	},
	lastName: (state = null, action) => {
		switch (action.type) {
			case constants.SET_LAST_NAME:
				return action.payload;
			case asyncConstants.CHECK_TOKEN.FULFILLED:
				return action.payload.user.lastName || null;
			default:
				return state;
		}
	},
	email: (state = null, action) => {
		switch (action.type) {
			case constants.SET_EMAIL:
				return action.payload;
			case asyncConstants.CHECK_TOKEN.FULFILLED:
				return action.payload.user.email || null;
			default:
				return state;
		}
	},
	telephone: (state = null, action) => {
		switch (action.type) {
			case constants.SET_TELEPHONE:
				return action.payload;
			case asyncConstants.CHECK_TOKEN.FULFILLED:
				return action.payload.user.telephone || null;
			default:
				return state;
		}
	},
	password: (state = null, action) =>
		action.type === constants.SET_PASSWORD ? action.payload : state,
	confirmPassword: (state = null, action) =>
		action.type === constants.SET_CONFIRM_PASSWORD ? action.payload : state,
	companyName: (state = '', action) => {
		switch (action.type) {
			case constants.SET_COMPANY_NAME:
				return action.payload;
			case asyncConstants.CHECK_TOKEN.FULFILLED:
				return action.payload.account.name || '';
			default:
				return state;
		}
	},
	hasNewPricing: (state = false, action) =>
		action.type === constants.SET_HAS_NEW_PRICING ? action.payload : state,
	category: createReducer<AccountCategory>(null)
		.handleAction(setAccountCategory, (state, action) => action.payload)
		.handleAnyAction(
			asyncConstants.CHECK_TOKEN.FULFILLED,
			(state, action) => action.payload.account.category || null
		),
	street: (state = '', action) => {
		switch (action.type) {
			case constants.SET_STREET:
				return action.payload;
			case asyncConstants.CHECK_TOKEN.FULFILLED:
				return action.payload.account.address.streetAddress || '';
			default:
				return state;
		}
	},
	postalCode: (state = '', action) => {
		switch (action.type) {
			case constants.SET_POSTAL_CODE:
				return action.payload;
			case asyncConstants.CHECK_TOKEN.FULFILLED:
				return action.payload.account.address.postalCode || '';
			default:
				return state;
		}
	},
	locality: (state = '', action) => {
		switch (action.type) {
			case constants.SET_LOCALITY:
				return action.payload;
			case asyncConstants.CHECK_TOKEN.FULFILLED:
				return action.payload.account.address.locality || '';
			default:
				return state;
		}
	},
	country: (state = 'DE', action) => {
		switch (action.type) {
			case constants.SET_COUNTRY:
				return action.payload;
			case asyncConstants.CHECK_TOKEN.FULFILLED:
				return action.payload.account.address.country || 'DE';
			default:
				return state;
		}
	},
	agreedToTerms: (state = false, action) =>
		action.type === constants.SET_AGREED_TO_TERMS ? action.payload : state,
	subscribedToNewsletter: (state = false, action) =>
		action.type === constants.SET_SUBSCRIBED_TO_NEWSLETTER ? action.payload : state,
	isProcessing: (state = false, action) => {
		switch (action.type) {
			case asyncConstants.REGISTRATION_SUBMIT.PENDING:
				return true;
			case asyncConstants.REGISTRATION_SUBMIT.REJECTED:
			case asyncConstants.REGISTRATION_SUBMIT.FULFILLED:
				return false;
			default:
				return state;
		}
	},
	companyDescription: (state = '', action) =>
		action.type === constants.SET_COMPANY_DESCRIPTION ? action.payload : state,
	companyWebsite: (state = '', action) =>
		action.type === constants.SET_COMPANY_WEBSITE ? action.payload : state,
	taskAreas: (state = [], action) =>
		action.type === constants.SET_TASK_AREAS ? action.payload : state,
	radius: (state = -1, action) => (action.type === constants.SET_RADIUS ? action.payload : state)
});
