import * as Sentry from '@sentry/browser';
declare global {
	interface ImportMeta {
		env: {
			MODE: string;
			VITE_CHARGEBEE_SITE: string;
			VITE_VERSION: string;
		};
	}
}
if (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'integration') {
	Sentry.init({
		dsn: 'https://160ecc675fc54d7092197b2c568ac4c5@sentry.io/1302281',
		environment: import.meta.env.MODE,
		release: `${import.meta.env.VITE_VERSION}`,
		ignoreErrors: ['Request failed with status code 401'],
		blacklistUrls: [/^file:\/\/\//, /^localhost:\/\//]
	});
}
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, StyledEngineProvider, alpha } from '@mui/material/styles';
declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
		mobile: true;
	}
}
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import qs from 'qs';
import * as React from 'react';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { CoreApp } from './core/App';
import { initializeStore } from './core/createStore';
import { history } from './core/history';
import { reducers } from './core/reducers';
import { ErrorHandler } from './ErrorHandler';
import { i18n } from './i18n';
import { initializeValidators } from './lib/validator';
import moment from 'moment-with-locales-es6';
import { lightGreen } from '@mui/material/colors';
import { createThemeV2 } from './theme';
const store = initializeStore({}, reducers);
(window as any).intercomSettings = {
	hide_default_launcher: true
};

initializeValidators();

moment.updateLocale('de', {
	week: {
		dow: 0
	}
});

const relayGrey = {
	100: '#f4f4f6',
	200: '#e4e4e9',
	300: '#c7c7d1',
	400: '#8f8fa3',
	500: '#5c5c70',
	600: '#29293d',
	700: '#101024'
};
const defaultTheme = createTheme();
const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			mobile: 801
		}
	},
	typography: {
		fontWeightBold: 500
	},
	palette: {
		primary: {
			light: '#B3B4E5',
			main: '#5C60D6',
			dark: '#262973',
			contrastText: '#FFF'
		},
		secondary: {
			light: '#ff4081',
			main: '#f50057',
			dark: '#c51162',
			contrastText: '#FFF'
		},
		success: {
			light: lightGreen[100],
			main: lightGreen[500],
			dark: lightGreen[700],
			contrastText: 'rgba(0, 0, 0, 0.87)'
		},
		grey: relayGrey,
		text: {
			primary: '#241010'
		},
		error: {
			main: '#ff333a',
			contrastText: '#f4f4f6'
		},
		notification: {
			main: '#32967E',
			contrastText: '#F4F4F6'
		},
		background: {
			default: '#fafafa'
		}
	},
	orderStatus: {
		open: {
			light: '#DA4A4F',
			main: '#DA4A4F',
			dark: '#DA4A4F',
			contrastText: '#F4F4F6'
		},
		inProgress: {
			light: '#5C60D6',
			main: '#5C60D6',
			dark: '#5C60D6',
			contrastText: '#F4F4F6'
		},
		finished: {
			light: '#FFAE67',
			main: '#FFAE67',
			dark: '#FFAE67',
			contrastText: '#241010'
		},
		declined: {
			light: '#8f8fa3',
			main: '#8f8fa3',
			dark: '#8f8fa3',
			contrastText: '#F4F4F6'
		}
	},
	overrides: {
		MuiAvatar: {
			root: {
				transition: defaultTheme.transitions.create('background-color')
			}
		},
		MuiDialog: {
			paper: {
				width: 600
			}
		},
		MuiPickersDialog: {
			dialogRoot: {
				width: 'unset',
				minHeight: 504
			}
		},
		MuiListItem: {
			root: {
				'&$focusVisible': {
					backgroundColor: 'none'
				}
			}
		}
	},
	components: {
		MuiButton: {
			variants: [
				{
					props: { variant: 'contained', color: 'grey' },
					style: {
						color: defaultTheme.palette.getContrastText(defaultTheme.palette.grey[300]),
						backgroundColor: relayGrey[200],
						'&:hover': {
							backgroundColor: relayGrey[300]
						}
					}
				},
				{
					props: { variant: 'text-only', color: 'grey' },
					style: {
						color: '#000'
					}
				},
				{
					props: { variant: 'outlined', color: 'grey' },
					style: {
						color: defaultTheme.palette.text.primary,
						borderColor:
							defaultTheme.palette.mode === 'light'
								? 'rgba(0, 0, 0, 0.23)'
								: 'rgba(255, 255, 255, 0.23)',
						'&.Mui-disabled': {
							border: `1px solid ${defaultTheme.palette.action.disabledBackground}`
						},
						'&:hover': {
							borderColor:
								defaultTheme.palette.mode === 'light'
									? 'rgba(0, 0, 0, 0.23)'
									: 'rgba(255, 255, 255, 0.23)',
							backgroundColor: alpha(
								defaultTheme.palette.text.primary,
								defaultTheme.palette.action.hoverOpacity
							)
						}
					}
				}
			]
		}
	}
});

if (window.location.search) {
	const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
	if (query.lang) {
		i18n.changeLanguage(query.lang);
	}
}

/* this wrapper is for theme of DesignV2 */
const ThemeWrapper = ({ children }) => (
	<ThemeProvider
		theme={themeV2}
	>
		{children}
	</ThemeProvider>
);

/* themeV2 extend and override from old theme (V1) */
const themeV2 = createThemeV2(theme);
render(
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<StyledEngineProvider injectFirst>
				<ThemeWrapper>
					<CssBaseline />
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<ErrorHandler>
							<Router history={history}>
								<Route path="/" component={CoreApp} />
							</Router>
						</ErrorHandler>
					</LocalizationProvider>
				</ThemeWrapper>
			</StyledEngineProvider>
		</I18nextProvider>
	</Provider>,
	window.document.getElementById('app')
);
