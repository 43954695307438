import React from 'react';
import { ButtonProps, Button as MuiButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/**
 * Same code as in app/types/mui-custom.d.ts.
 * Has to be close to the component, because
 * otherwise Storybook will throw an error
 * without any reason.
 * Maybe related issues:
 * - https://github.com/storybookjs/storybook/issues/16386
 * - https://github.com/storybookjs/storybook/issues/19056 */
export enum ButtonColor {
	Creative = 'primary',
	Neutral = 'secondary',
	Destructive = 'error'
}

export type PrimaryButtonProps = Omit<ButtonProps, 'variant' | 'color'> & {
	color?: ButtonColor;
	isLoadingButton?: boolean;
	[x: string]: unknown; // for flexibility by having standardized APIs from MUI for LoadingButton
};

/**
 * by isLoadingButton=**true**, use the same APIs from MUI for LoadingButton.
 * Related documentation:
 * - https://mui.com/material-ui/api/loading-button/
**/
export function PrimaryButton({
	color = ButtonColor.Creative,
	size = 'medium',
	isLoadingButton = false,
	...props
}: PrimaryButtonProps) {
	return isLoadingButton ? (
		<LoadingButton variant="contained" {...props} />
	) : (
		<MuiButton variant="contained" size={size} color={color} {...props} />
	);
}

export type PrimaryButton = typeof PrimaryButton;

// eslint-disable-next-line import/no-default-export
export default PrimaryButton;
