import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { Button } from '@mui/material';
import { State } from './core/reducers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

const mapStateToProps = (state: State) => ({
	user: state.auth.user
});

interface ErrorHandlerProps extends ReturnType<typeof mapStateToProps>, WithTranslation {}

class ErrorHandler extends React.Component<ErrorHandlerProps, { error: boolean }> {
	constructor(props: ErrorHandlerProps) {
		super(props);
		this.state = { error: false };
	}
	componentDidCatch(error: Error, errorInfo: object) {
		this.setState({ error: Boolean(error) });
		const { user } = this.props;
		Sentry.configureScope((scope) => {
			if (user) {
				scope.setUser({
					email: user.email,
					username: `${user.firstName} ${user.lastName}`
				});
			}
			Object.keys(errorInfo).forEach((key) => {
				scope.setExtra(key, errorInfo[key]);
			});
		});
		Sentry.captureException(error);
	}
	render() {
		if (this.state.error) {
			const { t, user } = this.props;
			return (
				<Button
					onClick={() =>
						Sentry.showReportDialog({
							lang: 'de',
							user: user
								? {
										email: user.email,
										name: `${user.firstName} ${user.lastName}`
								  }
								: null,
							title: t('error_page:sentry_dialog_title'),
							subtitle: t('error_page:sentry_dialog_subtitle_1'),
							subtitle2: t('error_page:sentry_dialog_subtitle_2'),
							labelClose: t('error_page:sentry_dialog_button_close'),
							labelSubmit: t('error_page:sentry_dialog_button_submit')
						})
					}
				>
					{t('error_page:report_button')}
				</Button>
			);
		} else {
			return this.props.children;
		}
	}
}

const wrapped = compose(connect(mapStateToProps), withTranslation())(ErrorHandler);
export { wrapped as ErrorHandler };
