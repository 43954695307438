import { DateTime } from 'luxon';
import { api } from './api';

export const authenticateForFeatureManagement = () =>
	api.get('/api/sysadmin/features/auth').then(({ url }) => window.open(url, '_blank'));

export const features = {
	checkFeatureByAccountId: async (flag: string, accountId: number) => {
		return api.get(`/api/sysadmin/features/${accountId}/variant/${flag}`);
	},
	createFeatureByAccountId: async (flag: string, accountId: number) => {
		return api.post(`/api/sysadmin/features/${accountId}/variant`, { flagName: flag });
	},
	deleteFeatureByAccountId: async (flag: string, accountId: number) => {
		return api.delete(`/api/sysadmin/features/${accountId}/variant/${flag}`);
	}
};

const getLastMonthDateTime = () => DateTime.fromMillis(Date.now()).minus({ months: 1 });

export const getCurrentPeriod = (): string => getLastMonthDateTime().toFormat('y-MM');

export const getLastYearPeriods = () => {
	const periods: string[] = [];
	for (let i = 0; i < 12; i++) {
		periods.push(getLastMonthDateTime().minus({ months: i }).toFormat('y-MM'));
	}
	return periods;
};
