import { Chip, Paper, Theme, IconButton } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Delete from '@mui/icons-material/Delete';
import { compact } from 'lodash';
import * as React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { LoadingScreen } from '../../components/LoadingScreen';
import { State } from '../../core/reducers';
import { getAccounts, deleteAccount } from './actions';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
			padding: theme.spacing(2),
			overflow: 'auto'
		},
		paper: {
			width: '100%',
			overflowX: 'auto'
		},
		link: {
			color: theme.palette.primary.main
		}
	});

const getFullAdress = (params) => {
	return compact([
		params.row.address?.streetAddress,
		compact([params.row.address?.postalCode, params.row.address?.locality]).join(' '),
		params.row.address?.country
	]).join(', ');
};

const getCountryFromAddress = (params) => {
	return `${params.row.address?.country || ''}`;
};

const DeleteIconButton = (params) => {
	const dispatch = useDispatch();
	return (
		<IconButton
			id={`sysadmin_account_list_button_delete_${params.id}`}
			size="small"
			color="primary"
			onClick={() => dispatch(deleteAccount(params.id))}
			disabled={
				!useSelector((state: State) => state.sysadmin.auth.user.permissions)?.includes(
					'delete_account'
				)
			}
		>
			<Delete />
		</IconButton>
	);
};

const columns = [
	{
		field: 'id',
		headerName: Trans({ i18nKey: 'sysadmin:account_id' }),
		editable: false,
		resizable: true
	},
	{
		field: 'name',
		headerName: Trans({ i18nKey: 'sysadmin:account_name' }),
		width: 200,
		editable: false,
		resizable: true,
		valueFormatter: ({ value }) => `${value}`,
		renderCell: (params) => {
			return params.value ? (
				<Link to={`/sysadmin/app/accounts/${params.id}`}>{params.value}</Link>
			) : (
				''
			);
		}
	},
	{
		field: 'category',
		headerName: Trans({ i18nKey: 'sysadmin:account_category' }),
		editable: false,
		width: 150,
		valueFormatter: ({ value }) => `${value}`,
		renderCell: (params) => {
			return params.value ? Trans({ i18nKey: `sysadmin:${params.value}` }) : '';
		}
	},
	{
		field: 'fullAddress',
		headerName: Trans({ i18nKey: 'sysadmin:account_address' }),
		editable: false,
		width: 350,
		valueGetter: getFullAdress
	},
	{
		field: 'externalProfileRadius',
		headerName: Trans({ i18nKey: 'sysadmin:account_radius' }),
		editable: false,
		valueFormatter: ({ value }) => `${value}`,
		renderCell: (params) => {
			return !isNaN(params.value) ? params.value : '';
		}
	},
	{
		field: 'usage',
		headerName: Trans({ i18nKey: 'sysadmin:account_type' }),
		editable: false,
		valueFormatter: ({ value }) => `${value}`,
		renderCell: (params) => {
			return params.value ? (
				<Chip
					variant="outlined"
					size="small"
					color={params.value === 'prod' ? 'primary' : 'secondary'}
					label={params.value}
				/>
			) : (
				''
			);
		}
	},
	{
		field: 'row',
		headerName: Trans({ i18nKey: 'sysadmin:account_delete' }),
		editable: false,
		valueFormatter: ({ value }) => ' - ',
		renderCell: function renderIconButton(params) {
			return <DeleteIconButton {...params} />;
		}
	},
	{
		field: 'country',
		headerName: Trans({ i18nKey: 'sysadmin:account_country' }),
		editable: false,
		valueGetter: getCountryFromAddress
	}
];

const mapStateToProps = (state: State) => ({
	accounts: state.sysadmin.accounts,
	permissions: state.sysadmin.auth.user.permissions
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAccounts,
			deleteAccount
		},
		dispatch
	);

interface AccountListProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		WithStyles<typeof styles>,
		WithTranslation {}

class AccountList extends React.Component<AccountListProps> {
	componentDidMount() {
		if (!this.props.accounts) {
			this.props.getAccounts();
		}
	}
	render() {
		const { accounts, classes } = this.props;
		if (accounts) {
			return (
				<div className={classes.root}>
					<Paper className={classes.paper} style={{ height: '100%', width: '100%' }}>
						<DataGrid
							rows={accounts}
							columns={columns}
							pageSize={50}
							rowsPerPageOptions={[5]}
							components={{
								Toolbar: GridToolbar
							}}
							disableSelectionOnClick
						/>
					</Paper>
				</div>
			);
		}
		return <LoadingScreen />;
	}
}

const wrapped = compose(
	withStyles(styles),
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(AccountList);
export { wrapped as AccountList };
