import { combineReducers } from 'redux';
import { createReducer } from '../../redux/createReducer';
import { AccountSpecificFeature, Feature } from './features';
import {
	activateAccountSpecificFeature,
	deactivateAccountSpecificFeature,
	loadAccountSpecificFeatures,
	loadActiveFeatures
} from './actions';

export interface AclState {
	accountSpecificFeatures: `${AccountSpecificFeature}`[];
	features: `${Feature}`[];
}

export const reducers = combineReducers<AclState>({
	accountSpecificFeatures: createReducer<`${AccountSpecificFeature}`[]>(null)
		.handleAction(loadAccountSpecificFeatures.success, (_state, action) => action.payload)
		.handleAction(activateAccountSpecificFeature.success, (state, action) => [
			...state,
			action.payload
		])
		.handleAction(deactivateAccountSpecificFeature.success, (state, action) =>
			state.filter((feature) => feature !== action.payload)
		),
	features: createReducer<`${Feature}`[]>([]).handleAction(
		loadActiveFeatures.success,
		(_state, action) => action.payload
	)
});
