import {
	Button,
	ButtonGroup,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminExclusivePermission } from '../../../server/permissions/permission';
import { State } from '../reducers';
import { openAcceptingDialog, openEditDialog, openRejectingDialog } from './actions';
import { getProperties } from '../selectors';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		overflowX: 'auto',
		marginTop: theme.spacing(1)
	}
}));

export const PropertiesTable = () => {
	const classes = useStyles();
	const { canAcceptMore, permissions } = useSelector((state: State) => {
		return {
			canAcceptMore: state.properties.list.metaData.canAcceptMore,
			permissions: state.auth.user.permissions
		};
	});
	const { properties } = useSelector(getProperties);
	const dispatch = useDispatch();

	const hasAdminView = permissions[AdminExclusivePermission.VIEW_ADMIN];

	return (
		properties.length > 0 && (
			<Paper className={classes.root} elevation={0}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell component="th" scope="row">
								<Trans i18nKey="properties:table_header_internalid" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="properties:table_header_name" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="properties:table_header_address" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="properties:table_header_customer" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="properties:table_header_contracts" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="properties:table_header_status" />
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{properties.map((property) => (
							<TableRow key={property.id}>
								<TableCell component="th" scope="row">
									{property.internalId}
								</TableCell>
								<TableCell component="th" scope="row">
									{property.name}
								</TableCell>
								<TableCell component="th" scope="row">
									{property.address?.streetAddress}&nbsp;
									{property.address?.postalCode}&nbsp;
									{property.address?.locality}
								</TableCell>
								<TableCell component="th" scope="row">
									{property.customer?.name}
								</TableCell>
								<TableCell component="th" scope="row">
									{property.insurances?.map((insurance, index) => (
										<>
											<Link to="/app/customers_and_properties/insurances">
												{insurance?.externalId}
											</Link>
											<span style={{ whiteSpace: 'pre' }}>
												{index !== property.insurances.length - 1 ? ', ' : ''}
											</span>
										</>
									))}
								</TableCell>
								<TableCell>
									{(() => {
										switch (property.status) {
											case 'ACCEPTED':
												return (
													<Grid container alignItems="center" spacing={2}>
														<Grid item sm={10}>
															{property.statusUpdatedAt ? (
																<Trans
																	i18nKey="properties:status_accepted_at"
																	values={{
																		date: Date.parse(property.statusUpdatedAt)
																	}}
																/>
															) : null}
														</Grid>
														<Grid item sm={2}>
															<IconButton
																size="small"
																color="primary"
																onClick={() => dispatch(openEditDialog(property))}
															>
																<EditIcon></EditIcon>
															</IconButton>
														</Grid>
													</Grid>
												);
											case 'REJECTED':
												return property.statusUpdatedAt ? (
													<Trans
														i18nKey="properties:status_rejected_at"
														values={{
															date: Date.parse(property.statusUpdatedAt)
														}}
													/>
												) : null;
											default:
												return hasAdminView ? (
													<ButtonGroup variant="outlined" size="small">
														<Button
															disabled={!canAcceptMore}
															onClick={() => dispatch(openAcceptingDialog(property))}
														>
															<Trans i18nKey="properties:action_button_accept" />
														</Button>
														<Button onClick={() => dispatch(openRejectingDialog(property))}>
															<Trans i18nKey="properties:action_button_reject" />
														</Button>
													</ButtonGroup>
												) : null;
										}
									})()}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	);
};
