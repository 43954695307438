import React from 'react';
import { styled } from '@mui/material/styles';
import { Tab } from '@mui/material';

export const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
	minWidth: 160,
	color: 'rgba(255, 255, 255, 0.7)',
	'&.Mui-selected': {
		color: '#fff'
	}
}));
