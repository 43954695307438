import React, { useState } from 'react';
import { Fade } from '@mui/material';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	src: string;
}

export const Image = (props: ImageProps) => {
	const [loaded, setLoaded] = useState<boolean>(false);
	const onLoad = () => {
		setLoaded(true);
	};
	return (
		<Fade in={loaded}>
			<img {...props} onLoad={onLoad} />
		</Fade>
	);
};
