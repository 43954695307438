import { FormControlLabel, Grid, Switch, Theme, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers';
import * as actions from '../actions';
import { DropzoneDialog } from './DropzoneDialog';
import { Feature, useFeature } from '../../acl/features';
import clsx from 'clsx';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { Chat } from '../../../mui-custom/Chat';
import { useAccountDeactivatedCheck } from '../../../hooks/useAccountDeactivatedCheck';
import { OrderMessage } from '../../../mui-custom/Chat/OrderMessage';

const useStyles = makeStyles((theme) => ({
	internalNoteLabel: {
		fontWeight: 700,
		color: theme.orderStatus.finished.main
	},
	internalNoteLabelIsInternal: {
		color: theme.palette.grey[400]
	},
	internalNoteContainer: {
		alignSelf: 'flex-end',
		marginRight: theme.spacing(5)
	}
}));

const InternalNoteSwitch = withStyles((theme: Theme) => {
	return {
		switchBase: {
			color: theme.palette.grey[400],
			'&$checked': {
				color: theme.orderStatus.finished.main
			},
			'&$checked + $track': {
				backgroundColor: theme.orderStatus.finished.main
			}
		},
		checked: {},
		track: {}
	};
})(Switch);

export function CommentCompose({ sx = {} }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles({});

	const { accountDeactivated } = useAccountDeactivatedCheck();

	const isAddingComment = useSelector(
		(state: State) => state.orders?.single.commentCompose.isAdding
	);
	const order = useSelector((state: State) => state.orders?.single.order);
	const internalNote = useFeature(Feature.InternalNote);
	const { text, files, fileDialogOpen, isInternalComment, isInternalOrder } = useSelector(
		(state: State) => ({
			text: state.orders.commentDrafts[state.orders.single.order.id] || '',
			files: state.orders.single.commentCompose.files,
			fileDialogOpen: state.orders.single.commentCompose.fileDialogOpen,
			isInternalComment: state.orders.single.commentCompose.isInternal,
			isInternalOrder: state.orders.single.order.isInternal
		})
	);
	const {
		changeCommentText: onTextChange,
		openCommentFileDialog: openDialog,
		closeCommentFileDialog: closeDialog,
		addCommentFile: onAddFile,
		changeCommentFile: onChangeFile,
		removeCommentFile: onRemoveFile,
		postComment: onCreate,
		removeAllCommentFiles: removeAllFiles,
		onInternalNoteSwitch
	} = actions;
	useEffect(() => {
		dispatch(removeAllFiles());
	}, []);
	return (
		<>
			<div id="comment-container">
				<Chat
					title={t('orders:comment_chat_title')}
					files={files}
					handleAttachmentClick={() => dispatch(openDialog())}
					text={text}
					handleChange={(e) => {
						dispatch(onTextChange(e.target.value));
					}}
					handleSend={() => dispatch(onCreate(text))}
					comments={order.comments}
					sx={sx}
					disabled={accountDeactivated}
					Item={OrderMessage}
				>
					<Grid container direction="column" alignItems="stretch" justifyContent="center">
						{!!internalNote && !isInternalOrder && (
							<Grid
								item
								xs={12}
								id="order_details_toggle_button_internal_note_switch"
								className={classes.internalNoteContainer}
							>
								<FormControlLabel
									control={
										<InternalNoteSwitch
											disableRipple
											checked={!isInternalComment}
											onChange={(e) => {
												dispatch(onInternalNoteSwitch(!e.target.checked));
											}}
										/>
									}
									label={
										<Typography
											variant="caption"
											className={clsx(classes.internalNoteLabel, {
												[classes.internalNoteLabelIsInternal]: isInternalComment
											})}
										>
											{t('orders:internal_note_label')}
										</Typography>
									}
									labelPlacement="start"
								/>
							</Grid>
						)}
					</Grid>
					{isAddingComment && <LoadingScreen />}
				</Chat>
			</div>
			<DropzoneDialog
				open={fileDialogOpen}
				onClose={() => dispatch(closeDialog())}
				title={t('general:attachments_title')}
				dropzoneProps={{
					text: t('general:attachments_upload'),
					files,
					onDrop: (file) => dispatch(onAddFile(file)),
					onChangeFile: (file, data) => dispatch(onChangeFile(file, data)),
					onRemove: (file) => dispatch(onRemoveFile(file))
				}}
			/>
		</>
	);
}
