import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DefaultOptionType } from 'app/components/AsyncSelect';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { DropzoneValidator } from '../../components/Dropzone';
import { State } from '../reducers';
import {
	addFileToCreationDialog,
	changeDescription,
	changeFileInCreationDialog,
	changeProperty,
	changeTitle,
	closeCreationDialog,
	createDocument,
	removeFileFromCreationDialog
} from './actions';
import { PropertySelect } from './PropertySelect';

export const DocumentCreationDialog = () => {
	const { open, title, description, property, files } = useSelector(
		(state: State) => state?.documents?.creationDialog
	);
	const dispatch = useDispatch();
	return (
		<Dialog open={open} fullWidth>
			<ValidatorForm onSubmit={() => dispatch(createDocument())}>
				<DialogTitle>
					<Trans i18nKey="documents:creation_dialog_header" />
				</DialogTitle>
				<DialogContent>
					<TextValidator
						variant="standard"
						name="title"
						value={title}
						onChange={(e) => dispatch(changeTitle(e.target.value))}
						label={<Trans i18nKey="documents:creation_dialog_label_title" />}
						fullWidth
						margin="normal"
						validators={['required']}
						errorMessages={[<Trans i18nKey="validation:required" />]}
					/>
					<TextValidator
						variant="standard"
						name="description"
						value={description}
						onChange={(e) => dispatch(changeDescription(e.target.value))}
						label={<Trans i18nKey="documents:creation_dialog_label_description" />}
						fullWidth
						margin="normal"
						multiline
						minRows={1}
						maxRows={5}
					/>
					<PropertySelect
						name="object"
						label={<Trans i18nKey="documents:creation_dialog_label_object" />}
						value={property}
						onChange={(value: DefaultOptionType) => dispatch(changeProperty(value))}
						fullWidth
						validators={['required']}
						errorMessages={[<Trans i18nKey="validation:required" />]}
					/>
					<DropzoneValidator
						name="files"
						value={files}
						margin="normal"
						text={<Trans i18nKey="documents:creation_dialog_dropzone_text" />}
						onDrop={(file) => dispatch(addFileToCreationDialog(file))}
						onRemove={(tempId) => dispatch(removeFileFromCreationDialog(tempId))}
						onChangeFile={(tempId, file) => dispatch(changeFileInCreationDialog(tempId, file))}
						validators={['required', 'filesUploaded']}
						errorMessages={[
							<Trans i18nKey="validation:required" />,
							<Trans i18nKey="validation:files_uploading_error" />
						]}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="secondary" onClick={() => dispatch(closeCreationDialog())}>
						<Trans i18nKey="general:cta_cancel" />
					</Button>
					<Button color="primary" type="submit">
						<Trans i18nKey="documents:creation_dialog_cta" />
					</Button>
				</DialogActions>
			</ValidatorForm>
		</Dialog>
	);
};
