import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { LoadingScreen } from '../components/LoadingScreen';
import { Notification } from '../core/Notification';
import { checkToken } from './auth/actions';
import { AuthApp } from './auth/App';
import { General } from './General';

class NoMatch extends React.Component {
	render() {
		return <div>404</div>;
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			checkToken
		},
		dispatch
	);

interface SysadminAppProps extends ReturnType<typeof mapDispatchToProps>, RouteComponentProps<{}> {}
interface SysadminAppState {
	hasFiguredOut: boolean;
}

class SysadminApp extends React.Component<SysadminAppProps, SysadminAppState> {
	state = {
		hasFiguredOut: false
	};
	componentDidMount() {
		this.props.checkToken((err, data) => {
			if (err || !_.isObject(data)) {
				if (this.props?.location?.pathname?.indexOf('/sysadmin/app') === 0) {
					this.props.history.replace('/sysadmin/login');
				} else if (this.props.match.isExact) {
					this.props.history.replace('/sysadmin/login');
				}
			} else if (this.props.match.isExact) {
				this.props.history.replace('/sysadmin/app');
			}
			this.setState({ hasFiguredOut: true });
		});
	}
	render() {
		if (this.state.hasFiguredOut) {
			return [
				<Switch key={0}>
					<Route path="/sysadmin/app" component={General} />
					<Route path="/" component={AuthApp} />
					<Route component={NoMatch} />
				</Switch>,
				<Notification key={1} />
			];
		}
		return <LoadingScreen />;
	}
}

const wrapped = connect(null, mapDispatchToProps)(SysadminApp);
export { wrapped as SysadminApp };
