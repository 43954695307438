import { UIAddress } from '@api/v1';
import * as React from 'react';
import { displayAddress, getGoogleMapsLink } from '../../lib/address';

interface GoogleMapsLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	address: UIAddress;
}

export const GoogleMapsLink = ({ address, ...props }: GoogleMapsLinkProps) => {
	return (
		<a {...props} target="_blank" href={getGoogleMapsLink(address)}>
			{displayAddress(address)}
		</a>
	);
};
