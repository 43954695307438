import { dateTime } from '../../../lib/dateHelper';

export const getPrettyPlanName = (plan: string) => {
	const [, _plan] = plan?.split('-') || ['undefined', 'undefined'];
	return _plan[0].toUpperCase() + _plan.slice(1);
};

export const getTrialEndDate = (trialEndTimestamp: number) => {
	const trialEndDate = new Date(trialEndTimestamp * 1000);
	return dateTime(trialEndDate.toUTCString()).plus({ days: 1 }).toFormat('dd.MM.yyyy');
};
