import * as React from 'react';

export const Convert = () => {
	return (
		<svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="M24 13L20 9V12H11V14H20V17M18 16V21H2V12H0L10 2L18 10H15L10 5L4 11V19H16V16H18Z"
			/>
		</svg>
	);
};
