import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	InputAdornment,
	IconButton
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { api } from '../../api';
import { showNotification } from '../../../core/actions';
import { i18n } from '../../../i18n';
import { IntegrationPartner } from './CustomerEditDialog';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const CredentialsModal = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { selectedIntegration, customer } = props;
	const [showPasword, setShowPassword] = React.useState(false);
	const [shouldUpdateCredentials, setShouldUpdateCredentials] = React.useState(false);
	const [credentialsModalIsOpen, setCredentialsModalIsOpen] = React.useState(false);
	const initialCredentialsState = {
		[IntegrationPartner.TECHEM]: { username: '' },
		[IntegrationPartner.OASIS]: { username: '' },
		[IntegrationPartner.ASSFINET]: { username: '' }
	};

	const [customerCredentials, setCustomerCredentials] = React.useState(initialCredentialsState);
	const [initialCredentials, setInitialCredentials] = React.useState(initialCredentialsState);
	const handleCredentialsChange = (event, key) => {
		const newCredentials = { ...customerCredentials };
		newCredentials[selectedIntegration] = {
			...newCredentials[selectedIntegration],
			[key]: event.target.value
		};
		setCustomerCredentials(newCredentials);
	};

	const handleCredentialsModalClose = () => {
		setCredentialsModalIsOpen(false);
		setCustomerCredentials(initialCredentials);
	};

	React.useEffect(() => {
		setShouldUpdateCredentials(
			customerCredentials[selectedIntegration]?.username &&
				(customerCredentials[selectedIntegration]?.username !==
					initialCredentials[selectedIntegration]?.username ||
					customerCredentials[selectedIntegration]?.password)
		);
	}, [
		customerCredentials[selectedIntegration].username,
		customerCredentials[selectedIntegration].password
	]);

	const fetchCredentials = async () => {
		const credentials = await api.get(
			`/api/sysadmin/customers/${customer.accountId}/integrationCredentials/${customer.id}`
		);
		setInitialCredentials(credentials);
		setCustomerCredentials(credentials);
	};

	React.useEffect(() => {
		fetchCredentials().catch(console.error);
	}, []);

	const handleCredentialSave = () => {
		if (initialCredentials[selectedIntegration]?.username) {
			api
				.patch(
					`/api/sysadmin/customers/${customer.accountId}/integrationCredentials/${customer.id}`,
					{
						username: customerCredentials[selectedIntegration].username,
						integrationType: selectedIntegration,
						...(customerCredentials[selectedIntegration]?.password && {
							password: customerCredentials[selectedIntegration].password
						})
					}
				)
				.then(() => {
					dispatch(showNotification(i18n.t('sysadmin:integration_id_update_sucess'), 'success'));
					fetchCredentials().catch(console.error);
				})
				.catch(() =>
					dispatch(showNotification(i18n.t('sysadmin:integration_id_update_fail'), 'error'))
				);
		} else {
			api
				.post(
					`/api/sysadmin/customers/${customer.accountId}/integrationCredentials/${customer.id}`,
					{
						username: customerCredentials[selectedIntegration].username,
						password: customerCredentials[selectedIntegration].password,
						integrationType: selectedIntegration
					}
				)
				.then(() => {
					dispatch(showNotification(i18n.t('sysadmin:integration_id_update_sucess'), 'success'));
					fetchCredentials().catch(console.error);
				})
				.catch(() =>
					dispatch(showNotification(i18n.t('sysadmin:integration_id_update_fail'), 'error'))
				);
		}
		setCredentialsModalIsOpen(false);
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPasword);
	};

	return (
		<div>
			<Button
				variant="outlined"
				color="primary"
				onClick={() => setCredentialsModalIsOpen(true)}
				sx={{ mt: 2 }}
			>
				{t('sysadmin:edit_credentials')}
			</Button>
			<Dialog
				open={credentialsModalIsOpen}
				onClose={handleCredentialsModalClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{`${selectedIntegration.charAt(
					0
				)}${selectedIntegration.slice(1).toLowerCase()}`}</DialogTitle>
				<DialogContent>
					<TextField
						margin="dense"
						id="username"
						fullWidth
						value={customerCredentials[selectedIntegration]?.username || ''}
						onChange={(event) => handleCredentialsChange(event, 'username')}
						label={t('sysadmin:username')}
						variant="standard"
					/>
					<TextField
						margin="dense"
						id="password"
						type={showPasword ? 'text' : 'password'}
						fullWidth
						value={customerCredentials[selectedIntegration]?.password || ''}
						onChange={(event) => handleCredentialsChange(event, 'password')}
						label={t('sysadmin:password')}
						variant="standard"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										edge="end"
									>
										{showPasword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCredentialsModalClose} color="secondary" variant="text">
						{t('sysadmin:restrictions_dialog_cancel')}
					</Button>
					<Button
						onClick={handleCredentialSave}
						color="primary"
						disabled={!shouldUpdateCredentials}
					>
						{t('sysadmin:restrictions_dialog_save')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
