import React, { useEffect, useState } from 'react';
import { ChargebeePlansV2 } from '../../../server/chargebee/chargebee.enums';
import { stopListeningForSubscriptionChanges } from '../../components/UpgradeButton/actions';
import { PrimaryButton } from '../../mui-custom/Button';
import { ConfirmPlanDialog } from './ConfirmPlanDialog';
import { useDispatch } from 'react-redux';
import { submitRegistrationV2 } from './actions';
import { useRegSteps } from './Progress';
import { UIRegistrationSteps } from '../../data/enums';

export function ChangePlanButton({
	buttonText,
	planName,
	hasConfirmation = true
}: {
	buttonText?: string;
	planName: ChargebeePlansV2;
	hasConfirmation?: boolean;
}) {
	useEffect(() => {
		return () => stopListeningForSubscriptionChanges();
	}, []);
	const dispatch = useDispatch();
	const { onSubmitSuccess } = useRegSteps(UIRegistrationSteps.INSERT_PLAN);

	const [openConfirmPlanDialog, setOpenConfirmPlanDialog] = useState(false);
	const handleUpgradeButtonClick = () =>
		hasConfirmation
			? setOpenConfirmPlanDialog(true)
			: dispatch(submitRegistrationV2(planName, onSubmitSuccess()));

	const handleOnClose = () => {
		setOpenConfirmPlanDialog(false);
	};

	return (
		<>
			<PrimaryButton onClick={handleUpgradeButtonClick}>{buttonText}</PrimaryButton>
			<ConfirmPlanDialog open={openConfirmPlanDialog} plan={planName} onClose={handleOnClose} />
		</>
	);
}
