import { UINotification } from '@api/v1';
import { Avatar, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextGroupSmall } from '../../mui-custom/TextGroupSmall';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Avatar as CustomAvatar } from '../../components/Avatar';
import {
	getNotificationHeadline,
	getNotificationIcon,
	getNotificationLink,
	getNotificationType,
	getTimeSinceCreation,
	getNotificationContent,
	getNotificationButtonText,
	getNotificationIconBackground,
	getNotificationIconV1
} from './utils';
import { dateTime } from '../../lib/dateHelper';
import SecondaryButton, { ButtonColor } from '../../mui-custom/Button/SecondaryButton';
import { useHistory } from 'react-router';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1)
	},
	content: {
		display: 'flex'
	},
	title: {
		flex: '1 1 100%'
	},
	link: {
		textDecoration: 'none',
		color: 'inherit'
	}
}));

interface NotificationListItemProps {
	notification: UINotification;
}

export function NotificationListItem(props: NotificationListItemProps) {
	const classes = useStyles(props);
	const { t } = useTranslation();
	const { notification } = props;
	const { createdAt } = notification;
	const type = useMemo(() => getNotificationType(notification), [notification]);
	const pathname = useMemo(() => getNotificationLink(notification), [notification]);
	const notificationContent = useMemo(() => getNotificationContent(notification), [notification]);
	const notificationHeadline = useMemo(() => getNotificationHeadline(notification, t), [
		notification,
		t
	]);
	const timeSinceCreation = useMemo(() => getTimeSinceCreation(createdAt), [createdAt]);
	const notificationIcon = useMemo(() => getNotificationIcon(type), [type]);
	return (
		<NavLink
			className={classes.link}
			to={{
				pathname,
				search: location.search
			}}
		>
			<Card className={classes.root}>
				<CardHeader
					title={<Typography variant="body1">{notificationHeadline}</Typography>}
					action={notification.notifiedAt ? null : <ActivityIndicator />}
					avatar={<CustomAvatar>{notificationIcon}</CustomAvatar>}
				/>
				<CardContent className={classes.content}>
					<Typography variant="body2" className={classes.title}>
						{notificationContent}
						<br />
						{timeSinceCreation}
					</Typography>
				</CardContent>
			</Card>
		</NavLink>
	);
}

export function NotificationListItemV1(props: NotificationListItemProps) {
	const history = useHistory();
	const { t } = useTranslation();
	const { notification } = props;
	const { createdAt } = notification;
	const theme = useTheme();
	const type = useMemo(() => getNotificationType(notification), [notification]);
	const pathname = useMemo(() => getNotificationLink(notification), [notification]);
	const notificationContent = useMemo(() => getNotificationContent(notification), [notification]);
	const notificationHeadline = useMemo(() => getNotificationHeadline(notification, t), [
		notification,
		t
	]);
	const notificationIcon = useMemo(() => getNotificationIconV1(type, theme), [type]);
	const notificationIconBackground = useMemo(() => getNotificationIconBackground(type, theme), [
		type
	]);
	const notificationButtonText = useMemo(() => getNotificationButtonText(type, t), [type]);
	const creationDate = dateTime(createdAt).toFormat('dd.MM.yyyy');
	const handleSecondaryButtonClick = () => {
		history.push({
			pathname
		});
	};
	return (
		<Stack direction="row" spacing={1}>
			<Avatar sx={{ background: notificationIconBackground }} variant="circular">
				{notificationIcon}
			</Avatar>
			<Stack direction="column">
				<TextGroupSmall
					overline={creationDate}
					headline={notificationHeadline}
					text={notificationContent}
				/>
				<SecondaryButton
					onClick={handleSecondaryButtonClick}
					color={ButtonColor.Creative}
					size="small"
				>
					{notificationButtonText}
				</SecondaryButton>
			</Stack>
		</Stack>
	);
}
