import { Callback } from '../../lib/types';
import { createConstants } from '../../lib/createConstants';
import { SysadminAuthLoginRequest, SRSysadminUser } from '@api/sysadmin';
import { api } from '../api';
import { showNotification } from '../../core/actions';
import { i18n } from '../../i18n';
import { history } from '../../core/history';

export const constants = createConstants(
	[
		'LOGIN',
		'LOGOUT',
		'GET_USER',
		'RESET_PASSWORD',
		'CHOOSE_PASSWORD',
		'SET_PASSWORD',
		'SET_PASSWORD_CONFIRM'
	],
	{
		prefix: 'SYSADMIN'
	}
);

export const setPassword = (password: string) => ({
	type: constants.SET_PASSWORD,
	payload: password
});

export const setPasswordConfirm = (passwordConfirm: string) => ({
	type: constants.SET_PASSWORD_CONFIRM,
	payload: passwordConfirm
});

export const login = (body: SysadminAuthLoginRequest, callback: Callback = () => null) => (
	dispatch
) =>
	dispatch({
		type: constants.LOGIN,
		payload: api
			.post<SysadminAuthLoginRequest>('/api/sysadmin/login', body)
			.then((data) => {
				window.localStorage.setItem('sysadmin_jwt', data.token);
				callback(null, data.user);
				return data.user;
			})
			.catch((err) => {
				callback(err);
				dispatch(showNotification(i18n.t('general:login_failed'), 'error'));
				throw err;
			})
	});
export const logout = () => ({
	type: constants.LOGOUT,
	payload: api.post('/api/sysadmin/logout').then(() => {
		window.localStorage.removeItem('sysadmin_jwt');
		const isLoggedInAsUser = window.localStorage.getItem('sysadmin_logged_in_as_user');
		if (isLoggedInAsUser) {
			window.localStorage.removeItem('sysadmin_logged_in_as_user');
			window.localStorage.removeItem('jwt');
		}
		history.push('/sysadmin/login');
	})
});
export const checkToken = (callback: Callback = () => null) => ({
	type: constants.GET_USER,
	payload: api
		.get<SRSysadminUser>('/api/sysadmin/user')
		.then((data) => {
			callback(null, data);
			return data;
		})
		.catch((err) => {
			callback(err);
			throw err;
		})
});
export const resetPassword = (body, callback: Callback) => (dispatch) => ({
	type: constants.RESET_PASSWORD,
	payload: api
		.post('/api/sysadmin/reset_password', body)
		.then((data) => {
			callback(null, data);
			return data;
		})
		.catch((err) => {
			callback(err);
			dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
			throw err;
		})
});
export const choosePassword = (body) => (dispatch) => ({
	type: constants.CHOOSE_PASSWORD,
	payload: api
		.post('/api/sysadmin/choose_password', body)
		.then((data) => {
			window.localStorage.setItem('sysadmin_jwt', data.token);
			history.push('/sysadmin/app');
			return data;
		})
		.catch((err) => {
			if (err.response.status === 403) {
				dispatch(
					showNotification(i18n.t('general:notifications.reset_password_invalid_token'), 'error')
				);
			} else {
				dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
			}
			throw err;
		})
});
