import React, { FocusEvent, useCallback } from 'react';
import { Checkbox, FormControlLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { CasaviCustomField, CasaviFieldType } from '../../server/tickets/casavi.types';

export const CustomField = ({
	position,
	name,
	fieldType,
	required,
	description,
	dataJSON,
	onChange,
	value
}: Partial<CasaviCustomField> & {
	onChange: (e: any) => void;
}) => {
	const props = {
		name,
		label: description,
		value,
		required,
		onChange,
		fullWidth: true,
		variant: 'outlined' as const
	};
	const { options, decimalPlaces, withTime = false } = dataJSON ?? {};

	const handleNumberValue = useCallback(
		(option: FocusEvent<HTMLInputElement>) => {
			const floatNumber = parseFloat(option?.target?.value?.replace(',', '.')) || 0;
			props.onChange(floatNumber.toFixed(decimalPlaces));
		},
		[props.onChange, decimalPlaces]
	);

	return (
		<Stack>
			{!!name && (
				<Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
					{name} {required && '*'}
				</Typography>
			)}
			{(() => {
				switch (fieldType) {
					case CasaviFieldType.TEXTFIELD:
						return <TextField {...props} type="text" />;
					case CasaviFieldType.TEXTBOX:
						return <TextField {...props} multiline rows={5} sx={{ resize: 'none' }} />;
					case CasaviFieldType.NUMBER:
						return (
							<TextField
								{...props}
								type="number"
								onBlur={handleNumberValue}
							/>
						);
					case CasaviFieldType.CHECKBOX:
						return <FormControlLabel {...props} control={<Checkbox name={name} value={null} />} />;
					case CasaviFieldType.DROPDOWN:
						return (
							<TextField {...props} select>
								{[...(options || [])].map((option, id) => (
									<MenuItem key={id + option} value={option}>
										{option}
									</MenuItem>
								))}
							</TextField>
						);
					case CasaviFieldType.DATE:
						return withTime ? (
							<TextField {...props} type="datetime-local" />
						) : (
							<TextField {...props} type="date" />
						);
					case CasaviFieldType.DOCUMENT:
						return (
							<TextField
								{...props}
								type="file"
								inputProps={{
									multiple: true,
									style: { height: '5rem' },
									maxSize: 20 * 1024 * 1024
								}}
							/>
						);
					default:
						return null;
				}
			})()}
		</Stack>
	);
};
