import { spawn } from 'redux-saga/effects';
import { loadOrdersSaga } from '../core/orders/actions';
import { loadOfferRequestsSaga } from '../core/offer-requests/actions';
import { loadStatisticsAndIninitalizePendoSaga } from '../core/auth/actions';

export function* rootSaga() {
	yield spawn(loadOrdersSaga);
	yield spawn(loadOfferRequestsSaga);
	yield spawn(loadStatisticsAndIninitalizePendoSaga);
}
