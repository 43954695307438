import {
	UIAddress,
	UIAttachment,
	UICustomer,
	UIEmployee,
	UIOffer,
	UIOfferRequest,
	UIOfferRequestComment,
	UIOfferRequestCommentAuthor
} from '@api/v1';
import { DropzoneFile } from '../../components/Dropzone/types';

export interface CommentDrafts {
	[index: number]: string;
}

export const initOfferRequestCommentAuthor = {} as UIOfferRequestCommentAuthor;
export const initAddress = {} as UIAddress;
export const initAttachments = [] as UIAttachment[];
export const initCustomer = {} as UICustomer;
export const initEmployee = {} as UIEmployee;
export const initDropzoneFiles = [] as DropzoneFile[];
export const initCommentDrafts = {} as CommentDrafts;

export const initOffer = {
	attachments: initAttachments,
	internalAuthor: initEmployee
} as UIOffer;

export const initOfferRequestComment = {
	author: initOfferRequestCommentAuthor,
	attachments: initAttachments
} as UIOfferRequestComment;

export const initOfferRequest = {
	address: initAddress,
	offers: [initOffer],
	attachments: initAttachments,
	customer: initCustomer,
	assignee: initEmployee,
	comments: [initOfferRequestComment]
} as UIOfferRequest;
