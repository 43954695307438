import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, Button, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment, { Moment } from 'moment-with-locales-es6';
import { useTranslation } from 'react-i18next';
import { CalendarPicker } from '@mui/x-date-pickers';

const useStyles = makeStyles({
	dialog: {
		width: 310,
		overflowX: 'hidden'
	},
	calendar: {
		'& .PrivatePickersSlideTransition-root': {
			minHeight: 216
		},
		'& .PrivatePickersFadeTransitionGroup-root': {
			cursor: 'auto'
		}
	}
});

interface DateDialogProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (date: Moment) => void;
	dialogTitle: React.ReactNode;
	submitLabel: React.ReactNode;
	content?: React.ReactNode;
	initialValue?: Moment;
	disableFuture?: boolean;
	disablePast?: boolean;
	maxDate?: Moment;
	minDate?: Moment;
	shouldDisableDate?: (day: Moment) => boolean;
}

export function DateDialog(props: DateDialogProps) {
	const {
		open,
		onClose,
		onSubmit,
		dialogTitle,
		submitLabel,
		content,
		initialValue,
		...calendarProps
	} = props;
	const classes = useStyles({});
	const { t } = useTranslation();
	const [value, setValue] = useState(moment());
	useEffect(() => {
		if (open && initialValue) {
			setValue(initialValue);
		}
	}, [open, initialValue]);
	return (
		<Dialog open={open} classes={{ paper: classes.dialog }}>
			<DialogTitle>{dialogTitle}</DialogTitle>
			<CalendarPicker
				{...calendarProps}
				className={classes.calendar}
				views={['day']}
				date={value}
				onChange={(day) => setValue(day)}
			/>
			{content}
			<DialogActions>
				<Button color="secondary" variant="text" onClick={() => onClose()}>
					{t('general:cta_cancel')}
				</Button>
				<Button
					data-testid="submit-button-date-dialog"
					color="primary"
					onClick={() => onSubmit(value)}
				>
					{submitLabel}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
