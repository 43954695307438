import CardMedia from '@mui/material/CardMedia';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { LoggedOutView } from '../../components/LoggedOutView';
import { ChoosePassword } from './ChoosePassword';
import { DemandPassword } from './DemandPassword';
import { LoginForm } from './LoginForm';

const useStyles = makeStyles((theme: Theme) => ({
	image: {
		height: 0,
		paddingTop: '25%',
		backgroundSize: 'contain',
		margin: theme.spacing(5)
	}
}));

export const LoginApp = () => {
	const classes = useStyles();
	return (
		<LoggedOutView>
			<CardMedia
				id="login_logo"
				className={classes.image}
				image="./../../static/images/relay-logo.svg"
				title="relay"
			/>
			<Switch>
				<Route path="/login" component={LoginForm} />
				<Route path="/demand_password" component={DemandPassword} />
				<Route path="/choose_password" component={ChoosePassword} />
				<Route path="/confirm_email" component={LoginForm} />
				<Route path="/unsubscribe" component={LoginForm} />
			</Switch>
		</LoggedOutView>
	);
};
