import MUIDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useMobile } from '../hooks/useBreakpoints';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

export const SIDEBAR_WIDTH = '17rem';

const useStyles = makeStyles((theme) => ({
	drawer: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: SIDEBAR_WIDTH,
		zIndex: 0,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		'@media print': {
			display: 'none'
		},
		'& > nav': {
			flexShrink: 0
		}
	},
	drawerMin: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing(7)
	},
	link: {
		textDecoration: 'none',
		color: 'inherit',
		'& > *:hover': {
			background: 'rgba(93, 96, 214, 0.15)'
		}
	},
	activeLink: {
		'& > *': {
			background: theme.palette.grey[200]
		}
	},
	icon: {
		minWidth: theme.spacing(5)
	},
	blackBackground: {
		background: '#373F51',
		borderRadius: '10px 10px 0px 0px',
		color: '#FFFFFF'
	},
	whiteLinkText: {
		color: '#FFFFFF',
		'& > *:hover': {
			background: 'rgba(93, 96, 214, 0.15)'
		}
	}
}));

export interface TopItem {
	show?: boolean;
	id: string;
	to: string | { pathname: string; search: string };
	onClick?: () => void;
	Icon: React.ReactNode;
	text: string;
	SubList?: React.ReactNode;
	Logo?: React.ReactNode;
	extra?: React.ReactNode;
}

export interface BottomItem {
	id: string;
	to?: string;
	onClick?: () => void;
	Icon?: React.ReactNode;
	text: string;
}

export interface DrawerProps {
	Top: TopItem[];
	Bottom: BottomItem[];
	onClose: () => void;
	open: boolean;
}

function Drawer({ Top, Bottom, onClose, open, ...rest }: DrawerProps) {
	const classes = useStyles();
	const isMobile = useMobile();

	return (
		<MUIDrawer
			variant={!isMobile ? 'permanent' : 'temporary'}
			{...rest}
			classes={{ paper: classNames(classes.drawer, !open && classes.drawerMin) }}
			open={open}
			onClose={onClose}
		>
			<List component="nav" sx={{ pt: 0, flexGrow: isMobile ? 0 : 99 }}>
				{Top.map(
					({ show = true, id, to, onClick, Icon, text, SubList, Logo, extra }) =>
						!!show && (
							<Fragment key={id}>
								{Logo}
								<NavLink
									id={`navigation_link_${id}`}
									to={to}
									className={classes.link}
									activeClassName={classes.activeLink}
									onClick={onClick || onClose}
								>
									<ListItem button>
										<ListItemIcon className={classes.icon}>{Icon}</ListItemIcon>
										<ListItemText sx={{ flex: 'none' }} primary={text} />
										<span>{extra}</span>
									</ListItem>
								</NavLink>
								{SubList}
							</Fragment>
						)
				)}
			</List>

			<List
				component="nav"
				sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'flex-start' }}
				className={classes.blackBackground}
			>
				<div className={classes.whiteLinkText}>
					{Bottom.map(({ id, to, onClick, Icon, text }) => (
						<ListItem
							button
							key={id}
							id={`navigation_link_${id}`}
							target={!onClick && '_blank'}
							href={to}
							onClick={onClick ? onClick : onClose}
							component="a"
						>
							{Icon}
							<ListItemText
								primary={
									<Typography
										variant="caption"
										color="#FFFFFF"
										sx={Icon ? { ml: 3, fontWeight: 700 } : {}}
									>
										{text}
									</Typography>
								}
							/>
						</ListItem>
					))}
				</div>
			</List>
		</MUIDrawer>
	);
}

export type Drawer = typeof Drawer;

// eslint-disable-next-line import/no-default-export
export default Drawer;
