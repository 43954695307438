import { MetaList, UIMetaData, UIAddress } from '../api-types/v1';

export interface UIProperty {
	id: number;
	name: string;
	status: string;
	statusUpdatedAt: string;
	address: UIAddress;
	customer: { name: string; id: number };
	createdAt: string;
	internalId: string;
	insurances: Array<{ externalId: string }>;
	origin: string;
}
export interface UIPropertiesStatistics {
	ACCEPTED?: number;
	PENDING?: number;
	DECLINED?: number;
	ALL?: number;
}

export interface CreatePropertyBody {
	internalId?: string;
	status: PropertyStatus;
	name: string;
	address: UIAddress;
	origin: PropertyOrigin;
	customerId: number;
}

export interface UIUIProperty extends MetaList<UIProperty, { propertiesCount: number }> {}

export enum PropertyStatus {
	Pending = 'PENDING',
	Accepted = 'ACCEPTED',
	Rejected = 'REJECTED'
}

export enum PropertyOrigin {
	Relay = 'relay',
	Casavi = 'casavi',
	Techem = 'techem',
	AccountCreation = 'accountCreation'
}

export interface GetPropertiesResponse extends MetaList<UIProperty, UIMetaData> {}
