import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../reducers';
import {
	loadOfferRequests,
	loadArchivedOfferRequests,
	setStatusFilter,
	getFiltersFromSearch,
	getStatusFromSearch,
	OfferRequestStatus,
	subMenuItems
} from '../offer-requests/actions';
import { useLocation } from 'react-router';
import qs from 'qs';
import { SubList } from './SubList';
import { SubListItem } from './SubListItem';
import { getStatusLabel } from '../offer-requests/helpers';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	chip: {
		borderWidth: '1.5pt',
		fontWeight: 500,
		color: '#8F8FA3',
		borderColor: '#8F8FA3',
		cursor: 'inherit',
		marginLeft: theme.spacing(1)
	},
	OPEN: {
		backgroundColor: theme.orderStatus.open.main,
		borderColor: theme.orderStatus.open.main,
		color: theme.orderStatus.open.contrastText
	},
	SUBMITTED: {
		backgroundColor: theme.orderStatus.inProgress.main,
		borderColor: theme.orderStatus.inProgress.main,
		color: theme.orderStatus.open.contrastText
	},
	FINISHED: {
		backgroundColor: theme.orderStatus.finished.main,
		borderColor: theme.orderStatus.finished.main,
		color: theme.orderStatus.finished.contrastText
	},
	ALL: {
		backgroundColor: theme.orderStatus.declined.main,
		borderColor: theme.orderStatus.declined.contrastText,
		color: theme.orderStatus.declined.contrastText
	},
	content: {
		alignContent: 'space-between',
		display: 'flex'
	},
	label: {
		flex: '1 1 100%',
		fontSize: '0.875rem',
		// for crossbrowser: border consistency of the label and the chip (MuiChip-outlined) nearby on the right side
		border: '1px solid transparent'
	},
	activeLink: {
		color: theme.palette.grey[100]
	},
	link: {
		color: 'inherit',
		textDecoration: 'none'
	}
}));

export function OfferRequestSubList() {
	const location = useLocation();
	const { statistics, filters } = useSelector((state: State) => state.offerRequests);
	const dispatch = useDispatch();
	const classes = useStyles({});
	const { t } = useTranslation();
	const search = qs.parse(location.search, { ignoreQueryPrefix: true });
	const handleOnClick = (status: OfferRequestStatus) => {
		if (status === 'ARCHIVE') dispatch(loadArchivedOfferRequests());
		else dispatch(loadOfferRequests({ ...filters, status }));
		dispatch(setStatusFilter(status, true, '/app/offer-requests'));
	};

	const [active, setActive] = useState<OfferRequestStatus>('ALL');

	useEffect(() => {
		if (location.pathname !== '/app/offer-requests') return;

		const { status } = getFiltersFromSearch(location.search);
		const _status = getStatusFromSearch(filters.status || status || '');
		setActive(_status);
		if (filters.status !== _status) dispatch(setStatusFilter(_status));
	}, [filters?.status, location?.pathname, statistics]);

	return (
		<SubList path="/app/offer-requests">
			{subMenuItems.map((status) => {
				const isActive = active === status;
				const count = statistics ? statistics[status] : 0;
				return (
					<SubListItem
						key={status}
						to={{
							pathname: '/app/offer-requests',
							search: qs.stringify({ ...search, status })
						}}
						isActive={isActive}
						onClick={() => handleOnClick(status)}
					>
						<div className={classes.content}>
							<span
								className={clsx(classes.label, {
									[classes.activeLink]: isActive
								})}
							>
								{getStatusLabel(status, t)}
							</span>
							{status !== 'ARCHIVE' && typeof count === 'number' ? (
								<Chip
									variant="outlined"
									className={clsx(classes.chip, {
										[classes[status]]: isActive
									})}
									size="small"
									label={String(count)}
								/>
							) : null}
						</div>
					</SubListItem>
				);
			})}
		</SubList>
	);
}
