import { Grid } from '@mui/material';
import { GridProps } from '@mui/material/Grid';
import * as React from 'react';
import { Thumbnail } from './Thumbnail';
import { DropzoneFile, OnRemoveCallback } from './types';

interface ThumbnailListProps extends GridProps {
	files: DropzoneFile[];
	onRemove: OnRemoveCallback;
}

export const ThumbnailList = ({ files, onRemove, ...props }: ThumbnailListProps) => {
	return (
		<Grid container spacing={1} {...props}>
			{files.map((file, i) => (
				<Grid key={i} item>
					<Thumbnail file={file} onRemove={onRemove} />
				</Grid>
			))}
		</Grid>
	);
};
