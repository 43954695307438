import { Box, Grid } from '@mui/material';
import { State } from 'app/core/reducers';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import {
	handleSubmit,
	prefillProfileEditForm,
	setEmailAddress,
	setFirstName,
	setLastName,
	setTelephone
} from './actions';
import { RouteLeavingGuard } from '../../components/RouteLeavingGuard';
import { useHistory } from 'react-router';
import Page from '../../mui-custom/Page/Page';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';
import { AdminExternalProfileAppV3 } from '../admin/externalProfile/AppV3';

export const ProfileForm = () => {
	const { firstName, lastName, telephone, email } = useSelector(
		(state: State) => state?.profileEdit
	);
	const { user } = useSelector((state: State) => state.auth);
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		dispatch(
			prefillProfileEditForm({
				user
			})
		);
	}, []);

	const onSubmit = async () => {
		setIsEditing(false);
		// eslint-disable-next-line @typescript-eslint/await-thenable
		await dispatch(handleSubmit());
	};

	const handleFocus = (_ev: Event) => {
		setIsEditing(true);
	};
	const formRef = useRef(null);

	return (
		<ValidatorForm
			ref={formRef}
			onSubmit={onSubmit}
			onError={(errors) => console.log(errors)}
			onFocus={handleFocus}
		>
			<Page
				headline={t('general:profile')}
				actionBar={
					isEditing && (
						<ActionBar
							primary={{ children: t('general:cta_save_edit'), type: 'submit' }}
							primaryAsSecondary
							secondary={{
								children: t('general:cta_discard'),
								onClick: () => {
									setIsEditing(false);
									dispatch(
										prefillProfileEditForm({
											user
										})
									);
								}
							}}
						/>
					)
				}
			>
				<Box>
					<Grid container spacing={2}>
						<Grid item {...{ xs: 12, mobile: 6 }}>
							<TextValidator
								name="profileFirstNameInput"
								label={t('general:first_name')}
								validators={['required']}
								value={firstName}
								onChange={(e) => dispatch(setFirstName(e.target.value))}
								errorMessages={[t('general:required_message')]}
								fullWidth
							/>
						</Grid>
						<Grid item {...{ xs: 12, mobile: 6 }}>
							<TextValidator
								label={t('general:last_name')}
								name="profileLastNameInput"
								validators={['required']}
								value={lastName}
								onChange={(e) => dispatch(setLastName(e.target.value))}
								errorMessages={[t('general:required_message')]}
								fullWidth
							/>
						</Grid>
						<Grid item {...{ xs: 12 }}>
							<TextValidator
								label={t('general:telephone')}
								name="profilePhoneInput"
								value={telephone}
								type="telephone"
								validators={['required', 'isValidPhoneNumber']}
								errorMessages={[
									t('general:required_message'),
									t('validation:invalid_phone_number')
								]}
								onChange={(e) => dispatch(setTelephone(e.target.value))}
								fullWidth
							/>
						</Grid>
						<Grid item {...{ xs: 12 }}>
							<TextValidator
								label={t('general:email')}
								name="profileEMailInput"
								validators={['required', 'isEmail', 'isEmailChangedAndAvailable']}
								value={email}
								onChange={(e) => dispatch(setEmailAddress(e.target.value))}
								errorMessages={[
									t('general:required_message'),
									t('validation:invalid_email_address'),
									t('validation:email_already_exists')
								]}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
				<RouteLeavingGuard
					navigate={(path) => history.push(path)}
					shouldBlockNavigation={(_location) => isEditing}
					title={t('profile:discard_changes')}
					confirmButtonText={t('general:cta_discard_edit')}
				/>
				<AdminExternalProfileAppV3 />
			</Page>
		</ValidatorForm>
	);
};
