import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export const BasicChip = (props) => {
	return (
		<Stack direction="row" spacing={1}>
			<Chip color="info" style={{ marginLeft: '8px' }} {...props} />
		</Stack>
	);
};
