import React from 'react';
import {
	Badge,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
	useTheme
} from '@mui/material';
import { AttachFile, Block, Send } from '@mui/icons-material';
import { DropzoneFile } from '../../components/Dropzone/types';
import { containsFailedFiles, isReadyToUpload } from '../../components/Dropzone/validation';
import { isWhiteSpaceOnly } from '../../lib/validator';
import { UIOrderComment, UITicketComment } from '@api/v1';
import { sortGroupByDate, compareBy } from '../../lib/dateHelper';
import { DateDivider } from './DateDivider';
import { useTranslation } from 'react-i18next';

export interface ChatProps<T extends UIOrderComment | UITicketComment> {
	title?: string;
	text?: string;
	handleChange?: (e) => void;
	children?: unknown;
	files: DropzoneFile[];
	handleAttachmentClick: React.MouseEventHandler<HTMLButtonElement>;
	handleSend: (input: string) => void;
	comments?: T[];
	Item: ({ item }: { item: T }) => React.JSX.Element;
	sx?: object;
	disabled: boolean;
}

export function Chat<T extends UIOrderComment | UITicketComment>({
	title,
	text,
	handleChange,
	children,
	files,
	handleAttachmentClick,
	handleSend,
	comments,
	Item,
	sx,
	disabled = false
}: ChatProps<T>) {
	const theme = useTheme();
	const { t } = useTranslation();

	const isUploaded = isReadyToUpload(files);
	const uploadFailed = containsFailedFiles(files);
	const isValid = isUploaded && !isWhiteSpaceOnly(text);
	const badgeContent: React.ReactNode = (() => {
		if (uploadFailed) return <Block />;
		if (!isUploaded) return <CircularProgress color="inherit" size={16} />;
		return files?.length;
	})();
	const uniqueExternalAuthors = [
		...new Set(
			comments.flatMap((comment) => {
				if (comment.author.id) return [];
				return comment.author.name;
			})
		)
	];

	function formatArrayWithCommasAndAnd(array: string[]) {
		if (array.length === 0) {
			return '';
		} else if (array.length === 1) {
			return array[0];
		} else {
			const lastElement = array.pop();
			const joinedString = array.join(', ');
			return `${joinedString} und ${lastElement}`;
		}
	}

	const handleSubmitComment = () => {
		handleSend(text);
	};

	return (
		<Card variant="outlined" sx={{ width: theme.spacing(45), ...sx }}>
			<CardHeader
				title={
					title ||
					`Konversation zwischen Ihnen${
						uniqueExternalAuthors.length === 1 ? ' und' : ', '
					} ${formatArrayWithCommasAndAnd(uniqueExternalAuthors)}`
				}
			/>
			<Divider />
			<CardContent
				id="chat-content"
				sx={{
					overflowY: 'auto',
					height: theme.spacing(70),
					display: 'flex',
					flexDirection: 'column',
					gap: 2
				}}
			>
				{comments?.length ? (
					sortGroupByDate(comments).map((commentGroup: [string, T[]]) => {
						const [dateString, groupedComments] = commentGroup;
						const sortedComments = [...groupedComments].sort(compareBy('createdAt', 'desc'));
						return (
							<React.Fragment key={dateString}>
								<DateDivider dateString={dateString} />
								{sortedComments.map((comment) => (
									<Item key={comment.id} item={comment} />
								))}
							</React.Fragment>
						);
					})
				) : (
					<Typography>Hier findet ihre Unterhaltung statt</Typography>
				)}
			</CardContent>
			<Divider />
			<Stack
				spacing={1}
				sx={{
					py: 2,
					px: 1,
					'@media print': {
						display: 'none'
					}
				}}
				direction="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<IconButton onClick={handleAttachmentClick} size="large" disabled={disabled}>
					{files?.length ? (
						<Badge badgeContent={badgeContent} color={uploadFailed ? 'error' : 'primary'}>
							<AttachFile />
						</Badge>
					) : (
						<AttachFile />
					)}
				</IconButton>
				<TextField
					placeholder={t('orders:comment_placeholderV2')}
					multiline
					value={text}
					onChange={handleChange}
					sx={{
						'& .MuiOutlinedInput-root': { p: 0 },
						flexGrow: 1,
						background: theme.palette.grey[100]
					}}
					disabled={disabled}
				/>
				<IconButton disabled={disabled || !isValid} onClick={handleSubmitComment} size="large">
					<Send />
				</IconButton>
			</Stack>
			{children}
		</Card>
	);
}

export type Chat = ReturnType<typeof Chat>;
