import { api } from '../../lib/api';
import { createApiAction } from '../../redux/createApiAction';
import { AccountSpecificFeature } from './features';

export const loadActiveFeatures = createApiAction('acl/load_active_features', () => {
	return api.get('/api/features/active');
});

export const loadAccountSpecificFeatures = createApiAction(
	'acl/load_account_specific_features',
	({ accountId }: { accountId: number }) => {
		return api.get(`/api/features/${accountId}/variants`);
	}
);

export const activateAccountSpecificFeature = createApiAction(
	'acl/activate_account_specific_feature',
	({
		accountId,
		flag,
		callback
	}: {
		accountId: number;
		flag: `${AccountSpecificFeature}`;
		callback?: () => void;
	}) => {
		return api.post(`/api/features/${accountId}/variant`, { flagName: flag }).then(callback);
	}
);

export const deactivateAccountSpecificFeature = createApiAction(
	'acl/deactivate_account_specific_feature',
	({
		accountId,
		flag,
		callback
	}: {
		accountId: number;
		flag: `${AccountSpecificFeature}`;
		callback?: () => void;
	}) => {
		return api.delete(`/api/features/${accountId}/variant/${flag}`).then(callback);
	}
);
