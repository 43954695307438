import React from 'react';
import { Avatar, Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextGroupHeadline } from '../TextGroupSmall/TextGroupHeadline';
import { TextGroupText } from '../TextGroupSmall/TextGroupText';
import { useMobile } from '../../hooks/useBreakpoints';

export interface ListedCardProps {
	headline: React.ReactNode;
	icon: React.ReactNode;
	onClick: React.MouseEventHandler<React.ReactNode>;
	text: React.ReactNode;
	headlineIcon?: React.ReactNode;
	thumbnail?: React.ReactNode;
	placeholder?: React.ReactNode;
	warning?: boolean;
	children?: React.ReactNode;
}

interface ListedCardContainerProps {
	warning: boolean;
}

const ListedCardContainer = styled(Stack)<ListedCardContainerProps>(({ theme, warning }) => ({
	width: '100%',
	flexGrow: 1,
	borderRadius: theme.spacing(0.5),
	border: '1px solid',
	backgroundColor: `${warning ? theme.palette.warningShades4p : theme.palette.background.paper}`,
	borderColor: `${warning ? theme.palette.warning.light : theme.palette.otherOutlinedBorderLight}`,
	'&:hover': {
		border: '1px solid',
		borderColor: `${warning ? theme.palette.warning.dark : 'rgba(0, 0, 0, 0.8)'}`,
		cursor: 'pointer'
	}
}));

const MiddleColumStack = styled(Stack)(({ theme }) => ({
	minHeight: theme.spacing(10),
	padding: theme.spacing(1, 2),
	flexGrow: 1,
	boxSizing: 'content-box'
}));

const IconStack = styled(Stack)(({ theme }) => ({
	color: theme.palette.text.secondary,
	minHeight: theme.spacing(10),
	padding: theme.spacing(1, 2, 1, 0),
	boxSizing: 'content-box',
	minWidth: theme.spacing(4)
}));

const ThumbnailBox = styled(Box)(({ theme }) => ({
	padding: theme.spacing(1, 0, 1, 1),
	height: theme.spacing(10),
	width: theme.spacing(10),
	boxSizing: 'content-box',
	flexShrink: 0
}));

export function ListedCard({
	children,
	thumbnail,
	headline,
	text,
	icon,
	headlineIcon,
	placeholder,
	warning = false,
	onClick
}: ListedCardProps) {
	const isMobile = useMobile();
	return (
		<ListedCardContainer direction="row" spacing={0} onClick={onClick} warning={warning}>
			{thumbnail && (
				<ThumbnailBox>
					{typeof thumbnail === 'string' ? (
						<Avatar
							alt="listed-card-avatar"
							src={thumbnail}
							variant="rounded"
							sx={{ height: 1, width: 1, '> img': { objectFit: 'contain' } }}
						/>
					) : (
						thumbnail
					)}
				</ThumbnailBox>
			)}
			<MiddleColumStack justifyContent="center">
				<Stack sx={{ mt: !!(placeholder || children) ? 'auto' : 0 }}>
					<Stack direction="row" spacing={1}>
						<TextGroupHeadline>{headline || '\u00A0'}</TextGroupHeadline>
						{headlineIcon}
					</Stack>
					<TextGroupText>{text || '\u00A0'}</TextGroupText>
				</Stack>
				<Box sx={{ mt: !!(placeholder || children) ? 'auto' : 0 }}>{placeholder || children}</Box>
			</MiddleColumStack>
			{!isMobile && (
				<IconStack alignItems="flex-start" justifyContent="center">
					{icon}
				</IconStack>
			)}
		</ListedCardContainer>
	);
}

export type ListedCard = ReturnType<typeof ListedCard>;
