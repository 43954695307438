import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../reducers';
import { closeDeactivationDialog, deactivateUser } from './actions';
import { getUserName } from './utils';
import CustomConfirmDialog from '../../../mui-custom/ConfirmDialog/ConfirmDialog';
import { ButtonColor } from '../../../mui-custom/Button/PrimaryButton';

export const AdminUserDeactivationDialogV2 = () => {
	const { open, user } = useSelector((state: State) => state.admin.users.deactivationDialog);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	return (
		<CustomConfirmDialog
			open={open}
			title={t('admin:user_deactivation_dialog_header')}
			text={t('admin:user_deactivation_dialog_content', {
				name: user ? getUserName(user) : null,
				emailAddress: user ? user.email : null
			})}
			onClose={() => dispatch(closeDeactivationDialog())}
			primaryAction={{
				children: t('admin:user_deactivation_dialog_primary'),
				onClick: () => dispatch(deactivateUser(user)),
				color: ButtonColor.Destructive
			}}
			secondaryAction={{
				children: t('general:cta_cancel')
			}}
		/>
	);
};
