import { UIOffer } from '@api/v1';
import { Chip, Card, Typography, Theme, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentList, AttachmentThumbnail } from '../../../components/Attachment';
import { Close as CloseIcon, Check } from '@mui/icons-material';
import { dateTime } from '../../../lib/dateHelper';
import { NumberFormatter } from '../../../lib/numberFormater';
import { UIOfferWithIndex } from './CommentContainer';

const useStyles = makeStyles((theme: Theme) => ({
	content: {
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		margin: theme.spacing(1),
		border: '2px solid #8F8FA3',
		backgroundColor: '#F8F8F8'
	},
	header: {
		display: 'flex',
		marginBottom: theme.spacing(3),
		'& h6': {
			fontSize: '1.125rem',
			marginLeft: theme.spacing(2)
		}
	},
	chip: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		background: ' #FFFFFF',
		border: '1px solid #8F8FA3',
		boxSizing: 'border-box',
		borderRadius: '16px'
	},
	lightChip: {
		background: 'rgba(199, 199, 209, 0.2)',
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(4),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	footer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	time: {
		'& span': {
			marginLeft: theme.spacing(1)
		}
	},
	accepted: {
		borderColor: '#38977F'
	},
	declined: {
		borderColor: '#DB494F'
	},
	footerStatusText: {
		'& span, svg': {
			color: ({ status }: UIOffer) => ({ ACCEPTED: '#38977F', DECLINED: '#DB494F' }[status])
		},
		'& svg': {
			marginRight: theme.spacing(1)
		}
	},
	lineThroughIfDeclined: {
		textDecoration: ({ status }: UIOffer) => (status === 'DECLINED' ? 'line-through' : '')
	}
}));

export function Offer({ offer, assignRef }: { offer: UIOfferWithIndex; assignRef: any }) {
	const classes = useStyles(offer);
	const { t } = useTranslation();
	return (
		<Card
			variant="outlined"
			className={clsx(
				classes.content,
				{ ACCEPTED: classes.accepted, DECLINED: classes.declined }[offer.status] ?? ''
			)}
			ref={(node) => assignRef(node, offer.index)}
		>
			<div className={classes.header}>
				<Chip size="small" label={t('offers:offer')} className={classes.chip} />
				<Typography className={classes.lineThroughIfDeclined} variant="h6">
					{offer.title}
				</Typography>
			</div>
			<Box display="flex" flexWrap="wrap">
				{!!offer.executionDate && (
					<Box display="flex" justifyContent="flex-start" alignItems="center">
						<Typography variant="caption">{t('offers:label_earliest_execution_date')}:</Typography>
						<Chip
							size="small"
							label={dateTime(offer.executionDate).toFormat('dd.MM.yyyy')}
							className={clsx(classes.lightChip, classes.lineThroughIfDeclined)}
						/>
					</Box>
				)}
				{!!offer.price && (
					<Box display="flex" justifyContent="flex-start" alignItems="center">
						<Typography variant="caption">{t('offers:label_price')}:</Typography>
						<Chip
							size="small"
							// options could be then taken from i18n
							label={`${NumberFormatter.currency().format(offer.price)}`}
							className={clsx(classes.lightChip, classes.lineThroughIfDeclined)}
						/>
					</Box>
				)}
			</Box>
			{!!offer.description && (
				<>
					<br />
					<Typography variant="caption">{t('offers:label_description')}:</Typography>
					<Typography className={classes.lineThroughIfDeclined} variant="body2">
						{offer.description}
					</Typography>
				</>
			)}

			{!!offer.attachments?.length && (
				<>
					<br />
					<AttachmentList>
						{offer.attachments.map((attachment) => (
							<AttachmentThumbnail
								key={attachment.id}
								name={attachment.name}
								link={attachment.url}
								thumbnail={attachment.thumbnailS3Key}
							/>
						))}
					</AttachmentList>
				</>
			)}
			<br />
			<div className={classes.footer}>
				<Box display="flex" className={classes.footerStatusText}>
					{['ACCEPTED', 'DECLINED'].includes(offer.status) && (
						<>
							{offer.status === 'ACCEPTED' && <Check fontSize="small" />}
							{offer.status === 'DECLINED' && <CloseIcon fontSize="small" />}
							<Typography variant="caption">
								{t(
									offer.status === 'ACCEPTED'
										? 'offers:accepted_footer_text'
										: 'offers:declined_footer_text',
									{
										date: offer.statusUpdatedAt
											? dateTime(offer.statusUpdatedAt).toFormat('dd.MM.yyyy, HH:mm')
											: '?',
										name: offer.statusUpdater?.name || ''
									}
								)}
							</Typography>
						</>
					)}
				</Box>

				<Typography variant="caption" className={classes.time} color="textSecondary">
					{t('offers:created_from')}
					{` ${offer.internalAuthor?.firstName} ${offer.internalAuthor?.lastName} `}
					{dateTime(offer.createdAt).toFormat('dd.MM.yyyy, HH:mm')}
				</Typography>
			</div>
		</Card>
	);
}
