import Card from '@mui/material/Card';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Footer } from '../core/auth/Footer';
import { Stack } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		minWidth: 600,
		[theme.breakpoints.down('sm')]: {
			minWidth: 'unset',
			width: '100%'
		}
	}
}));

export const LoggedOutView = ({ sx = {}, children }) => {
	const classes = useStyles();
	return (
		<Stack>
			<Card className={classes.card} sx={{ maxWidth: 600, mx: 'auto', ...sx }}>
				{children}
			</Card>
			<Footer />
		</Stack>
	);
};
