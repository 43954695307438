import i18next from 'i18next';

export const getStatusLabel = (status: string, t: i18next.TFunction): string =>
	t(
		{
			ALL: 'offers:status_OVERALL',
			OPEN: 'offers:status_OPEN',
			SUBMITTED: 'offers:status_SUBMITTED',
			ARCHIVE: 'offers:status_ARCHIVED'
		}[status]
	) ?? null;

export const getItemChipLabel = (status: string, t: i18next.TFunction): string =>
	t(
		{
			OPEN: 'offers:status_chip_REQUEST',
			DECLINED: 'offers:status_chip_REQUEST',
			SUBMITTED: 'offers:status_chip_SUBMITTED',
			REJECTED: 'offers:status_chip_SUBMITTED',
			ARCHIVE: 'offers:status_chip_ARCHIVE'
		}[status]
	) ?? null;

export const scrollToElementWithOffset = (
	element: HTMLElement,
	yOffset = 0,
	targetScrollContainerId?: string
) => {
	const bodyRect = document.body.getBoundingClientRect().top;
	const elementRect = element.getBoundingClientRect().top;
	const elementPosition = elementRect - bodyRect;
	const y = elementPosition - yOffset;
	document
		.getElementById(targetScrollContainerId)
		.scrollBy({ left: 0, top: y, behavior: 'smooth' });
};
