import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../reducers';
import { closeInvitationDialog, inviteUser } from './actions';
import { getUserName } from './utils';
import CustomConfirmDialog from '../../../mui-custom/ConfirmDialog/ConfirmDialog';

export const AdminUserInvitationDialogV2 = () => {
	const { open, user } = useSelector((state: State) => state.admin.users.invitationDialog);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	return (
		<CustomConfirmDialog
			open={open}
			title={t('admin:user_invitation_dialog_header')}
			text={t('admin:user_invitation_dialog_content', {
				name: user ? getUserName(user) : null,
				emailAddress: user ? user.email : null
			})}
			onClose={() => dispatch(closeInvitationDialog())}
			primaryAction={{
				children: t('admin:user_invitation_dialog_invite'),
				onClick: () => dispatch(inviteUser(user))
			}}
			secondaryAction={{
				children: t('general:cta_cancel')
			}}
		/>
	);
};
