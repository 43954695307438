import {
	Typography,
	ButtonBase as OrgButtonBase,
	Card,
	Chip,
	Box,
	Tooltip,
	Alert,
	AlertTitle,
	Stack,
	CardHeader
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Chat, Description, Edit, Print } from '@mui/icons-material';
import { Convert } from '../../../components/Icons/Convert';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { State } from '../../reducers';
import {
	fetchOrder,
	openAcceptDialog,
	openMakeExternalDialog,
	openInternalIdDialog,
	openTitleDialog,
	openDeclineDialog,
	assignOrder,
	openEditDialog
} from '../actions';
import { AcceptDialog } from './AcceptDialog';
import { CommentCompose } from './CommentCompose';
import { OrderContent } from './Content';
import { DeclineDialog } from './DeclineDialog';
import { FinishDialog } from './FinishDialog';
import { InitialActions } from './InitialActions';
import { InProgressActions } from './InProgressActions';
import { InternalIdDialog } from './InternalIdDialog';
import { OrderProgress } from './Progress';
import { showNotification } from '../../../core/actions';
import { TitleDialog } from './TitleDialog';
import { HoverBase } from '../../../components/HoverBase';
import { OrderStatus } from '../../../data/types/orderStatus';
import { MakeExternalDialog } from './MakeExternalDialog';
import { OrderEditDialog } from './EditDialog';
import Page from '../../../mui-custom/Page/Page';
import { getStatusVariant } from '../../../mui-custom/Chat/OrderMessage';
import { getStatusChangeLabel } from './Comment';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMobile } from '../../../hooks/useBreakpoints';
import ListOrTabNav from '../../../mui-custom/ListOrTabNav/ListOrTabNav';
import { getOrderTitle } from '../list/List';
import { useAccountDeactivatedCheck } from '../../../hooks/useAccountDeactivatedCheck';

const ButtonBase = (props: { [x: string]: unknown }) => {
	const { accountDeactivated } = useAccountDeactivatedCheck();
	if (!accountDeactivated) return <OrgButtonBase {...props} />;
	return <OrgButtonBase {...props} disabled />;
};

enum MOBILE {
	DETAIL,
	CHAT
}
const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: '-1.5rem',
		width: '100%',
		height: 'calc(100% + 1.5rem)',
		display: 'flex',
		flexDirection: 'column',
		'@media print': {
			display: 'block'
		}
	},
	details: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		overflow: 'unset',
		'@media print': {
			margin: 0,
			boxShadow: 'none',
			pageBreakAfter: 'always'
		},
		paddingBottom: theme.spacing(1)
	},
	compose: {
		flex: '0 0 auto',
		margin: theme.spacing(1)
	},
	statusBlock: {
		padding: theme.spacing(3)
	},
	separateLine: {
		height: theme.spacing(1),
		border: 0,
		margin: 0
	},
	declined: {
		background: theme.orderStatus.declined.main,
		color: theme.orderStatus.declined.contrastText
	},
	finished: {
		background: theme.orderStatus.finished.main,
		color: theme.orderStatus.finished.contrastText
	},
	open: {
		background: theme.orderStatus.open.main,
		color: theme.orderStatus.open.contrastText
	},
	inProgress: {
		background: theme.orderStatus.inProgress.main,
		color: theme.orderStatus.inProgress.contrastText
	},
	link: {
		color: 'inherit'
	},
	stepperContainer: {
		display: 'flex'
	},
	hideOnPrint: {
		'@media print': {
			display: 'none'
		}
	},
	fullWidthOnPrint: {
		'@media print': {
			flexDirection: 'column',
			margin: 0
		}
	},
	textButton: {
		justifyContent: 'flex-start',
		width: '100%'
	},
	textInsideButton: {
		width: '100%',
		textAlign: 'left',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	fullWidth: {
		width: '100%',
		'& > div': {
			width: '100%'
		}
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: '4px 4px 0 0',
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
		backgroundColor: theme.palette.grey[200],
		'& > *:first-child': {
			marginRight: 'auto'
		}
	},
	headerIsInternal: {
		backgroundColor: '#D9D9F2'
	},
	headerButton: {
		padding: theme.spacing(1)
	},
	headerIsTest: {
		backgroundColor: '#D1C7C7'
	}
}));
export function OrderDetailView() {
	const dispatch = useDispatch();
	const params = useParams();
	const location = useLocation();
	const { t } = useTranslation();
	const classes = useStyles();
	const order = useSelector((state: State) => state.orders.single.order);
	const isAddingComment = useSelector(
		(state: State) => state.orders.single.commentCompose.isAdding
	);
	const [mobileActive, setMobileActive] = useState(0);
	const isMobile = useMobile();
	const { accountDeactivated } = useAccountDeactivatedCheck();

	useEffect(() => {
		const targetElement = document.getElementById('comment-container')?.children?.[1];
		if (targetElement) {
			/* fast scroll to the view port */
			targetElement.scrollIntoView({ block: 'nearest', behavior: 'auto' });
			/* smooth scroll to the last comment */
			targetElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
		}
		if (!isAddingComment) dispatch(fetchOrder(Number(params.orderId)));
		const query = qs.parse(location?.search, {
			ignoreQueryPrefix: true
		});
		switch (query.action) {
			case 'decline':
				if (order.status === OrderStatus.OPEN || order.status === OrderStatus.IN_PROGRESS) {
					dispatch(openDeclineDialog());
				} else {
					dispatch(showNotification(t('orders:cannot_change_status'), 'error'));
				}
				return;
			case 'accept':
				if (order.status === OrderStatus.OPEN) {
					dispatch(openAcceptDialog());
				} else {
					dispatch(showNotification(t('orders:cannot_change_status'), 'error'));
				}
				return;
			case 'internalId':
				dispatch(openInternalIdDialog());
				return;
			default:
				return;
		}
	}, [isAddingComment, params?.orderId]);

	if (order instanceof Error) return 'An error occurred';
	if (!order) return <LoadingScreen />;

	return (
		<>
			{isMobile && (
				<ListOrTabNav
					variant="tab"
					navItems={[
						{ icon: Description, label: t('orders:detail_page_mobile_nav_order') },
						{ icon: Chat, label: t('orders:detail_page_mobile_nav_chat') }
					]}
					setActiveId={setMobileActive}
					activeId={mobileActive}
					className={classes.hideOnPrint}
				/>
			)}
			<Stack
				direction="row"
				sx={isMobile ? {} : { m: 2, px: 2, gap: 2 }}
				className={classes.fullWidthOnPrint}
			>
				{(!isMobile || mobileActive === MOBILE.DETAIL) && (
					<Stack sx={{ flex: '1 1 67%' }}>
						<Page
							id="order_details_container"
							headline={
								<Stack direction="row" sx={{ justifyContent: 'space-between' }}>
									<Stack direction="row" spacing={2}>
										<span>{getOrderTitle(order)}</span>
										{order.isTest ? (
											<Chip size="small" label={t('orders:test_order')} />
										) : (
											<Chip
												size="small"
												label={t(
													order.isInternal ? 'orders:internal_order' : 'orders:external_order'
												)}
											/>
										)}
									</Stack>
									{order.isInternal && (
										<Stack direction="row">
											<Tooltip title={t('orders:edit')} placement="left">
												<ButtonBase
													className={classes.headerButton}
													onClick={() => dispatch(openEditDialog())}
												>
													<Edit />
												</ButtonBase>
											</Tooltip>
											<Tooltip title={t('orders:cta_send_order')} placement="left">
												<ButtonBase
													className={classes.headerButton}
													onClick={() => dispatch(openMakeExternalDialog())}
												>
													<Convert />
												</ButtonBase>
											</Tooltip>
										</Stack>
									)}
									<ButtonBase onClick={() => window.print()} className={classes.headerButton}>
										<Print />
									</ButtonBase>
								</Stack>
							}
							text={
								<>
									{`${t('orders:label_identifier')}: ${order.taskId || order.id}`}
									{order.isInternal ? (
										!!order.internalId && (
											<Typography
												className={classes.textInsideButton}
												variant="body2"
												color="textSecondary"
											>
												{t('orders:label_internal_id')}: #{order.internalId}
											</Typography>
										)
									) : (
										<ButtonBase
											className={classes.textButton}
											id="order_details_button_internal_order_number"
											onClick={() => dispatch(openInternalIdDialog())}
										>
											{
												<Typography
													className={classes.textInsideButton}
													variant="body2"
													color={order.internalId ? 'textSecondary' : 'primary'}
												>
													{order.internalId
														? `${t('orders:label_internal_id')}: #${order.internalId}`
														: t('orders:label_add_internal_id')}
												</Typography>
											}
										</ButtonBase>
									)}
								</>
							}
						>
							<Alert severity={getStatusVariant(order.status)} className={classes.hideOnPrint}>
								<AlertTitle>
									{getOrderTitle(order) || t('orders:detail_page_alert_title')}
								</AlertTitle>
								<Typography variant="body2">
									{getStatusChangeLabel(order.status, t) ||
										t('orders:detail_page_alert_description')}
								</Typography>
								{order.status === OrderStatus.IN_PROGRESS && <InProgressActions />}
								{order.status === OrderStatus.OPEN && <InitialActions />}
							</Alert>

							<OrderContent order={order} assign={(e) => dispatch(assignOrder(e))}>
								<div className={classes.hideOnPrint} style={{ margin: '0 -2rem' }}>
									<OrderProgress />
								</div>
							</OrderContent>
							<Card sx={{ p: 2 }}>
								<CardHeader title={getOrderTitle(order)} />
								<Box display="inline" pr={1}>
									{order.isInternal ? (
										<Typography variant="body1" align="left"></Typography>
									) : (
										<HoverBase
											id="order_details_button_change_title"
											color="primary"
											onClick={(e) => dispatch(openTitleDialog(e))}
											disabled={accountDeactivated}
										>
											<Typography variant="body1" align="left">
												{getOrderTitle(order)}
											</Typography>

											<Box pl={1}>
												<Edit className={classes.hideOnPrint} fontSize="small" color="primary" />
											</Box>
										</HoverBase>
									)}
								</Box>
								{!!order.type && <Chip label={order.type} variant="outlined" size="small" />}
							</Card>
							<AcceptDialog />
							<MakeExternalDialog />
							<DeclineDialog />
							<FinishDialog />
							<InternalIdDialog />
							<TitleDialog />
							<OrderEditDialog />
						</Page>
					</Stack>
				)}

				{isMobile ? (
					mobileActive === MOBILE.CHAT && <CommentCompose sx={{ minWidth: '100vw' }} />
				) : (
					<Stack sx={{ flex: '1 0 33%' }}>
						<CommentCompose sx={{ width: 'unset' }} />
					</Stack>
				)}
			</Stack>
		</>
	);
}
