import { combineReducers } from 'redux';
import { adminAccountReducers, AdminAccountState } from './account/reducers';
import { adminUsersReducers, AdminUsersState } from './users/reducers';

export interface AdminState {
	users: AdminUsersState;
	account: AdminAccountState;
}

export const adminReducers = combineReducers<AdminState>({
	users: adminUsersReducers,
	account: adminAccountReducers
});
