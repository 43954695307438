import {
	Paper,
	TableCell,
	Table,
	TableRow,
	TableBody,
	TableHead,
	IconButton,
	Link,
	Chip,
	Select,
	InputLabel,
	MenuItem
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'app/core/reducers';
import * as actions from './actions';
import moment from 'moment-with-locales-es6';
import { CronjobHistoryModal } from './CronjobHistoryModal';
import { getCurrentPeriod, getLastYearPeriods } from '../utils';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		padding: theme.spacing(2),
		overflow: 'auto'
	},
	paper: {
		width: '100%',
		overflowX: 'auto'
	}
}));

export function ScheduledTasksApp() {
	const dispatch = useDispatch();
	const permissions = useSelector((state: State) => state.sysadmin.auth.user.permissions);
	const { scheduledTasks } = useSelector((state: State) => state.sysadmin);
	const classes = useStyles({});
	const lastYearPeriods = getLastYearPeriods();

	const [modalOpen, setModalOpen] = React.useState(false);
	const [selectedTask, setTask] = React.useState(undefined);
	const [selectedPeriod, setSelectedPeriod] = React.useState(getCurrentPeriod());

	const handleOpen = (task) => {
		setTask(task);
		setModalOpen(true);
	};

	React.useEffect(() => {
		dispatch(actions.getScheduledTasks());
	}, []);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<Trans i18nKey="sysadmin:table_categorie" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="sysadmin:table_action" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="sysadmin:table_next_run" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="sysadmin:table_intervall" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="sysadmin:table_duration" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="sysadmin:scheduled_tasks_table_options" />
							</TableCell>
							<TableCell>
								<Trans i18nKey="sysadmin:manually_start_scheduled_task" />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{scheduledTasks?.map((task) => (
							<TableRow key={task.id}>
								<TableCell>{task.category}</TableCell>
								<TableCell>
									<Link onClick={() => handleOpen(task)} style={{ cursor: 'pointer' }}>
										{task.name}
									</Link>
								</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell>
									{task.latestRun ? (
										task.latestRun.finishedAt ? (
											moment
												.utc(
													moment(task.latestRun?.finishedAt).diff(moment(task.latestRun?.startedAt))
												)
												.format('m[m] s[s]')
										) : (
											<>
												<Chip
													variant="outlined"
													size="small"
													color={'secondary'}
													label={'Failed'}
												/>
											</>
										)
									) : (
										' -/- '
									)}
								</TableCell>
								<TableCell>
									{task.name === 'sendConsumptionsToEedCron' && (
										<>
											<InputLabel>{'Period'}</InputLabel>
											<Select
												value={selectedPeriod}
												onChange={(event) => setSelectedPeriod(event.target.value)}
												label={'period'}
											>
												{lastYearPeriods.map((period) => (
													<MenuItem value={period} key={period}>
														{period}
													</MenuItem>
												))}
											</Select>
										</>
									)}
								</TableCell>
								<TableCell>
									<IconButton
										size="small"
										color="primary"
										onClick={() =>
											dispatch(
												actions.startTask(
													task.id,
													task.name === 'sendConsumptionsToEedCron'
														? { period: selectedPeriod }
														: undefined
												)
											)
										}
										disabled={!permissions?.includes('trigger_scheduled_task')}
									>
										<PlayCircleFilled />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<CronjobHistoryModal
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
					selectedTask={selectedTask}
				/>
			</Paper>
		</div>
	);
}
