import { UINotification } from '@api/v1';
import InfoIcon from '@mui/icons-material/Info';
import SmsIcon from '@mui/icons-material/Sms';
import i18next from 'i18next';
import moment from 'moment-with-locales-es6';
import React from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { Theme } from '@mui/material';

export function getNotificationType(notification: UINotification) {
	const { activityLog, orderComment } = notification;
	const { event } = activityLog;
	return orderComment?.source === 'CHANGE' ? 'order.changed' : event;
}
export function getNotificationContent(notification: UINotification) {
	const { activityLog } = notification;
	const { event } = activityLog;

	if (['connection.request', 'offerRequest.created', 'offer.declined'].includes(event)) {
		return activityLog.newValue;
	}
	return activityLog.order?.title ?? '';
}
export function getNotificationLink(notification: UINotification) {
	const { activityLog } = notification;
	const { event } = activityLog;

	if (event === 'connection.request') {
		return '/app/customers_and_properties/customers';
	}
	if (event === 'offerRequest.created' || event === 'offer.declined') {
		return `/app/offer-requests/${activityLog?.offerRequestId || ''}`;
	}
	return activityLog.order ? `/app/orders/${activityLog.order.id}` : '/app/orders';
}

export const getNotificationIcon = (type: string) =>
	({
		'order.commented': <SmsIcon />,
		'order.created': <AddOutlinedIcon />,
		'order.followup': <CachedOutlinedIcon />,
		'offer.declined': <PanToolOutlinedIcon />,
		'connection.request': <GroupOutlinedIcon />,
		'offerRequest.created': <AddOutlinedIcon />,
		'order.changed': <CachedOutlinedIcon />
	}[type] ?? <InfoIcon />);

export const getNotificationIconV1 = (type: string, theme: Theme) =>
	({
		'order.commented': <SmsIcon sx={{ color: theme.palette?.secondary.main }} />,
		'order.created': <AddOutlinedIcon sx={{ color: theme.palette.info.main }} />,
		'order.followup': <CachedOutlinedIcon sx={{ color: theme.palette?.secondary.main }} />,
		'offer.declined': <PanToolOutlinedIcon sx={{ color: theme.palette?.secondary.main }} />,
		'connection.request': <GroupOutlinedIcon sx={{ color: theme.palette?.secondary.main }} />,
		'offerRequest.created': <AddOutlinedIcon sx={{ color: theme.palette.info.main }} />,
		'order.changed': <CachedOutlinedIcon sx={{ color: theme.palette?.secondary.main }} />
	}[type] ?? <InfoIcon sx={{ color: theme.palette?.secondary.main }} />);

export const getNotificationIconBackground = (type: string, theme: Theme) =>
	({
		'order.created': theme.palette?.infoShades12p,
		'offerRequest.created': theme.palette?.infoShades12p
	}[type] ?? theme.palette?.secondaryShades12p);

export const getNotificationButtonText = (type: string, t: i18next.TFunction) =>
	({
		'order.commented': t('notification:button_to_comment'),
		'order.created': t('notification:button_to_order'),
		'order.followup': t('notification:button_to_order'),
		'order.changed': t('notification:button_to_order'),
		'offer.declined': t('notification:button_to_offer'),
		'connection.request': t('notification:button_to_connection_request'),
		'offerRequest.created': t('notification:button_to_offer_request')
	}[type] ?? t('notification:button_fallback'));

export function getNotificationHeadline(notification: UINotification, t: i18next.TFunction) {
	const { activityLog } = notification;
	const { event, newValue } = activityLog;

	return (
		{
			'order.commented': t('notification:comment_headline'),
			'order.created': t('notification:order_created_headline'),
			'order.followup': t('notification:order_followup_headline'),
			'offer.declined': t('notification:offer_declined'),
			'connection.request': t('notification:notification_request_headline'),
			'offerRequest.created': t('notification:offer_request_created'),
			'order.changed': t('notification:order_changed_headline', {
				type: getTranslatedOrderChangedItems(newValue, t).join(', ')
			})
		}[event] ?? t('notification:order_changed_fallback_headline')
	);
}

export function getTimeSinceCreation(createdAt: Date) {
	const diff = moment(createdAt).diff(Date.now());
	return moment.duration(diff, 'ms').humanize(true);
}

function getTranslatedOrderChangedItems(changedItems: string, t: i18next.TFunction) {
	try {
		const returnItems: { key: string; value: string }[] = JSON.parse(changedItems);
		const _notis = {
			title: 'notification:order_changed_title',
			dueDate: 'notification:order_changed_due_date',
			status: 'notification:order_changed_status',
			assigneeId: 'notification:order_changed_assignee',
			fallback: 'notification:order_changed_fallback'
		};
		return returnItems.map(({ key }) => t(_notis[key] ?? _notis.fallback));
	} catch (error) {
		return [];
	}
}
