import { combineReducers } from 'redux';
import { createReducer } from '../../redux/createReducer';
import {
	loadProperties,
	loadTicketDetails,
	loadTicketTypes,
	initTicketList,
	loadMoreTickets,
	loadTicketCustomFields,
	constants
} from './actions';
import { Property } from '../../../server/db/models/Property';
import { CasaviCustomField } from '@api/v1';
import { CasaviTicket } from './casavi.types';
import { DropzoneFile } from '../../../app/components/Dropzone/types';
import {
	handleAddFile,
	handleChangeFile,
	handleRemoveFile,
	handleRemoveAllFiles
} from '../../../app/components/Dropzone';

export interface UITicketTypes {
	id: number;
	name: string;
}

export interface UITicket extends Partial<CasaviTicket> {}

export interface TicketsState {
	properties: Property[];
	ticketTypes: UITicketTypes[];
	ticketCustomFields: CasaviCustomField[];
	ticketList: UITicket[];
	ticketDetails: UITicket & { isLoading?: boolean };
	commentCompose: CommentComposeState;
	[x: string]: unknown /* reserved for custom fields and temp saved form data */;
}

interface CommentComposeState {
	text: string;
	readonly files: DropzoneFile[];
	readonly fileDialogOpen: boolean;
	readonly isAdding: boolean;
}

const PENDING = '_PENDING';
const REJECTED = '_REJECTED';
const FULFILLED = '_FULFILLED';

export const ticketsReducers = combineReducers<TicketsState>({
	properties: createReducer<Property[]>([]).handleAction(
		loadProperties.success,
		(_state, action) => action.payload
	),
	ticketTypes: createReducer<UITicketTypes[]>([]).handleAction(
		loadTicketTypes.success,
		(_state, action) => action.payload
	),
	ticketCustomFields: createReducer<CasaviCustomField[]>([]).handleAction(
		loadTicketCustomFields.success,
		(_state, action) => action.payload
	),
	ticketList: createReducer<UITicket[]>([])
		.handleAction(initTicketList.success, (_state, action) => action.payload)
		.handleAction(loadMoreTickets.success, (state, action) => [...state, ...action.payload]),
	ticketDetails: createReducer<UITicket & { isLoading?: boolean }>({})
		.handleAction(loadTicketDetails.success, (_state, action) => action.payload)
		.handleAction(loadTicketDetails.request, (state, action) => ({
			...state,
			isLoading: true
		})),
	commentCompose: combineReducers<CommentComposeState>({
		fileDialogOpen: (state = false, action) => {
			switch (action.type) {
				case constants.OPEN_COMMENT_FILE_DIALOG:
					return true;
				case constants.CLOSE_COMMENT_FILE_DIALOG:
					return false;
				default:
					return state;
			}
		},
		files: (state = [], action) => {
			switch (action.type) {
				case constants.ADD_COMMENT_FILE:
					return handleAddFile(state, action);
				case constants.CHANGE_COMMENT_FILE:
					return handleChangeFile(state, action);
				case constants.REMOVE_ALL_COMMENT_FILES:
					return handleRemoveAllFiles(state, action);
				case constants.REMOVE_COMMENT_FILE:
					return handleRemoveFile(state, action);
				default:
					return state;
			}
		},
		isAdding: (state = false, action) => {
			switch (action.type) {
				case constants.CREATE_TICKET_COMMENT + PENDING:
					return true;
				case constants.CREATE_TICKET_COMMENT + REJECTED:
				case constants.CREATE_TICKET_COMMENT + FULFILLED:
					return false;
				default:
					return state;
			}
		},
		text: (state = '', action) => {
			return action.type === constants.CHANGE_COMMENT_TEXT ? action.payload : state;
		}
	})
});
