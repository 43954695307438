import { compact } from 'lodash';

interface Person {
	firstName: string;
	lastName: string;
}

export function getInitialsForPerson({ firstName, lastName }: Person): string {
	return compact([firstName, lastName])
		.map((s) => s.substr(0, 1))
		.join('')
		.toUpperCase();
}

export function getName(obj: Person): string {
	return `${obj.firstName} ${obj.lastName}`;
}

interface Company {
	name: string;
}

export function getFirstLetterOfCompanyForIcon(company: Company) {
	if (!company) {
		return '?';
	}
	return company.name ? company.name[0].toUpperCase() : '?';
}

export function getInitialsFromString(name: string) {
	const nameElements = name ? name.trim().split(' ') : ['?'];
	nameElements.length = 2;
	return nameElements
		.map((s) => s.substring(0, 1))
		.join('')
		.toUpperCase();
}
