import { combineReducers } from 'redux';
import {
	loadUserStatistics,
	loadOrderStatistics,
	loadRegistrationStatistics,
	loadCustomerStatistics,
	loadAccountCategoryStatistics,
	loadTaskareaStatistics
} from './actions';
import {
	SysadminUsersStatistics,
	SysadminRegistrationStatistics,
	SysadminOrderStatistics,
	SysadminCustomersStatistics
} from '@api/sysadmin';
import { createReducer } from '../../redux/createReducer';

export interface DashboardState {
	users: SysadminUsersStatistics;
	registration: SysadminRegistrationStatistics;
	orders: SysadminOrderStatistics;
	customers: SysadminCustomersStatistics;
	accounts: Record<string, any>;
	taskareas: Record<string, any>;
}

export const reducers = combineReducers<DashboardState>({
	users: createReducer<SysadminUsersStatistics>(null).handleAction(
		loadUserStatistics.success,
		(state, { payload }) => payload
	),
	registration: createReducer<SysadminRegistrationStatistics>(null).handleAction(
		loadRegistrationStatistics.success,
		(state, { payload }) => payload
	),
	customers: createReducer<SysadminCustomersStatistics>(null).handleAction(
		loadCustomerStatistics.success,
		(state, { payload }) => payload
	),
	orders: createReducer<SysadminOrderStatistics>(null).handleAction(
		loadOrderStatistics.success,
		(state, { payload }) => payload
	),
	accounts: createReducer(null).handleAction(
		loadAccountCategoryStatistics.success,
		(state, { payload }) => payload
	),
	taskareas: createReducer(null).handleAction(
		loadTaskareaStatistics.success,
		(state, { payload }) => payload
	)
});
