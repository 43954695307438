import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useMobile } from '../../hooks/useBreakpoints';
import { PrimaryButton, SecondaryButton } from '../../mui-custom/Button';
import { openPortalSession } from '../../components/UpgradeButton/actions';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';

export const TrialExceededPaywall = ({ open, close }) => {
	const { paymentSources } = useSelector((state: State) => state.admin.account);
	const isMobile = useMobile();
	const dispatch = useDispatch();

	useEffect(() => {
		if (paymentSources?.length > 0) {
			close();
		}
	}, [paymentSources, close]);

	return (
		<>
			<Dialog open={open} maxWidth={'sm'}>
				<DialogTitle>
					<Trans i18nKey="general:paywall_trial_exceeded_title" />
				</DialogTitle>
				<DialogContent>
					<Trans i18nKey="general:paywall_trial_exceeded_content" />
				</DialogContent>
				<DialogActions sx={{ flexDirection: isMobile ? 'column-reverse' : 'row', gap: 1 }}>
					<PrimaryButton
						onClick={() => {
							dispatch(openPortalSession(true));
						}}
					>
						<Trans i18nKey="admin:add_payment_details" />
					</PrimaryButton>
					<SecondaryButton href="/app/settings/subscription">
						<Trans i18nKey="general:paywall_action" />
					</SecondaryButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
