import { constants } from './actions';
import { combineReducers } from 'redux';

export interface ProfileEditState {
	firstName: string;
	lastName: string;
	telephone: string;
	email?: string;
}

export const reducers = combineReducers<ProfileEditState>({
	firstName: (state = '', action) => {
		switch (action.type) {
			case constants.SET_FIRSTNAME:
				return action.payload;
			case constants.PREFILL_PROFILE_EDIT_FORM:
				return action.payload.user.firstName || '';
			default:
				return state;
		}
	},
	lastName: (state = '', action) => {
		switch (action.type) {
			case constants.SET_LASTTNAME:
				return action.payload;
			case constants.PREFILL_PROFILE_EDIT_FORM:
				return action.payload.user.lastName || '';
			default:
				return state;
		}
	},
	telephone: (state = '', action) => {
		switch (action.type) {
			case constants.SET_TELEPHONE:
				return action.payload;
			case constants.PREFILL_PROFILE_EDIT_FORM:
				return action.payload.user.telephone || '';
			default:
				return state;
		}
	},
	email: (state = '', action) => {
		switch (action.type) {
			case constants.SET_EMAIL_ADDRESS:
				return action.payload;
			case constants.PREFILL_PROFILE_EDIT_FORM:
				return action.payload.user.email;
			default:
				return state;
		}
	}
});
