import { PatchCustomerBodySysadmin } from '@api/v1';
import { createConstants, createAsyncConstants } from '../../../lib/createConstants';
import { api } from '../../api';
import { State } from '../../../core/reducers';
import { showNotification } from '../../../../app/core/actions';
import { i18n } from '../../../i18n';

export interface PatchRestrictionsOptions {
	maxActiveUsers: number;
	maxActiveProperties: number;
	maxActiveCustomers: number;
}

export const asyncConstants = createAsyncConstants(
	[
		'GET_USERS',
		'GET_ACCOUNT',
		'MERGE_ACCOUNT',
		'GET_ACTIVE_RESTRICTIONS_COUNT',
		'GET_CUSTOMERS',
		'UPDATE_CUSTOMER_INTEGRATION_IDS',
		'CREATE_API_TOKEN'
	],
	{
		prefix: 'SYSADMIN'
	}
);
export const constants = createConstants(['GET_USER_TOKEN'], {
	prefix: 'SYSADMIN'
});

export const getUsers = (accountId: number) => ({
	type: asyncConstants.GET_USERS.TYPE,
	payload: api.get(`/api/sysadmin/accounts/${accountId}/users/`)
});
export const getCustomers = (accountId: number) => ({
	type: asyncConstants.GET_CUSTOMERS.TYPE,
	payload: api.get(`/api/sysadmin/customers/${accountId}`)
});
export const updateCustomerIntegrationIds =
	(accountId: number, customerId: number, body: PatchCustomerBodySysadmin) =>
	(dispatch, getState: () => State) => {
		const currentState = getState().sysadmin.users.customersList;
		return dispatch({
			type: asyncConstants.UPDATE_CUSTOMER_INTEGRATION_IDS.TYPE,
			payload: api
				.patch(`/api/sysadmin/customers/${accountId}/${customerId}`, { ...body })
				.then((data) => {
					dispatch(showNotification(i18n.t('sysadmin:integration_id_update_sucess'), 'success'));
					return {
						currentState,
						data
					};
				})
				.catch(() =>
					dispatch(showNotification(i18n.t('sysadmin:integration_id_update_fail'), 'error'))
				)
		});
	};

export const getAccount = (accountId: number) => ({
	type: asyncConstants.GET_ACCOUNT.TYPE,
	payload: api.get(`/api/sysadmin/accounts/${accountId}/`)
});

export const mergeAccount = (accountId: number, mergeAccountId: number) => ({
	type: asyncConstants.MERGE_ACCOUNT.TYPE,
	payload: api.post(`/api/sysadmin/accounts/${accountId}/merge/${mergeAccountId}`)
});

export const getActiveRestrictionsCount = (accountId: number) => ({
	type: asyncConstants.GET_ACTIVE_RESTRICTIONS_COUNT.TYPE,
	payload: api.get(`/api/sysadmin/accounts/${accountId}/active_restrictions`)
});
export const patchActiveRestrictionsCount = (
	accountId: number,
	body: PatchRestrictionsOptions
) => ({
	type: asyncConstants.GET_ACTIVE_RESTRICTIONS_COUNT.TYPE,
	payload: api.patch(`/api/sysadmin/accounts/${accountId}/active_restrictions`, body)
});

export const createToken = (accountId, dispatch) => {
	dispatch({
		type: asyncConstants.CREATE_API_TOKEN.TYPE,
		payload: api.post(`/api/sysadmin/accounts/${accountId}/create_api_token`, {})
	});
};

export const loginAsUser = (accountId: number, userId: number) => ({
	type: constants.GET_USER_TOKEN,
	payload: api.get(`/api/sysadmin/accounts/${accountId}/users/${userId}/token`).then((data) => {
		window.localStorage.setItem('jwt', data.token);
		window.localStorage.setItem('sysadmin_logged_in_as_user', 'true');
		window.open(`${window.location.origin}`, '_blank');
	})
});
