import React, { useEffect, useMemo, useState } from 'react';
import { UIInsurance, UIProperty, UICustomer, DamageType } from '@api/v1';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	Typography,
	MenuItem,
	Checkbox,
	Chip,
	InputAdornment,
	IconButton
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { Trans, useTranslation } from 'react-i18next';
import { loadCustomers } from '../customers/actions';
import Autocomplete from '@mui/material/Autocomplete';
import { loadProperties } from '../properties/actions';
import { DateDialog } from '../../components/DateTime';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment-with-locales-es6';
import { createInsurance, updateInsurance, deleteInsurance } from './actions';
import { transformDamageType } from './utils';
import { Delete } from '@mui/icons-material';
import { createInsuranceStateKey } from '../../lib/i18keyGenerator';

const useStyles = makeStyles((theme) => ({
	dialogActions: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(3),
		display: 'flex',
		flexWrap: 'wrap'
	},
	content: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	}
}));

// TODO: fix initial values
const initialState: UIInsurance = {
	id: 0,
	status: '',
	description: '',
	externalId: '',
	damageType: [],
	properties: [],
	expirationDate: '',
	customer: null
};

export const AddDialog = (props: {
	open: boolean;
	onClose: (event?: React.SyntheticEvent<any>) => void;
	onSave: () => void;
	onDelete: () => void;
	insuranceData: UIInsurance | null;
	searchInput: string;
}) => {
	const { customers, properties } = useSelector((state: State) => state);

	const { open, onClose, insuranceData, onSave, onDelete, searchInput } = props;
	const { t } = useTranslation();
	const classes = useStyles(props);

	const damageTypeOptions = useMemo(() => {
		return transformDamageType(
			[
				'FIRE',
				'EXPLOSION',
				'MAINS_WATER',
				'ELEMENTARY_LOSS',
				'GLASS_BREAKAGE',
				'STORM_DAMAGE',
				'HAIL_DAMAGE',
				'LIGHTNING_DAMAGE',
				'FLOOD_DAMAGE',
				'OTHER'
			] as DamageType[],
			t
		);
	}, []);

	const [insurance, setInsurance] = useState<UIInsurance>(insuranceData || initialState);

	const dispatch = useDispatch();

	const handleSubmit = (_ev) => {
		if (insurance.id !== 0) {
			dispatch(updateInsurance(insurance, searchInput));
		} else {
			dispatch(createInsurance(insurance));
		}
		onSave();
	};

	const handleSelectStatusChange = (ev) => {
		setInsurance({ ...insurance, status: ev.target.value });
	};

	const [inputValueCustomer, setInputValueCustomer] = useState('');
	const [inputValueDamageType, setInputValueDamageType] = useState('');
	const [inputValueProperty, setInputValueProperty] = useState('');
	const [isExpirationDateDialogOpen, setIsExpirationDateDialogOpen] = useState<boolean>(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

	useEffect(() => {
		dispatch(loadCustomers());
		dispatch(loadProperties({ limit: 'noLimit' }));
	}, []);

	useEffect(() => {
		setInsurance(
			insuranceData
				? {
						...insuranceData,
						damageType: transformDamageType(insuranceData.damageType as DamageType[], t)
				  }
				: initialState
		);
	}, [insuranceData, open]);

	useEffect(() => {
		ValidatorForm.addValidationRule('hasPropertySelected', (_value: string) => {
			return !!insurance.properties?.length;
		});
	}, [insurance.properties]);

	const insuranceStates = ['active', 'inactive', 'underConstruction', 'quit'];

	return (
		<Dialog open={open} maxWidth="md" fullWidth>
			<ValidatorForm onSubmit={handleSubmit}>
				<br />
				<DialogTitle>
					{insuranceData ? t('insurances:add_dialog_title_edit') : t('insurances:add_dialog_title')}
					<br />
					<Typography variant="caption">
						{insuranceData
							? t('insurances:add_dialog_title_label_edit')
							: t('insurances:add_dialog_title_label')}
					</Typography>
				</DialogTitle>
				<DialogContent className={classes.content}>
					<Grid container spacing={4}>
						<Grid item sm={6} xs={12}>
							<TextValidator
								variant="standard"
								fullWidth
								validators={['required']}
								errorMessages={[t('general:required_message')]}
								label={t('insurances:add_dialog_external_id')}
								onChange={(e) => setInsurance({ ...insurance, externalId: e.target.value })}
								value={insurance.externalId}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<SelectValidator
								onChange={handleSelectStatusChange}
								fullWidth
								value={insurance.status}
								label={t('insurances:add_dialog_status')}
								variant="standard"
							>
								<MenuItem value={t('insurances:add_dialog_status')} disabled>
									{t('insurances:add_dialog_status')}
								</MenuItem>
								{insuranceStates.map((state, idx) => {
									return (
										<MenuItem value={state} key={idx}>
											{t(createInsuranceStateKey(state))}
										</MenuItem>
									);
								})}
							</SelectValidator>
						</Grid>
						<Grid item sm={6} xs={12}>
							<Autocomplete
								options={customers?.list?.data || []}
								getOptionLabel={(customer: UICustomer) => customer.name}
								onChange={(_event, newValue: UICustomer) => {
									setInsurance({
										...insurance,
										customer: newValue,
										properties: initialState.properties
									});
								}}
								isOptionEqualToValue={(option, value) => {
									return option.id === value.id;
								}}
								value={insurance.customer}
								inputValue={inputValueCustomer}
								onInputChange={(_event, value) => {
									setInputValueCustomer(value);
								}}
								renderInput={(params) => (
									<TextValidator
										variant="standard"
										{...params}
										fullWidth
										value={inputValueCustomer}
										label={t('insurances:add_dialog_customer')}
										validators={['required']}
										errorMessages={[t('general:required_message')]}
									/>
								)}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<Autocomplete
								multiple
								options={damageTypeOptions}
								getOptionLabel={(damageType) => damageType.label}
								isOptionEqualToValue={(option, value) => {
									return option.value === value.value;
								}}
								onChange={(_event, newValues: DamageType[]) => {
									setInsurance({
										...insurance,
										damageType: newValues
									});
								}}
								onInputChange={(_event, value) => {
									setInputValueDamageType(value);
								}}
								value={insurance.damageType}
								inputValue={inputValueDamageType}
								renderInput={(params) => {
									return (
										<TextValidator
											variant="standard"
											{...params}
											fullWidth
											value={inputValueDamageType}
											label={t('insurances:add_dialog_damages')}
										/>
									);
								}}
								renderOption={(props, option, { selected }) => (
									<li {...props}>
										<Checkbox style={{ marginRight: 8 }} checked={selected} />
										{option.label}
									</li>
								)}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip
											variant="outlined"
											label={option.label}
											size="small"
											{...getTagProps({ index })}
										/>
									))
								}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<Autocomplete
								multiple
								disabled={!insurance.customer}
								options={
									properties?.list?.data?.filter(
										(property) => property.customer.id === insurance?.customer?.id
									) || []
								}
								isOptionEqualToValue={(option, value) => {
									return option.id === value.id;
								}}
								getOptionLabel={(property) => property.name}
								onChange={(_event, newValue: UIProperty[]) => {
									setInsurance({
										...insurance,
										properties: newValue
									});
								}}
								onInputChange={(_event, value) => {
									setInputValueProperty(value);
								}}
								value={insurance.properties}
								inputValue={inputValueProperty}
								renderInput={(params) => {
									return (
										<TextValidator
											variant="standard"
											{...params}
											fullWidth
											value={inputValueProperty}
											label={t('insurances:add_dialog_property')}
											validators={['hasPropertySelected']}
											errorMessages={[t('general:required_message')]}
										/>
									);
								}}
								renderOption={(props, option, { selected }) => (
									<li {...props}>
										<Checkbox style={{ marginRight: 8 }} checked={selected} />
										{option.name}
									</li>
								)}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip
											variant="outlined"
											label={option.name}
											size="small"
											{...getTagProps({ index })}
										/>
									))
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{ marginRight: 'auto' }}>
							<TextValidator
								variant="standard"
								fullWidth
								label={t('insurances:add_dialog_expiration_date')}
								onClick={() => setIsExpirationDateDialogOpen(true)}
								value={
									insurance.expirationDate
										? moment(insurance.expirationDate).format('DD.MM.YYYY')
										: ''
								}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton size="large">
												<CalendarIcon />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
							<DateDialog
								open={isExpirationDateDialogOpen}
								onClose={() => setIsExpirationDateDialogOpen(false)}
								onSubmit={(date) => {
									setIsExpirationDateDialogOpen(false);
									setInsurance({
										...insurance,
										expirationDate: date.toISOString()
									});
								}}
								initialValue={moment(insurance.expirationDate || Date.now())}
								dialogTitle={t('orders:due_date_dialog_header')}
								submitLabel={t('orders:due_date_dialog_button_save')}
								disablePast
							/>
						</Grid>
						<Grid item xs={12}>
							<TextValidator
								variant="standard"
								fullWidth
								label={t('insurances:add_dialog_provider')}
								name="insurances_add_dialog_provider"
								onChange={(e) => {
									setInsurance({ ...insurance, provider: e.target.value });
								}}
								value={insurance.provider || ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextValidator
								variant="standard"
								fullWidth
								multiline
								rows={3}
								label={t('insurances:add_dialog_description')}
								onChange={(e) => setInsurance({ ...insurance, description: e.target.value })}
								value={insurance.description}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className={classes.dialogActions}>
					{insuranceData && (
						<>
							<Button
								color="secondary"
								onClick={() => {
									setIsDeleteDialogOpen(true);
								}}
								style={{ marginRight: 'auto' }}
							>
								<Delete fontSize="small" />
								<Trans i18nKey="insurances:edit_dialog_delete" />
							</Button>
							<Dialog open={isDeleteDialogOpen} maxWidth="xs">
								<DialogContent>
									<Trans i18nKey="insurances:edit_dialog_delete_confirmation" />
								</DialogContent>
								<DialogActions>
									<Button
										color="secondary"
										variant="text"
										onClick={() => {
											setIsDeleteDialogOpen(false);
										}}
									>
										<Trans i18nKey="insurances:edit_dialog_cancel" />
									</Button>
									<Button
										data-testid="verify-deletion-button"
										color="primary"
										onClick={() => {
											dispatch(deleteInsurance(insurance));
											onDelete();
											setIsDeleteDialogOpen(false);
										}}
									>
										<Trans i18nKey="insurances:edit_dialog_delete" />
									</Button>
								</DialogActions>
							</Dialog>
						</>
					)}
					<Button color="secondary" variant="text" onClick={onClose}>
						<Trans i18nKey="insurances:edit_dialog_cancel" />
					</Button>
					<Button color="primary" type="submit">
						<Trans i18nKey="insurances:edit_dialog_save" />
					</Button>
				</DialogActions>
			</ValidatorForm>
		</Dialog>
	);
};
