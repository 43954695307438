import { AppBar, Tabs, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { usePermission } from '../acl/permissions';
import { AdminAccountPage } from './account/App';
import { AdminUsersApp } from './users/App';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OwnerExclusivePermission } from '../../../server/permissions/permission';
import { AdminExternalProfileApp } from './externalProfile/App';
import { StyledTab } from '../StyledTab';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#fff'
	},
	content: {
		height: '100%',
		flex: '1 1 100%',
		overflowY: 'scroll',
		padding: theme.spacing(1)
	},
	header: {
		backgroundColor: theme.palette.grey[400]
	},
	headerTitle: {
		padding: theme.spacing(2)
	},
	link: {
		textDecoration: 'none',
		color: 'inherit'
	},
	indicator: {
		backgroundColor: '#fff'
	}
}));

function LinkTab(props) {
	const classes = useStyles(props);
	return (
		<NavLink to={props.value} className={classes.link}>
			<StyledTab {...props} />
		</NavLink>
	);
}

export function AdminApp(props: {}) {
	const classes = useStyles(props);
	const { t } = useTranslation();
	const hasAccountView = usePermission(OwnerExclusivePermission.VIEW_ACCOUNT);
	return (
		<div className={classes.root}>
			<AppBar position="sticky" className={classes.header}>
				<Tabs
					value={location.pathname}
					classes={{ indicator: classes.indicator }}
					variant="scrollable"
					scrollButtons="auto"
				>
					<LinkTab label={t('general:admin_account')} value="/app/admin/account" />
					<LinkTab label={t('general:admin_users')} value="/app/admin/users" />
					<LinkTab label={t('general:company_data')} value="/app/admin/external-profile" />
				</Tabs>
			</AppBar>
			<div className={classes.content}>
				<Switch>
					{/* get rid of old routes V1 later */}
					<Route exact path="/app/admin" component={() => <Redirect to="/app/admin/account" />} />
					<Route path="/app/admin/users" component={AdminUsersApp} />
					<Route path="/app/admin/external-profile" component={AdminExternalProfileApp} />
					{hasAccountView ? <Route path="/app/admin/account" component={AdminAccountPage} /> : null}
					<Redirect to="/app/admin/account" />
				</Switch>
			</div>
		</div>
	);
}
