import {
	UIAccount,
	UICustomerStatistics,
	UIOrderStatistics,
	UIPropertiesStatistics,
	UIUser
} from '@api/v1';
import { AccountSpecificFeature } from '../core/acl/features';
import { isEmpty } from 'lodash';

declare let pendo: any;

/* e2e emails from here https://casavi.atlassian.net/wiki/spaces/QM/pages/2428372043/Relay+Account+user+overview */
const isE2EAccount = (email: string) =>
	[
		'auto+',
		'auto_',
		'711ce339-0bf0-4c25-81a2-262bdf083dd9@mailslurp.com',
		'a9c67007-a0b9-43fb-8a23-2c5c9ea98f70@mailslurp.com',
		'16f222a4-02c2-4729-9d15-1701be4bbfd0@mailslurp.com',
		'268c1988-2f02-41ea-87d7-ffa65ef58f8d@mailslurp.com'
	].some((el) => email.startsWith(el));

export function initializePendo(
	user: UIUser,
	account: UIAccount,
	orderStatistics: UIOrderStatistics,
	customerStatistics: UICustomerStatistics,
	propertiesStatistics: UIPropertiesStatistics
) {
	if (['production', 'integration'].includes(import.meta.env.MODE) && pendo && isEmpty(pendo._q)) {
		const stagingPrefix = import.meta.env.MODE === 'integration' ? 'staging_' : '';
		pendo.initialize({
			visitor: {
				id: `${isE2EAccount(user.email) ? 'e2e_' : ''}${stagingPrefix}${user.id}`,
				permissions: Object.keys(user.permissions).filter((key) => user.permissions[key]),
				roles: user.roles
			},

			account: {
				id: `${stagingPrefix}${account.id}`,
				plan: account.plan,
				usage: account.usage,
				category: account.category,
				order_open: orderStatistics.OPEN,
				order_finished: orderStatistics.FINISHED,
				order_in_progress: orderStatistics.IN_PROGRESS,
				order_declined: orderStatistics.DECLINED,
				order_internal: orderStatistics.IS_INTERNAL,
				order_overall: orderStatistics.ALL,
				customers_open: customerStatistics.OPEN,
				customers_declined: customerStatistics.DECLINED,
				customers_accepted: customerStatistics.ACCEPTED,
				customers_overall: customerStatistics.ALL,
				properties_pending: propertiesStatistics.PENDING,
				properties_declined: propertiesStatistics.DECLINED,
				properties_accepted: propertiesStatistics.ACCEPTED,
				properties_overall: propertiesStatistics.ALL,
				has_design_system: user.accountSpecificFeatures.includes(
					AccountSpecificFeature.DesignSystem
				),
				has_pricing_model_v2: user.accountSpecificFeatures.includes(
					AccountSpecificFeature.PricingModelV2
				)
			}
		});
	}
}
