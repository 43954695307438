import * as React from 'react';
import ReactGA from 'react-ga';
import { HeaderSection } from './HeaderSection';
import { ProductSection } from './ProductSection';
import { ReferencesSection } from './ReferencesSection';
import { AbonnementsSection } from './AbonnementsSection';
import { FAQsSection } from './FAQsSection';

export const StarterPage: React.FC = (): JSX.Element => {
	ReactGA.pageview(window.location.pathname + window.location.search);

	return (
		<>
			<HeaderSection />
			<ProductSection />
			<ReferencesSection />
			<AbonnementsSection />
			<FAQsSection />
		</>
	);
};
