import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: `${theme.spacing(0.5)} 0`
	}
}));

export const AttachmentList = ({ children }) => {
	const classes = useStyles();
	return <div className={classes.root}>{children}</div>;
};
