import * as React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CardContent, CardActions, Button } from '@mui/material';
import { bindActionCreators } from 'redux';
import { choosePassword, setPassword, setPasswordConfirm } from './actions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { State } from '../../core/reducers';
import { RouteComponentProps } from 'react-router';
import qs from 'qs';

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			choosePassword,
			setPassword,
			setPasswordConfirm
		},
		dispatch
	);

const mapStateToProps = (state: State) => ({
	password: state.sysadmin.auth.password,
	passwordConfirm: state.sysadmin.auth.passwordConfirm
});

interface ChoosePasswordProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps>,
		WithTranslation,
		RouteComponentProps<{}> {}

class ChoosePassword extends React.Component<ChoosePasswordProps> {
	componentDidMount() {
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			if (value !== this.props.password) {
				return false;
			}
			return true;
		});
	}
	onSubmit(password: string) {
		const query = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});
		this.props.choosePassword({
			password,
			token: query ? query.token : null
		});
	}
	render() {
		const { t, password, passwordConfirm } = this.props;
		return (
			<ValidatorForm
				ref="form"
				onSubmit={() => this.onSubmit(password)}
				onError={(errors) => console.log(errors)}
			>
				<CardContent key="content">
					<TextValidator
						name="password"
						label={t('general:password')}
						onChange={(e) => this.props.setPassword(e.target.value)}
						type="password"
						value={password}
						validators={['passwordLength', 'required']}
						errorMessages={[t('validation:password_length'), t('general:required_message')]}
						fullWidth
						margin="normal"
						variant="standard"
					/>
					<TextValidator
						name="passwordConfirm"
						label={t('general:repeat_password')}
						onChange={(e) => this.props.setPasswordConfirm(e.target.value)}
						type="password"
						value={passwordConfirm}
						validators={['isPasswordMatch', 'required']}
						errorMessages={[t('validation:password_confirm'), t('general:required_message')]}
						fullWidth
						margin="normal"
						variant="standard"
					/>
				</CardContent>
				<CardActions key="actions">
					<Button variant="contained" type="submit">
						{t('general:reset_password')}
					</Button>
				</CardActions>
			</ValidatorForm>
		);
	}
}

const wrapped = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChoosePassword));
export { wrapped as ChoosePassword };
