import { Grid } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers';
import Page from '../../../mui-custom/Page/Page';
import ActionBar from '../../../mui-custom/ActionBar/ActionBar';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
	setEmail,
	setFirstName,
	setLastName,
	setTelephone
} from '../../../components/EmployeeCreationDialog/actions';

export const UserCreationPage = ({ onSubmit, onCancel }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { email, firstName, lastName, telephone } = useSelector(
		(state: State) => state?.employeeCreationDialog
	);
	const ref = useRef(null);
	return (
		<ValidatorForm
			debounceTime={300}
			ref={ref}
			onSubmit={onSubmit}
			onError={(errors) => console.log(errors)}
		>
			<Page
				headline={t('admin:user_creation_page_headline')}
				actionBar={
					<ActionBar
						primary={{
							children: t('admin:user_create_user_button'),
							type: 'submit'
						}}
						secondary={{ children: 'Abbrechen', onClick: () => onCancel() }}
					/>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} {...{ mobile: 6 }}>
						<TextValidator
							name="firstname"
							label={t('employees:first_name')}
							validators={['required']}
							value={firstName}
							onChange={(e) => dispatch(setFirstName(e.target.value))}
							errorMessages={[t('general:required_message')]}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} {...{ mobile: 6 }}>
						<TextValidator
							label={t('employees:last_name')}
							name="lastName"
							value={lastName}
							onChange={(e) => dispatch(setLastName(e.target.value))}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							label={t('general:email_address')}
							value={email}
							name="email"
							onChange={(e) => dispatch(setEmail(e.target.value))}
							validators={['required', 'isEmail', 'isEmailAvailable']}
							errorMessages={[
								t('general:required_message'),
								t('validation:invalid_email_address'),
								t('validation:email_already_exists')
							]}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							label={t('employees:telephone')}
							value={telephone}
							name="telephone"
							onChange={(e) => dispatch(setTelephone(e.target.value))}
							validators={['required', 'isValidPhoneNumber']}
							errorMessages={[t('general:required_message'), t('validation:invalid_phone_number')]}
							fullWidth
							type="telephone"
						/>
					</Grid>
				</Grid>
			</Page>
		</ValidatorForm>
	);
};
