import * as React from 'react';
import { CardContent, Typography, Button, Theme, CardActions, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../reducers';
import {
	setCompanyName,
	setStreet,
	setPostalCode,
	setLocality,
	setCountry,
	setAccountCategory
} from './actions';
import { UIRegistrationSteps } from '../../data/enums';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CountrySelect } from '../../components/CountrySelect';
import { AccountCategorySelect } from '../../components/AccountCategorySelect';
import ReactGA from 'react-ga';
import { useRegSteps } from './Progress';

const useStyles = makeStyles((_theme: Theme) =>
	createStyles({
		actions: {
			justifyContent: 'space-between'
		},
		content: {
			flexGrow: 1,
			marginTop: '12px'
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '420px'
		}
	})
);

export function InsertAccountDataForm() {
	ReactGA.pageview(window.location.pathname + window.location.search);
	const classes = useStyles({});
	const { t } = useTranslation();
	const { companyName, category, street, postalCode, locality, country } = useSelector(
		(state: State) => state.registration
	);
	const dispatch = useDispatch();
	const postalCodeTrimmed = postalCode.trim();

	const { query, onSubmitSuccess } = useRegSteps(UIRegistrationSteps.INSERT_ACCOUNT_DATA);
	React.useEffect(() => {
		if (query?.selfRegistration) {
			dispatch(setAccountCategory('CRAFTSMEN'));
		}
	}, []);
	React.useEffect(() => {
		ValidatorForm.addValidationRule('isPostalCodeCountryMatch', (value: string) => {
			switch (country) {
				case 'DE':
				case 'IT':
					return /^\d{5}$/.test(value);
				default:
					return /^\d{4}$/.test(value);
			}
		});
	}, [country]);

	return (
		<ValidatorForm className={classes.container} onSubmit={onSubmitSuccess()}>
			<CardContent className={classes.content}>
				<Typography color="inherit" variant="h5">
					{t('registration:description_insert_account_data')}:
				</Typography>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={12}>
						<TextValidator
							id="companyName"
							label={t('registration:company_name')}
							value={companyName}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								dispatch(setCompanyName(e.target.value))
							}
							margin="dense"
							fullWidth
							name="companyNameValidator"
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12}>
						<AccountCategorySelect
							name="category"
							label={t('registration:account_category')}
							value={category}
							onChange={(value) => dispatch(setAccountCategory(value))}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							margin="dense"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							id="street"
							label={t('registration:street')}
							value={street}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								dispatch(setStreet(e.target.value))
							}
							margin="dense"
							fullWidth
							name="streetValidator"
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextValidator
							id="postalCode"
							label={t('registration:postalCode')}
							value={postalCodeTrimmed}
							validators={['required', 'isPostalCodeCountryMatch']}
							errorMessages={[
								t('general:required_message'),
								t('general:postal_code_length_message')
							]}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								dispatch(setPostalCode(e.target.value))
							}
							margin="dense"
							fullWidth
							name="postalCodeValidator"
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={8}>
						<TextValidator
							id="locality"
							label={t('registration:locality')}
							value={locality}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								dispatch(setLocality(e.target.value))
							}
							margin="dense"
							fullWidth
							name="localityValidator"
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12}>
						<CountrySelect
							name="country"
							label={t('registration:country')}
							fullWidth
							margin="dense"
							value={country}
							onChange={(value) => dispatch(setCountry(value))}
							validators={['required', 'country_iso_code']}
							errorMessages={[t('general:required_message'), t('validation:country_iso_code')]}
						/>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions className={classes.actions}>
				{!query?.selfRegistration && (
					<Button variant="contained" color="secondary" onClick={onSubmitSuccess(-1)}>
						{t('registration:button_back')}
					</Button>
				)}
				<Button variant="contained" color="primary" type="submit">
					{t('registration:button_create_account')}
				</Button>
			</CardActions>
		</ValidatorForm>
	);
}
