import { UIOrderComment, UIOrderStatus } from '@api/v1';
import { Avatar, Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import i18next from 'i18next';
import { dateTime } from '../../../lib/dateHelper';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentList, AttachmentThumbnail } from '../../../components/Attachment';
import { OrderStatus } from '../../../data/types/orderStatus';
import { ActivityIndicator } from '../../../components/ActivityIndicator';

const useStyles = makeStyles((theme) => ({
	outer: {
		margin: theme.spacing(1)
	},
	layoutReversed: {
		flexDirection: 'row-reverse',
		'& > $avatar': {
			marginLeft: theme.spacing(1),
			marginRight: 0
		},
		'& $isArtificial': {
			marginRight: theme.spacing(6),
			marginLeft: 0
		}
	},
	internalComment: {
		background: theme.palette.grey[200]
	},
	avatar: {
		flex: '0 0 auto',
		marginLeft: 0,
		marginRight: theme.spacing(1)
	},
	content: {
		padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
		display: 'flex',
		flexDirection: 'column',
		border: 'none',
		backgroundColor: '#F8F8F8'
	},
	isArtificial: {
		marginLeft: theme.spacing(6),
		background: theme.palette.grey[200],
		'& $time': {
			marginTop: 0
		}
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	},
	title: {
		maxWidth: '15rem',
		fontWeight: 500
	},
	body: {
		whiteSpace: 'pre-line',
		margin: `${theme.spacing(0.5)} 0`,
		wordBreak: 'break-word'
	},
	topRightContainer: {
		marginTop: theme.spacing(-0.5),
		color: theme.orderStatus.finished.main,
		display: 'flex',
		alignItems: 'center'
	},
	source: {
		marginLeft: theme.spacing(1)
	},
	time: {
		color: theme.palette.grey[400],
		alignSelf: 'flex-end',
		margin: `${theme.spacing(1)} 0 -${theme.spacing(0.5)} ${theme.spacing(1)}px`
	},
	open: {
		backgroundColor: theme.orderStatus.open.main,
		color: theme.orderStatus.open.contrastText,
		'& $time': {
			color: theme.orderStatus.open.contrastText
		}
	},
	inProgress: {
		backgroundColor: theme.orderStatus.inProgress.main,
		color: theme.orderStatus.inProgress.contrastText,
		'& $time': {
			color: theme.orderStatus.inProgress.contrastText
		}
	},
	finished: {
		backgroundColor: theme.orderStatus.finished.main,
		color: theme.orderStatus.finished.contrastText,
		'& $time': {
			color: theme.orderStatus.finished.contrastText
		}
	},
	declined: {
		backgroundColor: theme.orderStatus.declined.main,
		color: theme.orderStatus.declined.contrastText,
		'& $time': {
			color: theme.orderStatus.declined.contrastText
		}
	},
	internal: {
		color: theme.palette.grey[400]
	},
	multiLine: {
		marginBottom: theme.spacing(2),
		'& + $time': {
			alignSelf: 'flex-end'
		}
	}
}));

export function OrderComment({ item: comment }: { item: UIOrderComment }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const { author, isInternal, text, source } = comment;
	const isArtificial = source === 'CHANGE';
	const containerClasses = `${classes.content}
	${getStatusColorClass(comment.statusChange, classes)} 
	${isArtificial ? classes.isArtificial : ''} ${isInternal ? classes.internalComment : ''}`;

	return (
		<div className={classes.outer}>
			<Grid
				container
				wrap="nowrap"
				className={comment.author.type === 'INTERNAL' ? classes.layoutReversed : null}
			>
				{!isArtificial && (
					<Grid item className={classes.avatar}>
						<Avatar src={author.avatar}>{author.initials}</Avatar>
					</Grid>
				)}
				<Grid item>
					{!isArtificial ? (
						<Card className={containerClasses} variant="outlined">
							<Grid container spacing={8} justifyContent="space-between">
								<Grid item>
									<Typography noWrap variant="body2" className={classes.title}>
										<div className={classes.truncate}>
											{author.name || t('orders:comment_deleted_author')}
										</div>
									</Typography>
								</Grid>
								<Grid
									item
									className={`${classes.topRightContainer} ${isInternal ? classes.internal : ''}`}
								>
									{comment.isUnread && <ActivityIndicator />}
									<Typography className={classes.source} variant="caption">
										{isInternal ? t('orders:comment_internal') : t('orders:comment_external')}
									</Typography>
								</Grid>
							</Grid>
							<Typography variant="body2" className={classes.body}>
								{comment.text}
							</Typography>
							{!!comment.attachments?.length && (
								<AttachmentList>
									{comment.attachments.map((a) => (
										<AttachmentThumbnail
											key={a.id}
											name={a.name}
											link={a.url}
											thumbnail={a.thumbnailS3Key}
										/>
									))}
								</AttachmentList>
							)}
							<Typography variant="caption" className={classes.time}>
								{dateTime(comment.createdAt).toFormat('dd.MM.yyyy, HH:mm')}
							</Typography>
						</Card>
					) : (
						<Card className={containerClasses} variant="outlined">
							<Typography
								className={`${comment.statusChange && text ? classes.multiLine : ''}`}
								variant="body2"
								dangerouslySetInnerHTML={{
									__html: comment.statusChange
										? `${t('orders:comment_status_change', {
												name: author.name,
												status: getStatusChangeLabel(comment.statusChange, t)
										  })}<br>${text}`
										: text
								}}
							/>
							{!!comment.attachments?.length && (
								<AttachmentList>
									{comment.attachments.map((a) => (
										<AttachmentThumbnail
											key={a.id}
											name={a.name}
											link={a.url}
											thumbnail={a.thumbnailS3Key}
										/>
									))}
								</AttachmentList>
							)}
							<Typography variant="body2" className={classes.time}>
								{dateTime(comment.createdAt).toFormat('dd.MM.yyyy, HH:mm')}
							</Typography>
						</Card>
					)}
				</Grid>
			</Grid>
		</div>
	);
}
export const getStatusChangeLabel = (status: UIOrderStatus, t: i18next.TFunction) =>
	({
		OPEN: t('orders:status_OPEN'),
		IN_PROGRESS: t('orders:status_IN_PROGRESS'),
		FINISHED: t('orders:status_FINISHED'),
		DECLINED: t('orders:status_DECLINED')
	}[status] ?? '');

const getStatusColorClass = (status: UIOrderStatus, classes) =>
	({
		[OrderStatus.DECLINED]: classes.declined,
		[OrderStatus.FINISHED]: classes.finished,
		[OrderStatus.IN_PROGRESS]: classes.inProgress,
		[OrderStatus.OPEN]: classes.open
	}[status] ?? '');
