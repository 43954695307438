import * as React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { compareBy, ItemType } from '../lib/dateHelper';
import { DateTime } from 'luxon';
import { SectionHeader } from './SectionHeader';

const useStyles = makeStyles((theme: Theme) => ({
	sectionHeader: {
		margin: theme.spacing(2)
	}
}));

interface GroupCardProps<T> {
	group: [string, T[]];
	Item: React.FC<{ key?: number; item: T; assignRef: (el: HTMLElement, index: number) => void }>;
	assignRef: (el: HTMLElement, index: number) => void;
}

export const GroupCard = <T extends ItemType>({ group, Item, assignRef }: GroupCardProps<T>) => {
	const classes = useStyles();
	const [dateString, groupedItems] = group;
	const sortedItems = groupedItems.sort(compareBy('createdAt', 'desc'));
	return (
		<>
			<SectionHeader className={classes.sectionHeader}>
				{DateTime.fromFormat(dateString, 'yyyyMMdd').toFormat('dd.MM.yyyy')}
			</SectionHeader>
			{sortedItems.map((item, id) => (
				<Item key={id} item={item} assignRef={assignRef} />
			))}
		</>
	);
};
