export interface CasaviTicket {
	id: number;
	identifier: number;
	TenantId: number;
	CommunityId: number;
	title?: string;
	status?: keyof typeof TicketStatus;
	description?: string;
	createdAt?: string;
	updatedAt?: string;
	CreatedById?: number;
	AssignedToId?: number;
	UnitId?: number;
	referenceId?: string;
	contactName?: string;
	contactTelephone?: string;
	contactEmail?: string;
	ContactId?: number;
	UserId?: number;
	reportedAt?: string;
	deadlineAt?: string;
	closedAt?: string;
	TicketTypeId?: number;
	statusText?: string;
	channel?: keyof typeof TicketChannel;
	priority?: number;
	payable?: number;
	seenActivities?: number;
	seenAt?: string;
	deletedAt?: number;
	originalDeadlineAt?: string;
	inProgressAt?: string;
	postponedAt?: string;
	openedAt?: string;
	firstResponseAt?: string;
	Community?: CasaviCommunity;
	Unit?: CasaviUnit;
	Tenant?: {
		id: number;
		name: string;
	};
	Attachments?: CasaviAttachment[];
	TicketComments?: CasaviTicketComment[];
}

export interface CasaviTicketComment {
	id: number;
	text: string;
	Attachments: CasaviAttachment[];
	AuthorId: number;
	TenantId: number;
	createdAt: string;
	TicketId: number;
	identifier: number;
	isInternal: boolean;
	textRendered: string;
	updatedAt: string;
	Author?: {
		id: number;
		firstName: string;
		lastName: string;
		Contacts?: [{
			id: number;
			firstName: string;
			lastName: string;
			relayAccountId: number;
		}];
	};
	Contact?: {
		id: number;
		firstName: string;
		lastName: string;
		relayAccountId: number;
	};
}

export interface CasaviAttachment {
	type: string;
	size: number;
	id: number;
	name: string;
	s3key: string;
	thumbS3key: string;
	title: string;
	itemType: string;
	itemId: number;
	superItemType: string;
	superItemId: number;
	temporaryUrl: string;
	originalName?: string;
	keyPart?: string;
	path?: string;
	fileType?: string;
	fileSize?: number;
	key?: string;
	TenantId: number;
	secureUrl: string;
	temporaryThumbUrl?: string;
	fileName?: string;
}

export enum TicketStatus {
	OPEN = 'OPEN',
	INPROGRESS = 'INPROGRESS',
	POSTPONED = 'POSTPONED',
	CLOSED = 'CLOSED'
}

export enum TicketChannel {
	PORTAL = 'PORTAL',
	PHONE = 'PHONE',
	EMAIL = 'EMAIL',
	POST = 'POST',
	PERSONAL = 'PERSONAL',
	OTHER = 'OTHER',
	MISCELLANEOUS = 'Miscellaneous'
}

export interface CasaviCommunity {
	id: number;
	description: string;
	identifier?: string;
	name: string;
	postalCode?: string;
	city?: string;
	country?: string;
	TenantId: number;
	active: number;
	number?: string;
	createdAt: Date | string;
	updatedAt: Date | string;
	deletedAt: Date | string | null;
	createdAtRendered?: Date | string;
	updatedAtRendered?: Date | string;
	archivedAtRendered?: Date | string;
	mscNumber?: string;
	mscProviderName?: string;
	mscProviderIdentifier?: string;
	isEedEnabled?: number;
	workspaceRegistrationUuid?: string;
	Addresses: CasaviAddress[];
}

export interface CasaviAddress {
	id: number;
	street: string;
}

export interface CasaviUnit {
	id: number;
	identifier?: string;
	name?: string;
	mscNumber?: string;
	propertyMscNumber?: string;
	deletedAt: null | string;
	number?: string;
	area?: number;
	floor?: string;
	description?: string;
	community?: CasaviCommunity;
	Community?: CasaviCommunity;
	CommunityId: number;
	TenantId: number;
	AddressId: number;
	purpose: Purpose;
	createdAt: Date;
	updatedAt: Date;
	Address?: {
		street: string;
	};
}

export enum Purpose {
	PRIVATE = 'PRIVATE',
	COMMERCIAL = 'COMMERCIAL',
	PARKING = 'PARKING'
}

export enum CasaviFieldType {
	TEXTFIELD = 'TEXTFIELD',
	TEXTBOX = 'TEXTBOX',
	NUMBER = 'NUMBER',
	CHECKBOX = 'CHECKBOX',
	DROPDOWN = 'DROPDOWN',
	DATE = 'DATE',
	DOCUMENT = 'DOCUMENT',
	SERVICE_PROVIDER_CONTRACT = 'SERVICE_PROVIDER_CONTRACT'
}
export interface FieldOptions {
	options: string[];
	decimalPlaces: number;
	withTime: boolean;
}
export interface CasaviCustomField {
	dataJSON: FieldOptions;
	id: number;
	name: string;
	description: string | null;
	required: boolean;
	position: number;
	fieldType: CasaviFieldType;
	value?: unknown;
	fieldOptions?: FieldOptions;
	data: string | null;
	TargetModel: 'TicketType';
	TargetModelId: number;
	hidden: boolean;
	defaultContent: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	TenantId: number;
}
