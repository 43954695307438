import {
	TableCell,
	Table,
	TableRow,
	TableBody,
	TableHead,
	IconButton,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	Collapse,
	TablePagination,
	DialogActions,
	Button,
	styled,
	tableCellClasses,
	Tooltip,
	Chip
} from '@mui/material';
import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import moment from 'moment-with-locales-es6';
import { api } from '../api';
import InfoIcon from '@mui/icons-material/Info';

export const CronRunRow = (props) => {
	const runLimit = 10;
	const logLimit = 10;

	const { cronRun } = props;
	const [cronLogPage, setCronLogPage] = React.useState(0);

	const handleCronLogPageChange = (event: unknown, newPage: number, cronRun) => {
		setCronLogPage(newPage);
		fetchCronLogs(cronRun, newPage);
	};

	const fetchCronLogs = async (cronRun, page?: number) => {
		const offset = page ? runLimit * page : 0;
		const logs = await api.get(
			`/api/sysadmin/cronlog/cronlogs/${cronRun.id}?limit=${logLimit}&offset=${offset}`
		);
		setLogs([]);
		setLogs(logs);
	};

	const fetchCronLogCount = async (cronRun) => {
		cronRun.logCount = 0;
		const count = await api.get(`/api/sysadmin/cronlog/cronlogs/${cronRun.id}/count`);
		cronRun.logCount = count.count;
	};

	const handleDetailOpen = (cronRun) => {
		detailOpen[cronRun.id] = !detailOpen[cronRun.id];
		fetchCronLogCount(cronRun);
		if (detailOpen[cronRun.id]) {
			setTimeout(() => {
				fetchCronLogs(cronRun);
			}, 100);
		} else {
			setLogs([]);
		}
	};

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.grey[400],
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const [cronLogs, setLogs] = React.useState([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [detailOpen, setDetailOpen] = React.useState({});
	return (
		<>
			<TableRow key={cronRun.id}>
				<TableCell>{cronRun.id}</TableCell>
				<TableCell>{moment(cronRun.startedAt).format('L LT')}</TableCell>
				<TableCell>
					{cronRun.finishedAt ? moment(cronRun.finishedAt).format('L LT') : null}
				</TableCell>
				<TableCell>{cronRun.manuallyTriggered.toString()}</TableCell>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => handleDetailOpen(cronRun)}
					>
						{detailOpen[cronRun.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell>
					<Tooltip
						title={
							<div
								dangerouslySetInnerHTML={{
									__html: JSON.stringify(cronRun.summaryParsed, null, 4)
										.replace(/["{[\}\]]/g, '')
										.replace(/,/g, '<br />')
								}}
							></div>
						}
					>
						<span>
							<Chip label="Summary" variant="outlined" avatar={<InfoIcon />} />
						</span>
					</Tooltip>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={detailOpen[cronRun.id]} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<StyledTableCell>ID</StyledTableCell>
										<StyledTableCell>Action</StyledTableCell>
										<StyledTableCell>Item Id</StyledTableCell>
										<StyledTableCell>Item Type</StyledTableCell>
										<StyledTableCell>Info</StyledTableCell>
										<StyledTableCell>Successful</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{cronLogs
										? cronLogs.map((cronLog) => (
												<TableRow key={cronLog.createdAt}>
													<TableCell>{cronLog.id}</TableCell>
													<TableCell>{cronLog.action}</TableCell>
													<TableCell>{cronLog.itemId}</TableCell>
													<TableCell>{cronLog.itemType}</TableCell>
													<TableCell>
														{cronLog.message ? JSON.parse(cronLog.message).info : null}
													</TableCell>
													<TableCell>{cronLog.successful ? 'true' : 'false'}</TableCell>
												</TableRow>
										  ))
										: null}
								</TableBody>
							</Table>
							{cronRun?.logCount ? (
								<>
									<TablePagination
										component="div"
										count={cronRun.logCount}
										rowsPerPage={logLimit}
										page={cronLogPage}
										rowsPerPageOptions={[logLimit]}
										onPageChange={(event, newPage) => {
											handleCronLogPageChange(event, newPage, cronRun);
										}}
									/>
								</>
							) : (
								<></>
							)}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export const CronjobHistoryModal = (props) => {
	const { t } = useTranslation();
	const { modalOpen, selectedTask, setModalOpen } = props;

	const [isOpen, setIsOpen] = React.useState(false);

	React.useEffect(() => {
		if (!selectedTask || !selectedTask.cronId) {
			handleClose();
		} else {
			fetchCronRuns(selectedTask);
			fetchCronRunCount(selectedTask);
			setCronRunPage(0);
			setIsOpen(modalOpen);
		}
	}, [modalOpen, selectedTask]);

	const runLimit = 10;

	const handleCronRunPageChange = (event: unknown, newPage: number, task) => {
		setCronRunPage(newPage);
		fetchCronRuns(task, newPage);
	};

	const fetchCronRuns = async (task, page?: number) => {
		const offset = page ? runLimit * page : 0;
		let runs = await api.get(
			`/api/sysadmin/cronlog/cronruns/${task.cronId}?limit=${runLimit}&offset=${offset}`
		);
		setRuns([]); // necessary to reset the object first
		runs = runs.map((run) => {
			return { ...run, summaryParsed: JSON.parse(run.summary) };
		});
		setRuns(runs);
	};

	const fetchCronRunCount = async (task) => {
		const count = await api.get(`/api/sysadmin/cronlog/cronruns/${task.cronId}/count`);
		setRunCount(count?.count);
	};

	const [cronRuns, setRuns] = React.useState([]);
	const [cronRunCount, setRunCount] = React.useState(0);
	const [cronRunPage, setCronRunPage] = React.useState(0);

	const handleClose = () => {
		setRuns([]);
		setCronRunPage(0);
		setRunCount(0);
		setIsOpen(false);
		setModalOpen(false);
	};

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			fullWidth={true}
			maxWidth={false}
		>
			<DialogTitle id="form-dialog-title">
				{selectedTask ? `${selectedTask.category} - ${selectedTask.name}` : null}
			</DialogTitle>
			<DialogContent>
				Cronjob ID: {selectedTask ? selectedTask.id : null}
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Started</TableCell>
							<TableCell>Finished</TableCell>
							<TableCell>Manually Triggered</TableCell>
							<TableCell>Detail Log</TableCell>
							<TableCell>Summary</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{cronRuns?.map((cronRun) => (
							<>
								<CronRunRow cronRun={cronRun} />
							</>
						))}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={cronRunCount}
					rowsPerPage={runLimit}
					page={cronRunPage}
					rowsPerPageOptions={[runLimit]}
					onPageChange={(event, newPage) => {
						handleCronRunPageChange(event, newPage, selectedTask);
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					{t('sysadmin:cron_history_modal_close')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
