import * as countries from 'i18n-iso-countries';
import * as de from 'i18n-iso-countries/langs/de.json';
import { UIAddress } from '@api/v1';
import { compact } from 'lodash';

countries.registerLocale(de);

export function getCountryNameByCode(code: string) {
	return countries.getName(code, 'de');
}

export function getGoogleMapsLink(address: UIAddress) {
	return `https://maps.google.com/?q=${encodeURIComponent(displayAddress(address))}`;
}

export function displayAddress(address: UIAddress): string {
	if (!address) return null;
	return compact([
		address.streetAddress,
		compact([address.postalCode, address.locality]).join(' '),
		getCountryNameByCode(address.country)
	]).join(', ');
}

export function getAddress(address = {} as UIAddress) {
	const streetAddress = address.streetAddress;
	const municipality = compact([address.postalCode, address.locality]).join(' ');
	const additionalInfo = address.additionalInfo ? `(${address.additionalInfo})` : null;
	return compact([compact([streetAddress, municipality]).join(', '), additionalInfo]).join(' ');
}

export function getBillingAddress(address = {} as UIAddress) {
	return compact([
		address.additionalInfo,
		address.streetAddress,
		(address.postalCode || address.locality) &&
			`${address.postalCode || ''} ${address.locality || ''}`,
		address.country
	]).join('\n');
}

export function getMapAddress(address = {} as UIAddress) {
	return compact([
		address.streetAddress,
		compact([address.postalCode, address.locality]).join(' ')
	]).join(', ');
}
