import { api } from './api';
import { UIAccount, UIRawUser } from '@api/v1';
import { addIntercomScript } from './intercomScript';

export function getIntercomAppId() {
	switch (import.meta.env.MODE) {
		case 'production':
			return 'v4pbz9tv';
		case 'integration':
			return 'u4sw542i';
		default:
			return null;
	}
}

export async function intercomIdentify() {
	const appId = getIntercomAppId();
	if (appId) {
		const data = await api.get('/api/v1/auth/intercom');
		const user: UIRawUser = data.user;
		const account: UIAccount = data.account;
		const isSysadminInDisguise: boolean = data.isSysadminInDisguise;

		if (isSysadminInDisguise) {
			return 'not launching intercom because user is sysadmin in disguise';
		}
		addIntercomScript();
		window.Intercom('boot', {
			app_id: appId,
			email: user.email,
			name: `${user.firstName} ${user.lastName}`,
			company: {
				id: account?.id,
				name: account?.name
			},
			companyId: account?.id,
			user_id: String(user.id)
		});
	}
}
export function intercomLogout() {
	if (getIntercomAppId()) {
		(window as any).Intercom('shutdown');
	}
}
