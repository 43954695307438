import React, { useEffect, useRef, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Grid,
	Typography,
	LinearProgress,
	IconButton,
	InputAdornment,
	Divider,
	useTheme,
	useMediaQuery,
	Hidden,
	Fab
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBack from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Trans, useTranslation } from 'react-i18next';
import {
	UICustomer,
	UIProperty,
	UIAddress,
	UIOrderStatus,
	UIOrderField,
	UIOrderContactPerson,
	UIOrderComment
} from '@api/v1';
import { closeEditDialog, updateOrder } from '../actions';
import { loadCustomers } from '../../customers/actions';
import { loadProperties } from '../../properties/actions';
import { DateDialog } from '../../../../app/components/DateTime';
import { MaterialDropzone } from '../../../components/Dropzone';
import moment from 'moment-with-locales-es6';
import classNames from 'classnames';
import { AttachmentList, AttachmentThumbnail } from '../../../components/Attachment';
import { DropzoneFile } from 'app/components/Dropzone/types';
import { orderDialogUseStyles } from './orderDialogStyles';
import { getProperties, getRelayCustomers } from '../../selectors';

interface EditDialogOrderBody {
	id: number;
	title: string;
	address: UIAddress;
	orderDetailField: UIOrderField;
	status: UIOrderStatus;
	attachments: DropzoneFile[];
	dueDate: string;
	followUpDate: string;
	customer: UICustomer;
	contactPerson: UIOrderContactPerson;
	comments: UIOrderComment[];
	createdAt: Date;
	updatedAt: Date;
	internalId: string;
	type: string;
	taskId: string;
	hasUnreadNotifications: boolean;
	isInternal?: boolean;
	property?: UIProperty;
}

const transformOrder = (order: EditDialogOrderBody) => {
	return {
		...order,
		files:
			order.attachments.length > 0
				? order.attachments.map((attachment) => ({
						s3Key: attachment.s3Key,
						thumbnailS3Key: attachment.thumbnailS3Key,
						fileName: attachment.fileName,
						fileType: attachment.fileType,
						tempId: attachment.tempId
				  }))
				: []
	};
};

const emptyProperty = {
	id: 0,
	name: 'new-property',
	status: '',
	statusUpdatedAt: '',
	address: {
		streetAddress: '',
		postalCode: '',
		locality: '',
		country: ''
	},
	customer: {
		id: 0,
		name: '',
		emailAddress: ''
	},
	createdAt: '',
	internalId: '',
	contracts: []
};

const emptyCustomer = {
	id: 0,
	name: '',
	emailAddress: ''
};

export const OrderEditDialog = (props) => {
	const { open, originalOrder } = useSelector((state: State) => ({
		open: state.orders.single.editDialog.open,
		originalOrder: state.orders.single.order
	}));
	const { customers } = useSelector(getRelayCustomers);
	const { properties } = useSelector(getProperties);
	const theme = useTheme();
	const hasSmallWidth = useMediaQuery(theme.breakpoints.down('md'));
	const hasBigWidth = useMediaQuery(theme.breakpoints.up('lg'));
	const { t } = useTranslation();
	const classes = orderDialogUseStyles();

	const DialogContentRef = useRef<HTMLDivElement>(null);
	const initialState = {
		...originalOrder,
		followUpDate: originalOrder?.followUpDate,
		dueDate: originalOrder?.dueDate,
		contactPerson: {
			...originalOrder?.contactPersons?.[0]
		},
		orderDetailField:
			originalOrder?.fields?.length > 0 &&
			originalOrder?.fields.find((field) => field.name === 'Kommentar'),
		attachments:
			originalOrder?.attachments?.length >= 1
				? originalOrder.attachments.map((attachment) => ({
						tempId: attachment.id.toString(),
						fileName: attachment.name,
						fileType: attachment.itemType,
						s3Key: attachment.s3Key,
						thumbnailS3Key: attachment.thumbnailS3Key,
						preview: attachment.url,
						progress: 100
				  }))
				: []
	};
	const [order, setOrder] = useState<EditDialogOrderBody>(initialState);
	const [progress, setProgress] = useState<number>(0);
	const [isDueDateDialogOpen, setIsDueDateDialogOpen] = useState<boolean>(false);
	const [isFollowUpDateDialogOpen, setIsFollowUpDateDialogOpen] = useState<boolean>(false);

	const [inputValueCustomer, setInputValueCustomer] = React.useState('');
	const [inputValueProperty, setInputValueProperty] = React.useState('');

	const dispatch = useDispatch();

	const handleSubmit = (_ev) => {
		if (progress === 2) {
			dispatch(updateOrder(transformOrder(order)));
			return;
		}
		setProgress(progress + 1);
	};
	const handleGoBack = (_ev) => {
		if (progress === 0) {
			dispatch(closeEditDialog());
			return;
		}
		setProgress(progress - 1);
	};

	useEffect(() => {
		if (open && isEmpty(customers)) {
			dispatch(loadCustomers());
		}
		if (open && isEmpty(properties)) {
			dispatch(loadProperties());
		}
		if (open === false) {
			setProgress(0);
			setOrder(initialState);
		}
	}, [open]);

	const handleAddFile = (file) => {
		const newAttachment: DropzoneFile = {
			...file,
			thumbnailS3Key: ''
		};
		setOrder({
			...order,
			attachments: [...order.attachments, newAttachment]
		});
	};

	const handleChangeFile = (file, data) => {
		const newAttachment: DropzoneFile = {
			tempId: file,
			...data
		};
		setOrder({
			...order,
			attachments: [...order.attachments, newAttachment]
		});
		if (data.progress === 100) {
			setOrder({
				...order,
				attachments: [...order.attachments, newAttachment]
			});
		}
	};

	const handleRemoveFile = (file) => {
		const filteredFiles = order.attachments.filter((item) => item.tempId !== file);
		setOrder({ ...order, attachments: filteredFiles });
	};

	useEffect(() => {
		const dialogContentRef = DialogContentRef?.current;
		if (dialogContentRef) {
			dialogContentRef.scrollTop = 0;
		}
	}, [progress]);

	const customerFilter: (options: UICustomer[], filter) => UICustomer[] = createFilterOptions();
	const propertyFilter: (options: UIProperty[], filter) => UIProperty[] = createFilterOptions();

	return (
		<Dialog
			open={open}
			classes={{
				container: classes.container,
				paper: classNames(
					classes.paper,
					hasSmallWidth && classes.paperMobile,
					hasBigWidth && classes.paperBigScreenWidth
				)
			}}
			PaperComponent={ValidatorForm}
			PaperProps={{ onSubmit: handleSubmit }}
			maxWidth={false}
		>
			<div className={classes.dialogHeader}>
				<Typography variant="caption" color="textSecondary">
					{t('orders:edit_dialog_title')}
				</Typography>
				<IconButton aria-label="close" onClick={() => dispatch(closeEditDialog())} size="large">
					<CloseIcon />
				</IconButton>
			</div>
			<LinearProgress
				variant="determinate"
				value={(progress / 2) * 100}
				className={classes.progress}
			/>
			{progress > 0 && (
				<Hidden smUp>
					<br />
					<Fab onClick={handleGoBack} size="small" className={classes.goBackFab}>
						<ArrowBack />
					</Fab>
				</Hidden>
			)}
			<DialogTitle>
				{(() => {
					switch (progress) {
						case 1:
							return t('orders:add_dialog_create_description');
						case 2:
							return t('orders:add_dialog_overview');
						default:
							return t('orders:add_dialog_select_customer');
					}
				})()}
				<Typography variant="caption" color="textSecondary" paragraph>
					{(() => {
						switch (progress) {
							case 1:
								return t('orders:add_dialog_create_description_label');
							case 2:
								return t('orders:add_dialog_overview_label');
							default:
								return t('orders:add_dialog_select_customer_label');
						}
					})()}
				</Typography>
			</DialogTitle>
			<DialogContent ref={DialogContentRef}>
				<Grid container spacing={2}>
					{(() => {
						switch (progress) {
							case 1:
								return (
									<>
										<Grid item xs={12}>
											<TextValidator
												variant="standard"
												fullWidth
												label={t('orders:add_dialog_create_title')}
												onChange={(e) =>
													setOrder({
														...order,
														title: e.target.value
													})
												}
												value={order.title}
												validators={['required']}
												errorMessages={[t('general:required_message')]}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												multiline
												rows={3}
												label={t('orders:add_dialog_create_details')}
												onChange={(e) =>
													setOrder({
														...order,
														orderDetailField: {
															...order.orderDetailField,
															content: e.target.value
														}
													})
												}
												value={order?.orderDetailField?.content || ''}
												variant="standard"
											/>
										</Grid>
										<Grid item xs={12}>
											<br />
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextValidator
												variant="standard"
												fullWidth
												label={t('orders:add_dialog_create_internal_id')}
												onChange={(e) =>
													setOrder({
														...order,
														internalId: e.target.value
													})
												}
												value={order.internalId || ''}
											/>
										</Grid>
										<Grid item xs={12} sm={6} style={{ marginRight: 'auto' }}>
											<Grid container spacing={1}>
												<Grid item xs>
													<TextField
														fullWidth
														label={t('orders:add_dialog_create_due_date')}
														onClick={() => setIsDueDateDialogOpen(true)}
														value={order.dueDate ? moment(order.dueDate).format('DD.MM.YYYY') : ''}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton size="large">
																		<CalendarIcon />
																	</IconButton>
																</InputAdornment>
															)
														}}
														variant="standard"
													/>
												</Grid>
												{order.dueDate && (
													<Grid container item xs={1} justifyContent="center">
														<IconButton
															className={classes.dateDeleteButton}
															onClick={() => setOrder({ ...order, dueDate: null })}
															size="large"
														>
															<CloseIcon fontSize="small" color="primary" />
														</IconButton>
													</Grid>
												)}
											</Grid>
											<DateDialog
												open={isDueDateDialogOpen}
												onClose={() => setIsDueDateDialogOpen(false)}
												onSubmit={(date) => {
													setIsDueDateDialogOpen(false);
													setOrder({
														...order,
														dueDate: date.toISOString()
													});
												}}
												initialValue={moment(order.dueDate || Date.now())}
												dialogTitle={t('orders:due_date_dialog_header')}
												submitLabel={t('orders:due_date_dialog_button_save')}
												disablePast
											/>
										</Grid>
										<Grid item xs={12} sm={6} style={{ marginRight: 'auto' }}>
											<Grid container spacing={1}>
												<Grid item xs>
													<TextField
														fullWidth
														label={t('orders:add_dialog_create_follow_up_date')}
														onClick={() => setIsFollowUpDateDialogOpen(true)}
														value={
															order.followUpDate
																? moment(order.followUpDate).format('DD.MM.YYYY')
																: ''
														}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton size="large">
																		<CalendarIcon />
																	</IconButton>
																</InputAdornment>
															)
														}}
														variant="standard"
													/>
												</Grid>
												{order.followUpDate && (
													<Grid container item xs={1} justifyContent="center">
														<IconButton
															className={classes.dateDeleteButton}
															onClick={() => setOrder({ ...order, followUpDate: null })}
															size="large"
														>
															<CloseIcon fontSize="small" color="primary" />
														</IconButton>
													</Grid>
												)}
											</Grid>
											<DateDialog
												open={isFollowUpDateDialogOpen}
												onClose={() => setIsFollowUpDateDialogOpen(false)}
												onSubmit={(date) => {
													setIsFollowUpDateDialogOpen(false);
													setOrder({
														...order,
														followUpDate: date.toISOString()
													});
												}}
												initialValue={moment(order.followUpDate || Date.now())}
												dialogTitle={t('orders:follow_up_date_dialog_header')}
												submitLabel={t('orders:follow_up_date_dialog_button_save')}
												disablePast
											/>
										</Grid>
										<Grid item sm={12}>
											<br />
										</Grid>
										<Grid item xs={12}>
											<Typography variant="body1">{t('orders:add_dialog_attachment')}</Typography>
											<MaterialDropzone
												id="create_order_attachment_dropzone"
												text={t('orders:add_dialog_upload')}
												onDrop={(file) => handleAddFile(file)}
												onRemove={handleRemoveFile}
												onChangeFile={handleChangeFile}
												files={order.attachments}
											/>
										</Grid>
									</>
								);
							case 2:
								return (
									<>
										<Grid item sm={6} xs={12}>
											<Typography variant="caption" color="textSecondary">
												{t('orders:add_dialog_customer_name')}
											</Typography>
											<Typography>{order.customer.name}</Typography>
										</Grid>
										<Grid item sm={6} xs={12}>
											<Typography variant="caption" color="textSecondary">
												{t('orders:add_dialog_property_name')}
											</Typography>
											<Typography>{order.property.name}</Typography>
										</Grid>
										<Grid item sm={12}>
											<Divider />
										</Grid>
										{order.contactPerson.name && (
											<>
												<Grid item sm={6} xs={12} style={{ marginRight: 'auto' }}>
													<Typography variant="caption" color="textSecondary">
														{t('orders:add_dialog_contact_name')}
													</Typography>
													<Typography>{order.contactPerson.name}</Typography>
													<Typography>{order.contactPerson.emailAddress}</Typography>
													<Typography>{order.contactPerson.telephone}</Typography>
												</Grid>
												<Grid item sm={12}>
													<Divider />
												</Grid>
											</>
										)}
										{order?.orderDetailField?.content && (
											<>
												<Grid item xs={12} style={{ marginRight: 'auto' }}>
													<Typography variant="caption" color="textSecondary">
														{t('orders:add_dialog_create_details')}
													</Typography>
													<Typography>{order.orderDetailField.content}</Typography>
												</Grid>
												<Grid item sm={12}>
													<Divider />
												</Grid>
											</>
										)}
										{order.internalId && (
											<Grid item sm={6} xs={12}>
												<Typography variant="caption" color="textSecondary">
													{t('orders:add_dialog_create_internal_id')}
												</Typography>
												<Typography>{order.internalId}</Typography>
											</Grid>
										)}
										{order.dueDate && (
											<Grid item sm={6} xs={12} style={{ marginRight: 'auto' }}>
												<Typography variant="caption" color="textSecondary">
													{t('orders:add_dialog_create_due_date')}
												</Typography>
												<Typography>{moment(order.dueDate).format('DD.MM.YYYY')}</Typography>
											</Grid>
										)}
										{order.followUpDate && (
											<Grid item sm={6} xs={12} style={{ marginRight: 'auto' }}>
												<Typography variant="caption" color="textSecondary">
													{t('orders:add_dialog_create_follow_up_date')}
												</Typography>
												<Typography>{moment(order.followUpDate).format('DD.MM.YYYY')}</Typography>
											</Grid>
										)}
										{(order.internalId || order.dueDate) && (
											<Grid item sm={12}>
												<Divider />
											</Grid>
										)}
										{!order.isInternal && (
											<Grid item sm={12} className={classes.internalInfo}>
												<InfoIcon color="disabled" />
												<div>
													<Typography>{t('orders:add_dialog_external_info')}</Typography>
													<Typography variant="caption" color="textSecondary">
														{t('orders:add_dialog_external_info_label', {
															email: order.customer.emailAddress
														})}
													</Typography>
												</div>
											</Grid>
										)}
										<Grid item sm={12}>
											<Divider />
										</Grid>
										{order.attachments.length !== 0 && (
											<>
												<Grid item xs={12} id="create_order_summary_attachment">
													<Typography variant="caption" color="textSecondary">
														{t('orders:add_dialog_summary_attachment_heading')}
													</Typography>
													<AttachmentList>
														{order.attachments.map((file) => (
															<AttachmentThumbnail
																key={file.tempId}
																name={file.fileName}
																link={file.preview}
																thumbnail={file.preview}
															/>
														))}
													</AttachmentList>
												</Grid>
											</>
										)}
									</>
								);
							default:
								return (
									<>
										<Grid item xs={12}>
											<Typography>{t('orders:add_dialog_customer_and_property')}</Typography>
										</Grid>
										<Grid item xs={12}>
											<Autocomplete
												classes={{ option: classes.autocompleteOption }}
												options={[...(customers || []), emptyCustomer]}
												getOptionLabel={(customer: UICustomer) => customer.name}
												onChange={(_event, newValue: UICustomer) => {
													setOrder({
														...order,
														customer: newValue
													});
												}}
												isOptionEqualToValue={(option, value) => {
													return option.id === value.id;
												}}
												value={order.customer}
												inputValue={inputValueCustomer}
												onInputChange={(_event, value) => {
													setInputValueCustomer(value);
												}}
												renderInput={(params) => (
													<TextValidator
														variant="standard"
														{...params}
														fullWidth
														value={inputValueCustomer}
														label={t('orders:add_dialog_customer_name_required')}
														validators={['required']}
														errorMessages={[t('general:required_message')]}
													/>
												)}
												renderOption={(props, option) => {
													if (option.name === '') {
														return (
															<li {...props}>
																<Link
																	to="/app/customers_and_properties/customers"
																	className={classes.addCustomerLink}
																>
																	{t('orders:add_dialog_customer_add_new')}
																</Link>
															</li>
														);
													}
													return <li {...props}> {option.name}</li>;
												}}
												filterOptions={(options, params) => {
													const filtered = customerFilter(options, params);
													if (params.inputValue !== '') {
														filtered.push({
															name: '',
															emailAddress: ''
														});
													}
													return filtered;
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Autocomplete
												classes={{ option: classes.autocompleteOption }}
												options={[
													...(properties || []),
													{
														...emptyProperty,
														name: 'new-property'
													}
												]}
												getOptionLabel={(property: UIProperty) => property.name}
												onChange={(_event, newValue: UIProperty) => {
													setOrder({
														...order,
														property: newValue
													});
												}}
												isOptionEqualToValue={(option, value) => {
													return option.id === value.id;
												}}
												value={order.property}
												inputValue={inputValueProperty}
												onInputChange={(_event, value) => {
													setInputValueProperty(value);
												}}
												renderInput={(params) => (
													<TextValidator
														variant="standard"
														{...params}
														fullWidth
														label={t('orders:add_dialog_property_name_required')}
														validators={['required']}
														errorMessages={[t('general:required_message')]}
														value={inputValueProperty}
													/>
												)}
												renderOption={(props, option) => {
													if (option.name === 'new-property') {
														return (
															<li {...props}>
																<Link
																	to="/app/customers_and_properties/properties"
																	className={classes.addCustomerLink}
																>
																	{t('orders:add_dialog_property_add_new')}
																</Link>
															</li>
														);
													}
													return <li {...props}>{option.name}</li>;
												}}
												filterOptions={(options, params) => {
													const filtered = propertyFilter(options, params);
													if (params.inputValue !== '') {
														filtered.push({
															...initialState.property,
															name: 'new-property'
														});
													}
													return filtered;
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<br />
										</Grid>
										<Grid item xs={12}>
											<Typography>{t('orders:add_dialog_contact_label')}</Typography>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextValidator
												variant="standard"
												fullWidth
												label={t('orders:add_dialog_contact_name')}
												onChange={(e) =>
													setOrder({
														...order,
														contactPerson: {
															...order.contactPerson,
															name: e.target.value
														}
													})
												}
												value={order.contactPerson.name}
											/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextValidator
												variant="standard"
												fullWidth
												label={t('orders:add_dialog_contact_email')}
												onChange={(e) =>
													setOrder({
														...order,
														contactPerson: {
															...order.contactPerson,
															emailAddress: e.target.value
														}
													})
												}
												value={order.contactPerson.emailAddress}
												validators={['isEmail']}
												errorMessages={[t('orders:dialog_email_validation_error_message')]}
											/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextValidator
												variant="standard"
												fullWidth
												label={t('orders:add_dialog_contact_tel')}
												onChange={(e) =>
													setOrder({
														...order,
														contactPerson: {
															...order.contactPerson,
															telephone: e.target.value
														}
													})
												}
												value={order.contactPerson.telephone}
												validators={['isEmptyOrValidPhoneNumber']}
												errorMessages={[t('orders:dialog_email_validation_error_message')]}
											/>
										</Grid>
									</>
								);
						}
					})()}
				</Grid>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Hidden mdDown>
					<Button color="secondary" variant="text" onClick={handleGoBack}>
						{progress === 0 ? (
							<Trans i18nKey="general:cta_cancel" />
						) : (
							<Trans i18nKey="general:cta_go_back" />
						)}
					</Button>
				</Hidden>
				<Button
					color="primary"
					type="submit"
					variant={hasSmallWidth ? 'contained' : 'text'}
					fullWidth={hasSmallWidth}
				>
					{progress === 2 ? (
						<Trans i18nKey="orders:cta_save" />
					) : (
						<Trans i18nKey="general:cta_continue" />
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
