import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DashboardHeader } from './Header';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getAccounts } from '../accounts/actions';
import { Button } from '@mui/material';
import { api } from '../api';
import { StateAndCityCounts } from '@api/sysadmin';

export function GeographicalSection() {
	const [stateAndCityCounts, setStateAndCityCounts] = React.useState(null);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	React.useEffect(() => {
		dispatch(getAccounts());
	}, []);
	const handleClick = () => {
		const fetchCounts = async () => {
			try {
				const counts = await api.get('/api/sysadmin/accounts/stateAndCityCount');
				setStateAndCityCounts(counts);
			} catch (e) {
				console.error(e);
			}
		};
		fetchCounts();
	};
	return (
		<div>
			<DashboardHeader>{t('sysadmin:dashboard_geographical_section_header')}</DashboardHeader>
			{!stateAndCityCounts ? (
				<Button variant="outlined" onClick={handleClick}>
					{t('sysadmin:dashboard_geographical_section_calculate_count_button')}
				</Button>
			) : (
				<CollapsibleTable stateAndCityCounts={stateAndCityCounts} />
			)}
		</div>
	);
}

function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					{!!row?.cities?.length && (
						<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</TableCell>
				<TableCell component="th" scope="row">
					{row.state}
				</TableCell>
				<TableCell>{row.stateTotalCount}</TableCell>
			</TableRow>
			{open && (
				<>
					{row.cities?.map((city) => (
						<TableRow key={city.name}>
							<TableCell />
							<TableCell sx={{ paddingLeft: '3rem' }}>{city.name}</TableCell>
							<TableCell sx={{ paddingLeft: '3rem' }}>{city.count}</TableCell>
						</TableRow>
					))}
				</>
			)}
		</React.Fragment>
	);
}

interface StateAndCityRow {
	state: string;
	stateTotalCount: number;
	cities?: CityRow[];
}

interface CityRow {
	name: string;
	count: number;
}

export const createRows = (countData: StateAndCityCounts): StateAndCityRow[] => {
	let rows = [];
	for (const [key, value] of Object.entries(countData)) {
		rows = [
			...rows,
			{
				state: key,
				stateTotalCount: value.count,
				cities:
					value?.cities &&
					Object.entries(value.cities).map((cityNameAndCount) => ({
						name: cityNameAndCount[0],
						count: cityNameAndCount[1]
					}))
			}
		];
	}
	return rows;
};

export function CollapsibleTable(props) {
	const { stateAndCityCounts } = props;
	const { t } = useTranslation();
	return (
		<TableContainer component={Paper} sx={{ width: '60%' }}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>{t('sysadmin:dashboard_geographical_section_stateCity_column')}</TableCell>
						<TableCell>{t('sysadmin:dashboard_geographical_section_totalCount_column')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{createRows(stateAndCityCounts)?.map((row, idx) => (
						<Row key={idx} row={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
