import { showNotification } from '../../core/actions';
import { i18n } from '../../i18n';
import { createAsyncConstants } from '../../lib/createConstants';
import { api } from '../api';

export const asyncConstants = createAsyncConstants(
	['GET_SCHEDULED_TASKS', 'START_SCHEDULED_TASK'],
	{
		prefix: 'SYSADMIN'
	}
);

export const getScheduledTasks = () => ({
	type: asyncConstants.GET_SCHEDULED_TASKS.TYPE,
	payload: api.get('/api/sysadmin/scheduled-tasks/list?includelatestrun=true')
});
export const startTask = (taskId: number, params?: Record<string, string>) => (dispatch) => {
	const confirmed = window.confirm(i18n.t('sysadmin:start_scheduled_task_prompt'));
	if (confirmed) {
		const path = `/api/sysadmin/scheduled-tasks/${taskId}/start${
			params ? `?${new URLSearchParams(params).toString()}` : ''
		}`;
		return dispatch({
			type: asyncConstants.START_SCHEDULED_TASK.TYPE,
			payload: api
				.post(path)
				.then(() => {
					dispatch(showNotification(i18n.t('sysadmin:scheduled_task_started_manually'), 'success'));
					return taskId;
				})
				.catch(() => dispatch(showNotification(i18n.t('general:generic_error'), 'error')))
		});
	}
};
