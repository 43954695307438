import { Button, CardActions, CardContent, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetPassword } from './actions';

const useStyles = makeStyles(() => ({
	link: {
		textDecoration: 'none'
	}
}));

export const DemandPassword = () => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const onSubmit = () => {
		dispatch(
			resetPassword({ email }, (err, data) => {
				if (!err) {
					setSubmitted(true);
				}
			})
		);
	};
	const classes = useStyles();
	const { t } = useTranslation();
	if (submitted) {
		return [
			<CardContent key="content">
				<Typography variant="h5" gutterBottom>
					{t('general:after_reset_password_header')}
				</Typography>
				<Trans
					i18nKey="general:after_reset_password_description"
					components={[
						<Typography key="0" gutterBottom>
							0
						</Typography>,
						<Typography key="1">1</Typography>
					]}
				/>
			</CardContent>,
			<CardActions key="actions">
				<Link to="/login" className={classes.link}>
					<Button size="small" color="primary">
						{t('general:back_to_login')}
					</Button>
				</Link>
			</CardActions>
		];
	}
	return [
		<CardContent key="content">
			<TextField
				id="demand_password_field_email"
				label={t('general:email')}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				margin="normal"
				fullWidth
				variant="standard"
			/>
		</CardContent>,
		<CardActions key="actions">
			<Button variant="contained" disabled={!email} onClick={onSubmit}>
				{t('general:reset_password')}
			</Button>
		</CardActions>
	];
};
