import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField
} from '@mui/material';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import { closeEditDialog, editProperty, setInternalId } from './actions';

export const PropertyEditDialog = () => {
	const dispatch = useDispatch();
	const handleSubmit = (submittedProperty) => {
		dispatch(editProperty(submittedProperty));
		dispatch(closeEditDialog());
	};
	const { open, property } = useSelector((state: State) => state?.properties?.editDialog);
	return (
		<Dialog open={open}>
			<DialogTitle>
				<Trans i18nKey="properties:edit_dialog_title" />
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<TextField
							defaultValue={property.internalId}
							onChange={(e) => dispatch(setInternalId(e.target.value))}
							fullWidth
							label={<Trans i18nKey="properties:edit_dialog_internal_id" />}
							variant="standard"
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							defaultValue={property.name}
							disabled
							fullWidth
							label={<Trans i18nKey="properties:edit_dialog_name" />}
							variant="standard"
						/>
					</Grid>
				</Grid>
				{property.address ? (
					<Grid container spacing={2}>
						<Grid item sm={6} xs={12}>
							<TextField
								defaultValue={property.address.country}
								disabled
								fullWidth
								label={<Trans i18nKey="properties:edit_dialog_country" />}
								variant="standard"
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								defaultValue={property.address.locality}
								disabled
								fullWidth
								label={<Trans i18nKey="properties:edit_dialog_locality" />}
								variant="standard"
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								defaultValue={property.address.postalCode}
								disabled
								fullWidth
								label={<Trans i18nKey="properties:edit_dialog_postal_code" />}
								variant="standard"
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								defaultValue={property.address.streetAddress}
								disabled
								fullWidth
								label={<Trans i18nKey="properties:edit_dialog_street_address" />}
								variant="standard"
							/>
						</Grid>
					</Grid>
				) : null}
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={() => dispatch(closeEditDialog())}>
					<Trans i18nKey="properties:edit_dialog_cancel" />
				</Button>
				<Button onClick={() => handleSubmit(property)}>
					<Trans i18nKey="properties:edit_dialog_save" />
				</Button>
			</DialogActions>
		</Dialog>
	);
};
