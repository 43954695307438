import { ButtonBase, Theme, ImageListItemBar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ImageIcon from '@mui/icons-material/Image';
import React from 'react';
import { Image } from '../Image';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		color: 'unset',
		marginRight: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5)
	},
	background: {
		width: theme.spacing(16),
		height: theme.spacing(10),
		background: theme.palette.grey['200'],
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative'
	},
	image: {
		position: 'absolute',
		width: theme.spacing(16),
		height: theme.spacing(10),
		objectFit: 'cover'
	},
	icon: {
		fontSize: theme.spacing(6)
	}
}));

interface AttachmentThumbnailProps {
	name: string;
	link: string;
	thumbnail?: string;
}

export const AttachmentThumbnail = ({ name, link, thumbnail }: AttachmentThumbnailProps) => {
	const classes = useStyles();
	return (
		<a target="_blank" href={link} className={classes.root}>
			<ButtonBase>
				<div className={classes.background}>
					<ImageIcon className={classes.icon} />
					{thumbnail ? <Image src={thumbnail} className={classes.image} /> : null}
				</div>
				<ImageListItemBar title={name} />
			</ButtonBase>
		</a>
	);
};
