import { ResponseCustomer, CustomerListResponse, UICustomerStatistics } from '@api/v1';
import { history } from '../history';
import { showNotification } from '../../core/actions';
import { i18n } from '../../i18n';
import { api } from '../../lib/api';
import { createAsyncConstants, createConstants } from '../../lib/createConstants';
import { createApiAction } from '../../redux/createApiAction';
import { State } from '../reducers';

export const constants = createConstants(
	[
		'OPEN_EDIT_DIALOG',
		'CLOSE_EDIT_DIALOG',
		'SET_INTERNAL_ID',
		'SET_AUTO_ASSIGNEE_ID',
		'OPEN_UPGRADE_DIALOG',
		'CLOSE_UPGRADE_DIALOG',
		'OPEN_ADD_DIALOG',
		'CLOSE_ADD_DIALOG'
	],
	{ prefix: 'CUSTOMERS' }
);

export const asyncConstants = createAsyncConstants(
	['EDIT_CUSTOMER', 'CHECK_TOKEN', 'CREATE_CUSTOMER', 'FETCH_STATISTICS'],
	{
		prefix: 'CUSTOMERS'
	}
);

export const loadCustomers = createApiAction('customers/get_list', async () => {
	return api.get<CustomerListResponse>('/api/v1/customers/');
});

export const registerAndLoadCustomers = (token: string) => (dispatch) =>
	dispatch(checkToken(token)).then(() => {
		dispatch(createCustomer({ status: 'PENDING' }, token)).then(() => {
			history.push('/app/customers');
		});
	});

export const openEditDialog = (customer: ResponseCustomer) => ({
	type: constants.OPEN_EDIT_DIALOG,
	payload: customer
});
export const closeEditDialog = () => ({
	type: constants.CLOSE_EDIT_DIALOG
});
export const openAddDialog = () => ({
	type: constants.OPEN_ADD_DIALOG
});
export const closeAddDialog = () => ({
	type: constants.CLOSE_ADD_DIALOG
});
export const openUpgradeDialog = () => ({
	type: constants.OPEN_UPGRADE_DIALOG
});
export const closeUpgradeDialog = () => ({
	type: constants.CLOSE_UPGRADE_DIALOG
});
export const setInternalId = (payload: string) => ({
	type: constants.SET_INTERNAL_ID,
	payload
});
export const setAutoAssignUserId = (payload: number) => ({
	type: constants.SET_AUTO_ASSIGNEE_ID,
	payload
});

export const editCustomer =
	(customer: ResponseCustomer, status?: string, history?) => (dispatch) => {
		return dispatch({
			type: asyncConstants.EDIT_CUSTOMER.TYPE,
			payload: api
				.patch(`/api/v1/customers/${customer.id}`, {
					internalId: customer.internalId,
					autoAssignUserId: customer.autoAssignUserId,
					status
				})
				.then(() => {
					dispatch(showNotification(i18n.t('general:generic_save_success'), 'success'));
					if (history) {
						history.push('/app/customers_and_properties/customers');
					}
					dispatch(loadCustomers());
				})
				.catch((err) => {
					dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
				})
		});
	};

export const checkToken = (token: string) => (dispatch) =>
	dispatch({
		type: asyncConstants.CHECK_TOKEN.TYPE,
		payload: api.get(`/api/v1/registration/actions/check_token?token=${token}`).catch((err) => {
			dispatch(showNotification(i18n.t('registration:token_invalid'), 'error'));
			throw err;
		})
	});

export const createCustomer = (body, token?) => (dispatch) =>
	dispatch({
		type: asyncConstants.CREATE_CUSTOMER.TYPE,
		payload: api
			.post('/api/v1/customers', {
				token,
				body
			})
			.then(() => {
				dispatch(closeAddDialog());
				dispatch(showNotification(i18n.t('general:generic_save_success'), 'success'));
				dispatch(loadCustomers());
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
				throw err;
			})
	});

export const fetchCustomerStatistics = () => (dispatch, getState: () => State) => {
	return dispatch({
		type: asyncConstants.FETCH_STATISTICS.TYPE,
		payload: api.get<UICustomerStatistics>('/api/v1/customers/statistics')
	});
};
