import { UIAccount, UICustomer, UIOrder } from '@api/v1';
import { constants } from './actions';
import { createReducer } from '../../redux/createReducer';

const FULFILLED = '_FULFILLED';

export interface OrderReplyState {
	order?: UIOrder;
	account?: UIAccount;
	customer?: UICustomer;
}

export const reducers = createReducer<OrderReplyState>({}).handleAnyAction(
	constants.CHECK_TOKEN + FULFILLED,
	(_state, action) => {
		return action.payload;
	}
);
