import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../reducers';
import { acceptAllProperties, closeAcceptAllDialog } from './actions';

export const AcceptAllDialog = () => {
	const { open } = useSelector((state: State) => state?.properties?.acceptAllDialog);
	const dispatch = useDispatch();
	return (
		<Dialog open={open}>
			<DialogTitle>
				<Trans i18nKey="properties:accept_all_dialog_header" />
			</DialogTitle>
			<DialogContent>
				<Trans i18nKey="properties:accepting_all_dialog_content" />
			</DialogContent>
			<DialogActions>
				<Button onClick={() => dispatch(closeAcceptAllDialog())}>
					<Trans i18nKey="general:cta_cancel" />
				</Button>
				<Button color="primary" onClick={() => dispatch(acceptAllProperties())}>
					<Trans i18nKey="properties:action_button_accept" />
				</Button>
			</DialogActions>
		</Dialog>
	);
};
