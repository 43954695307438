import React from 'react';
import { Button as MuiButton, Box } from '@mui/material';
import { PrimaryButtonProps } from './PrimaryButton';
import { LoadingButton } from '@mui/lab';

/**
 * Same code as in app/types/mui-custom.d.ts.
 * Has to be close to the component, because
 * otherwise Storybook will throw an error
 * without any reason.
 * Maybe related issues:
 * - https://github.com/storybookjs/storybook/issues/16386
 * - https://github.com/storybookjs/storybook/issues/19056 */
export enum ButtonColor {
	Creative = 'primary',
	Neutral = 'secondary',
	Destructive = 'error'
}

export type SecondaryButtonProps = PrimaryButtonProps;

/**
 * by isLoadingButton=**true**, use the same APIs from MUI for LoadingButton.
 * Related documentation:
 * - https://mui.com/material-ui/api/loading-button/
 **/
export function SecondaryButton({
	color = ButtonColor.Neutral,
	key,
	size = 'medium',
	isLoadingButton = false,
	...props
}: SecondaryButtonProps): ReturnType<typeof MuiButton> {
	return isLoadingButton ? (
		<LoadingButton variant="text" sx={{ mx: size === 'small' ? 0.5 : 1 }} {...props} />
	) : (
		<Box>
			<MuiButton
				variant="text"
				size={size}
				color={color}
				sx={{ mx: size === 'small' ? 0.5 : 1 }}
				{...props}
			/>
		</Box>
	);
}

export type SecondaryButton = typeof SecondaryButton;

// eslint-disable-next-line import/no-default-export
export default SecondaryButton;
