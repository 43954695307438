import { UIOfferRequestComment, UIOfferRequestStatus } from '@api/v1';
import { Theme, Avatar, Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import i18next from 'i18next';
import { dateTime } from '../../../lib/dateHelper';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentList, AttachmentThumbnail } from '../../../components/Attachment';
import { getInitialsFromString } from '../../../lib/nameHelper';

const useStyles = makeStyles<Theme, StylingProps>((theme) => ({
	outer: {
		margin: theme.spacing(1)
	},
	avatar: (props) => ({
		flex: '0 0 auto',
		marginLeft: props.isInternal ? theme.spacing(1) : 0,
		marginRight: props.isInternal ? 0 : theme.spacing(1)
	}),
	content: {
		padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
		display: 'flex',
		flexDirection: 'column',
		border: 'none',
		backgroundColor: '#F8F8F8'
	},
	closeRequestContent: {
		backgroundColor: '#8F8FA3',
		color: '#FFFFFF'
	},
	isArtificial: {
		marginLeft: theme.spacing(6),
		background: theme.palette.grey[200],
		'& $time': {
			marginTop: 0
		}
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	},
	title: {
		fontWeight: 500,
		whiteSpace: 'pre-line',
		maxWidth: '15rem'
	},
	body: {
		whiteSpace: 'pre-line',
		margin: `${theme.spacing(0.5)} 0`,
		wordBreak: 'break-word'
	},
	topRightContainer: {
		marginTop: theme.spacing(-0.5),
		color: theme.orderStatus.finished.main,
		display: 'flex',
		alignItems: 'center'
	},
	source: {
		marginLeft: theme.spacing(1)
	},
	time: {
		color: theme.palette.grey[400],
		alignSelf: 'flex-end',
		margin: `${theme.spacing(1)} 0 -${theme.spacing(0.5)} ${theme.spacing(1)}px`
	},
	date: {
		color: '#FFFFFF',
		alignSelf: 'flex-end',
		margin: `${theme.spacing(1)} 0 -${theme.spacing(0.5)} ${theme.spacing(1)}px`
	},
	multiLine: {
		marginBottom: theme.spacing(2),
		'& + $time': {
			alignSelf: 'flex-end'
		}
	},
	layoutReversed: (props) => ({
		flexDirection: props.isInternal ? 'row-reverse' : 'row',
		'& $isArtificial': {
			marginRight: theme.spacing(6),
			marginLeft: 0
		}
	})
}));

interface StylingProps {
	isInternal: boolean;
}

export function OfferRequestComment(props: { comment: UIOfferRequestComment }) {
	const { t } = useTranslation();
	const { comment } = props;
	const { author, text, source } = comment;
	const isArtificial = source === 'CHANGE';
	const isInternal = author?.type === 'INTERNAL';
	const classes = useStyles({ isInternal });
	const containerClasses = `${classes.content} ${isArtificial ? classes.isArtificial : ''}`;
	return (
		<div className={classes.outer}>
			<Grid container wrap="nowrap" className={classes.layoutReversed}>
				{!isArtificial && (
					<Grid item className={classes.avatar}>
						<Avatar src={author.avatar}>{author.initials}</Avatar>
					</Grid>
				)}
				<Grid item>
					{!isArtificial ? (
						<Card className={containerClasses} variant="outlined">
							<Grid container spacing={8} justifyContent="space-between">
								<Grid item>
									<Typography noWrap variant="body2" className={classes.title}>
										<div className={classes.truncate}>
											{author.name || t('offers:comment_deleted_author')}
										</div>
									</Typography>
								</Grid>
								<Grid item className={`${classes.topRightContainer}`}>
									<Typography className={classes.source} variant="caption" />
								</Grid>
							</Grid>
							<Typography variant="body2" className={classes.body}>
								{comment.text}
							</Typography>
							{!!comment.attachments?.length && (
								<AttachmentList>
									{comment.attachments.map((a) => (
										<AttachmentThumbnail
											key={a.id}
											name={a.name}
											link={a.url}
											thumbnail={a.thumbnailS3Key}
										/>
									))}
								</AttachmentList>
							)}
							<Typography variant="caption" className={classes.time}>
								{dateTime(comment.createdAt).toFormat('dd.MM.yyyy, HH:mm')}
							</Typography>
						</Card>
					) : (
						<Card className={containerClasses} variant="outlined">
							<Typography
								className={`${comment.statusChange && text ? classes.multiLine : ''}`}
								variant="body2"
								dangerouslySetInnerHTML={{
									__html: comment.statusChange
										? `${t('offers:comment_status_change', {
												name: author.name,
												status: getStatusChangeLabel(comment.statusChange, t)
										  })}<br>${text}`
										: text
								}}
							/>
							{!!comment.attachments?.length && (
								<AttachmentList>
									{comment.attachments.map((a) => (
										<AttachmentThumbnail
											key={a.id}
											name={a.name}
											link={a.url}
											thumbnail={a.thumbnailS3Key}
										/>
									))}
								</AttachmentList>
							)}
							<Typography variant="body2" className={classes.time}>
								{dateTime(comment.createdAt).toFormat('dd.MM.yyyy, HH:mm')}
							</Typography>
						</Card>
					)}
				</Grid>
			</Grid>
		</div>
	);
}

export function OfferRequestCloseComment(props: {
	statusUpdatedBy: string;
	statusUpdatedAt: Date;
	closeDescription: string;
}) {
	const { t } = useTranslation();
	const { statusUpdatedAt, statusUpdatedBy, closeDescription } = props;
	const classes = useStyles();
	const containerClasses = `${classes.content} ${classes.closeRequestContent}`;
	return (
		<div className={classes.outer}>
			<Grid container wrap="nowrap" className={classes.layoutReversed}>
				<Grid item className={classes.avatar}>
					<Avatar src={null}>{getInitialsFromString(statusUpdatedBy)}</Avatar>
				</Grid>
				<Grid item>
					<Card className={containerClasses} variant="outlined">
						<Grid container spacing={8} justifyContent="space-between">
							<Grid item>
								<Typography noWrap variant="body2" className={classes.title}>
									{`${t('offerRequest:closed_by')} ${
										statusUpdatedBy || t('offers:comment_deleted_author')
									}`}
								</Typography>
							</Grid>
						</Grid>
						<Typography variant="body2" className={classes.body}>
							{closeDescription || t('offerRequest:no_text')}
						</Typography>
						<Typography variant="caption" className={classes.date}>
							{dateTime(statusUpdatedAt).toFormat('dd.MM.yyyy, HH:mm')}
						</Typography>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
}

const getStatusChangeLabel = (status: UIOfferRequestStatus, t: i18next.TFunction) =>
	({
		OPEN: t('offers:status_OPEN'),
		DECLINED: t('offers:status_DECLINED')
	}[status] ?? '');
