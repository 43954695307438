import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';

export const AdminRestrictionAlert = () => {
	const { t } = useTranslation();
	return (
		<MuiAlert
			icon={false}
			severity="info"
			sx={{
				width: '100%',
				display: 'block'
			}}
		>
			<MuiAlertTitle>
				<Typography variant="h6">{t('admin:admin_restriction_alert')}</Typography>
			</MuiAlertTitle>
			<Typography variant="body1" sx={{ margin: '8px 0px 8px 0px' }}>
				{t('admin:admin_restriction_alert_description')}
			</Typography>
		</MuiAlert>
	);
};
