import React from 'react';
import { TextField, Grid } from '@mui/material';

/** Add this component with documentation to Storybook later **/
const Input = ({ sm = 12, onChange = null, children = null, disabled = false,...rest }) => (
	<Grid item sm={sm} xs={12} sx={{ display: 'flex' }}>
		<TextField
			{...rest}
			onChange={onChange}
			disabled={disabled || (!onChange && !rest.onClick)}
			fullWidth
			variant="outlined"
		/>
		{children}
	</Grid>
);

// eslint-disable-next-line import/no-default-export
export default Input;
