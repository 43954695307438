import { Feature, AccountSpecificFeature } from '../../../server/features/feature';
import { useSelector } from 'react-redux';
import { State } from '../reducers';

export { Feature, AccountSpecificFeature };

export function useFeature(feature: `${Feature}`) {
	const features: string[] = useSelector((state: State) => state.auth.user?.features || []);
	return features.includes(feature);
}

/* checking feature without accountId, valid for all users no matter if logged in (from state.auth?.user) or not (from state.acl) */
export function useActiveFeature(feature: `${Feature}`) {
	const { features: activeFeatures } = useSelector((state: State) => state.auth?.user || state.acl);
	return activeFeatures.includes(feature);
}

/**
 * @param accountSpecificFeature
 * by checking PricingModelV2 always checks if new pricing system is rolled out yet: isPricingV2RolledOut or Feature.PricingV2RolledOut is active no matter if user logged in or not *
 * new customer doesn't have hasFeature for PricingModelV2 will show PricingModelV2 by default but only shown if rolled out *
 **/
export function useCheckFeature(accountSpecificFeature: `${AccountSpecificFeature}`) {
	const { accountSpecificFeatures } = useSelector((state: State) => state.acl);
	const init = useSelector((state: State) => state.auth.user?.accountSpecificFeatures || []);
	const features = accountSpecificFeatures ?? init;
	const hasFeature = features?.includes(accountSpecificFeature);

	const isPricingV2RolledOut = useActiveFeature(Feature.PricingV2RolledOut);
	const isCheckingPricingV2 = accountSpecificFeature === AccountSpecificFeature.PricingModelV2;
	if (isCheckingPricingV2) return isPricingV2RolledOut && hasFeature;
	return hasFeature;
}

export function useAccountSpecificFeature(accountSpecificFeature: `${AccountSpecificFeature}`) {
	const features: string[] = useSelector(
		(state: State) => state.auth.user?.accountSpecificFeatures || []
	);
	const hasFeature = features?.includes(accountSpecificFeature);
	return hasFeature;
}
