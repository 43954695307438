import React, { useState, useEffect } from 'react';
import { Drawer, InputBase, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FilterProps, FilterTypeEnum, FilterQuery, AnchorEnum } from './filters.types';
import { FilterSection } from './FilterSection';
import { makeStyles } from '@mui/styles';
import { Headline3 } from '../Headline3';
import { useMobile } from '../../hooks/useBreakpoints';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
	searchBarContainer: {
		backgroundColor: palette.grey[200],
		borderRadius: '20px',
		width: `calc(100% - ${spacing(2)})`,
		margin: `${spacing(2)} ${spacing(1)}`,
		boxSizing: 'border-box',
		padding: `0 ${spacing(2)}`
	},
	iconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: spacing(3),
		height: spacing(3),
		marginRight: spacing(1)
	},
	searchBarInputContainer: { width: `calc(100% - ${spacing(4)})` },
	closeIcon: {
		position: 'absolute',
		m: 2,
		right: spacing(1.5),
		padding: spacing(1.5),
		height: spacing(6),
		width: spacing(6),
		top: spacing(0.5),
		cursor: 'pointer'
	}
}));

export function getObjKeysTrue(obj: Object) {
	if (!obj) return [];
	return Object.keys(obj).filter((k) => obj[k] === true);
}

export const Filter = ({
	isChangingFilter,
	side,
	textSearch,
	filters,
	onChange: onChangeEmitter,
	drawerHeadline,
	updateFilters
}: FilterProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const [filterState, _setFilterState] = useState<Partial<FilterQuery>>({});
	const [compMounted, setCompMounted] = useState(false);
	// this is used to not trigger the onChangeEmitter on initial render
	const setFilterState = (newState: Partial<FilterQuery>, triggerEmitter = true) => {
		const tmp = _.clone(newState);
		_setFilterState(newState);
		if (triggerEmitter) {
			const stateToEmit = formatFilterQuery(tmp);
			onChangeEmitter(stateToEmit);
		}
	};
	const [textSearchState, setTextSearchState] = useState<string>('');
	const updateTextSearch = (value: string) => {
		setTextSearchState(value);
		setFilterState({ ...filterState, [textSearch.filterKey]: { value } });
	};

	useEffect(() => {
		if (!compMounted) createInitialFilterState();
	}, [filters]);

	useEffect(() => {
		createInitialFilterState();
	}, [updateFilters]);

	function createInitialFilterState() {
		const initialFilterState = {};

		for (const filterItem of filters) {
			if (filterItem.filterType === FilterTypeEnum.SECTION_HEADER) {
				continue;
			}
			if (filterItem.filterType === FilterTypeEnum.CHIP) {
				const values = filterItem.defaultValue;
				if (values?.length) {
					for (const value of values) {
						initialFilterState[filterItem.filterKey] = {
							...initialFilterState[filterItem.filterKey],
							[value]: true
						};
					}
				}
				continue;
			}
			if (filterItem.defaultValue) {
				initialFilterState[filterItem.filterKey] = {
					[filterItem.defaultValue]: true
				};
			} else {
				initialFilterState[filterItem.filterKey] = {};
			}
		}
		initialFilterState[textSearch.filterKey] = { value: textSearch.defaultValue || '' };
		setTextSearchState(textSearch.defaultValue || '');
		setCompMounted(true);
		setFilterState(initialFilterState, false);
	}

	function formatFilterQuery(_filterState): FilterQuery {
		// @ts-ignore
		const stateToEmit: FilterQuery = {};
		for (const [key, value] of Object.entries(_filterState)) {
			// @ts-ignore
			const filter = filters.find((predicate) => predicate?.filterKey === key);
			if (filter?.filterType === FilterTypeEnum.CHIP) {
				stateToEmit[key] = getObjKeysTrue(value);
			} else {
				stateToEmit[key] = getObjKeysTrue(value)?.[0];
			}
		}
		if (_filterState[textSearch.filterKey]) {
			stateToEmit[textSearch.filterKey] = _filterState[textSearch.filterKey].value;
		}
		return stateToEmit;
	}

	const removeSelectedFilter = (filterKey: string) => {
		const newFilterState = { ...filterState, [filterKey]: {} };
		setFilterState(newFilterState);
	};

	const updateFilterState = (filterKey: string, value: string | number) => {
		if (!value) return removeSelectedFilter(filterKey);
		const newValue = {
			...filterState[filterKey],
			[value]: !filterState[filterKey]?.[value]
		};
		const newFilterState = { ...filterState, [filterKey]: newValue };
		setFilterState(newFilterState);
	};

	const [toggleDrawerState, setToggleDrawerState] = useState<null | AnchorEnum>(null);
	const toggleDrawer = (anchor: AnchorEnum) => (event) => {
		if (event.type === 'keydown' && ['Tab', 'Shift'].includes(event)) return;
		setToggleDrawerState(anchor);
	};
	const isMobile = useMobile();

	return (
		<React.Fragment key={side}>
			{textSearch && (
				<div>
					<div className={classes.searchBarContainer}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%'
							}}
						>
							<span>
								<div className={classes.iconContainer}>
									<SearchIcon />
								</div>
							</span>
							<span className={classes.searchBarInputContainer}>
								<InputBase
									id="order_filters_field_general_search"
									onChange={(e) => updateTextSearch(e.currentTarget.value)}
									placeholder={textSearch.placeholder || t('general:search')}
									value={textSearchState}
									sx={{ width: '100%' }}
									inputProps={{ 'aria-label': 'Search' }}
								/>
							</span>
							{isMobile && (
								<span
									onClick={toggleDrawer(side)}
									style={{
										cursor: 'pointer'
									}}
								>
									<div className={classes.iconContainer}>
										<TuneIcon />
									</div>
								</span>
							)}
						</div>
					</div>
				</div>
			)}
			{isMobile ? (
				<Drawer
					anchor={side}
					open={Object.values(AnchorEnum).includes(toggleDrawerState)}
					onClose={toggleDrawer(null)}
					sx={{ zIndex: 1300 }}
					PaperProps={{ sx: { width: '20rem' } }}
				>
					<CloseIcon onClick={toggleDrawer(null)} className={classes.closeIcon} />
					{drawerHeadline && <Headline3 sx={{ m: 2 }}>{drawerHeadline}</Headline3>}

					<FilterSection
						filters={filters}
						filterState={filterState}
						updateFilterState={updateFilterState}
						disabledFilter={isChangingFilter}
						sx={{ flexDirection: 'column', mb: 2 }}
					/>
				</Drawer>
			) : (
				<FilterSection
					filters={filters}
					filterState={filterState}
					updateFilterState={updateFilterState}
					disabledFilter={isChangingFilter}
				/>
			)}
		</React.Fragment>
	);
};
