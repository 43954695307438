import i18next from 'i18next';
import qs from 'qs';
import { Callback } from '../../../app/lib/types';
import { api } from '../../lib/api';
import { createConstants } from '../../lib/createConstants';
import { showNotification } from '../actions';
import { State } from '../reducers';

export const constants = createConstants(['CHECK_TOKEN', 'CREATE_ORDER_REPLY']);

export const checkToken = (callback: Callback = () => null) => (dispatch) => {
	const { token } = qs.parse(location.search, {
		ignoreQueryPrefix: true
	});
	return dispatch({
		type: constants.CHECK_TOKEN,
		payload: api
			.get(`/api-ext/v1/orders/checkToken/${token}`)
			.then((data) => {
				callback(null, data);
				return data;
			})
			.catch((err) => {
				callback(err);
				throw err;
			})
	});
};

export const createOrderReply = (reply, successCallback) => (dispatch, getState: () => State) => {
	return dispatch({
		type: constants.CREATE_ORDER_REPLY,
		payload: api
			.post(`/api-ext/v1/orders/${getState().orderReply.order.id}/comments/reply`, {
				...reply,
				accountId: getState().orderReply.account.id
			})
			.then(() => {
				successCallback();
			})
			.catch((err) => {
				dispatch(showNotification(i18next.t('general:generic_error'), 'error'));
				throw err;
			})
	});
};
