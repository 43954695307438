import { SRUser, SRAccount } from '@api/sysadmin';
import { ActiveRestrictionsCount, ResponseCustomerSysadmin } from '@api/v1';
import { asyncConstants } from './actions';
import { combineReducers } from 'redux';

export interface UsersState {
	usersList: SRUser[];
	account: SRAccount;
	activeRestrictions: ActiveRestrictionsCount;
	customersList: ResponseCustomerSysadmin[];
}

export const reducers = combineReducers<UsersState>({
	usersList: (state = null, action) => {
		switch (action.type) {
			case asyncConstants.GET_USERS.PENDING:
				return null;
			case asyncConstants.GET_USERS.FULFILLED:
				return action.payload;
			default:
				return state;
		}
	},
	customersList: (state = null, action) => {
		switch (action.type) {
			case asyncConstants.UPDATE_CUSTOMER_INTEGRATION_IDS.PENDING:
			case asyncConstants.GET_CUSTOMERS.PENDING:
				return null;
			case asyncConstants.GET_CUSTOMERS.FULFILLED:
				return action.payload;
			case asyncConstants.UPDATE_CUSTOMER_INTEGRATION_IDS.FULFILLED:
				const newState = action.payload.currentState;
				const indexOfTargetObject = newState.findIndex((c) => c.id === action.payload.data.id);
				newState[indexOfTargetObject] = action.payload.data;
				return newState;
			default:
				return state;
		}
	},
	account: (state = null, action) => {
		switch (action.type) {
			case asyncConstants.GET_ACCOUNT.PENDING:
				return null;
			case asyncConstants.GET_ACCOUNT.FULFILLED:
				return action.payload;
			case asyncConstants.CREATE_API_TOKEN.PENDING:
				return state;
			case asyncConstants.CREATE_API_TOKEN.FULFILLED:
				return { ...state, apiAccessToken: action.payload?.apiAccessToken };
			default:
				return state;
		}
	},
	activeRestrictions: (state = null, action) => {
		switch (action.type) {
			case asyncConstants.GET_ACTIVE_RESTRICTIONS_COUNT.PENDING:
				return null;
			case asyncConstants.GET_ACTIVE_RESTRICTIONS_COUNT.FULFILLED:
				return action.payload;
			default:
				return state;
		}
	}
});
