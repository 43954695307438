import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Stack,
	Typography,
	useTheme
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Headline5 } from '../../../mui-custom/Headline5';
import { ExpandMore } from '@mui/icons-material';
import { useMobile } from '../../../hooks/useBreakpoints';

export const FAQsSection: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const { palette } = useTheme();
	const isMobile = useMobile();
	const FAQs = [
		{
			summary: t('registration:starter_page_product_faq_1_headline'),
			text: t('registration:starter_page_product_faq_1_text')
		},
		{
			summary: t('registration:starter_page_product_faq_2_headline'),
			text: t('registration:starter_page_product_faq_2_text')
		},
		{
			summary: t('registration:starter_page_product_faq_3_headline'),
			text: t('registration:starter_page_product_faq_3_text')
		}
	];

	return (
		<Stack sx={{ alignItems: 'center', gap: 4, py: 4 }}>
			<Stack sx={{ alignItems: 'center', gap: 1 }}>
				<Typography color={palette.primary.main}>
					{t('registration:starter_page_FAQ').toUpperCase()}
				</Typography>
				<Headline5>{t('registration:starter_page_FAQ_headline')}</Headline5>
			</Stack>

			<Stack
				width={isMobile ? '90%' : '37.5rem'} /* 600px as in the design  could be 40 rem = 640px */
			>
				{FAQs.map(({ summary, text }, id) => (
					<Accordion key={summary} defaultExpanded={id === 0}>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls={`FAQ-${id + 1}`}
							id={`FAQ-${id + 1}`}
						>
							<Typography>{summary}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>{text}</Typography>
						</AccordionDetails>
					</Accordion>
				))}
			</Stack>
		</Stack>
	);
};
