import { ResponseCustomer, CustomerListResponse, UICustomerStatistics } from '@api/v1';
import { combineReducers } from 'redux';
import { asyncConstants, constants, loadCustomers } from './actions';
import { createReducer } from '../../redux/createReducer';

export interface CustomersState {
	list: CustomerListResponse;
	editDialog: CustomerEditDialogState;
	addDialog: CustomerAddDialogState;
	isUpgradeDialogOpen: boolean;
	statistics: UICustomerStatistics;
}

export interface CustomerEditDialogState {
	open: boolean;
	customer: ResponseCustomer;
}
export interface CustomerAddDialogState {
	open: boolean;
}

export const CustomersReducers = combineReducers<CustomersState>({
	list: createReducer<CustomerListResponse>({
		data: null,
		metaData: null,
		possibleAutoAssignUsers: null
	}).handleAction(loadCustomers.success, (state, action) => action.payload),
	editDialog: combineReducers<CustomerEditDialogState>({
		open: (state = false, action) => {
			switch (action.type) {
				case asyncConstants.EDIT_CUSTOMER.FULFILLED:
				case constants.CLOSE_EDIT_DIALOG:
					return false;
				case constants.OPEN_EDIT_DIALOG:
					return true;
				default:
					return state;
			}
		},
		customer: (state = null, action) => {
			switch (action.type) {
				case constants.OPEN_EDIT_DIALOG:
					return action.payload;
				case constants.SET_INTERNAL_ID:
					return { ...state, internalId: action.payload };
				case constants.SET_AUTO_ASSIGNEE_ID:
					return { ...state, autoAssignUserId: action.payload };
				default:
					return state;
			}
		}
	}),
	addDialog: combineReducers<CustomerAddDialogState>({
		open: (state = false, action) => {
			switch (action.type) {
				case constants.CLOSE_ADD_DIALOG:
					return false;
				case constants.OPEN_ADD_DIALOG:
					return true;
				default:
					return state;
			}
		}
	}),
	isUpgradeDialogOpen: (state = false, action) => {
		switch (action.type) {
			case constants.OPEN_UPGRADE_DIALOG:
				return true;
			case constants.CLOSE_UPGRADE_DIALOG:
				return false;
			default:
				return state;
		}
	},
	statistics: (state = {}, action) => {
		switch (action.type) {
			case asyncConstants.FETCH_STATISTICS.FULFILLED:
				return action.payload;
			default:
				return state;
		}
	}
});
