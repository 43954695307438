import * as React from 'react';
import { Theme, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import cx from 'classnames';

const useStyles = makeStyles<Theme, { backgroundImage: string; isUploading: boolean }>((theme) => ({
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		fontSize: '0.75rem'
	},
	large: {
		width: theme.spacing(12),
		height: theme.spacing(12),
		fontSize: '2.75rem',
		fontWeight: 500,
		border: `1px solid ${theme.palette.grey[300]}`,
		backgroundColor: theme.palette.grey[300]
	},
	changeableImage: (props) => ({
		backgroundColor: props.backgroundImage ? '#ffffff' : theme.palette.grey[300],
		backgroundSize: '100px auto',
		backgroundImage: `url(${props.backgroundImage})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		opacity: props.isUploading ? 0.5 : 1
	})
}));

interface AvatarProps extends Omit<MuiAvatarProps, 'classes'> {
	size?: 'normal' | 'small' | 'large';
	backgroundImage?: string;
	isUploading?: boolean;
}

export function Avatar(props: AvatarProps) {
	const { size, children, className, backgroundImage, isUploading, ..._props } = props;
	const classes = useStyles({ backgroundImage, isUploading });
	const _size = size || 'normal';
	return (
		<MuiAvatar
			className={cx(
				{
					[classes.small]: _size === 'small',
					[classes.large]: _size === 'large'
				},
				{
					[classes.changeableImage]: backgroundImage
				},
				className
			)}
			{..._props}
		>
			{!backgroundImage && !isUploading && children}
			{isUploading && <CircularProgress />}
		</MuiAvatar>
	);
}
