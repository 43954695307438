import axios, { AxiosRequestConfig } from 'axios';
import { UploadToTempResults } from '@api/v1';
import _ from 'lodash';

export const api = {
	async get<Response = any>(url: string, config?: AxiosRequestConfig): Promise<Response> {
		const { data } = await axios.get<Response>(
			url,
			mergeConfig(_.merge(config, { params: { _: Date.now() } }))
		);
		return data;
	},
	async post<Body = any, Response = any>(
		url: string,
		body?: Body,
		config?: AxiosRequestConfig
	): Promise<Response> {
		const { data } = await axios.post<Response>(url, body, mergeConfig(config));
		return data;
	},
	async put<Body = any, Response = any>(
		url: string,
		body?: Body,
		config?: AxiosRequestConfig
	): Promise<Response> {
		const { data } = await axios.put<Response>(url, body, mergeConfig(config));
		return data;
	},
	async delete<Response = any>(url: string, config?: AxiosRequestConfig): Promise<Response> {
		const { data } = await axios.delete(url, mergeConfig(config));
		return data;
	},
	async patch<Body = any, Response = any>(
		url: string,
		body?: Body,
		config?: AxiosRequestConfig
	): Promise<Response> {
		const { data } = await axios.patch<Response>(url, body, mergeConfig(config));
		return data;
	},
	async uploadFile(
		file: File,
		config?: AxiosRequestConfig,
		casavi = false
	): Promise<UploadToTempResults> {
		const formData = new FormData();
		formData.append('file', file);
		return axios
			.post<UploadToTempResults>(
				`/api/v1/uploads${casavi ? '/casavi' : ''}`,
				formData,
				mergeConfig(config)
			)
			.then(({ data }) => data);
	}
};

function mergeConfig(config: AxiosRequestConfig): AxiosRequestConfig {
	const jwt = window.localStorage.getItem('jwt');
	const preconfig: AxiosRequestConfig = {
		headers: {
			Accept: 'application/json',
			Authorization: jwt ? `Bearer ${jwt}` : undefined
		},
		timeout: 60000,
		timeoutErrorMessage: 'Timeout Error: timeout 60s'
	};
	return _.merge(preconfig, config);
}
