export function getAutoAssignUserLabel(customer, users): string {
	let autoAssignUserLabel = '';
	const autoAssignUser = customer.autoAssignUserId
		? users.find((user) => user.id === customer.autoAssignUserId)
		: null;
	if (autoAssignUser) {
		autoAssignUserLabel = `${autoAssignUser.firstName} ${autoAssignUser.lastName}`;
	}
	return autoAssignUserLabel;
}
