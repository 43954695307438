import makeStyles from '@mui/styles/makeStyles';

export const orderDialogUseStyles = makeStyles((theme) => ({
	container: {
		backdropFilter: 'blur(4px)',
		justifyContent: 'flex-end',
		alignItems: 'inherit'
	},
	paper: {
		maxHeight: '100%',
		margin: theme.spacing(3),
		background: 'white',
		width: `calc(100% - 256px - ${theme.spacing(6)})`,
		borderRadius: '4px'
	},
	paperBigScreenWidth: {
		width: `calc((100% - 256px) / 2 - ${theme.spacing(6)})`
	},
	paperMobile: {
		margin: 0,
		marginTop: theme.spacing(2),
		borderTopLeftRadius: theme.spacing(1),
		borderTopRightRadius: theme.spacing(1),
		width: 'auto',
		maxWidth: 'inherit'
	},
	switch: { alignItems: 'flex-start' },
	dialogActions: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(3)
	},
	dialogHeader: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		color: theme.palette.grey[300]
	},
	progress: {
		alignSelf: 'stretch',
		height: theme.spacing(1),
		backgroundColor: theme.palette.grey[100],
		'& > div': {
			backgroundColor: theme.palette.grey[400]
		}
	},
	addCustomerLink: {
		width: '100%',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
		textDecoration: 'none',
		textTransform: 'uppercase',
		fontSize: 'small'
	},
	divider: {
		margin: theme.spacing(2)
	},
	goBackFab: {
		marginLeft: theme.spacing(3)
	},
	internalInfo: {
		display: 'flex',
		alignItems: 'center',
		'& > *:first-child': {
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2)
		}
	},
	autocompleteOption: {
		'&[data-focus="true"]:last-child': {
			background: 'white'
		},
		'&[aria-selected="true"]:last-child': {
			background: 'white'
		}
	},
	attachmentName: {
		marginLeft: theme.spacing(1)
	},
	dateDeleteButton: {
		marginTop: theme.spacing(1)
	}
}));
