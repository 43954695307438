import {
	BOrderComment,
	UIEmployee,
	UIOrder,
	UIOrderComment,
	UIOrderStatus,
	OrderPatchRequest,
	UIOrdersList,
	UIOrderStatistics,
	CreateOrderBody,
	UICustomer
} from '@api/v1';
import { History } from 'history';
import { pickBy } from 'lodash';
import moment from 'moment-with-locales-es6';
import qs from 'qs';
import { AnyAction } from 'redux';
import { call, debounce, put, select } from 'redux-saga/effects';
import {
	createAddFileAction,
	createChangeFileAction,
	createRemoveFileAction
} from '../../components/Dropzone';
import { i18n } from '../../i18n';
import { api } from '../../lib/api';
import { createAsyncConstants, createConstants } from '../../lib/createConstants';
import { showNotification } from '../actions';
import { history } from '../history';
import { State } from '../reducers';
import { getSocket } from '../socket';
import { FiltersState } from './reducers';
import { createStandardAction, createAction } from 'typesafe-actions';
import { createApiAction } from '../../redux/createApiAction';
import { Moment } from 'moment';
import { ThunkDispatch } from 'redux-thunk';
import { FiltersFromUrl } from 'app/mui-custom/Filter/filters.types';
import { loadAccountUsedOrderCredits } from '../admin/account/actions';

const LIMIT_PER_PAGE = 15;

export const asyncConstants = createAsyncConstants(['FETCH'], { prefix: 'ORDERS' });

export const constants = createConstants([
	'SET_STATUS_FILTER',
	'SET_ASSIGNEE_FILTER',
	'SET_CUSTOMER_FILTER',
	'SET_POSTAL_CODE_FILTER',
	'SET_IDENTIFIER_FILTER',
	'OPEN_FILTER_DIALOG',
	'CLOSE_FILTER_DIALOG',
	'OPEN_MAKE_EXTERNAL_DIALOG',
	'CLOSE_MAKE_EXTERNAL_DIALOG',
	'FETCH_ORDER_LIST',
	'FETCH_ORDER_STATISTICS',
	'FETCH_ORDER',
	'CHANGE_COMMENT_TEXT',
	'ADD_COMMENT_FILE',
	'CHANGE_COMMENT_FILE',
	'REMOVE_COMMENT_FILE',
	'OPEN_COMMENT_FILE_DIALOG',
	'CLOSE_COMMENT_FILE_DIALOG',
	'CREATE_ORDER_COMMENT',
	'CREATE_ORDER',
	'OPEN_FINISH_DIALOG',
	'CLOSE_FINISH_DIALOG',
	'OPEN_ADD_DIALOG',
	'CLOSE_ADD_DIALOG',
	'CHANGE_FINISH_COMMENT',
	'ADD_FINISH_FILE',
	'CHANGE_FINISH_FILE',
	'DELETE_FINISH_FILE',
	'FINISH_ORDER',
	'SET_FULL_TEXT_FILTER',
	'SET_FILTERS_COLLAPSED',
	'SET_FILTERS',
	'SET_STREET_FILTER',
	'SET_LOCALITY_FILTER',
	'SET_HAS_MORE',
	'SET_IS_LOADING',
	'REMOVE_ALL_COMMENT_FILES',
	'SWITCH_INTERNAL_NOTE',
	'FETCH_UNREAD_NOTIFICATIONS_COUNT',
	'ADD_ORDER_FILE',
	'CHANGE_ORDER_FILE',
	'REMOVE_ORDER_FILE',
	'REMOVE_ALL_ORDER_FILES',
	'SET_ZIPPED_ATTACHMENTS_URL',
	'OPEN_EDIT_ORDER_DIALOG',
	'CLOSE_EDIT_ORDER_DIALOG'
]);

interface FetchOrdersOptions {
	status?: UIOrderStatus | 'ALL';
	assigneeId?: number;
	customerId?: number;
	postalCode?: string;
	identifier?: string;
	searchString?: string;
	street?: string;
	locality?: string;
	limit?: number;
	afterId?: number;
}

function getLoadOrdersParams(options: FetchOrdersOptions, { orders }: State): FetchOrdersOptions {
	const { assignee, customer, status, street, postalCode, locality, identifier, fullText } =
		orders.filters;
	return {
		assigneeId: assignee?.id,
		customerId: customer?.id,
		status,
		street,
		postalCode,
		locality,
		identifier,
		searchString: fullText,
		limit: LIMIT_PER_PAGE,
		...options
	};
}

/* remove this redundant action later after testing */
export function loadOrders(filters: FetchOrdersOptions) {
	return {
		type: constants.FETCH_ORDER_LIST + '_PENDING',
		payload: filters
	};
}

export const loadOrdersV2 = (filters: FetchOrdersOptions) => (dispatch, getState: () => State) => {
	const params = getLoadOrdersParams(filters, getState());
	dispatch({
		type: asyncConstants.FETCH.TYPE,
		payload: api.get('/api/v1/orders', { params })
	});
};

function* _loadOrders(filters: AnyAction) {
	const state = yield select();
	const params = getLoadOrdersParams(filters.payload, state);
	try {
		const results = yield call(api.get, '/api/v1/orders', { params });
		yield put({
			type: constants.FETCH_ORDER_LIST + '_FULFILLED',
			payload: results
		});
	} catch (err) {
		yield put({
			type: constants.FETCH_ORDER_LIST + '_REJECTED',
			payload: err
		});
	}
}
export function* loadOrdersSaga() {
	yield debounce(500, constants.FETCH_ORDER_LIST + '_PENDING', _loadOrders);
}

export const fetchMoreOrders = () => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_IS_LOADING,
		payload: true
	});
	return setTimeout(() => dispatch(appendOrdersToList({})), 1000);
};

export const toggleFilterCollapse = () => (dispatch, getState: () => State) => {
	const { collapsed } = getState().orders.filters;

	dispatch({
		type: constants.SET_FILTERS_COLLAPSED,
		payload: !collapsed
	});
};

export const setFullTextFilter = (searchString: string) => (dispatch) => {
	dispatch({
		type: constants.SET_FULL_TEXT_FILTER,
		payload: searchString
	});
	dispatch(fetchOrderStatistics());
	dispatch(loadOrders({ searchString }));
};

export const setStreetFilter = (street: string) => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_STREET_FILTER,
		payload: street
	});
	setFiltersInURL(getState().orders.filters);
	dispatch(fetchOrderStatistics());
	dispatch(loadOrders({ street }));
};

export const setLocalityFilter = (locality: string) => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_LOCALITY_FILTER,
		payload: locality
	});
	setFiltersInURL(getState().orders.filters);
	dispatch(fetchOrderStatistics());
	dispatch(loadOrders({ locality }));
};

export const setFilters = (filters: Partial<FiltersState>) => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_FILTERS,
		payload: filters
	});
	const hasInitialFilters = setFiltersInURL(getState().orders.filters);
	dispatch({
		type: constants.SET_FILTERS_COLLAPSED,
		payload: hasInitialFilters
	});
	dispatch(fetchOrderStatistics());
	dispatch(loadOrders(getState().orders.filters));
};

export const setFiltersV2 =
	(filters: Partial<FiltersState>) => (dispatch, getState: () => State) => {
		dispatch({
			type: constants.SET_FILTERS,
			payload: filters
		});
		const hasInitialFilters = setFiltersInURL(filters);
		dispatch({
			type: constants.SET_FILTERS_COLLAPSED,
			payload: hasInitialFilters
		});
		dispatch(fetchOrderStatistics());
		dispatch(loadOrdersV2(filters));
	};

/* remove this action later after testing */
export const setStatusFilter =
	(status: UIOrderStatus, navigateTo?: string) => (dispatch, getState: () => State) => {
		dispatch({
			type: constants.SET_STATUS_FILTER,
			payload: status
		});
		setFiltersInURL(getState().orders.filters, { navigateTo });
		dispatch(loadOrders({ status }));
	};
export const setAssigneeFilter = (assignee: UIEmployee) => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_ASSIGNEE_FILTER,
		payload: assignee
	});
	dispatch(fetchOrderStatistics());
	setFiltersInURL(getState().orders.filters);
	dispatch(loadOrders({ assigneeId: assignee ? assignee.id : null }));
};
export const setCustomerFilter = (customer: UICustomer) => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_CUSTOMER_FILTER,
		payload: customer
	});
	dispatch(fetchOrderStatistics());
	setFiltersInURL(getState().orders.filters);
	dispatch(loadOrders({ customerId: customer ? customer.id : null }));
};

export const updateFilters = (query: Partial<FetchOrdersOptions>) => (dispatch) => {
	setFiltersInURL(query);
	dispatch(loadOrders(query));
};
export const setPostalCodeFilter = (postalCode: string) => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_POSTAL_CODE_FILTER,
		payload: postalCode
	});
	setFiltersInURL(getState().orders.filters);
	dispatch(fetchOrderStatistics());
	dispatch(loadOrders({ postalCode }));
};
export const setIdentifierFilter = (identifier: string) => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_IDENTIFIER_FILTER,
		payload: identifier
	});
	const validatedIdentifier = identifier.replace('#', '');
	setFiltersInURL({
		...getState().orders.filters,
		identifier: validatedIdentifier
	});
	dispatch(fetchOrderStatistics());
	dispatch(loadOrders({ identifier: validatedIdentifier }));
};
export const openFilterDialog = () => ({
	type: constants.OPEN_FILTER_DIALOG
});
export const closeFilterDialog = () => ({
	type: constants.CLOSE_FILTER_DIALOG
});
export const openAddDialog = () => (dispatch) =>
	dispatch({
		type: constants.OPEN_ADD_DIALOG
	});
export const closeAddDialog = () => (dispatch) =>
	dispatch({
		type: constants.CLOSE_ADD_DIALOG
	});
export const openMakeExternalDialog = () => (dispatch) =>
	dispatch({
		type: constants.OPEN_MAKE_EXTERNAL_DIALOG
	});
export const closeMakeExternalDialog = () => (dispatch) =>
	dispatch({
		type: constants.CLOSE_MAKE_EXTERNAL_DIALOG
	});

export const appendOrdersToList = createApiAction(
	'orders/append_to_list',
	(options: FetchOrdersOptions = {}, dispatch, getState: () => State) => {
		const params = getLoadOrdersParams(
			{
				...options,
				afterId: getState().orders.list.metaData.lastId
			},
			getState()
		);
		return api.get<UIOrdersList>('/api/v1/orders', { params });
	}
);

export const fetchOrderStatistics = () => (dispatch, getState: () => State) => {
	const { status, limit, ...params } = getLoadOrdersParams({}, getState());
	return dispatch({
		type: constants.FETCH_ORDER_STATISTICS,
		payload: api.get<UIOrderStatistics>('/api/v1/orders/statistics', {
			params
		})
	});
};

export const fetchUnreadNotificationsCount = () => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.FETCH_UNREAD_NOTIFICATIONS_COUNT,
		payload: api.get<UIOrderStatistics>('/api/v1/notifications/count')
	});
};

export const fetchOrder = (id: number) => (dispatch) =>
	dispatch({
		type: constants.FETCH_ORDER,
		payload: api
			.get(`/api/v1/orders/${id}`)
			.then((order) => {
				dispatch(fetchUnreadNotificationsCount());
				return order;
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			})
	});
export const openCommentFileDialog = () => ({
	type: constants.OPEN_COMMENT_FILE_DIALOG
});
export const closeCommentFileDialog = () => ({
	type: constants.CLOSE_COMMENT_FILE_DIALOG
});
export const addCommentFile = createAddFileAction(constants.ADD_COMMENT_FILE);
export const changeCommentFile = createChangeFileAction(constants.CHANGE_COMMENT_FILE);
export const removeCommentFile = createRemoveFileAction(constants.REMOVE_COMMENT_FILE);
export const removeAllCommentFiles = () => ({
	type: constants.REMOVE_ALL_COMMENT_FILES
});
export const changeCommentText = (text: string) => (dispatch, getState: () => State) =>
	dispatch({
		type: constants.CHANGE_COMMENT_TEXT,
		payload: text,
		meta: getState().orders.single.order.id
	});
export const onInternalNoteSwitch = (toggle) => (dispatch) =>
	dispatch({
		type: constants.SWITCH_INTERNAL_NOTE,
		payload: toggle
	});
export const postComment = (text: string) => (dispatch, getState: () => State) =>
	dispatch({
		type: constants.CREATE_ORDER_COMMENT,
		payload: api.post<BOrderComment, UIOrderComment>(
			`/api/v1/orders/${getState().orders.single.order.id}/comments/`,
			{
				text,
				isInternal: getState().orders.single.commentCompose.isInternal,
				files: getState().orders.single.commentCompose.files
			}
		),
		meta: getState().orders.single.order.id
	});
export const createOrder = (order: CreateOrderBody) => (dispatch, getState: () => State) =>
	dispatch({
		type: constants.CREATE_ORDER,
		payload: api.post<CreateOrderBody, { id: string }>('/api/v1/orders', order)
	})
		.then(() => {
			dispatch(fetchOrderStatistics());
			dispatch(loadOrders(getState().orders.filters));
			dispatch(closeAddDialog());
			dispatch(
				showNotification(
					i18n.t(
						order.isInternal
							? 'orders:add_dialog_creation_success_internal'
							: 'orders:add_dialog_creation_success_external'
					),
					'success'
				)
			);
		})
		.catch((err) => {
			dispatch(showNotification(i18n.t('general:default_error'), 'error'));
			throw err;
		});
export const addOrderFile = createAddFileAction(constants.ADD_ORDER_FILE);
export const changeOrderFile = createChangeFileAction(constants.CHANGE_ORDER_FILE);
export const removeOrderFile = createRemoveFileAction(constants.REMOVE_ORDER_FILE);
export const removeAllOrderFiles = () => ({
	type: constants.REMOVE_ALL_ORDER_FILES
});
export const openAcceptDialog = createAction('orders/open_accept_dialog');
export const closeAcceptDialog = createAction('orders/close_accept_dialog', (action) => {
	removeActionFromURL(history);
	return () => action();
});
export const changeAcceptComment = createStandardAction('orders/change_accept_comment')<string>();
export const accept = createApiAction(
	'orders/accept_order',
	(a: void, dispatch, getState: () => State) => {
		const orderId = getState().orders.single.order.id;
		return api
			.patch<BOrderComment>(`/api/v1/orders/${orderId}/status/progress`, {
				text: getState().orders.single.acceptDialog.comment
			})
			.then(() => {
				removeActionFromURL(history);
				if (getState().orders.single.order.id === orderId) {
					dispatch(loadAccountUsedOrderCredits());
					dispatch(fetchOrder(orderId));
					dispatch(loadOrders(getState().orders.filters));
				}
				dispatch(fetchOrderStatistics());
				return orderId;
			});
	}
);
export const openDeclineDialog = createAction('orders/open_decline_dialog');
export const closeDeclineDialog = createAction('orders/close_decline_dialog', (action) => {
	removeActionFromURL(history);
	return () => action();
});
export const changeDeclineComment = createStandardAction('orders/change_decline_comment')<string>();
export const decline = createApiAction(
	'orders/decline',
	(a: void, dispatch, getState: () => State) => {
		return api
			.patch<BOrderComment>(`/api/v1/orders/${getState().orders.single.order.id}/status/declined`, {
				text: getState().orders.single.declineDialog.comment
			})
			.then((orderId) => {
				removeActionFromURL(history);
				if (getState().orders.single.order.id === orderId) {
					dispatch(fetchOrder(orderId));
				}
				dispatch(fetchOrderStatistics());
				dispatch(loadOrders(getState().orders.filters));
				return orderId;
			});
	}
);
export const openFinishDialog = () => ({
	type: constants.OPEN_FINISH_DIALOG
});
export const closeFinishDialog = () => ({
	type: constants.CLOSE_FINISH_DIALOG
});
export const changeFinishComment = (text: string) => ({
	type: constants.CHANGE_FINISH_COMMENT,
	payload: text
});
export const addFinishFile = createAddFileAction(constants.ADD_FINISH_FILE);
export const changeFinishFile = createChangeFileAction(constants.CHANGE_FINISH_FILE);
export const removeFinishFile = createRemoveFileAction(constants.DELETE_FINISH_FILE);
export const finish =
	() => (dispatch: ThunkDispatch<State, undefined, AnyAction>, getState: () => State) =>
		dispatch({
			type: constants.FINISH_ORDER,
			payload: api
				.patch<BOrderComment>(
					`/api/v1/orders/${getState().orders.single?.order?.id}/status/finished`,
					{
						files: getState().orders.single?.finishDialog?.files,
						text: getState().orders.single?.finishDialog?.comment
					}
				)
				.then((orderId) => {
					if (getState().orders.single?.order?.id === orderId) {
						dispatch(fetchOrder(orderId));
					}
					dispatch(fetchOrderStatistics());
					dispatch(loadOrders(getState().orders.filters));

					return orderId;
				})
				.catch((err) => {
					dispatch(showNotification(i18n.t('general:default_error'), 'error'));
					throw err;
				})
		});
export const assignOrder = createApiAction(
	'orders/assign',
	async (employee: UIEmployee, dispatch, getState: () => State) => {
		const orderId = getState().orders.single.order.id;

		return api.patch<OrderPatchRequest, UIOrder>(`/api/v1/orders/${orderId}`, {
			assigneeId: employee?.id || null,
			followUpDate: getState().orders.single.order?.followUpDate,
			internalId: getState().orders.single.order?.internalId,
			text: i18n.t('orders:comment_assignee_change', {
				name: `${getState().auth.user.firstName} ${getState().auth.user.lastName}`,
				assignee: employee ? `${employee.firstName} ${employee.lastName}` : null
			})
		});
	}
);
export const openInternalIdDialog = createAction('orders/open_internal_id_dialog');
export const saveInternalId = createApiAction(
	'orders/save_internal_id',
	(a: void, dispatch, getState: () => State) => {
		const orderId = getState().orders.single.order.id;
		const name = `${getState().auth.user.firstName} ${getState().auth.user.lastName}`;
		return api
			.patch<OrderPatchRequest, UIOrder>(`/api/v1/orders/${orderId}`, {
				internalId: getState().orders.single.internalIdDialog.internalId,
				followUpDate: getState().orders.single.order.followUpDate,
				assigneeId: getState().orders.single.order.assignee?.id,
				text: `${name}, `
			})
			.then((order) => {
				removeActionFromURL(history);
				if (getState().orders.single.order.id === orderId) {
					dispatch(fetchOrder(orderId));
				}
				dispatch(fetchOrderStatistics());
				return order;
			});
	}
);
export const closeInternalIdDialog = createAction('orders/close_internal_id_dialog', (action) => {
	removeActionFromURL(history);
	return () => action();
});
export const changeInternalId = createStandardAction('orders/change_internal_id')<string>();
export const openTitleDialog = createStandardAction('orders/open_title_dialog')<boolean>();
export const saveTitle = createApiAction(
	'orders/save_title',
	async (_a: void, _dispatch, getState: () => State) => {
		const { order, titleDialog } = getState().orders.single;
		const { id, internalId, followUpDate, assignee } = order;
		const { firstName, lastName } = getState().auth.user;
		const title = titleDialog.value || order.title;
		const name = `${firstName} ${lastName}`;
		return api.patch<OrderPatchRequest, UIOrder>(`/api/v1/orders/${id}`, {
			title,
			internalId,
			followUpDate,
			assigneeId: assignee?.id,
			text: i18n.t('orders:comment_title_change', { name, title })
		});
	}
);
export const closeTitleDialog = createAction('orders/close_title_dialog', (action) => {
	removeActionFromURL(history);
	return () => action();
});

export const changeTitle = createStandardAction('orders/change_title')<string>();
export const printOrder = () => (dispatch, getState: () => State) => {
	const orderId = getState().orders.single.order.id;
	api.post(`/api/v1/orders/${orderId}/actions/print`, {});
	window.print();
};
export const setDueDateDialogOpen = createStandardAction('orders/open_due_date_dialog')<boolean>();
export const changeDueDate = createApiAction(
	'orders/change_due_date',
	(date: Moment, dispatch, getState: () => State) => {
		const orderId = getState().orders.single.order.id;
		const dueDate = date.toISOString();
		return api.patch<OrderPatchRequest, UIOrder>(`/api/v1/orders/${orderId}`, {
			dueDate,
			text: `${getState().auth.user.firstName} ${getState().auth.user.lastName}`
		});
	}
);

export const changeFollowUpDate = createApiAction(
	'orders/change_follow_up_date',
	(date: Moment | null, dispatch, getState: () => State) => {
		const orderId = getState().orders.single.order.id;
		const followUpDate = date ? date.toISOString() : null;
		const name = `${getState().auth.user.firstName} ${getState().auth.user.lastName}`;
		return api.patch<OrderPatchRequest, UIOrder>(`/api/v1/orders/${orderId}`, {
			followUpDate,
			assigneeId: getState().orders.single.order.assignee?.id,
			internalId: getState().orders.single.order?.internalId,
			text: `${name}, `
		});
	}
);

export const convertOrderToExternal = createApiAction(
	'orders/convert_order_to_external',
	(_a: void, dispatch, getState: () => State) => {
		const orderId = getState().orders.single.order.id;
		const createdAt = new Date(Date.now()).toISOString();
		return api
			.patch<OrderPatchRequest, UIOrder>(`/api/v1/orders/${orderId}`, {
				isInternal: false,
				createdAt,
				text: i18n.t('orders:comment_is_internal_change', {
					name: `${getState().auth.user.firstName} ${getState().auth.user.lastName}`,
					creationDate: moment(createdAt).format('DD.MM.YYYY')
				})
			})
			.then((order) => {
				if (getState().orders.single.order.id === orderId) {
					dispatch(fetchOrder(orderId));
				}
				dispatch(loadOrders(getState().orders.filters));
				dispatch(fetchOrderStatistics());
				dispatch(closeMakeExternalDialog());
				dispatch(
					showNotification(i18n.t('orders:add_dialog_creation_success_external'), 'success')
				);
				return order;
			})
			.catch((_error) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
			});
	}
);

interface SetFiltersInURLOptions {
	navigateTo?: string;
}

// Returns true if filters other than status have been set
export function setFiltersInURL(
	filters: Partial<FiltersState>,
	options?: SetFiltersInURLOptions
): boolean {
	const pickedFilters = pickBy(filters, (val, key) => {
		return key !== 'collapsed' && key !== 'fullText' && key !== 'dialogOpen';
	});
	modifyHistory(history, {
		pathname: options?.navigateTo,
		search: { mode: 'merge', data: pickedFilters as Partial<FiltersFromUrl> },
		mode: 'replace'
	});
	if (Object.keys(pickedFilters).filter((val) => val !== 'status').length) {
		return true;
	}

	return false;
}

function removeActionFromURL(_history: History) {
	modifyHistory(history, {
		mode: 'replace',
		search: { data: { action: undefined }, mode: 'merge' }
	});
}

type HistoryModes = 'replace' | 'push';
interface ModifyHistoryOptions {
	search: ModifySearchOptions;
	pathname?: string;
	mode?: HistoryModes;
}

type SearchModes = 'merge' | 'replace';
export interface ModifySearchOptions {
	mode: SearchModes;
	data: Partial<FiltersFromUrl>;
}

export function modifySearch(
	_history: History,
	options: ModifySearchOptions
): Partial<FiltersFromUrl> {
	const currentSearch = qs.parse(_history.location.search, {
		ignoreQueryPrefix: true
	});
	if (currentSearch.status && typeof currentSearch.status === 'string') {
		currentSearch.status = currentSearch.status.split(',');
	}
	if (options.mode === 'replace') {
		return pickBy(options.data);
	} else {
		return pickBy({ ...currentSearch, ...options.data });
	}
}
function modifyHistory(_history: History, options: ModifyHistoryOptions): void {
	const search = modifySearch(_history, options.search) as any;
	if (search.status && Array.isArray(search.status)) {
		search.status = search.status.join(',');
	}
	switch (options.mode) {
		case 'push':
			return history.push({
				pathname: options.pathname,
				search: qs.stringify(search)
			});
		default:
			return history.replace({
				pathname: options.pathname,
				search: qs.stringify(search)
			});
	}
}
export const startListeningForOrders = () => (dispatch) => {
	const socket = getSocket();
	socket.emit('orders.entered');
	socket.on('order.created', () => {
		dispatch(showNotification(i18n.t('orders:notification_new_order'), 'info'));
	});
};
export const stopListeningForOrders = () => {
	const socket = getSocket();
	socket.emit('orders.exited');
	socket.off('order.created');
};

export async function getStatusForOrder(orderId) {
	return api.get(`/api/v1/orders/${orderId}`);
}

export const getSignedUrlForZippedAttachments = createApiAction(
	'orders/getSignedUrlForZippedAttachments',
	async (_a: void, _dispatch, getState: () => State) => {
		const orderId = getState().orders.single.order.id;
		const signedURL = await api.get(`/api/v1/orders/${orderId}/attachments`);
		if (signedURL.value) {
			window.location.href = signedURL.value;
		}
		return signedURL;
	}
);

export const openEditDialog = () => (dispatch) =>
	dispatch({
		type: constants.OPEN_EDIT_ORDER_DIALOG
	});

export const closeEditDialog = () => (dispatch) =>
	dispatch({
		type: constants.CLOSE_EDIT_ORDER_DIALOG
	});

export const updateOrder = createApiAction(
	'orders/updateOrder',
	async (patchRequest: OrderPatchRequest, dispatch, getState: () => State) => {
		const orderId = getState().orders.single.order.id;
		try {
			const order = await api.patch<OrderPatchRequest, UIOrder>(`/api/v1/orders/${orderId}`, {
				...patchRequest,
				text: i18n.t('orders:comment_order_edited', {
					name: `${getState().auth.user.firstName} ${getState().auth.user.lastName}`
				})
			});
			dispatch(fetchOrder(orderId));
			dispatch(loadOrders(getState().orders.filters));
			dispatch(fetchOrderStatistics());
			dispatch(closeEditDialog());
			dispatch(showNotification(i18n.t('orders:edit_dialog_save_success'), 'success'));
			return order;
		} catch (e) {
			dispatch(showNotification(i18n.t('general:default_error'), 'error'));
		}
	}
);
