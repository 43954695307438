import {
	AppBar,
	Chip,
	ClickAwayListener,
	Grow,
	MenuList,
	Paper,
	Popper,
	Hidden,
	CircularProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Notifications as NotiIcons, Menu } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { fetchMoreNotifications } from '../notifications/actions';
import { State } from '../reducers';
import { NotificationItem } from '../sidebar/NotificationItem';
import clsx from 'clsx';
import { BANNER_HEIGHT } from '../Banner';
import { useMobile } from '../../hooks/useBreakpoints';
import { openSidebar } from '../sidebar/actions';
import { RelayLogo } from '../../components/RelayLogo';
import { SIDEBAR_WIDTH } from '../../mui-custom/Drawer';

export const NAVBAR_HEIGHT = '4rem';

const useStyles = makeStyles((theme) => ({
	navBar: {
		'@media print': {
			display: 'none'
		},
		display: 'flex',
		flexDirection: 'row',
		height: NAVBAR_HEIGHT,
		padding: '0 .75rem',
		justifyContent: 'space-between',
		background: '#ffffff',
		zIndex: 1150, // to move bellow/behind the SideBar with zIndex 1200, but still need to be above/before anything with with zIndex 1100
		borderBottom: `1px solid ${theme.palette.grey[300]}`
	},
	list: {
		display: 'flex',
		alignItems: 'center'
	},
	link: {
		textDecoration: 'none',
		color: 'inherit',
		'& > *': {
			borderRadius: 4,
			padding: '0.75rem 1rem'
		},
		'& > *:hover': {
			background: theme.palette.grey[300]
		}
	},
	icon: {
		minWidth: theme.spacing(2)
	},
	notificationsChip: {
		transform: 'translate(-8px, -8px)',
		backgroundColor: theme.palette.notification.main,
		color: theme.palette.notification.contrastText
	},
	notificationsWrapper: {
		margin: '1rem',
		border: `1px solid ${theme.palette.grey[300]}`,
		backgroundColor: theme.palette.grey[100]
	},
	notificationsScrollContainer: {
		left: theme.spacing(1),
		// three dots button: height: 1.5rem, padding 2*0.75rem
		// margin top & bottom: 2*1rem
		// 0.5rem for the bottom of detail view
		maxHeight: `calc(100vh - ${NAVBAR_HEIGHT} - 1.5rem - 2*0.75rem - 2*1rem - 0.5rem)`,
		overflow: 'auto',
		'& > ul': {
			padding: 0
		}
	},
	showAllLink: {
		bottom: 0,
		textAlign: 'center',
		justifyContent: 'center',
		borderTop: `1px solid ${theme.palette.grey[300]}`,
		borderRadius: '0 0 4px 4px',
		'&:hover': {
			backgroundColor: theme.palette.grey[200]
		}
	},
	showAllLinkIsLoading: {
		pointerEvents: 'none'
	},
	notificationListItem: {
		'&:hover': {
			backgroundColor: theme.palette.grey[300]
		}
	},
	notificationListItemActive: {
		backgroundColor: theme.palette.grey[200]
	},
	notificationsPopper: {
		zIndex: 1100,
		inset: ({ hasBanner }: NavbarAppProps) =>
			`calc(${NAVBAR_HEIGHT} + ${hasBanner ? BANNER_HEIGHT : '0px'}) 0px auto auto !important`,
		transform: 'translate(0,0) !important',
		height: `calc(100vh - ${NAVBAR_HEIGHT})`,
		width: `calc(100% - ${SIDEBAR_WIDTH})`,
		[theme.breakpoints.up('lg')]: {
			// detail view's width = 50% by large screen
			// 0.5rem to divide the gap (1rem) in the middle of list view and detail view
			width: `calc((100% - ${SIDEBAR_WIDTH})/2 + 0.5rem)`
		}
	}
}));

interface NavbarAppProps {
	hasBanner: boolean;
}

export function NavbarApp(props: NavbarAppProps) {
	const { user } = useSelector((state: State) => state.auth);
	const { notifications } = user;
	const dispatch = useDispatch();
	const classes = useStyles(props);
	const location = useLocation();
	const isMobile = useMobile();

	const [notificationsMenuOpen, setNotificationsMenuOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);
	const scrollParentRef = useRef<HTMLDivElement>(null);
	const prevOpen = useRef(notificationsMenuOpen);

	const handleNotificationsMenuClose = ({ target }: MouseEvent<EventTarget>) => {
		if (anchorRef?.current?.contains(target as HTMLElement)) return;
		setNotificationsMenuOpen(false);
	};

	useEffect(() => {
		if (prevOpen.current && !notificationsMenuOpen) anchorRef?.current?.focus();

		prevOpen.current = notificationsMenuOpen;
	}, [notificationsMenuOpen]);

	const notificationListItem = (
		<ListItem
			button
			onClick={() => setNotificationsMenuOpen((o) => !o)}
			ref={anchorRef}
			className={`${classes.notificationListItem} ${
				notificationsMenuOpen || location.pathname.includes('/notifications')
					? classes.notificationListItemActive
					: null
			}`}
			style={{ borderRadius: 4, padding: '0.75rem 1rem' }}
		>
			<ListItemIcon className={classes.icon}>
				<NotiIcons />
			</ListItemIcon>

			{!!user.notifications?.unreadCount && (
				<Chip
					size="small"
					className={classes.notificationsChip}
					label={user.notifications.unreadCount}
				/>
			)}
		</ListItem>
	);

	return (
		<AppBar position="sticky" className={classes.navBar} elevation={0}>
			{isMobile ? (
				<IconButton onClick={() => dispatch(openSidebar())} size="large">
					<Menu />
				</IconButton>
			) : (
				<RelayLogo show="desktop" />
			)}
			<List component="nav" className={classes.list}>
				<Hidden mdUp>
					<NavLink
						className={classes.link}
						to={{
							pathname: '/app/notifications',
							search: location.search
						}}
					>
						{notificationListItem}
					</NavLink>
				</Hidden>
				<Hidden mdDown>
					{notificationListItem}
					<Popper
						open={notificationsMenuOpen}
						transition
						anchorEl={anchorRef.current}
						className={classes.notificationsPopper}
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<div>
									<ClickAwayListener onClickAway={handleNotificationsMenuClose}>
										<Paper elevation={2} className={classes.notificationsWrapper}>
											<>
												<div className={classes.notificationsScrollContainer} ref={scrollParentRef}>
													<MenuList id="menu-list-grow">
														<InfiniteScroll
															scrollThreshold={50}
															next={() => dispatch(fetchMoreNotifications())}
															hasMore={
																!notifications?.isLoading && notifications?.metaData?.hasMore
															}
															dataLength={notifications?.data?.length || 0}
															loader="...loading"
														>
															{notifications?.data?.map((noti, _id) => (
																<div key={_id} onClick={handleNotificationsMenuClose}>
																	<NotificationItem notification={noti} />
																</div>
															))}
														</InfiniteScroll>
													</MenuList>
												</div>
												<NavLink
													className={classes.link}
													onClick={handleNotificationsMenuClose}
													to={{
														pathname: '/app/notifications',
														search: location.search
													}}
												>
													<ListItem
														className={clsx(classes.showAllLink, {
															[classes.showAllLinkIsLoading]: !!notifications?.isLoading
														})}
													>
														{notifications?.isLoading ? (
															<CircularProgress size={20} />
														) : (
															<ListItemText primary="..." />
														)}
													</ListItem>
												</NavLink>
											</>
										</Paper>
									</ClickAwayListener>
								</div>
							</Grow>
						)}
					</Popper>
				</Hidden>
			</List>
		</AppBar>
	);
}
