import { TaskArea } from '@api/v1';

export const taskAreaValues: TaskArea[] = [
	'Abfallentsorgung',
	'Ablesedienst',
	'Architektur',
	'Aufzug',
	'Badsanierung',
	'Bauberatung',
	'Bauträger',
	'Bautrockenlegung',
	'Brandschutz',
	'Dachdecker',
	'Deckenarbeiten',
	'Energieberatung',
	'Entrümpelung',
	'Elektriker',
	'Energieversorgung',
	'Fensterbau/Glaser',
	'Fliesenleger/Glaser',
	'Garagentor',
	'Gärtner',
	'Gas-/Wasserinstallation',
	'Gebäudereinigung',
	'Gerüstbau',
	'Hausmeister',
	'Heizöllieferant',
	'Heizungsinstallation',
	'Isolierung/Dämmung',
	'Kabelanbieter/TV',
	'Kaminbau/Ofenbau',
	'Klempner',
	'Küchenbau',
	'Leckortung',
	'Maler/Tapezierer/Lackierer',
	'Maurer',
	'Modernisierung/Sanierung',
	'Notdienst',
	'Pflasterarbeiten',
	'Rohrreinigung',
	'Rollläden/Markisen',
	'Sanitär',
	'Schädlingsbekämpfung',
	'Schlosser und Metallbau',
	'Schlüsseldienst',
	'Schornsteinfeger',
	'Sicherheitsdienst',
	'Stukkateur',
	'Tischler/Schreiner',
	'Treppenbau',
	'Trockenbau',
	'Türen',
	'Verfugen/Fugarbeiten',
	'Veranda-/Terrassenbau',
	'Versicherung',
	'Winterdienst',
	'Wintergartenbau',
	'Zaunbau/Torbau',
	'Zimmerer',
	'Sonstige Handwerksleistungen'
];
export const radiusValues = [
	{ value: 10, label: '10 km' },
	{ value: 20, label: '20 km' },
	{ value: 30, label: '30 km' },
	{ value: 40, label: '40 km' },
	{ value: 50, label: '50 km' },
	{ value: 75, label: '75 km' },
	{ value: 100, label: '100 km' },
	{ value: 0, label: 'Keine Begrenzung' }
];
