import { Card, CardContent, InputBase, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		margin: theme.spacing(1, 1, 0),
		overflow: 'visible',
		zIndex: 1
	},
	cardContent: {
		padding: `${theme.spacing(1)} ${theme.spacing(1)} !important`
	},
	search: {
		position: 'relative',
		marginLeft: 0,
		width: '100%'
	},
	searchIcon: {
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	input: {
		width: '100%',
		'margin-left': theme.spacing(4)
	},
	searchContainer: {
		display: 'flex',
		alignItems: 'center',
		'& > label': {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			},
			'& > span': {
				color: theme.palette.grey[600],
				fontSize: 'small'
			}
		}
	}
}));

interface SearchBarProps {
	searchInput: string;
	setSearchInput(input: string): void;
	applySearch(searchTerm?: string): (dispatch: any) => any;
	placeholder: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
	searchInput,
	setSearchInput,
	applySearch,
	placeholder
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [delayTimer, setDelayTimer] = useState<NodeJS.Timer>();
	const handleOnChange = (event) => {
		setSearchInput(event.target.value);
		clearTimeout(delayTimer);
		setDelayTimer(setTimeout(() => dispatch(applySearch(event.target.value)), 500));
	};
	return (
		<Card className={classes.root} elevation={2}>
			<CardContent className={classes.cardContent}>
				<div className={classes.searchContainer}>
					<div className={classes.search}>
						<div className={classes.searchIcon}>
							<SearchIcon />
						</div>
						<InputBase
							onChange={handleOnChange}
							placeholder={t(placeholder)}
							value={searchInput}
							className={classes.input}
							inputProps={{ 'aria-label': 'Search' }}
						/>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};
