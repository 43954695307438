import { Checkbox, Grid, ListItemText, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UIExternalProfile } from '@api/v1';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { State } from '../../reducers';
import { fetchRatings } from '../../profile/actions';
import ContentCard from '../../../mui-custom/ContentCard/ContentCard';
import { CustomSelectV3 } from '../../../components/CustomSelect';
import { radiusValues, taskAreaValues } from './constants';

/*
this regex allows the following url pattern:
casavi.de
casavi.de/page1
www.casavi.de/page1
http://casavi.de
http://casavi.de/page1
http://www.casavi.de
http://www.casavi.de/page1
https://www.casavi.de
https://www.casavi.de/page1
*/
export const websiteRegex =
	'[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)';

export function AdminExternalProfileAppV3() {
	const { user } = useSelector((state: State) => state.auth);
	const { account } = useSelector((state: State) => state.admin.account);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const scrollRef = useRef(null);

	const initialFormState: UIExternalProfile = {
		name: account?.externalProfile?.name || account?.name || '',
		email: account?.externalProfile?.email || user?.email || '',
		address: account?.externalProfile?.address || account?.address,
		companyDescription: account?.externalProfile?.companyDescription || '',
		taskAreas: account?.externalProfile?.taskAreas,
		radius: account?.externalProfile?.radius,
		isActive: account?.externalProfile?.isActive || false,
		companyWebsite: account?.externalProfile?.companyWebsite || ''
	};
	const [formData, setFormData] = useState(initialFormState);

	const [error, setError] = React.useState({ taskAreas: false, radius: false });
	const touch = (name = '') => (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({
			...formData,
			[name]: e.target.value
		});
		setError({ ...error, [name]: false });
	};

	const renderSelectedTaskAreas = (selectedValues: string[]) => {
		if (selectedValues.length !== 0) return selectedValues.join(', ');
		return <em>{t('admin:external_profile_taskarea_placeholder')}</em>;
	};

	useEffect(() => {
		setFormData(initialFormState);
		if (account?.externalProfile) dispatch(fetchRatings());
	}, [account]);

	return (
		<ContentCard headline={t('general:company_data')} text={''}>
			<ValidatorForm
				onSubmit={null}
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 16
				}}
			>
				<TextValidator
					label={t('general:company_name')}
					validators={['required']}
					value={formData.name}
					onChange={(e) => setFormData({ ...formData, name: e.target.value })}
					errorMessages={[t('general:required_message')]}
					fullWidth
					variant="outlined"
					name="companyNameInput"
				/>
				<TextValidator
					label={t('general:streetAddress')}
					name="companyStreetInput"
					validators={['required']}
					value={formData.address?.streetAddress || ''}
					onChange={(e) =>
						setFormData({
							...formData,
							address: {
								...formData.address,
								streetAddress: e.target.value
							}
						})
					}
					errorMessages={[t('general:required_message')]}
					fullWidth
					variant="outlined"
				/>
				<Grid sx={{ display: 'flex', gap: 2 }}>
					<TextValidator
						label={t('general:postal_code')}
						name="companyPostInput"
						validators={['required']}
						value={formData.address?.postalCode || ''}
						onChange={(e) =>
							setFormData({
								...formData,
								address: {
									...formData.address,
									postalCode: e.target.value
								}
							})
						}
						errorMessages={[t('general:required_message')]}
						sx={{ width: '88px' }}
						variant="outlined"
					/>
					<Grid sx={{ flex: 1 }}>
						<TextValidator
							label={t('general:locality')}
							name="companyCityInput"
							validators={['required']}
							value={formData.address?.locality || ''}
							onChange={(e) =>
								setFormData({
									...formData,
									address: {
										...formData.address,
										locality: e.target.value
									}
								})
							}
							errorMessages={[t('general:required_message')]}
							fullWidth
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<TextValidator
					label={t('general:email_address')}
					name="companyEMailInput"
					validators={['required', 'isEmail']}
					errorMessages={[t('general:required_message'), t('validation:invalid_email_address')]}
					value={formData.email}
					onChange={(e) => setFormData({ ...formData, email: e.target.value })}
					fullWidth
					variant="outlined"
				/>
				<TextValidator
					label={t('general:website')}
					name="companySiteInput"
					validators={[`matchRegexp:${websiteRegex}`]}
					value={formData.companyWebsite}
					onChange={(e) =>
						setFormData({
							...formData,
							companyWebsite: e.target.value
						})
					}
					errorMessages={[t('general:no_valid_link')]}
					fullWidth
					variant="outlined"
				/>
				<Grid
					sx={{
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<Grid sx={{ width: '73%' }}>
						<CustomSelectV3
							name="taskAreas"
							id="external-profile-taskarea"
							header={t('admin:external_profile_taskarea')}
							placeholder={t('admin:external_profile_taskarea_placeholder')}
							multiple
							value={formData.taskAreas || []}
							onChange={touch('taskAreas')}
							renderValue={renderSelectedTaskAreas}
							error={error}
							sx={{ width: '100%' }}
							variant="outlined"
							ref={scrollRef}
						>
							{taskAreaValues.map((taskArea) => (
								<MenuItem key={taskArea} value={taskArea}>
									<Checkbox checked={!!formData.taskAreas?.includes(taskArea)} />
									<ListItemText primary={taskArea} />
								</MenuItem>
							))}
						</CustomSelectV3>
					</Grid>
					<Grid sx={{ width: '25%' }}>
						<CustomSelectV3
							name="radius"
							id="external-profile-radius"
							header={t('admin:external_profile_radius')}
							placeholder={t('admin:external_profile_radius_placeholder')}
							value={formData.radius >= 0 ? formData.radius : ''}
							onChange={touch('radius')}
							sx={{ width: '100%' }}
							error={error}
							variant="outlined"
						>
							{radiusValues.map((_radius) => (
								<MenuItem key={_radius.label} value={_radius.value}>
									{_radius.label}
								</MenuItem>
							))}
						</CustomSelectV3>
						{formData.radius === 0 && (
							<Typography variant="caption">
								{t('admin:external_profile_radius_unlimited_warning')}
							</Typography>
						)}
					</Grid>
				</Grid>
				<TextField
					multiline
					rows={6}
					label={t('general:company_description')}
					value={formData.companyDescription}
					onChange={(e) =>
						setFormData({
							...formData,
							companyDescription: e.target.value
						})
					}
					fullWidth
					variant="outlined"
				/>
			</ValidatorForm>
		</ContentCard>
	);
}
