import * as React from 'react';
import { AccountList } from './List';
import { UserList } from './users/List';
import { Switch, Route, Redirect } from 'react-router';

export function AccountApp() {
	return (
		<Switch>
			<Route path="/sysadmin/app/accounts/:accountId/users" component={UserList} />
			<Redirect
				from="/sysadmin/app/accounts/:accountId"
				to="/sysadmin/app/accounts/:accountId/users"
			/>
			<Route path="/sysadmin/app/accounts" component={AccountList} />
		</Switch>
	);
}
