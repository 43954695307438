import { Theme, Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Trans } from 'react-i18next';
import { PrivacyLink, ImprintLink, TermsLink, LicenseLink } from '../../components/LegalLink';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		padding: theme.spacing(1)
	},
	item: {
		padding: theme.spacing(1)
	}
}));

export const Footer = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.item}>
				<Typography variant="body2">
					<Trans
						i18nKey="general:footer_powered_by_casavi"
						components={[
							<Link
								href="http://www.casavi.de"
								target="_blank"
								variant="body2"
								underline="always"
								color="inherit"
							>
								casavi
							</Link>
						]}
					/>
				</Typography>
			</div>
			<div className={classes.item}>
				<ImprintLink variant="body2" underline="always" color="inherit" />
			</div>
			<div className={classes.item}>
				<TermsLink color="inherit" variant="body2" underline="always" />
			</div>
			<div className={classes.item}>
				<PrivacyLink variant="body2" underline="always" color="inherit" />
			</div>
			<div className={classes.item}>
				<LicenseLink variant="body2" underline="always" color="inherit" />
			</div>
		</div>
	);
};
