import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { OfferRequestDetailView } from './single/DetailView';
import { useDispatch } from 'react-redux';
import { getFiltersFromSearch, setFilter } from './actions';
import { Feature, useFeature } from '../acl/features';
import { Route } from 'react-router-dom';
import { Searchbar } from './list/SearchBar';
import { OfferRequestList } from './list/List';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: '100%',
		height: '100%'
	},
	leftLayout: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		},
		'@media print': {
			display: 'none'
		}
	},
	outer: {
		overflowY: 'hidden',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	gridContainer: {
		height: '100%',
		'@media print': {
			display: 'block'
		}
	},
	detailLayout: {
		flex: '1 1 100%',
		width: '100%',
		maxHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
		'@media print': {
			display: 'block'
		}
	}
}));

export const OfferRequestsApp = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles(props);
	const hideDetails = props.match.isExact;

	const { search } = useLocation();
	useEffect(() => {
		const filters = getFiltersFromSearch(search);
		dispatch(setFilter(filters, true));
	}, []);
	if (!useFeature(Feature.OfferView)) return null;

	return (
		<div className={classes.wrapper}>
			<Grid container className={classes.gridContainer}>
				<Hidden lgDown={!hideDetails}>
					<Grid item lg={hideDetails ? 12 : 6} className={classes.leftLayout}>
						<div className={classes.outer}>
							<Searchbar />
							<OfferRequestList />
						</div>
					</Grid>
				</Hidden>
				<Hidden lgDown={hideDetails}>
					<Grid item lg={6} className={classes.detailLayout}>
						<Route path="/app/offer-requests/:offerRequestId" component={OfferRequestDetailView} />
					</Grid>
				</Hidden>
			</Grid>
		</div>
	);
};
