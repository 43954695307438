import React from 'react';
import { Typography, useTheme } from '@mui/material';

export const TextGroupHeadline = ({ children }) => {
	const theme = useTheme();
	return (
		<Typography sx={{ color: theme.palette.text.primary }} variant="body1">
			{children}
		</Typography>
	);
};
