export const createInsuranceStateKey = (entryKey) => {
	switch (entryKey) {
		case 'active': {
			return 'insurances:add_dialog_status_active';
		}
		case 'inactive': {
			return 'insurances:add_dialog_status_inactive';
		}
		case 'underConstruction': {
			return 'insurances:add_dialog_status_under_construction';
		}
		case 'quit': {
			return 'insurances:add_dialog_status_quit';
		}
		default: {
			return 'add_dialog_status_UNDEFINED_KEY_GENERATOR_OPTION';
		}
	}
};
