import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import { fetchDocuments, openCreationDialog, fetchMoreDocuments } from './actions';
import { DocumentCreationDialog } from './CreationDialog';
import { DocumentListItem } from './ListItem';
import { useFeature, Feature } from '../acl/features';
import { LazyList, useStyles } from '../../components/LazyList';

export function DocumentApp() {
	const dispatch = useDispatch();
	const classes = useStyles();
	useEffect(() => {
		dispatch(fetchDocuments());
	}, []);
	const { list } = useSelector((state: State) => state.documents);
	if (!useFeature(Feature.DocumentView)) return null;

	return (
		<div className={classes.root} id="documents">
			<div className={classes.controlBar}>
				<Button
					color="secondary"
					variant="contained"
					onClick={() => dispatch(openCreationDialog())}
				>
					<Trans i18nKey="documents:list_button_create" />
				</Button>
			</div>
			<LazyList
				next={fetchMoreDocuments}
				metaList={list}
				i18TextNoList="documents:list_none_here"
				ListItem={DocumentListItem}
			/>
			<DocumentCreationDialog />
		</div>
	);
}
