import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { decline } from '../actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	width: {
		width: theme.spacing(50)
	}
}));

export function DeclineDialog(props) {
	const classes = useStyles({});
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [comment, setComment] = useState('');

	return (
		<Dialog open={props.open} classes={{ paper: classes.width }}>
			<DialogTitle>{t('offers:dialog_decline_title')}</DialogTitle>
			<DialogContent>
				<TextField
					id="offer_decline_dialog_field_comment"
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					label={t('offers:dialog_decline_reason')}
					multiline
					rows={3}
					error={!comment}
					maxRows={5}
					fullWidth
					variant="standard"
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={props.onClose}>
					{t('general:cta_cancel')}
				</Button>
				<Button
					id="offer_decline_dialog_button_submit"
					color="primary"
					disabled={!comment}
					onClick={() => {
						dispatch(decline(comment));
						props.onClose();
					}}
				>
					{t('offers:cta_decline')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
