import { Button, Card, TableCell, Theme, Alert, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AdminExclusivePermission } from '../../../server/permissions/permission';
import { GoogleMapsLink } from '../../components/GoogleMapsLink';
import { State } from '../reducers';
import { editCustomer, openUpgradeDialog } from './actions';
import { getAutoAssignUserLabel } from './util';
import { history } from '../history';
import { CustomerOrigin } from '../../../server/customers/browser.types';
import TableRowOrCard, { TableCtx } from '../../mui-custom/TableRowOrCard';
import { useMobile } from '../../hooks/useBreakpoints';
import { Check, Close } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		overflow: 'unset'
	},
	link: {
		color: 'inherit'
	},
	buttonContainer: {
		display: 'flex',
		'& > *:first-child': {
			marginRight: theme.spacing(1)
		}
	}
}));

export const CustomerTable = ({ addContact = null, editContact = null }) => {
	const isMobile = useMobile();
	const classes = useStyles();
	const dispatch = useDispatch();
	const handleInvitation = (customer, status) => {
		dispatch(editCustomer(customer, status, history));
	};
	const { t } = useTranslation();
	const {
		customerListWithoutTestCustomers,
		customerMetaData,
		possibleAutoAssignUsers,
		permissions
	} = useSelector((state: State) => {
		const customers = state.customers.list.data;
		return {
			customerListWithoutTestCustomers: customers.filter(
				(customer) => customer.origin !== CustomerOrigin.AccountCreation
			),
			customerMetaData: state.customers.list.metaData,
			possibleAutoAssignUsers: state.customers.list.possibleAutoAssignUsers,
			permissions: state.auth.user.permissions
		};
	});

	const hasAdminView = permissions[AdminExclusivePermission.VIEW_ADMIN];

	if (customerListWithoutTestCustomers?.length < 1) {
		return (
			<Alert severity="info">
				<Typography variant="body1">{t('customers:alert_no_contact')}</Typography>
				{addContact && (
					<Button sx={{ mt: 1 }} variant="contained" color="primary" onClick={addContact}>
						{t('customers:page_nav_action_add_contact')}
					</Button>
				)}
			</Alert>
		);
	}
	return (
		<Card className={classes.root} elevation={0}>
			<TableCtx
				headers={[
					t('customers:table_header_internal_id'),
					t('customers:table_header_name'),
					t('customers:table_header_address'),
					t('customers:table_header_email'),
					t('customers:table_header_telephone'),
					t('customers:table_header_auto_assignee'),
					t('customers:table_header_status'),
					t('customers:table_header_action')
				]}
			>
				{customerListWithoutTestCustomers.map((customer) => {
					const autoAssignUserLabel = getAutoAssignUserLabel(customer, possibleAutoAssignUsers);
					return (
						<TableRowOrCard key={customer.id}>
							<TableCell>{customer.internalId}</TableCell>
							<TableCell>{customer.name}</TableCell>
							<TableCell>
								<GoogleMapsLink className={classes.link} address={customer.address} />
							</TableCell>
							<TableCell>
								<a className={classes.link} href={`mailto:${customer.emailAddress}`}>
									{customer.emailAddress}
								</a>
							</TableCell>
							<TableCell>
								<a className={classes.link} href={`tel:${customer.telephone}`}>
									{customer.telephone}
								</a>
							</TableCell>
							<TableCell>{autoAssignUserLabel}</TableCell>

							<TableCell sx={isMobile ? { mt: 2 } : {}}>
								{(() => {
									switch (customer.status) {
										case 'ACCEPTED':
											return (
												!!customer.statusUpdatedAt && (
													<Trans
														i18nKey={
															customer.origin === CustomerOrigin.Casavi
																? 'customers:status_accepted_at'
																: 'customers:status_created_at'
														}
														values={{
															date: Date.parse(customer.statusUpdatedAt)
														}}
													/>
												)
											);
										case 'REJECTED':
											return (
												!!customer.statusUpdatedAt && (
													<Trans
														i18nKey="customers:status_rejected_at"
														values={{
															date: Date.parse(customer.statusUpdatedAt)
														}}
													/>
												)
											);
										default:
											return (
												hasAdminView && (
													<div className={classes.buttonContainer}>
														<Button
															onClick={() => handleInvitation(customer, 'REJECTED')}
															color="error"
															startIcon={<Close />}
														>
															{t('customers:connect_button_reject')}
														</Button>
														<Button
															onClick={
																customerMetaData?.canAcceptMore
																	? () => handleInvitation(customer, 'ACCEPTED')
																	: () => dispatch(openUpgradeDialog())
															}
															color="primary"
															startIcon={<Check />}
														>
															{t('customers:connect_button_accept')}
														</Button>
													</div>
												)
											);
									}
								})()}
							</TableCell>
							<TableCell>
								<Button
									size="small"
									color="inherit"
									startIcon={<EditIcon />}
									onClick={editContact(customer)}
								>
									{isMobile && <Trans i18nKey="contact:edit_action_button" />}
								</Button>
							</TableCell>
						</TableRowOrCard>
					);
				})}
			</TableCtx>
		</Card>
	);
};
