import { createConstants, createAsyncConstants } from '../../lib/createConstants';
import { api } from '../../lib/api';
import { State } from '../reducers';
import { UIProperty, CreatePropertyBody, UIPropertiesStatistics } from '@api/v1';
import { showNotification } from '../actions';
import { i18n } from '../../../app/i18n';
import { debounce } from 'lodash';

export const constants = createConstants(
	[
		'OPEN_REJECTING_DIALOG',
		'CLOSE_REJECTING_DIALOG',
		'OPEN_ACCEPTING_DIALOG',
		'CLOSE_ACCEPTING_DIALOG',
		'OPEN_ACCEPT_ALL_DIALOG',
		'CLOSE_ACCEPT_ALL_DIALOG',
		'OPEN_EDIT_DIALOG',
		'CLOSE_EDIT_DIALOG',
		'SET_INTERNAL_ID',
		'OPEN_ADD_DIALOG',
		'CLOSE_ADD_DIALOG',
		'SET_IS_LOADING',
		'CLEAR'
	],
	{ prefix: 'PROPERTIES' }
);

export const asyncConstants = createAsyncConstants(
	[
		'GET_PROPERTIES',
		'GET_MORE_PROPERTIES',
		'REJECT_PROPERTY',
		'ACCEPT_PROPERTY',
		'EDIT_PROPERTY',
		'ADD_PROPERTY',
		'FETCH_STATISTICS',
		'ACCEPT_ALL_PROPERTIES'
	],
	{
		prefix: 'PROPERTIES'
	}
);

export const openRejectingDialog = (property: UIProperty) => ({
	type: constants.OPEN_REJECTING_DIALOG,
	payload: property
});
export const closeRejectingDialog = () => ({
	type: constants.CLOSE_REJECTING_DIALOG
});
export const openAcceptAllDialog = () => ({
	type: constants.OPEN_ACCEPT_ALL_DIALOG
});
export const closeAcceptAllDialog = () => ({
	type: constants.CLOSE_ACCEPT_ALL_DIALOG
});
export const openAcceptingDialog = (property: UIProperty) => ({
	type: constants.OPEN_ACCEPTING_DIALOG,
	payload: property
});
export const closeAcceptingDialog = () => ({
	type: constants.CLOSE_ACCEPTING_DIALOG
});
export const openAddDialog = () => ({
	type: constants.OPEN_ADD_DIALOG
});
export const closeAddDialog = () => ({
	type: constants.CLOSE_ADD_DIALOG
});
export const loadProperties = (params?) => (dispatch, getState: () => State) => {
	return dispatch({
		type: asyncConstants.GET_PROPERTIES.TYPE,
		payload: api.get('/api/v1/properties/', { params: { ...params } })
	});
};
export const applySearch = (searchTerm: string = undefined) => (dispatch) => {
	dispatch({
		type: constants.CLEAR
	});
	return dispatch(loadProperties({ searchTerm }));
};
export const loadMoreProperties = () => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_IS_LOADING,
		payload: {}
	});
	return dispatch(debounce(appendPropertiesToList(), 1000));
};
export const appendPropertiesToList = () => (dispatch, getState: () => State) => {
	const metaData = getState().properties?.list?.metaData;
	let lastId: number;
	if (metaData) {
		lastId = metaData.lastId;
	}
	return dispatch({
		type: asyncConstants.GET_MORE_PROPERTIES.TYPE,
		payload: api.get('/api/v1/properties/', {
			params: { afterId: lastId }
		})
	});
};
export const rejectProperty = () => (dispatch, getState: () => State) => {
	const { id } = getState().properties.rejectingDialog.property;
	return dispatch({
		type: asyncConstants.REJECT_PROPERTY.TYPE,
		payload: api.patch(`/api/v1/properties/${id}`, { status: 'REJECTED' })
	});
};
export const acceptProperty = () => (dispatch, getState: () => State) => {
	const { id } = getState().properties.acceptingDialog.property;
	return dispatch({
		type: asyncConstants.ACCEPT_PROPERTY.TYPE,
		payload: api.patch(`/api/v1/properties/${id}`, { status: 'ACCEPTED' })
	});
};

export const acceptAllProperties = () => (dispatch, getState: () => State) => {
	const { id } = getState().auth.account;
	return dispatch({
		type: asyncConstants.ACCEPT_ALL_PROPERTIES.TYPE,
		payload: api
			.post(`/api/v1/properties/account/${id}/accept`, { status: 'ACCEPTED' })
			.then(() => {
				dispatch(loadProperties({}));
				dispatch(showNotification(i18n.t('properties:all_properties_accepted_success'), 'info'));
			})
			.catch((err) => {
				if (err.response?.data?.message === 'no propierties matching request') {
					dispatch(showNotification(i18n.t('properties:all_properties_accepted_error'), 'info'));
					dispatch(closeAcceptAllDialog());
				} else if (err.response?.data?.message === 'not enough object slots') {
					dispatch(showNotification(i18n.t('properties:no_object_slots_left'), 'error'));
					dispatch(closeAcceptAllDialog());
				} else {
					dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
				}
				throw err;
			})
	});
};
export const openEditDialog = (property: UIProperty) => ({
	type: constants.OPEN_EDIT_DIALOG,
	payload: property
});
export const closeEditDialog = () => ({
	type: constants.CLOSE_EDIT_DIALOG
});
export const setInternalId = (payload: string) => ({
	type: constants.SET_INTERNAL_ID,
	payload
});
export const editProperty = (property: UIProperty) => (dispatch) => {
	return dispatch({
		type: asyncConstants.EDIT_PROPERTY.TYPE,
		payload: api
			.patch(`/api/v1/properties/${property.id}`, {
				internalId: property.internalId
			})
			.then(() => {
				dispatch(loadProperties());
			})
	});
};
export const createProperty = (property: CreatePropertyBody) => (dispatch) => {
	return dispatch({
		type: asyncConstants.ADD_PROPERTY.TYPE,
		payload: api
			.post('/api/v1/properties', property)
			.then(() => {
				dispatch(loadProperties());
				dispatch(closeAddDialog());
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
				throw err;
			})
	});
};

export const fetchPropertiesStatistics = () => (dispatch, getState: () => State) => {
	return dispatch({
		type: asyncConstants.FETCH_STATISTICS.TYPE,
		payload: api.get<UIPropertiesStatistics>('/api/v1/properties/statistics')
	});
};
