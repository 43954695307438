import { BEmployee } from '@api/v1';
import { State } from 'app/core/reducers';
import { showNotification } from '../../core/actions';
import { api } from '../../lib/api';
import { createConstants, createAsyncConstants } from '../../lib/createConstants';

export const constants = createConstants([
	'SET_EMAIL',
	'SET_FIRSTNAME',
	'SET_LASTNAME',
	'SET_TELEPHONE',
	'PREFILL_EMPLOYEE_CREATION_FORM'
]);

export const asyncConstants = createAsyncConstants(['HANDLE_SUBMIT'], {
	prefix: 'ADMIN_USER_LIST'
});

export const setEmail = (email: string) => ({
	type: constants.SET_EMAIL,
	payload: email
});

export const setFirstName = (firstName: string) => ({
	type: constants.SET_FIRSTNAME,
	payload: firstName
});

export const setLastName = (lastName: string) => ({
	type: constants.SET_LASTNAME,
	payload: lastName
});

export const setTelephone = (telephone: string) => ({
	type: constants.SET_TELEPHONE,
	payload: telephone
});

export const handleSubmit = () => (dispatch, getState: () => State) =>
	dispatch({
		type: asyncConstants.HANDLE_SUBMIT.TYPE,
		payload: api
			.post<BEmployee>('/api/v1/employees', getState().employeeCreationDialog)
			.then((data) => {
				dispatch(showNotification('Mitarbeiter erfolgreich angelegt.', 'success'));
				return data;
			})
			.catch((err) => {
				dispatch(showNotification('Mitarbeiter konnte nicht angelegt werden.', 'error'));
			})
	});

export const prefillEmployeeCreationForm = (body) => ({
	type: constants.PREFILL_EMPLOYEE_CREATION_FORM,
	payload: body
});
