import * as React from 'react';
import { SelectValidator } from './CountrySelect/SelectValidator';
import { FormControlProps } from '@mui/material/FormControl';
import { AccountCategory } from '@api/v1';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

interface CategorySelectProps {
	name: string;
	label: React.ReactNode;
	value: AccountCategory;
	onChange: (value: AccountCategory) => void;
	validators?: string[];
	errorMessages?: string[];
	disabled?: boolean;
	fullWidth?: boolean;
	margin?: FormControlProps['margin'];
	className?: string;
}

export function AccountCategorySelect(props: CategorySelectProps) {
	const { t } = useTranslation();
	const isTestAccount = props.value === 'TEST';
	return (
		<SelectValidator
			name={props.name}
			label={props.label}
			value={props.value}
			disabled={isTestAccount ? true : props.disabled}
			onChange={(e) => props.onChange(e.currentTarget.value)}
			options={isTestAccount ? getTestCategories(t) : getCategories(t)}
			validators={props.validators}
			errorMessages={props.errorMessages}
			fullWidth={props.fullWidth}
			margin={props.margin}
			className={props.className}
		/>
	);
}

function getTestCategories(t: i18next.TFunction) {
	return [
		{
			value: 'TEST',
			label: translateAccountCategory('TEST', t)
		}
	];
}
function getCategories(t: i18next.TFunction) {
	const categories: AccountCategory[] = [
		'CRAFTSMEN',
		'ENERGY_PROVIDER',
		'FACILITY_MANAGEMENT',
		'INSURANCE_AGENT',
		'MEASUREMENT_SERVICE'
	];
	return _(categories)
		.map((category) => ({
			value: category,
			label: translateAccountCategory(category, t)
		}))
		.sortBy('label')
		.concat([
			{
				value: 'OTHER_SERVICE_PROVIDER',
				label: translateAccountCategory('OTHER_SERVICE_PROVIDER', t)
			}
		])
		.value();
}

export function translateAccountCategory(category: AccountCategory, t: i18next.TFunction) {
	switch (category) {
		case 'CRAFTSMEN':
			return t('registration:account_category_craftsmen');
		case 'ENERGY_PROVIDER':
			return t('registration:account_category_energy_provider');
		case 'FACILITY_MANAGEMENT':
			return t('registration:account_category_facility_management');
		case 'INSURANCE_AGENT':
			return t('registration:account_category_insurance_agent');
		case 'MEASUREMENT_SERVICE':
			return t('registration:account_category_measurement_service');
		case 'OTHER_SERVICE_PROVIDER':
			return t('registration:account_category_other');
		case 'TEST':
			return t('registration:account_category_test');
		default:
			return null;
	}
}
