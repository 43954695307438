import * as React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	TextField
} from '@mui/material';
import { MaterialDropzone } from '../../../components/Dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	closeFinishDialog,
	changeFinishComment,
	addFinishFile,
	changeFinishFile,
	removeFinishFile,
	finish
} from '../actions';
import { isReadyToUpload } from '../../../components/Dropzone/validation';
import { State } from '../../reducers';

export const FinishDialog = () => {
	const { open, comment, files } = useSelector(
		(state: State) => state?.orders?.single?.finishDialog
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const isValid = isReadyToUpload(files);
	return (
		<Dialog open={open}>
			<DialogTitle>{t('orders:dialog_finish_title')}</DialogTitle>
			<DialogContent>
				<Typography>{t('orders:dialog_finish_description')}</Typography>
				<TextField
					id="order_finish_dialog_field_comment"
					value={comment}
					onChange={(e) => dispatch(changeFinishComment(e.target.value))}
					label={t('orders:dialog_finish_comment_label')}
					fullWidth
					multiline
					rows={3}
					maxRows={5}
					margin="normal"
					variant="standard"
				/>
				<MaterialDropzone
					text={t('orders:dialog_finish_upload')}
					onDrop={(file) => dispatch(addFinishFile(file))}
					onRemove={(tempId) => dispatch(removeFinishFile(tempId))}
					onChangeFile={(tempId, data) => dispatch(changeFinishFile(tempId, data))}
					files={files}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => dispatch(closeFinishDialog())}>{t('general:cta_cancel')}</Button>
				<Button
					id="order_finish_dialog_button_submit"
					color="primary"
					onClick={() => dispatch(finish())}
					disabled={!isValid}
				>
					{t('orders:dialog_finish_cta')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
