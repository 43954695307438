import { Fab, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../../components/LoadingScreen';
import { fetchInsurances, fetchMoreInsurances, applySearch } from './actions';
import { InsuranceListEntry } from './ListEntry';
import { AddDialog } from './AddDialog';
import { UIInsurance } from '@api/v1';
import { State } from '../reducers';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchBar as InsuranceSearchBar } from '../../components/SearchBar';
import { NoResultsForSearchScreen } from '../../components/NoResultsForSearchScreen';
import { InfiniteLoaderEndMessage } from '../../components/InfiniteLoaderEndMessage';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		position: 'relative'
	},
	table: {
		width: '100%',
		overflowX: 'auto'
	},
	margin: {
		margin: theme.spacing(0, 4)
	},
	link: {
		textDecoration: 'none'
	},
	fab: {
		position: 'sticky',
		left: '100%',
		bottom: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(3),
		minHeight: '56px'
	},
	flex: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	outer: {
		overflowY: 'hidden',
		overflowX: 'hidden',
		width: '100%',
		height: '100%'
	},
	outerTable: {
		overflowY: 'auto',
		width: '100%',
		padding: theme.spacing(1, 2)
	}
}));

export function InsurancesList(props: {}) {
	const [dialogOpen, setDialogOpen] = useState(false);
	const { data, isFetching, hasMore } = useSelector((state: State) => ({
		data: state.insurances?.list?.data,
		isFetching: state.insurances?.list?.metaData?.isFetching,
		hasMore: state.insurances?.list?.metaData?.hasMore
	}));
	const dispatch = useDispatch();
	const classes = useStyles(props);
	const { t } = useTranslation();
	const loadMore = () => {
		dispatch(fetchMoreInsurances());
	};
	const [activeInsurance, setActiveInsurance] = useState(null);
	useEffect(() => {
		dispatch(fetchInsurances());
	}, []);
	const resetDialog = useCallback(() => {
		setDialogOpen(false);
		setActiveInsurance(null);
	}, []);
	const [searchInput, setSearchInput] = useState('');
	return (
		<div className={classes.outer}>
			<div className={classes.flex}>
				<InsuranceSearchBar
					searchInput={searchInput}
					setSearchInput={setSearchInput}
					applySearch={applySearch}
					placeholder={'insurances:search_placeholder'}
				/>
				{data && data.length ? (
					<div className={classes.outerTable} id="insurance-list-scroll-container">
						<InfiniteScroll
							next={loadMore}
							hasMore={hasMore}
							loader={<LoadingScreen />}
							dataLength={data.length}
							scrollableTarget="insurance-list-scroll-container"
							endMessage={<InfiniteLoaderEndMessage itemName="insurances" />}
						>
							<div className={classes.container}>
								<div className={classes.table}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>{t('insurances:table_header_external_id')}</TableCell>
												<TableCell>{t('insurances:table_header_status')}</TableCell>
												<TableCell>{t('insurances:table_header_customer')}</TableCell>
												<TableCell>{t('insurances:table_header_property')}</TableCell>
												<TableCell>{t('insurances:table_header_description')}</TableCell>
												<TableCell>{t('insurances:table_header_damage_type')}</TableCell>
												<TableCell>{t('insurances:table_header_damage_expiration_date')}</TableCell>
												<TableCell />
											</TableRow>
										</TableHead>
										<TableBody>
											{data.map((insurance, _index) => (
												<InsuranceListEntry
													insurance={insurance}
													key={insurance.id}
													onEditClick={(editInsurance: UIInsurance) => {
														setDialogOpen(true);
														setActiveInsurance(editInsurance);
													}}
												/>
											))}
										</TableBody>
									</Table>
								</div>

								{isFetching && <LoadingScreen />}
							</div>
						</InfiniteScroll>
					</div>
				) : data ? (
					<NoResultsForSearchScreen
						message={
							searchInput
								? t('insurances:nothing_found_on_search')
								: t('insurances:nothing_available_for_this_account')
						}
					/>
				) : (
					<LoadingScreen />
				)}
			</div>
			<Fab color="primary" onClick={() => setDialogOpen(true)} className={classes.fab}>
				<AddIcon />
			</Fab>
			<AddDialog
				open={dialogOpen}
				onClose={resetDialog}
				insuranceData={activeInsurance}
				onSave={resetDialog}
				onDelete={resetDialog}
				searchInput={searchInput}
			/>
		</div>
	);
}
