import React, { useEffect } from 'react';
import {
	CardContent,
	Typography,
	Button,
	Theme,
	CardActions,
	Grid,
	FormControl,
	FormControlLabel,
	Checkbox,
	FormHelperText
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import {
	setFirstName,
	setLastName,
	setEmail,
	setPassword,
	setConfirmPassword,
	setTelephone,
	submit,
	setAgreedToTerms,
	setSubscribedToNewsletter
} from './actions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CheckboxValidator } from '../../components/CheckboxValidator';
import { TermsLink, PrivacyLink } from '../../components/LegalLink';
import { makeStyles } from '@mui/styles';
import { UIRegistrationSteps } from '../../data/enums';
import ReactGA from 'react-ga';
import { phoneNumberRegex } from '../../lib/validator';
import { useRegSteps } from './Progress';
import { Feature, useActiveFeature } from '../acl/features';

const useStyles = makeStyles((theme: Theme) => ({
	actions: {
		justifyContent: 'space-between'
	},
	content: {
		flexGrow: 1,
		marginTop: '12px'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '420px',
		padding: theme.spacing(1)
	},
	checkboxGroup: {
		alignItems: 'flex-start'
	},
	checkboxLabel: {
		paddingTop: theme.spacing(1)
	}
}));

export const InsertUserDataForm = () => {
	ReactGA.pageview(window.location.pathname + window.location.search);
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		firstName,
		lastName,
		email,
		telephone,
		password,
		confirmPassword,
		agreedToTerms,
		subscribedToNewsletter,
		isProcessing
	} = useSelector((state: State) => state?.registration);
	const dispatch = useDispatch();
	const { onSubmitSuccess } = useRegSteps(UIRegistrationSteps.INSERT_USER_DATA);

	useEffect(() => {
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			return value === password;
		});
	}, [password]);

	/* trim country code at the beginning of the tel. number e.g. +49 or 0049 */
	const trimCountryCode = (tel: string) => tel.replace(/^(([+]|(00))[1-9]{2})/, '');
	/* Jump 2 steps skip the Abo Plan View by registration if PricingV2 isn't rolled out yet */
	const isPricingV2RolledOut = useActiveFeature(Feature.PricingV2RolledOut);
	const nextStep = isPricingV2RolledOut ? 1 : 2;

	const submitUserForm = () => {
		onSubmitSuccess()();
		dispatch(setAgreedToTerms(false));
	};

	const submitOrInsertPlan = isPricingV2RolledOut
		? () => submitUserForm()
		: () => dispatch(submit(onSubmitSuccess(nextStep)));

	const backClick = () => {
		onSubmitSuccess(-1)();
		dispatch(setAgreedToTerms(false));
	};

	return (
		<ValidatorForm debounceTime={300} className={classes.container} onSubmit={submitOrInsertPlan}>
			<CardContent className={classes.content}>
				<Typography color="inherit" variant="h5">
					{t('registration:description_insert_user_data')}:
				</Typography>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<TextValidator
							name="firstName"
							label={t('registration:first_name')}
							value={firstName || ''}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							onChange={(e) => dispatch(setFirstName(e.target.value))}
							margin="dense"
							fullWidth
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							name="lastName"
							label={t('registration:last_name')}
							value={lastName || ''}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							onChange={(e) => dispatch(setLastName(e.target.value))}
							margin="dense"
							fullWidth
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							name="email"
							label={t('registration:email')}
							value={email || ''}
							validators={['required', 'isEmail', 'isEmailAvailable']}
							errorMessages={[
								t('general:required_message'),
								t('validation:invalid_email_address'),
								t('validation:email_already_exists')
							]}
							onChange={(e) => dispatch(setEmail(e.target.value))}
							margin="dense"
							fullWidth
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6} sx={{ height: '7rem' }}>
						<TextValidator
							name="telephone"
							label={t('registration:telephone')}
							value={telephone || ''}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							margin="dense"
							onChange={(e) => dispatch(setTelephone(e.target.value))}
							type="tel"
							fullWidth
							variant="standard"
						/>
						<FormHelperText style={{ color: '#ED6C02' }}>
							{!!telephone &&
								(!phoneNumberRegex.test(telephone) || trimCountryCode(telephone).length < 9) &&
								t('validation:invalid_phone_number_warning_only')}
						</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							name="password"
							label={t('registration:password')}
							fullWidth
							margin="dense"
							value={password || ''}
							validators={['minStringLength:8']}
							errorMessages={[t('validation:password_length')]}
							onChange={(e) => dispatch(setPassword(e.target.value))}
							type="password"
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							name="confirmPassword"
							label={t('registration:confirm_password')}
							fullWidth
							margin="dense"
							value={confirmPassword || ''}
							validators={['isPasswordMatch', 'required']}
							errorMessages={[t('validation:password_confirm'), t('general:required_message')]}
							onChange={(e) => dispatch(setConfirmPassword(e.target.value))}
							type="password"
							variant="standard"
						/>
					</Grid>
				</Grid>
				<br />
				<FormControl variant="standard">
					<FormControlLabel
						classes={{
							root: classes.checkboxGroup,
							label: classes.checkboxLabel
						}}
						control={
							<Checkbox
								checked={subscribedToNewsletter}
								onChange={(event) => dispatch(setSubscribedToNewsletter(event.target.checked))}
								color="primary"
							/>
						}
						label={t('registration:checkbox_newsletter')}
					/>
				</FormControl>
				<CheckboxValidator
					name="agreedToTerms"
					value={agreedToTerms || ''}
					onChange={(event) => dispatch(setAgreedToTerms(event.target.checked))}
					classes={{
						group: classes.checkboxGroup,
						label: classes.checkboxLabel
					}}
					color="primary"
					label={
						<Trans
							i18nKey="registration:checkbox_terms_and_conditions"
							components={[<TermsLink>0</TermsLink>, <PrivacyLink>1</PrivacyLink>]}
						/>
					}
					validators={['required']}
					errorMessages={[t('general:required_message')]}
				/>
			</CardContent>
			<CardActions className={classes.actions}>
				<Button variant="contained" color="secondary" onClick={backClick}>
					{t('registration:button_back')}
				</Button>
				<Button variant="contained" color="primary" type="submit" disabled={isProcessing}>
					{isPricingV2RolledOut
						? t('registration:button_continue')
						: t('registration:button_register')}
				</Button>
			</CardActions>
		</ValidatorForm>
	);
};
