import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { State } from '../../reducers';
import { accept, changeAcceptComment, closeAcceptDialog } from '../actions';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	width: {
		width: theme.spacing(50)
	}
}));

export function AcceptDialog() {
	const classes = useStyles({});
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { open, comment, isProcessing } = useSelector((state: State) => ({
		open: state.orders.single.acceptDialog.open,
		comment: state.orders.single.acceptDialog.comment,
		isProcessing: state.orders.single.acceptDialog.isProcessing
	}));
	return (
		<Dialog open={open} classes={{ paper: classes.width }}>
			<DialogTitle>{t('orders:dialog_accept_title')}</DialogTitle>
			<DialogContent>
				<TextField
					id="order_accept_dialog_field_comment"
					value={comment}
					onChange={(e) => dispatch(changeAcceptComment(e.target.value))}
					label={t('orders:comment')}
					multiline
					rows={3}
					maxRows={5}
					fullWidth
					variant="standard"
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={() => dispatch(closeAcceptDialog())}>
					{t('general:cta_cancel')}
				</Button>
				<Button
					id="order_accept_dialog_button_submit"
					color="primary"
					disabled={isProcessing}
					onClick={() => dispatch(accept())}
				>
					{t('orders:cta_accept')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
