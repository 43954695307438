import React from 'react';
import { Box, ButtonBase, Button, useTheme } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

interface AttachmentThumbnailProps {
	link: string;
	thumbnail?: string;
	backGroundColor: string;
	fileName?: string;
}

export const AttachmentThumbnail = ({
	backGroundColor,
	link,
	thumbnail,
	fileName: fileNameInput
}: AttachmentThumbnailProps) => {
	const [isImage, setIsImage] = React.useState(false);
	const url = decodeURI(link);
	const fileName = url?.split('fileName%3D')?.[1] || fileNameInput;

	let fileType = url ? url?.split('.')?.slice(-1) : false;
	const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff'];
	React.useEffect(() => {
		if (fileType && imageTypes.includes(fileType[0])) {
			setIsImage(true);
		}
		if (fileNameInput) {
			const _fileType = fileNameInput.split('.').slice(-1);
			if (_fileType && imageTypes.includes(_fileType[0])) {
				fileType = _fileType;
				setIsImage(true);
			}
		}
	}, []);
	const theme = useTheme();

	return (
		<Box sx={{ color: 'unset', my: 0.5 }} component="a" target="_blank" href={link}>
			{isImage ? (
				<ButtonBase
					sx={{
						width: '100%',
						height: theme.spacing(24),
						p: 1,
						background: backGroundColor,
						borderRadius: 1
					}}
				>
					<Box
						sx={{
							background: theme.palette.grey['200'],
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							position: 'relative',
							width: '100%',
							height: '100%'
						}}
					>
						{/* <ImageIcon sx={{ fontSize: theme.spacing(6) }} /> */}
						{thumbnail ? (
							<Box
								component="img"
								sx={{
									position: 'absolute',
									height: '100%',
									width: '100%',
									objectFit: 'cover',
									borderRadius: 1
								}}
								src={thumbnail}
							/>
						) : null}
					</Box>
					{/* <ImageListItemBar title={name} /> */}
				</ButtonBase>
			) : (
				<ButtonBase
					sx={{
						width: '100%',
						p: 1,
						background: backGroundColor,
						borderRadius: 1
					}}
				>
					<Button
						component="label"
						role={undefined}
						variant="contained"
						tabIndex={-1}
						startIcon={<DownloadIcon />}
					>
						{decodeURI(fileName)}
					</Button>
				</ButtonBase>
			)}
		</Box>
	);
};
