// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
// TODO: use fonts dynamically from package with a font loader
// import roboto400 from "@fontsource/roboto/files/roboto-latin-400-normal.woff2";
// import roboto500 from "@fontsource/roboto/files/roboto-latin-500-normal.woff2";
// import robotoMono400 from "@fontsource/roboto-mono/files/roboto-mono-latin-500-normal.woff2";

import { createTheme } from '@mui/material/styles';
declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
		mobile: true;
	}
}
import { Typography } from '@mui/material/styles/createTypography';
import { Palette, PaletteMode, Theme as MUITheme } from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';
import { green, orange, lightBlue } from '@mui/material/colors';

import muiTypography from './design-tokens/mui-typography.json';
import muiTypographyExtra from './design-tokens/mui-typography-extra.json';
import muiLightColors from './design-tokens/mui-palette-light.json';
import muiLightColorsExtra from './design-tokens/mui-palette-light-extra.json';
import muiDarkColors from './design-tokens/mui-palette-dark.json';
import muiDarkColorsExtra from './design-tokens/mui-palette-dark-extra.json';

export type Theme = MUITheme & {
	palette: Palette &
		typeof muiLightColors &
		typeof muiLightColorsExtra &
		typeof muiDarkColors &
		typeof muiDarkColorsExtra;
};

function directedBoxModelFactory(attribute: string, direction: 'x' | 'y') {
	return function (value: string | number) {
		const suffixs = direction === 'x' ? ['Left', 'Right'] : ['Top', 'Bottom'];
		return suffixs.reduce(
			(acc, cur) => ({
				...acc,
				[`${attribute}${cur}`]: value
			}),
			{}
		);
	};
}

const px = directedBoxModelFactory('padding', 'x');
const py = directedBoxModelFactory('padding', 'y');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mx = directedBoxModelFactory('margin', 'x');
const my = directedBoxModelFactory('margin', 'y');

const BORDER_WIDTH = 1;

const buttonStyles = {
	large: {
		py: (48 - parseInt(muiTypographyExtra.buttonLarge.lineHeight, 10)) / 2
	},
	medium: {
		py: (40 - parseInt(muiTypographyExtra.buttonMedium.lineHeight, 10)) / 2
	},
	small: {
		py: (32 - parseInt(muiTypographyExtra.buttonSmall.lineHeight, 10)) / 2
	}
};

const inputTextLineHeight = parseInt(muiTypographyExtra.inputText.lineHeight, 10);
const inputStyles = {
	// large: {
	//   py: (56 - inputTextLineHeight) / 2,
	// },
	medium: {
		py: (48 - inputTextLineHeight) / 2
	},
	small: {
		py: (32 - inputTextLineHeight) / 2
	}
};

const breakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1536,
		mobile: 801
	}
};

export function useCreateMemoTheme(mode: PaletteMode = 'light') {
	const memoizedTheme = React.useMemo(() => {
		return createThemeV2({} as Theme, mode);
	}, [mode]);
	return memoizedTheme;
}

export function createThemeV2(_theme: Theme, mode: PaletteMode = 'light') {
	const palette = createPalette({
		mode,
		...(mode === 'light' ? muiLightColors : muiDarkColors),
		...(mode === 'light' ? muiLightColorsExtra : muiDarkColorsExtra)
	});
	let theme = createTheme({
		palette,
		typography: {
			...(muiTypography as Typography),
			fontFamily: 'Roboto, sans-serif',
			fontWeightBold: 500
		},
		spacing: 8,
		breakpoints
	});

	/**
	 * Update theme with component styles
	 */

	theme = createTheme(_theme, theme);
	theme = createTheme(theme, {
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					/** @ts-ignore */
					root: ({ theme }) => {
						return `
        body {
          background: ${theme?.palette?.background?.default};
        }
        @font-face {
          font-family: 'Roboto';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/roboto-latin-400-normal.woff2') format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Roboto';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src:  url('/fonts/roboto-latin-500-normal.woff2') format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Roboto Mono';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src:  url('/fonts/roboto-mono-latin-400-normal.woff2') format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `;
					}
				}
			},
			MuiAlert: {
				styleOverrides: {
					/** @ts-ignore */
					root: ({ ownerState: { severity }, theme }) => {
						return {
							'& .MuiAlert-icon': {
								color: theme.palette[severity].main
							},
							...(muiTypography.body2 || {}),
							...py(8)
						};
					},
					/** @ts-ignore */
					standardSuccess: ({ theme }) => ({
						background: theme.palette?.successShades12p
					}),
					/** @ts-ignore */
					standardInfo: ({ theme }) => ({
						background: theme.palette?.infoShades12p
					}),
					/** @ts-ignore */
					standardWarning: ({ theme }) => ({
						background: theme.palette?.warningShades12p
					}),
					/** @ts-ignore */
					standardError: ({ theme }) => ({
						background: theme.palette?.errorShades12p
					})
				}
			},
			MuiAlertTitle: {
				styleOverrides: {
					root: {
						...(muiTypographyExtra.alertTitle || {}),
						...my(0)
					}
				}
			},
			MuiAvatar: {
				defaultProps: {
					variant: 'rounded'
				},
				styleOverrides: {
					root: {
						...(muiTypographyExtra.avatarInitials || {})
					}
				}
			},
			MuiBadge: {
				styleOverrides: {
					root: {
						...(muiTypographyExtra.badgeLabel || {}),
						...py(2)
					}
				}
			},
			MuiCard: {
				defaultProps: {
					variant: 'outlined'
				}
			},
			MuiCardHeader: {
				defaultProps: {
					titleTypographyProps: { variant: 'h6' }
				}
			},
			MuiChip: {
				styleOverrides: {
					labelSmall: {
						...(muiTypographyExtra.chipSmall || {})
					},
					labelMedium: {
						...(muiTypographyExtra.chip || {})
					},
					colorSuccess: ({ theme }) => ({
						background: green[50],
						color: green[900]
					}),
					colorWarning: ({ theme }) => ({
						background: orange[50],
						color: orange[900]
					}),
					colorInfo: ({ theme }) => ({
						background: lightBlue[50],
						color: lightBlue[900]
					}),
					colorSecondary: ({ theme }) => ({
						background: theme.palette?.secondaryShades12p,
						color: theme.palette?.secondary.dark
					})
				}
			},
			MuiDialog: {
				defaultProps: {
					fullWidth: true,
					maxWidth: 'md'
				}
			},
			MuiDialogActions: {
				styleOverrides: {
					/** @ts-ignore */
					root: ({ theme }) => ({
						padding: theme.spacing(2),
						[theme.breakpoints.up('md')]: {
							...px(theme.spacing(3)),
							...py(20)
						}
					})
				}
			},
			MuiDialogContent: {
				defaultProps: {
					dividers: true
				},
				styleOverrides: {
					/** @ts-ignore */
					root: ({ theme }) => ({
						...px(theme.spacing(2)),
						...py(16 - BORDER_WIDTH),
						[theme.breakpoints.up('md')]: {
							...px(theme.spacing(3))
						}
					})
				}
			},
			MuiDialogTitle: {
				styleOverrides: {
					/** @ts-ignore */
					root: ({ theme }) => ({
						padding: theme.spacing(2),
						[theme.breakpoints.up('md')]: {
							...px(theme.spacing(3)),
							...py(20)
						}
					})
				}
			},
			MuiButtonBase: {
				defaultProps: {
					size: 'medium',
					variant: 'contained'
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 4,
						minWidth: 100
					},
					sizeLarge: {
						...(muiTypographyExtra.buttonLarge || {}),
						...px(22),
						...py(buttonStyles.large.py)
					},
					sizeMedium: {
						...(muiTypographyExtra?.buttonMedium || {}),
						...px(16),
						...py(buttonStyles.medium.py)
					},
					sizeSmall: {
						...(muiTypographyExtra?.buttonSmall || {}),
						...px(10),
						...py(buttonStyles.small.py)
					},
					outlinedSizeLarge: {
						...px(22 - BORDER_WIDTH),
						...py(buttonStyles.large.py - BORDER_WIDTH)
					},
					outlinedSizeMedium: {
						...px(16 - BORDER_WIDTH),
						...py(buttonStyles.medium.py - BORDER_WIDTH)
					},
					outlinedSizeSmall: {
						...px(10 - BORDER_WIDTH),
						...py(buttonStyles.small.py - BORDER_WIDTH)
					},
					textSizeLarge: {
						...px(8)
					},
					textSizeMedium: {
						...px(8)
					},
					textSizeSmall: {
						...px(4)
					},
					text: {
						minWidth: 0
					}
				}
			},
			MuiFormControl: {
				defaultProps: {
					size: 'medium'
				}
			},
			MuiBackdrop: {
				styleOverrides: {
					/** @ts-ignore */
					root: ({ theme }) => ({
						backgroundColor: theme.palette?.otherBackdropOverlay,
						backdropFilter: 'blur(3px)'
					})
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						...(muiTypographyExtra.inputText || {})
					},
					input: {
						...(muiTypographyExtra.inputText || {}),
						height: inputTextLineHeight
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					input: {
						...px(12),
						...py(inputStyles.medium.py)
					},
					inputSizeSmall: {
						...px(12),
						...py(inputStyles.small.py)
					}
				}
			},
			MuiTextField: {
				defaultProps: {
					variant: 'outlined',
					size: 'medium',
					InputLabelProps: {
						shrink: true
					}
				}
			},
			MuiPaper: {
				defaultProps: {
					elevation: 0
				}
			}
		}
	});
	return theme;
}
