import React from 'react';
import { Alert, AlertColor, AlertTitle, Avatar, Stack, Typography } from '@mui/material';
import { dateTime } from '../../lib/dateHelper';
import { UIOrderComment, UIOrderStatus } from '@api/v1';
import { getStatusChangeLabel } from '../../core/orders/single/Comment';
import { useTranslation } from 'react-i18next';
import { Message } from './Message';

export const OrderMessage = ({ item: comment }: { item: UIOrderComment }) => {
	const { t } = useTranslation();
	const { text, author, source, statusChange, createdAt } = comment;
	const { avatar, initials, type } = author;
	const isRightAligned = type === 'INTERNAL';

	if (source !== 'CHANGE') {
		return <Message item={comment} />;
	}

	return (
		<Stack gap={1} direction={isRightAligned ? 'row-reverse' : 'row'}>
			<Avatar variant="circular" src={avatar}>
				{avatar ? '' : initials}
			</Avatar>
			<Stack alignItems={isRightAligned ? 'flex-end' : 'flex-start'}>
				<Alert severity={getStatusVariant(statusChange)}>
					<AlertTitle
						dangerouslySetInnerHTML={{
							__html: statusChange
								? `${t('orders:comment_status_change_v2', {
									status: getStatusChangeLabel(statusChange, t)
								})}<br>${text}`
								: text
						}}
					/>
				</Alert>
				<Typography variant="caption">
					{dateTime(createdAt).toFormat('dd.MM.yyyy, HH:mm')}
				</Typography>
			</Stack>
		</Stack>
	);
};

export const getStatusVariant = (status: UIOrderStatus) =>
	(({
		DECLINED: 'error',
		FINISHED: 'success',
		IN_PROGRESS: 'info',
		OPEN: 'warning'
	}[status] ?? 'info') as AlertColor);
