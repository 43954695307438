import { BPrefillRegistrationData, BRegistrationSubmit, AccountCategory } from '@api/v1';
import { pick } from 'lodash';
import React from 'react';
import { Trans } from 'react-i18next';
import { i18n } from '../../i18n';
import { api } from '../../lib/api';
import { createConstants, createAsyncConstants } from '../../lib/createConstants';
import { showNotification } from '../actions';
import { State } from '../reducers';
import { createStandardAction } from 'typesafe-actions';

export const constants = createConstants(
	[
		'SET_REGISTRATION_STATUS',
		'GET_REGISTRATION_DATA',
		'PATCH_REGISTRATION_DATA',
		'SET_FIRST_NAME',
		'SET_LAST_NAME',
		'SET_COMPANY_NAME',
		'SET_EMAIL',
		'SET_TELEPHONE',
		'SET_TOKEN',
		'SET_STREET',
		'SET_COMPANY_DESCRIPTION',
		'SET_COMPANY_WEBSITE',
		'SET_POSTAL_CODE',
		'SET_LOCALITY',
		'SET_COUNTRY',
		'SET_PASSWORD',
		'SET_CONFIRM_PASSWORD',
		'REGISTRATION_GET',
		'SET_AGREED_TO_TERMS',
		'SET_SUBSCRIBED_TO_NEWSLETTER',
		'SET_TASK_AREAS',
		'SET_RADIUS',
		'SET_TOKEN_ISSUER',
		'SET_HAS_NEW_PRICING'
	],
	{ prefix: 'REGISTRATION' }
);

export const asyncConstants = createAsyncConstants(
	['CHECK_TOKEN', 'CHECK_REGISTRATION_JWT', 'REGISTRATION_SUBMIT'],
	{ prefix: 'REGISTRATION' }
);

export const checkToken = (token: string) => (dispatch) =>
	dispatch({
		type: asyncConstants.CHECK_TOKEN.TYPE,
		payload: api
			.get(`/api/v1/registration/actions/check_token?token=${token}`)
			.then((data) => {
				dispatch(
					showNotification(i18n.t('general:notifications.registration_code_valid'), 'success')
				);
				return data;
			})
			.catch((err) => {
				const message = err.response.data?.message;
				const propertyManager = message ? message.split(': ')[1] : null;
				dispatch(setPropertyManager(propertyManager));
				throw err;
			})
	});

export const submitRegistrationV2 = (plan, callback) => (dispatch, getState: () => State) => {
	const { registration } = getState();
	return dispatch({
		type: asyncConstants.REGISTRATION_SUBMIT.TYPE,
		payload: api
			.post('/api/v1/registration/actions/register', { ...registration, plan })
			.then(callback)
			.catch((err) => {
				dispatch(
					showNotification(
						React.createElement(Trans, {
							i18nKey: 'registration:error_during_registration',
							components: [React.createElement('a', { href: 'mailto:support' })]
						}),
						'error'
					)
				);
				throw err;
			})
	});
};

export const submit = (callback) => (dispatch, getState: () => State) => {
	const _body = pick(getState().registration, [
		'token',
		'firstName',
		'lastName',
		'email',
		'telephone',
		'password',
		'companyName',
		'street',
		'postalCode',
		'locality',
		'country',
		'category',
		'subscribedToNewsletter',
		'companyDescription',
		'companyWebsite',
		'taskAreas',
		'radius'
	]);
	const body = { companyDescription: '', companyWebsite: '', taskAreas: '', radius: '0', ..._body };

	return dispatch({
		type: asyncConstants.REGISTRATION_SUBMIT.TYPE,
		payload: api
			.post<BRegistrationSubmit>('/api/v1/registration/actions/register', body)
			.then(callback)
			.catch((err) => {
				dispatch(
					showNotification(
						React.createElement(Trans, {
							i18nKey: 'registration:error_during_registration',
							components: [
								React.createElement(
									'a',
									{ href: 'mailto:support@relay.immo' },
									'support@relay.immo'
								)
							]
						}),
						'error'
					)
				);
				throw err;
			})
	});
};

export const checkJwtInRegistrationUrl = (jwt: string) => ({
	type: asyncConstants.CHECK_REGISTRATION_JWT.TYPE,
	payload: api.get<BPrefillRegistrationData>(`/api/v1/registration/actions/check_jwt?token=${jwt}`)
});
export const setToken = (token: string) => ({
	type: constants.SET_TOKEN,
	payload: token
});
export const setPropertyManager = (propertyManager: string) => ({
	type: constants.SET_TOKEN_ISSUER,
	payload: propertyManager
});
export const setFirstName = (firstName: string) => ({
	type: constants.SET_FIRST_NAME,
	payload: firstName
});
export const setLastName = (lastName: string) => ({
	type: constants.SET_LAST_NAME,
	payload: lastName
});
export const setEmail = (email: string) => ({
	type: constants.SET_EMAIL,
	payload: email
});
export const setTelephone = (telephone: string) => ({
	type: constants.SET_TELEPHONE,
	payload: telephone
});
export const setPassword = (password: string) => ({
	type: constants.SET_PASSWORD,
	payload: password
});
export const setConfirmPassword = (confirmPassword: string) => ({
	type: constants.SET_CONFIRM_PASSWORD,
	payload: confirmPassword
});
export const setCompanyName = (companyName: string) => ({
	type: constants.SET_COMPANY_NAME,
	payload: companyName
});
export const setAccountCategory = createStandardAction(
	'registration/set_account_category'
)<AccountCategory>();
export const setStreet = (street: string) => ({
	type: constants.SET_STREET,
	payload: street
});
export const setPostalCode = (postalCode: string) => ({
	type: constants.SET_POSTAL_CODE,
	payload: postalCode
});
export const setLocality = (locality: string) => ({
	type: constants.SET_LOCALITY,
	payload: locality
});
export const setCountry = (country: string) => ({
	type: constants.SET_COUNTRY,
	payload: country
});
export const setAgreedToTerms = (agreed: boolean) => ({
	type: constants.SET_AGREED_TO_TERMS,
	payload: agreed
});
export const setSubscribedToNewsletter = (subscribed: boolean) => ({
	type: constants.SET_SUBSCRIBED_TO_NEWSLETTER,
	payload: subscribed
});
export const setCompanyDescription = (companyDescription: string) => ({
	type: constants.SET_COMPANY_DESCRIPTION,
	payload: companyDescription
});
export const setCompanyWebsite = (setCompanyWebsite: string) => ({
	type: constants.SET_COMPANY_WEBSITE,
	payload: setCompanyWebsite
});
export const setTaskAreas = (taskAreas: []) => ({
	type: constants.SET_TASK_AREAS,
	payload: taskAreas
});
export const setRadius = (radius: unknown) => ({
	type: constants.SET_RADIUS,
	payload: Number(radius)
});
