import { UIOfferRequestComment, UIOffer } from '@api/v1';
import { sortGroupByDate } from '../../../lib/dateHelper';
import * as React from 'react';
import { GroupCard } from '../../../components/GroupCard';
import { OfferRequestComment } from './Comment';
import { Offer } from './Offer';

interface CommentContainerProps {
	comments: UIOfferRequestComment[];
	offers: UIOffer[];
	assignRef: (el: HTMLElement, index: number) => void;
}

export const CommentContainer = ({ comments, offers, assignRef }: CommentContainerProps) => {
	const offerWithIndex = offers.map((offer, idx) => ({
		...offer,
		index: idx
	}));
	const listData = [...comments, ...offerWithIndex];
	if (!listData || !listData.length) return null;
	const listGroupsByDate = sortGroupByDate(listData);
	return (
		<>
			{listGroupsByDate.map((group) => (
				<GroupCard key={group[0]} group={group} Item={DetailListItem} assignRef={assignRef} />
			))}
		</>
	);
};

export interface UIOfferWithIndex extends UIOffer {
	index: number;
}

const DetailListItem = ({
	item,
	assignRef
}: {
	item: UIOfferWithIndex | UIOfferRequestComment;
	assignRef: (el: HTMLElement, index: number) => void;
}) =>
	item.hasOwnProperty('source') ? (
		<OfferRequestComment comment={item as UIOfferRequestComment} />
	) : (
		<Offer offer={item as UIOfferWithIndex} assignRef={assignRef} />
	);
