import { APIRatings } from '@api/v1';
import { combineReducers, Reducer } from 'redux';
import {
	EmployeeCreationDialogState,
	reducers as employeeCreationDialog
} from '../components/EmployeeCreationDialog/reducers';
import { ProfileEditState, reducers as profileEdit } from '../core/profile/reducers';
import { CustomersReducers, CustomersState } from './customers/reducers';
import { reducers as sysadmin, SysadminState } from '../sysadmin/reducers';
import { constants, NotificationVariant } from './actions';
import { adminReducers, AdminState } from './admin/reducers';
import { PropertiesReducers, PropertiesState } from './properties/reducers';
import { InsurancesReducers, InsurancesState } from './insurances/reducers';
import { AuthState, reducers as auth } from './auth/reducers';
import { DocumentState, reducers as documentReducers } from './documents/reducers';
import { AppointmentState, reducers as appointmentReducers } from './appointments/reducers';
import { OrdersState, reducers as orders } from './orders/reducers';
import { OfferRequestsState, reducers as offerRequests } from './offer-requests/reducers';
import { reducers as registration, RegistrationState } from './registration/reducers';
import { reducers as sidebar, SidebarState } from './sidebar/reducers';
import { OrderReplyState, reducers as orderReply } from './order-reply/reducers';
import { constants as profileEditConstants } from './profile/actions';
import { Status } from '../lib/createConstants';
import { constants as authConstants } from './auth/actions';
import { reducers as acl } from './acl/reducers';
import { AccountSpecificFeature, Feature } from './acl/features';
import { ticketsReducers, TicketsState } from './tickets/reducers';

export interface State {
	auth: AuthState;
	admin: AdminState;
	offerRequests: OfferRequestsState;
	orders: OrdersState;
	orderReply: OrderReplyState;
	appointments: AppointmentState;
	documents: DocumentState;
	notification: NotificationState;
	sidebar: SidebarState;
	sysadmin: SysadminState;
	registration: RegistrationState;
	employeeCreationDialog: EmployeeCreationDialogState;
	profileEdit: ProfileEditState;
	customers: CustomersState;
	properties: PropertiesState;
	insurances: InsurancesState;
	ratingState: APIRatings | null;
	acl: {
		accountSpecificFeatures: `${AccountSpecificFeature}`[];
		features: `${Feature}`[];
	};
	tickets: TicketsState;
}

interface NotificationState {
	open: boolean;
	message: string;
	variant: NotificationVariant;
	noAutoHide: boolean;
}

const notification = combineReducers<NotificationState>({
	noAutoHide: (state = true, action) => {
		switch (action.type) {
			case constants.SHOW_NOTIFICATION:
				return action.payload.noAutoHide || false;
			default:
				return state;
		}
	},
	open: (state = false, action) => {
		switch (action.type) {
			case constants.HIDE_NOTIFICATION:
				return false;
			case constants.SHOW_NOTIFICATION:
				return true;
			default:
				return state;
		}
	},
	message: (state = '', action) => {
		switch (action.type) {
			case constants.SHOW_NOTIFICATION:
				return action.payload.message;
			default:
				return state;
		}
	},
	variant: (state = 'info', action) => {
		switch (action.type) {
			case constants.SHOW_NOTIFICATION:
				return action.payload.variant;
			default:
				return state;
		}
	}
});

const ratingReducer = <T>(state: T, action: { type: string; payload: T }) => {
	switch (action.type) {
		case profileEditConstants.FETCH_RATINGS + Status.PENDING:
		case profileEditConstants.FETCH_RATINGS + Status.REJECTED:
			return null;
		case profileEditConstants.FETCH_RATINGS + Status.FULFILLED:
			return action.payload;
		default:
			return state;
	}
};

export const reducers: Reducer<State> = (orgState, action) => {
	const state = action.type !== authConstants.LOGOUT + '_FULFILLED' ? orgState : ({} as State);
	return {
		sysadmin: sysadmin(state.sysadmin, action),
		auth: auth(state.auth, action),
		sidebar: sidebar(state.sidebar, action),
		notification: notification(state.notification, action),
		registration: registration(state.registration, action),
		offerRequests: offerRequests(state.offerRequests, action),
		orders: orders(state.orders, action),
		orderReply: orderReply(state.orderReply, action),
		appointments: appointmentReducers(state.appointments, action),
		documents: documentReducers(state.documents, action),
		employeeCreationDialog: employeeCreationDialog(state.employeeCreationDialog, action),
		profileEdit: profileEdit(state.profileEdit, action),
		customers: CustomersReducers(state.customers, action),
		properties: PropertiesReducers(state.properties, action),
		insurances: InsurancesReducers(state.insurances, action),
		admin: adminReducers(state.admin, action),
		ratingState: ratingReducer(
			state.ratingState,
			action as { type: string; payload: APIRatings | null }
		),
		acl: acl(state.acl, action),
		tickets: ticketsReducers(state.tickets, action)
	};
};
