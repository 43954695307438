import * as React from 'react';
import { TextField, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import { closeEditDialog, editCustomer, setInternalId, setAutoAssignUserId } from './actions';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Page from '../../mui-custom/Page/Page';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';

const Input = ({ sm = 12, label = '', defaultValue = '', onChange = null }) => (
	<Grid item sm={sm} xs={12}>
		<TextField
			label={label}
			defaultValue={defaultValue}
			onChange={onChange}
			disabled={!onChange}
			fullWidth
			variant="outlined"
		/>
	</Grid>
);

export const EditingPage = ({ navBack = null }) => {
	const { t } = useTranslation();
	const { customer, possibleAutoAssignUsers } = useSelector((state: State) => ({
		customer: state?.customers?.editDialog?.customer,
		possibleAutoAssignUsers: state?.customers?.list?.possibleAutoAssignUsers
	}));
	const dispatch = useDispatch();
	const handleSubmit = (editedCustomer) => {
		dispatch(editCustomer(editedCustomer));
		dispatch(closeEditDialog());
	};
	const [delayTimer, setDelayTimer] = React.useState<NodeJS.Timer>();
	const handleOnChangeInternalId = (e) => {
		clearTimeout(delayTimer);
		setDelayTimer(setTimeout(() => dispatch(setInternalId(e.target.value)), 500));
	};

	return (
		<Page
			style={{ maxWidth: '50rem' }}
			headline={t('contact:edit_dialog_title')}
			actionBar={
				<ActionBar
					primary={{
						children: t('customers:edit_dialog_save'),
						onClick: () => {
							handleSubmit(customer);
							navBack();
						}
					}}
					secondary={{
						children: t('customers:edit_dialog_cancel'),
						onClick: () => {
							dispatch(closeEditDialog());
							navBack();
						}
					}}
				/>
			}
		>
			{!!customer && (
				<Grid container spacing={2}>
					<Input
						label={t('customers:edit_dialog_internal_id')}
						defaultValue={customer.internalId}
						onChange={handleOnChangeInternalId}
					/>
					<Input defaultValue={customer.name} label={t('customers:edit_dialog_name')} />
					<Input
						sm={6}
						label={t('contact:edit_dialog_street_address')}
						defaultValue={customer.address?.streetAddress}
					/>
					<Input
						sm={6}
						label={t('contact:edit_dialog_postal_code')}
						defaultValue={customer.address?.postalCode}
					/>
					<Input
						sm={6}
						label={t('customers:edit_dialog_locality')}
						defaultValue={customer.address?.locality}
					/>
					<Input
						sm={6}
						label={t('customers:edit_dialog_country')}
						defaultValue={customer.address?.country}
					/>
					<Input
						sm={6}
						label={t('contact:edit_dialog_email_address')}
						defaultValue={customer.emailAddress}
					/>
					<Input
						sm={6}
						label={t('contact:edit_dialog_telephone')}
						defaultValue={customer.telephone}
					/>
					<Grid item xs={12}>
						<Typography variant="h6">{t('customers:edit_auto_assignee')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2">{t('customers:edit_auto_assignee_help')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							id="auto-assignee"
							onChange={(event, value) =>
								// @ts-ignore
								dispatch(setAutoAssignUserId(value ? value.id : null))
							}
							options={possibleAutoAssignUsers}
							getOptionLabel={(option) => (option ? option.firstName + ' ' + option.lastName : '')}
							value={
								possibleAutoAssignUsers.find((user) => user.id === customer.autoAssignUserId) ||
								null
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t('contact:edit_auto_assignee_label')}
									margin="normal"
									variant="outlined"
								/>
							)}
						/>
					</Grid>
				</Grid>
			)}
		</Page>
	);
};
