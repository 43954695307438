import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	flex: {
		display: 'flex',
		alignItems: 'start'
	},
	icon: {
		marginRight: theme.spacing(1)
	},
	text: {
		textAlign: 'start',
		paddingTop: theme.spacing(0.25)
	}
}));

interface IconicTextProps {
	icon: React.ReactNode;
	text: React.ReactNode;
	variant?: string;
	className?: string;
}

export const IconicText = ({ icon, text, variant, className }: IconicTextProps) => {
	const classes = useStyles();
	const currentVariant = variant || 'body2';
	return (
		<div className={classNames(classes.flex, className)}>
			<div className={classes.icon}>{icon}</div>
			<Typography variant={currentVariant} className={classes.text}>
				{text}
			</Typography>
		</div>
	);
};
