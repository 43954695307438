import { useSelector } from 'react-redux';
import { PermissionType } from '@api/v1';
import { State } from '../reducers';

export function usePermission<T extends PermissionType>(permission: T) {
	const permissions: { [key in T]?: boolean } = useSelector(
		(state: State) => state.auth?.user?.permissions
	);
	return permissions?.[permission] ?? false;
}
