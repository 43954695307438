import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Headline5 } from '../../../mui-custom/Headline5';
import { IconListedItem } from '../../../mui-custom/IconListedItem';
import { StarBorderOutlined } from '@mui/icons-material';

export const ProductSection: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const { palette } = useTheme();
	return (
		<Stack sx={{ alignItems: 'center', gap: 4, py: 4, backgroundColor: palette.background.paper }}>
			<Stack sx={{ alignItems: 'center', gap: 1 }}>
				<Typography color={palette.primary.main}>
					{t('registration:starter_page_product').toUpperCase()}
				</Typography>
				<Headline5>{t('registration:starter_page_product_headline')}</Headline5>
			</Stack>
			<Grid container>
				<Box
					component="img"
					sx={{
						alignSelf: 'center',
						height: '100%',
						width: 'auto',
						m: 'auto',
						maxHeight: { xs: 200, md: 400 },
						maxWidth: { xs: 300, md: 600 }
					}}
					alt="relay screenshots"
					src="./../../static/images/relay-screenshots.png"
				/>

				<Grid item xs={12} md={6} sx={{ gap: 1, m: 'auto', p: 2 }}>
					<IconListedItem
						headline={t('registration:starter_page_product_feature_1_headline')}
						icon={<StarBorderOutlined />}
						text={t('registration:starter_page_product_feature_1_text')}
					/>
					<IconListedItem
						headline={t('registration:starter_page_product_feature_2_headline')}
						icon={<StarBorderOutlined />}
						text={t('registration:starter_page_product_feature_2_text')}
					/>
					<IconListedItem
						headline={t('registration:starter_page_product_feature_3_headline')}
						icon={<StarBorderOutlined />}
						text={t('registration:starter_page_product_feature_3_text')}
					/>
				</Grid>
			</Grid>
		</Stack>
	);
};
