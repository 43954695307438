import {
	UICustomer,
	UIEmployee,
	UIOrder,
	UIOrdersList,
	UIOrderStatistics,
	UIOrderStatus
} from '@api/v1';
import _ from 'lodash';
import { combineReducers } from 'redux';
import { handleAddFile, handleChangeFile, handleRemoveFile } from '../../components/Dropzone';
import { DropzoneFile } from '../../components/Dropzone/types';
import {
	constants,
	setDueDateDialogOpen,
	changeDueDate,
	changeFollowUpDate,
	closeTitleDialog,
	saveTitle,
	openTitleDialog,
	changeTitle,
	changeAcceptComment,
	closeAcceptDialog,
	openAcceptDialog,
	accept,
	openDeclineDialog,
	closeDeclineDialog,
	changeDeclineComment,
	decline,
	saveInternalId,
	changeInternalId,
	openInternalIdDialog,
	closeInternalIdDialog,
	assignOrder,
	appendOrdersToList,
	getSignedUrlForZippedAttachments,
	asyncConstants
} from './actions';
import { createReducer } from '../../redux/createReducer';

const PENDING = '_PENDING';
const REJECTED = '_REJECTED';
const FULFILLED = '_FULFILLED';

interface CommentComposeState {
	readonly files: DropzoneFile[];
	readonly fileDialogOpen: boolean;
	readonly isAdding: boolean;
	isInternal: boolean;
}
interface CommentDrafts {
	[index: number]: string;
}
interface AcceptDialogState {
	readonly open: boolean;
	readonly comment: string;
	readonly isProcessing: boolean;
}
interface InternalIdDialogState {
	readonly open: boolean;
	readonly internalId: string;
}
interface TitleDialogState {
	readonly open: boolean;
	readonly value: string;
}
interface AddDialogState {
	open: boolean;
	readonly files: DropzoneFile[];
}
interface MakeExternalDialogState {
	open: boolean;
}
interface DeclineDialogState {
	readonly open: boolean;
	readonly comment: string;
	isProcessing: boolean;
}
interface FinishDialogState {
	readonly open: boolean;
	readonly comment: string;
	readonly files: DropzoneFile[];
}
interface DueDateDialogState {
	readonly open: boolean;
}
interface SingleState {
	readonly order: UIOrder;
	readonly commentCompose: CommentComposeState;
	readonly acceptDialog: AcceptDialogState;
	readonly declineDialog: DeclineDialogState;
	readonly finishDialog: FinishDialogState;
	readonly makeExternalDialog: MakeExternalDialogState;
	readonly internalIdDialog: InternalIdDialogState;
	readonly titleDialog: TitleDialogState;
	readonly dueDateDialog: DueDateDialogState;
	hasUnreadNotifications?: boolean;
	readonly editDialog: EditDialogState;
}
export interface FiltersState {
	readonly status: UIOrderStatus | 'ALL';
	readonly assignee: UIEmployee | { id: string };
	readonly customer: UICustomer | { id: string };
	readonly postalCode: string;
	readonly identifier: string;
	readonly dialogOpen: boolean;
	readonly fullText: string;
	readonly collapsed: boolean;
	readonly street: string;
	readonly locality: string;
}

export interface OrdersState {
	readonly filters: FiltersState;
	readonly addDialog: AddDialogState;
	readonly list: UIOrdersList;
	readonly commentDrafts: CommentDrafts;
	readonly single: SingleState;
	readonly statistics: UIOrderStatistics;
	readonly isLoading?: boolean;
	readonly isChangingFilter?: boolean;
}

interface EditDialogState {
	open: boolean;
}

export const reducers = combineReducers<OrdersState>({
	isChangingFilter: createReducer(false)
		.handleAnyAction(constants.SET_FILTERS, () => true)
		.handleAnyAction(constants.FETCH_ORDER_LIST + FULFILLED, () => false),
	isLoading: createReducer(false)
		.handleAction(appendOrdersToList.success, () => false)
		.handleAnyAction(constants.SET_IS_LOADING, () => true),
	filters: combineReducers<FiltersState>({
		locality: (state = '', action) => {
			switch (action.type) {
				case constants.SET_LOCALITY_FILTER:
					return action.payload;
				case constants.SET_FILTERS:
					return action.payload.locality || '';
				default:
					return state;
			}
		},
		street: (state = '', action) => {
			switch (action.type) {
				case constants.SET_STREET_FILTER:
					return action.payload;
				case constants.SET_FILTERS:
					return action.payload.street || '';
				default:
					return state;
			}
		},
		collapsed: (state = false, action) => {
			switch (action.type) {
				case constants.SET_FILTERS_COLLAPSED:
					return action.payload;
				default:
					return state;
			}
		},
		fullText: (state = '', action) => {
			switch (action.type) {
				case constants.SET_FULL_TEXT_FILTER:
					return action.payload;
				default:
					return state;
			}
		},
		status: (state = 'OPEN', action) => {
			switch (action.type) {
				case constants.SET_STATUS_FILTER:
					return action.payload;
				case constants.SET_FILTERS:
					return action.payload.status || '';
				default:
					return state;
			}
		},
		assignee: (state = null, action) => {
			switch (action.type) {
				case constants.SET_ASSIGNEE_FILTER:
					return action.payload;
				case constants.SET_FILTERS:
					return action.payload.assignee || '';
				default:
					return state;
			}
		},
		customer: (state = null, action) => {
			switch (action.type) {
				case constants.SET_CUSTOMER_FILTER:
					return action.payload;
				case constants.SET_FILTERS:
					return action.payload.customer || '';
				default:
					return state;
			}
		},
		postalCode: (state = '', action) => {
			switch (action.type) {
				case constants.SET_POSTAL_CODE_FILTER:
					return action.payload;
				case constants.SET_FILTERS:
					return action.payload.postalCode || '';
				default:
					return state;
			}
		},
		identifier: (state = '', action) => {
			switch (action.type) {
				case constants.SET_IDENTIFIER_FILTER:
					return action.payload;
				case constants.SET_FILTERS:
					return action.payload.identifier || '';
				default:
					return state;
			}
		},
		dialogOpen: (state = false, action) => {
			switch (action.type) {
				case constants.OPEN_FILTER_DIALOG:
					return true;
				case constants.CLOSE_FILTER_DIALOG:
					return false;
				default:
					return state;
			}
		}
	}),
	list: createReducer<UIOrdersList>({
		data: null,
		metaData: { lastId: null, hasMore: null }
	})
		.handleAction(appendOrdersToList.success, (state, action) => ({
			data: [...state.data, ...action.payload.data],
			metaData: action.payload.metaData
		}))
		.handleAnyAction(constants.FETCH_ORDER + FULFILLED, (state, action) => {
			if (!state?.data?.length) return state;
			const newData = state.data.map((order) =>
				order.id === action.payload.id
					? { ...order, hasUnreadNotifications: action.payload.hasUnreadNotifications }
					: order
			);
			return { ...state, data: newData };
		})
		.handleAnyAction(constants.FETCH_ORDER_LIST + PENDING, () => null)
		.handleAnyAction(constants.FETCH_ORDER_LIST + REJECTED, () => {
			throw new Error('Orders could not be loaded');
		})
		.handleAnyAction(asyncConstants.FETCH.FULFILLED,  (state, { payload }) => payload)
		/* remove this redundant reducer later after testing */
		.handleAnyAction(constants.FETCH_ORDER_LIST + FULFILLED, (state, { payload }) => payload)
		.handleAction(accept.success, (state, action) => ({
			...state,
			data: state?.data?.filter((listEntry) => listEntry.id !== action.payload) || []
		}))
		.handleAction(decline.success, (state, action) => ({
			...state,
			data: state?.data?.filter((order) => order?.id !== action?.payload) || []
		}))
		.handleAnyAction(constants.FINISH_ORDER + FULFILLED, (state, action) => ({
			...state,
			data: state?.data?.filter((order) => order?.id !== action?.payload) || []
		}))
		.handleAction(saveTitle.success, (state, action) =>
			handleChangedOrderInList(state, action.payload)
		)
		.handleAction(assignOrder.success, (state, action) =>
			handleChangedOrderInList(state, action.payload)
		)
		.handleAction(saveInternalId.success, (state, action) =>
			handleChangedOrderInList(state, action.payload)
		)
		.handleAction(changeDueDate.success, (state, action) =>
			handleChangedOrderInList(state, action.payload)
		)
		.handleAction(changeFollowUpDate.success, (state, action) =>
			handleChangedOrderInList(state, action.payload)
		),
	statistics: (state = null, action) => {
		switch (action.type) {
			case constants.FETCH_ORDER_STATISTICS + FULFILLED:
				return action.payload;
			default:
				return state;
		}
	},
	commentDrafts: (state: CommentDrafts = {}, action) => {
		switch (action.type) {
			case constants.CHANGE_COMMENT_TEXT:
				return {
					...state,
					[action.meta]: action.payload
				};
			case constants.CREATE_ORDER_COMMENT + PENDING:
				return {
					...state,
					[action.meta]: ''
				};
			default:
				return state;
		}
	},
	single: combineReducers<SingleState>({
		order: createReducer<UIOrder>(null)
			.handleAnyAction(constants.FETCH_ORDER + PENDING, () => null)
			.handleAnyAction(constants.FETCH_ORDER + FULFILLED, (state, action) => action.payload)
			.handleAnyAction(constants.CREATE_ORDER_COMMENT + FULFILLED, (state, action) =>
				state?.id === action.payload.orderId
					? _.assign({}, state, {
							comments: _.concat([], state.comments, [action.payload])
					  })
					: state
			)
			.handleAction(assignOrder.success, (state, action) => {
				return _.assign({}, state, {
					assignee: action.payload.assignee,
					comments: action.payload.comments
				});
			})
			.handleAction(saveTitle.success, (state, action) => {
				return _.assign({}, state, {
					title: action.payload.title,
					comments: action.payload.comments
				});
			})
			.handleAction(changeDueDate.success, (state, action) => {
				return _.assign({}, state, {
					dueDate: action.payload.dueDate,
					comments: action.payload.comments
				});
			})
			.handleAction(changeFollowUpDate.success, (state, action) => {
				return _.assign({}, state, {
					followUpDate: action.payload.followUpDate,
					comments: action.payload.comments
				});
			})
			.handleAction(getSignedUrlForZippedAttachments.success, (state, action) => {
				return {
					...state,
					zippedAttachments: {
						url: action.payload.value,
						isProcessing: false
					}
				};
			})
			.handleAction(getSignedUrlForZippedAttachments.request, (state) => {
				return {
					...state,
					zippedAttachments: {
						...state.zippedAttachments,
						isProcessing: true
					}
				};
			})
			.handleAction(getSignedUrlForZippedAttachments.failure, (state) => {
				return {
					...state,
					zippedAttachments: {
						url: '',
						isProcessing: false
					}
				};
			}),
		commentCompose: combineReducers<CommentComposeState>({
			files: (state = [], action) => {
				switch (action.type) {
					case constants.ADD_COMMENT_FILE:
						return handleAddFile(state, action);
					case constants.CHANGE_COMMENT_FILE:
						return handleChangeFile(state, action);
					case constants.REMOVE_COMMENT_FILE:
						return handleRemoveFile(state, action);
					case constants.CREATE_ORDER_COMMENT + PENDING:
					case constants.REMOVE_ALL_COMMENT_FILES:
						return [];
					default:
						return state;
				}
			},
			fileDialogOpen: (state = false, action) => {
				switch (action.type) {
					case constants.OPEN_COMMENT_FILE_DIALOG:
						return true;
					case constants.CLOSE_COMMENT_FILE_DIALOG:
						return false;
					default:
						return state;
				}
			},
			isAdding: (state = false, action) => {
				switch (action.type) {
					case constants.CREATE_ORDER_COMMENT + PENDING:
						return true;
					case constants.CREATE_ORDER_COMMENT + REJECTED:
					case constants.CREATE_ORDER_COMMENT + FULFILLED:
						return false;
					default:
						return state;
				}
			},
			isInternal: (state = false, action) => {
				switch (action.type) {
					case constants.SWITCH_INTERNAL_NOTE:
						return action.payload;
					case constants.CREATE_ORDER_COMMENT + FULFILLED:
						return false;
					default:
						return state;
				}
			}
		}),
		declineDialog: combineReducers<DeclineDialogState>({
			open: createReducer(false)
				.handleAction(openDeclineDialog, () => true)
				.handleAction(closeDeclineDialog, () => false)
				.handleAction(decline.success, () => false),
			comment: createReducer('')
				.handleAction(changeDeclineComment, (state, { payload }) => payload)
				.handleAction(decline.success, () => ''),
			isProcessing: createReducer(false)
				.handleAction(decline.request, () => true)
				.handleAction(decline.failure, () => false)
				.handleAction(decline.success, () => false)
		}),
		acceptDialog: combineReducers<AcceptDialogState>({
			open: createReducer(false)
				.handleAction(closeAcceptDialog, () => false)
				.handleAction(openAcceptDialog, () => true)
				.handleAction(accept.success, () => false),
			comment: createReducer('')
				.handleAction(changeAcceptComment, (state, { payload }) => payload)
				.handleAction(accept.success, () => ''),
			isProcessing: createReducer(false)
				.handleAction(accept.request, () => true)
				.handleAction(accept.failure, () => false)
				.handleAction(accept.success, () => false)
		}),
		finishDialog: combineReducers<FinishDialogState>({
			open: (state = false, action) => {
				switch (action.type) {
					case constants.OPEN_FINISH_DIALOG:
						return true;
					case constants.CLOSE_FINISH_DIALOG:
						return false;
					case constants.FINISH_ORDER + FULFILLED:
						return false;
					default:
						return state;
				}
			},
			comment: (state = '', action) => {
				switch (action.type) {
					case constants.CHANGE_FINISH_COMMENT:
						return action.payload;
					case constants.FINISH_ORDER + FULFILLED:
						return '';
					default:
						return state;
				}
			},
			files: (state = [], action) => {
				switch (action.type) {
					case constants.ADD_FINISH_FILE:
						return handleAddFile(state, action);
					case constants.DELETE_FINISH_FILE:
						return handleRemoveFile(state, action);
					case constants.CHANGE_FINISH_FILE:
						return handleChangeFile(state, action);
					case constants.FINISH_ORDER + FULFILLED:
						return [];
					default:
						return state;
				}
			}
		}),
		internalIdDialog: combineReducers<InternalIdDialogState>({
			open: createReducer(false)
				.handleAction(openInternalIdDialog, () => true)
				.handleAction(closeInternalIdDialog, () => false)
				.handleAction(saveInternalId.success, () => false),
			internalId: createReducer('')
				.handleAction(saveInternalId.success, () => '')
				.handleAction(changeInternalId, (state, action) => action.payload)
		}),
		titleDialog: combineReducers<TitleDialogState>({
			open: createReducer(false)
				.handleAction(openTitleDialog, () => true)
				.handleAction(closeTitleDialog, () => false)
				.handleAction(saveTitle.success, () => false),
			value: createReducer<string>(null)
				.handleAction(changeTitle, (state, action) => action.payload)
				.handleAction(saveTitle.success, () => '')
		}),
		dueDateDialog: combineReducers<DueDateDialogState>({
			open: createReducer(false)
				.handleAction(setDueDateDialogOpen, (state, action) => action.payload)
				.handleAction(changeDueDate.success, () => false)
		}),
		makeExternalDialog: combineReducers<MakeExternalDialogState>({
			open: (state = false, action) => {
				switch (action.type) {
					case constants.OPEN_MAKE_EXTERNAL_DIALOG:
						return true;
					case constants.CLOSE_MAKE_EXTERNAL_DIALOG:
						return false;
					default:
						return state;
				}
			}
		}),
		editDialog: combineReducers<EditDialogState>({
			open: (state = false, action) => {
				switch (action.type) {
					case constants.OPEN_EDIT_ORDER_DIALOG:
						return true;
					case constants.CLOSE_EDIT_ORDER_DIALOG:
						return false;
					default:
						return state;
				}
			}
		})
	}),
	addDialog: combineReducers<AddDialogState>({
		open: (state = false, action) => {
			switch (action.type) {
				case constants.CLOSE_ADD_DIALOG:
					return false;
				case constants.OPEN_ADD_DIALOG:
					return true;
				default:
					return state;
			}
		},
		files: (state = [], action) => {
			switch (action.type) {
				case constants.ADD_ORDER_FILE:
					return handleAddFile(state, action);
				case constants.CHANGE_ORDER_FILE:
					return handleChangeFile(state, action);
				case constants.REMOVE_ORDER_FILE:
					return handleRemoveFile(state, action);
				case constants.REMOVE_ALL_ORDER_FILES:
					return [];
				default:
					return state;
			}
		}
	})
});

function handleChangedOrderInList(state: UIOrdersList, payload: UIOrder): UIOrdersList {
	return {
		...state,
		data: state.data.map((order) => {
			if (order.id !== payload.id) return order;
			return payload;
		})
	};
}
