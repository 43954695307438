import * as React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { AsyncSelect } from '../../components/AsyncSelect';
import { api } from '../../lib/api';

export class PropertySelect extends ValidatorComponent {
	renderValidatorComponent() {
		return (
			<AsyncSelect
				margin="normal"
				fullWidth={this.props.fullWidth}
				label={this.props.label}
				value={this.props.value}
				onChange={this.props.onChange}
				loadOptions={(search) =>
					api
						.get(`/api/v1/properties?searchTerm=${encodeURIComponent(search)}`)
						.then((data) => data.data.map((p) => ({ label: p.name, key: p.id })))
				}
				errorText={this.state.isValid ? null : this.getErrorMessage()}
			/>
		);
	}
}
