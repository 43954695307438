import { UIRawUser, UIRawUserList } from '@api/v1';
import { combineReducers } from 'redux';
import { createReducer } from '../../../redux/createReducer';
import {
	closeDeactivationDialog,
	closeDeletionDialog,
	closeInvitationDialog,
	deactivateUser,
	deleteUser,
	inviteUser,
	loadUsers,
	openDeactivationDialog,
	openDeletionDialog,
	openInvitationDialog
} from './actions';

export interface AdminUsersState {
	list: UIRawUserList;
	invitationDialog: AdminUsersInvitationDialogState;
	deactivationDialog: AdminUsersRemovalDialogState;
	deletionDialog: AdminUsersDeletionDialogState;
}

export interface AdminUsersInvitationDialogState {
	open: boolean;
	user: UIRawUser;
}

export interface AdminUsersRemovalDialogState {
	open: boolean;
	user: UIRawUser;
}
export interface AdminUsersDeletionDialogState {
	open: boolean;
	user: UIRawUser;
}

export const adminUsersReducers = combineReducers<AdminUsersState>({
	list: createReducer<UIRawUserList>(null).handleAction(
		loadUsers.success,
		(_state, action) => action.payload
	),
	invitationDialog: combineReducers<AdminUsersInvitationDialogState>({
		open: createReducer(false)
			.handleAction(openInvitationDialog, () => true)
			.handleAction(closeInvitationDialog, () => false)
			.handleAction(inviteUser.success, () => false),
		user: createReducer<UIRawUser>(null).handleAction(
			openInvitationDialog,
			(_state, action) => action.payload
		)
	}),
	deactivationDialog: combineReducers<AdminUsersRemovalDialogState>({
		open: createReducer(false)
			.handleAction(openDeactivationDialog, () => true)
			.handleAction(closeDeactivationDialog, () => false)
			.handleAction(deactivateUser.success, () => false),
		user: createReducer<UIRawUser>(null).handleAction(
			openDeactivationDialog,
			(_state, action) => action.payload
		)
	}),
	deletionDialog: combineReducers<AdminUsersDeletionDialogState>({
		open: createReducer(false)
			.handleAction(openDeletionDialog, () => true)
			.handleAction(closeDeletionDialog, () => false)
			.handleAction(deleteUser.success, () => false),
		user: createReducer<UIRawUser>(null).handleAction(
			openDeletionDialog,
			(_state, action) => action.payload
		)
	})
});
