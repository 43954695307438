import React, { useRef } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogProps } from '@mui/material/Dialog';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { setEmail, setFirstName, setLastName, setTelephone, handleSubmit } from './actions';
import { State } from '../../core/reducers';

import { useDispatch, useSelector } from 'react-redux';

interface EmployeeFormProps {
	onClose: DialogProps['onClose'];
}

export const EmployeeForm = ({ onClose }: EmployeeFormProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { email, firstName, lastName, telephone } = useSelector(
		(state: State) => state?.employeeCreationDialog
	);
	const ref = useRef(null);

	const onSubmit = async (event) => {
		// eslint-disable-next-line @typescript-eslint/await-thenable
		const value = await dispatch(handleSubmit());
		onClose(value);
	};

	return (
		<ValidatorForm
			debounceTime={300}
			ref={ref}
			onSubmit={onSubmit}
			onError={(errors) => console.log(errors)}
		>
			<DialogTitle>{t('employees:label_create')}</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<TextValidator
							label={t('employees:first_name')}
							validators={['required']}
							value={firstName}
							onChange={(e) => dispatch(setFirstName(e.target.value))}
							errorMessages={[t('general:required_message')]}
							fullWidth
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							label={t('employees:last_name')}
							name="lastName"
							value={lastName}
							onChange={(e) => dispatch(setLastName(e.target.value))}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							fullWidth
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							label={t('general:email_address')}
							value={email}
							name="email"
							onChange={(e) => dispatch(setEmail(e.target.value))}
							validators={['required', 'isEmail', 'isEmailAvailable']}
							errorMessages={[
								t('general:required_message'),
								t('validation:invalid_email_address'),
								t('validation:email_already_exists')
							]}
							fullWidth
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							label={t('employees:telephone')}
							value={telephone}
							name="telephone"
							onChange={(e) => dispatch(setTelephone(e.target.value))}
							validators={['required', 'isValidPhoneNumber']}
							errorMessages={[t('general:required_message'), t('validation:invalid_phone_number')]}
							fullWidth
							type="telephone"
							variant="standard"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={onClose}>
					{t('general:cta_cancel')}
				</Button>
				<Button color="primary" type="submit">
					{t('employees:cta_create')}
				</Button>
			</DialogActions>
		</ValidatorForm>
	);
};
