import React from 'react';
import { Avatar, Stack, Typography, useTheme } from '@mui/material';
import { dateTime } from '../../lib/dateHelper';
import { UIAttachment } from '@api/v1';
import { AttachmentThumbnail } from './AttachmentThumbnail';
import { useTranslation } from 'react-i18next';

export interface UIComment {
	id: number;
	text: string;
	author: UICommentAuthor;
	attachments: UIAttachment[];
	createdAt: Date | string;
	isInternal: boolean;
	isHtml?: boolean;
}

export interface UICommentAuthor {
	id: number;
	name: string;
	avatar?: string;
	initials: string;
	type: 'INTERNAL' | 'EXTERNAL';
}

export const Message = ({ item: comment }: { item: UIComment }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { text, author, isInternal, attachments, createdAt, isHtml } = comment;
	const { avatar, initials, type } = author;
	const isRightAligned = type === 'INTERNAL';
	const backGroundColor = isRightAligned
		? theme.palette.secondaryShades8p
		: theme.palette.primaryShades8p;
	return (
		<Stack gap={1} direction={isRightAligned ? 'row-reverse' : 'row'}>
			<Avatar variant="circular" src={avatar}>
				{avatar ? '' : initials}
			</Avatar>
			<Stack alignItems={isRightAligned ? 'flex-end' : 'flex-start'} sx={{ width: 1 }}>
				{isHtml ? (
					<Typography
						sx={{ borderRadius: 1, background: backGroundColor, p: 1 }}
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				) : (
					<Typography sx={{ borderRadius: 1, background: backGroundColor, p: 1 }}>
						{text}
					</Typography>
				)}
				{!!attachments?.length && (
					<Stack sx={{ gap: 0.5, width: 1 }}>
						{attachments.map((a) => (
							<AttachmentThumbnail
								key={a.id}
								link={a.url}
								thumbnail={a.thumbnailS3Key}
								backGroundColor={backGroundColor}
								fileName={a.name}
							/>
						))}
					</Stack>
				)}
				<Typography variant="caption">
					<Typography
						noWrap
						variant="caption"
						sx={{ mr: 1, color: isInternal ? theme.palette.grey[400] : 'orange' }}
					>
						{isInternal ? t('orders:comment_internal') : t('orders:comment_external')}
					</Typography>
					{dateTime(createdAt).toFormat('HH:mm')}
				</Typography>
			</Stack>
		</Stack>
	);
};
