import { combineReducers } from 'redux';
import { createReducer } from '../../redux/createReducer';
import { openSidebar, closeSidebar } from './actions';

export interface SidebarState {
	open: boolean;
}

export const reducers = combineReducers<SidebarState>({
	open: createReducer(false)
		.handleAction(openSidebar, () => true)
		.handleAction(closeSidebar, () => false)
});
