import { createAsyncConstants } from '../../lib/createConstants';
import { api } from '../api';

export const asyncConstants = createAsyncConstants(['GET_USERS'], {
	prefix: 'SYSADMIN'
});

export const getUsers = () => ({
	type: asyncConstants.GET_USERS.TYPE,
	payload: api.get('/api/sysadmin/users')
});
