import { CardMedia, Divider, Hidden } from '@mui/material';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { UIRegistrationSteps } from '../../data/enums';
import { checkJwtInRegistrationUrl } from './actions';
import { StarterPage } from './StarterPage';
import { InsertTokenForm } from './InsertTokenForm';
import { InsertUserDataForm } from './InsertUserDataForm';
import { InsertAccountDataForm } from './InsertAccountDataForm';
import { RegistrationProgress, constructQueryString } from './Progress';
import { LoggedOutView } from '../../components/LoggedOutView';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { RegistrationFinished } from './RegistrationFinished';

interface RegistrationAppProps extends RouteComponentProps<{}> {}

export const RegistrationApp: React.FC<RegistrationAppProps> = ({ location }) => {
	const dispatch = useDispatch();
	const query = qs.parse(location.search, {
		ignoreQueryPrefix: true
	});
	const { step } = query;
	const history = useHistory();
	const { palette } = useTheme();

	useEffect(() => {
		if (query?.token) {
			dispatch(checkJwtInRegistrationUrl(query.token as string));
		}
		history.push({
			pathname: location.pathname,
			search: constructQueryString({
				...(query?.selfRegistration && { selfRegistration: 'true' }),
				step: query?.selfRegistration
					? UIRegistrationSteps.INSERT_ACCOUNT_DATA
					: UIRegistrationSteps.INSERT_TOKEN
			})
		});
	}, []);

	const renderRegistrationForm = () => {
		switch (step) {
			case UIRegistrationSteps.INSERT_TOKEN:
				return <InsertTokenForm />;
			case UIRegistrationSteps.INSERT_ACCOUNT_DATA:
				return <InsertAccountDataForm />;
			case UIRegistrationSteps.INSERT_USER_DATA:
				return <InsertUserDataForm />;
			case UIRegistrationSteps.INSERT_PLAN:
				return <StarterPage />;
			case UIRegistrationSteps.REGISTRATION_FINISHED:
				return <RegistrationFinished />;
			default:
				return null;
		}
	};

	return (
		<LoggedOutView
			sx={
				step === UIRegistrationSteps.INSERT_PLAN
					? { width: 1, maxWidth: 'none', backgroundColor: palette.background.default }
					: { maxWidth: 720 }
			}
		>
			<CardMedia
				sx={{ height: '6rem', backgroundSize: 'contain', m: '4rem auto 2rem' }}
				image="./../../static/images/relay-logo.svg"
				title="relay"
			/>
			{step !== UIRegistrationSteps.INSERT_PLAN && (
				<>
					<Divider key="divider" />
					<Hidden smDown>
						<RegistrationProgress
							status={step as UIRegistrationSteps}
							isSelfRegistration={query?.selfRegistration === 'true'}
						/>
						<Divider key="divider" />
					</Hidden>
				</>
			)}
			{renderRegistrationForm()}
		</LoggedOutView>
	);
};
