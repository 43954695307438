import { api } from '../../lib/api';
import { createAsyncConstants, createConstants } from '../../lib/createConstants';
import { UIInsurance, UpdateInsuranceBody, DamageType } from '@api/v1';
import { showNotification } from '../actions';
import { i18n } from '../../../app/i18n';
import { State } from '../reducers';
import { debounce } from 'lodash';

export const constants = createConstants(['SET_IS_LOADING', 'CLEAR'], {
	prefix: 'INSURANCES'
});

export const asyncConstants = createAsyncConstants(['CREATE', 'FETCH', 'FETCH_MORE'], {
	prefix: 'INSURANCES'
});

export const fetchInsurances = (searchTerm?: string) => (dispatch) => {
	return dispatch({
		type: asyncConstants.FETCH.TYPE,
		payload: api.get('/api/v1/insurances', { params: { searchTerm } })
	});
};

export const applySearch = (searchTerm: string = undefined) => (dispatch) => {
	dispatch({
		type: constants.CLEAR
	});
	return dispatch(fetchInsurances(searchTerm));
};

export const fetchMoreInsurances = () => (dispatch) => {
	dispatch({
		type: constants.SET_IS_LOADING
	});
	return dispatch(debounce(appendInsurancesToList(), 1000));
};

export const appendInsurancesToList = () => (dispatch, getState: () => State) => {
	const metaData = getState().insurances?.list?.metaData;
	let lastId: number;
	if (metaData) {
		lastId = metaData.lastId;
	}
	return dispatch({
		type: asyncConstants.FETCH_MORE.TYPE,
		payload: api.get('/api/v1/insurances', { params: { afterId: lastId } })
	});
};

export const createInsurance = (insurance: UIInsurance) => (dispatch, _getState: () => State) => {
	return dispatch({
		type: asyncConstants.CREATE,
		payload: api.post('/api/v1/insurances', transformInsuranceData(insurance))
	})
		.then(() => {
			dispatch(showNotification(i18n.t('insurances:success_message'), 'success'));
			dispatch(fetchInsurances());
		})
		.catch((err) => {
			dispatch(showNotification(i18n.t('general:default_error'), 'error'));
			throw err;
		});
};

export const updateInsurance = (insurance: UIInsurance, searchTerm: string) => (
	dispatch,
	_getState: () => State
) => {
	return dispatch({
		type: asyncConstants.CREATE,
		payload: api.patch(`/api/v1/insurances/${insurance.id}`, transformInsuranceData(insurance))
	})
		.then(() => {
			dispatch(showNotification(i18n.t('insurances:success_message'), 'success'));
			dispatch(fetchInsurances(searchTerm));
		})
		.catch((err) => {
			dispatch(showNotification(i18n.t('general:default_error'), 'error'));
			throw err;
		});
};
export const deleteInsurance = (insurance: UIInsurance) => (dispatch, _getState: () => State) => {
	return dispatch({
		type: asyncConstants.CREATE,
		payload: api.delete(`/api/v1/insurances/${insurance.id}`)
	})
		.then(() => {
			dispatch(showNotification(i18n.t('insurances:success_message_delete'), 'success'));
			dispatch(fetchInsurances());
		})
		.catch((err) => {
			dispatch(showNotification(i18n.t('general:default_error'), 'error'));
			throw err;
		});
};

const transformInsuranceData = (insurance: UIInsurance): UpdateInsuranceBody => {
	return {
		status: insurance.status,
		description: insurance.description,
		externalId: insurance.externalId,
		provider: insurance.provider,
		expirationDate: insurance.expirationDate,
		damageType: (insurance.damageType as Array<{
			value: DamageType;
			label: string;
		}>)
			.map((damageType) => damageType.value)
			.join(','),
		properties: insurance.properties.map((property) => property.id),
		customerId: insurance.customer.id
	};
};
