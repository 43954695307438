import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { HelpOutline, ContactSupport } from '@mui/icons-material';
import { Link } from '@mui/material';

export const Header = styled('header')({
	display: 'flex',
	maxWidth: '220px',
	fontSize: '.875rem',
	alignSelf: 'start'
});

const CenterText = styled('div')({
	textAlign: 'center',
	margin: 'auto 10px'
});

export const BaseTileContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	maxWidth: 220,
	fontWeight: 500,
	color: '#69696B'
});

const Line = styled('div')({
	display: 'flex',
	maxWidth: '220px',
	fontSize: '.75rem',
	alignSelf: 'start'
});

const Contact = styled('div')({
	fontWeight: 400,
	fontSize: '.75rem',
	margin: '.75rem .75rem 0'
});

export const HelpLine = ({ text = '', href = '' }) => (
	<Line>
		<ContactSupport sx={{ m: 1.5 }} />
		<CenterText>
			<Link href={href}>{text}</Link>
		</CenterText>
	</Line>
);

export const HelpCenterTile = () => {
	const { t } = useTranslation();
	return (
		<BaseTileContainer>
			<Header>
				<HelpOutline sx={{ height: 48, width: 48 }} />
				<CenterText>{t('home:dashboard_help_center_tile_header')}</CenterText>
			</Header>
			<HelpLine
				text={t('home:dashboard_help_center_link_help')}
				href="https://hilfe.relay.immo/de-form/"
			/>
			<HelpLine
				text={t('home:dashboard_help_center_link_faq_admin')}
				href="https://hilfe.relay.immo/de-form/collections/1880623-administration"
			/>
			<HelpLine
				text={t('home:dashboard_help_center_link_faq_orders')}
				href="https://hilfe.relay.immo/de-form/collections/1839847-auftragsmanagement"
			/>
			<Contact>
				{t('home:dashboard_help_center_link_contact')}
				<a href="mailto:support@relay.immo">support@relay.immo</a>
			</Contact>
		</BaseTileContainer>
	);
};
