import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PrimaryButton from '../../../mui-custom/Button/PrimaryButton';
import { SecondaryButton } from '../../../mui-custom/Button';
import { useMobile } from '../../../hooks/useBreakpoints';
import { buyAdditionalOrder } from '../../admin/account/actions';

export const BuyCreditsModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isMobile = useMobile();

	const buyCredits = () => {
		dispatch(buyAdditionalOrder());
		onClose();
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{t('orders:add_dialog_additional_credits_tittle')}</DialogTitle>
			<DialogContent dividers={false}>
				{t('orders:add_dialog_additional_credits_text_1')}
				<br />
				<br />
				{t('orders:add_dialog_additional_credits_text_2')}
			</DialogContent>
			<DialogActions sx={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
				<SecondaryButton onClick={onClose}>
					{t('orders:add_dialog_additional_credits_button_cancel')}
				</SecondaryButton>
				<PrimaryButton onClick={buyCredits}>
					{t('orders:add_dialog_additional_credits_button_buy_credits')}
				</PrimaryButton>
			</DialogActions>
		</Dialog>
	);
};
