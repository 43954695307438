import { CircularProgress, FormControl, FormHelperText } from '@mui/material';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { emphasize, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import { WithStyles } from '@mui/styles';
import { DistributiveOmit } from '@mui/types';
import clsx from 'clsx';
import { CSSProperties, default as React } from 'react';
import { Trans } from 'react-i18next';
import Select, {
	MenuProps,
	MultiValueProps,
	OptionsType,
	PlaceholderProps,
	Props as SelectProps,
	SingleValueProps,
	ValueContainerProps,
	ValueType
} from 'react-select';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			height: theme.spacing(31),
			minWidth: theme.spacing(36)
		},
		input: {
			display: 'flex',
			padding: 0,
			height: 'auto'
		},
		valueContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			flex: 1,
			alignItems: 'center',
			overflow: 'hidden'
		},
		chip: {
			margin: theme.spacing(0.5, 0.25)
		},
		chipFocused: {
			backgroundColor: emphasize(
				theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
				0.08
			)
		},
		noOptionsMessage: {
			padding: theme.spacing(1, 2)
		},
		placeholder: {
			position: 'absolute',
			bottom: 6
		},
		menuSelectedItem: {
			fontWeight: 500
		},
		paper: {
			position: 'absolute',
			zIndex: 1,
			marginTop: theme.spacing(1),
			left: 0,
			right: 0
		},
		divider: {
			height: theme.spacing(2)
		},
		loadingIndicator: {
			width: theme.spacing(3)
		}
	});

function LoadingIndicator(props) {
	return <CircularProgress size={16} className={props.selectProps.classes.loadingIndicator} />;
}

function NoOptionsMessage(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

const inputComponent = React.forwardRef((props, inputRef) => {
	return <div ref={inputRef} {...props} />;
});

function Control(props) {
	const {
		children,
		innerProps,
		innerRef,
		selectProps: { classes, textFieldProps }
	} = props;

	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: classes.input,
					children,
					...innerProps
				}
			}}
			inputRef={innerRef}
			{...textFieldProps}
			variant="standard"
		/>
	);
}

function Option(props) {
	return (
		<MenuItem
			ref={props.innerRef}
			selected={props.isFocused}
			component="div"
			className={clsx({
				[props.selectProps.classes.menuSelectedItem]: props.isSelected
			})}
			{...props.innerProps}
		>
			{props.children}
		</MenuItem>
	);
}

type MuiPlaceholderProps<OptionType> = DistributiveOmit<
	PlaceholderProps<OptionType, boolean>,
	'innerProps'
> &
	Partial<Pick<PlaceholderProps<OptionType, boolean>, 'innerProps'>>;
function Placeholder<OptionType>(props: MuiPlaceholderProps<OptionType>) {
	const { selectProps, innerProps = {}, children } = props;
	return (
		<Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
			{children}
		</Typography>
	);
}

function SingleValue<OptionType>(props: SingleValueProps<OptionType>) {
	return (
		<Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function ValueContainer<OptionType>(props: ValueContainerProps<OptionType, boolean>) {
	return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue<OptionType>(props: MultiValueProps<OptionType>) {
	return (
		<Chip
			tabIndex={-1}
			label={props.children}
			className={clsx(props.selectProps.classes.chip, {
				[props.selectProps.classes.chipFocused]: props.isFocused
			})}
			onDelete={props.removeProps.onClick}
			deleteIcon={<CancelIcon {...props.removeProps} />}
		/>
	);
}

function Menu<OptionType>(props: MenuProps<OptionType, boolean>) {
	return (
		<Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
			{props.children}
		</Paper>
	);
}

export interface DefaultOptionType {
	label: string;
	key: string;
}

interface AsyncSelectProps<OptionType = DefaultOptionType> extends WithStyles<typeof styles> {
	label: React.ReactNode;
	value: ValueType<OptionType, boolean>;
	onChange: (value: ValueType<OptionType, boolean>) => void;
	loadOptions: (inputValue: string) => Promise<OptionType[]>;
	margin?: TextFieldProps['margin'];
	isMulti?: boolean;
	isClearable?: boolean;
	inputId?: string;
	placeholder?: React.ReactNode;
	noOptionsMessage?: SelectProps<OptionType>['noOptionsMessage'];
	fullWidth?: boolean;
	errorText?: React.ReactNode;
	disabled?: boolean;
}

interface AsyncSelectState<OptionType> {
	shrink: boolean;
	inputValue: string;
	isLoading: boolean;
	loadedOptions: OptionsType<OptionType>;
}

class AsyncSelect<OptionType> extends React.Component<
	AsyncSelectProps<OptionType>,
	AsyncSelectState<OptionType>
> {
	constructor(props: AsyncSelectProps<OptionType>) {
		super(props);
		this.state = {
			shrink: this.hasText(props),
			inputValue: '',
			isLoading: true,
			loadedOptions: []
		};
	}
	componentDidMount() {
		this.loadOptions('');
	}
	async loadOptions(inputValue: string) {
		try {
			this.setState({
				inputValue,
				isLoading: true
			});
			const loadedOptions = await this.props.loadOptions(inputValue);
			this.setState({
				loadedOptions,
				isLoading: false
			});
		} catch (err) {
			this.setState({
				isLoading: false
			});
		}
	}
	onBlur() {
		if (!this.hasText(this.props)) {
			this.setState({ shrink: false });
		}
	}
	hasText(props: AsyncSelectProps<OptionType>) {
		if (Array.isArray(props.value)) {
			return Boolean(props.value.length || props.placeholder);
		} else {
			return Boolean(props.value || props.placeholder);
		}
	}
	render() {
		const { classes } = this.props;

		const components = {
			Control,
			Menu,
			MultiValue,
			NoOptionsMessage,
			Option,
			Placeholder,
			SingleValue,
			ValueContainer,
			LoadingIndicator
		};

		const selectStyles = {
			input: (base: CSSProperties) => ({
				...base,
				// color: theme.palette.text.primary,
				'& input': {
					font: 'inherit'
				}
			})
		};
		return (
			<FormControl
				error={Boolean(this.props.errorText)}
				fullWidth={this.props.fullWidth}
				margin={this.props.margin}
				variant="standard"
			>
				<Select
					classes={classes}
					styles={selectStyles}
					inputId={this.props.inputId}
					textFieldProps={{
						label: this.props.label,
						error: Boolean(this.props.errorText),
						fullWidth: this.props.fullWidth,
						InputLabelProps: {
							htmlFor: this.props.inputId,
							shrink: this.state.shrink
						}
					}}
					isOptionSelected={(option) => {
						if (this.props.value) {
							return option.key === this.props.value.key;
						}
						return false;
					}}
					menuPosition="fixed"
					maxMenuHeight={220}
					placeholder={this.props.placeholder || ''}
					noOptionsMessage={
						this.props.noOptionsMessage ||
						(() => <Trans i18nKey="general:async_select_no_options" />)
					}
					loadingMessage={() => <Trans i18nKey="general:async_select_loading" />}
					inputValue={this.state.inputValue}
					onInputChange={(inputValue) => this.loadOptions(inputValue)}
					options={this.state.loadedOptions}
					filterOption={() => true}
					isLoading={this.state.isLoading}
					components={components}
					value={this.props.value}
					onChange={this.props.onChange}
					isClearable={this.props.isClearable}
					isMulti={this.props.isMulti}
					isDisabled={this.props.disabled}
					onFocus={() => this.setState({ shrink: true })}
					onBlur={this.onBlur.bind(this)}
				/>

				{this.props.errorText ? <FormHelperText>{this.props.errorText}</FormHelperText> : null}
			</FormControl>
		);
	}
}

const wrapped = withStyles(styles)(AsyncSelect);
export { wrapped as AsyncSelect };
