import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModifySearchOptions, modifySearch, setFilters, loadMoreTickets } from '../actions';
import { Alert, Card, Chip, TableCell, Typography, useTheme } from '@mui/material';
import { Theme } from '../../../theme';
import { State } from 'app/core/reducers';
import { useTranslation } from 'react-i18next';
import TableRowOrCard, { TableCtx } from '../../../mui-custom/TableRowOrCard';
import { dateTime } from '../../../lib/dateHelper';
import { Image } from '../../../components/Image';
import { useMobile } from '../../../hooks/useBreakpoints';
import { useCheckViewport } from '../../../hooks/useCheckViewport';
import { Filter } from '../../../mui-custom/Filter';
import {
	AnchorEnum,
	FilterTypeEnum,
	FiltersFromUrl
} from '../../../mui-custom/Filter/filters.types';
import { loadCustomers } from '../../customers/actions';
import { loadProperties } from '../../properties/actions';
import { UICustomer } from 'server/customers/browser.types';
import { UIProperty } from 'server/properties/browser.types';
import { useHistory } from 'react-router';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { mapTicketStatusToColor } from '../../../lib/types';
import { mapStatusToLabelTicket } from '../../../lib/mapStatusToLabel';
import { PriorityTag } from '../single/DetailSubPage';
import { PRIORITIES } from '../single/AddCustomFieldsPage';

export const LIMIT = 20;

export interface FiltersState {
	readonly customer: UICustomer | { id: string };
	readonly property: UIProperty | { id: string };
}

export const ListPage = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const isMobile = useMobile();
	const openTicket = (ticketId: number) => {
		history.push({
			pathname: `/app/tickets/detail/${ticketId}`
		});
	};
	const theme = useTheme<Theme>();
	const dispatch = useDispatch();

	const { ticketList } = useSelector((state: State) => state.tickets);
	const { list: customerList } = useSelector((state: State) => state.customers);
	const { list: propertyList } = useSelector((state: State) => state.properties);

	const [localFilters, setLocalFilters] = useState<Partial<FiltersFromUrl> | false>(false);
	const [offset, setOffset] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [existMoreTickets, setExistMoreTickets] = useState(true);

	useEffect(() => {
		dispatch(loadCustomers());
		dispatch(loadProperties());
		const _search: ModifySearchOptions = {
			mode: 'merge',
			data: { action: undefined }
		};
		const filtersFromUrl = modifySearch(history, _search);
		setLocalFilters(filtersFromUrl);
		dispatch(setFilters(filtersFromUrl));
	}, []);

	const handleFilterChange = (newValue) => {
		setOffset(0);
		setExistMoreTickets(true);
		newValue = {
			searchTerm: newValue?.searchTerm,
			customerIds: newValue?.customer?.join(',') || undefined,
			propertyIds: newValue?.property?.join(',') || undefined,
			statuses: newValue?.status?.join(',') || undefined,
			priorities: newValue?.priority?.join(',') || undefined
		};
		setLocalFilters(newValue);
		dispatch(setFilters(newValue));
	};

	const loadMore = () => {
		setExistMoreTickets(true);
		setIsLoading(true);
		dispatch(
			loadMoreTickets({ limit: LIMIT, offset: offset + LIMIT, filters: localFilters as object })
		);
	};

	useEffect(() => {
		setIsLoading(false);
		if (ticketList?.length < offset + LIMIT) {
			setExistMoreTickets(false);
		}
		setOffset(offset + LIMIT);
	}, [ticketList]);

	const lastElementRef = useRef<HTMLDivElement>(null);
	const isViewingLastElement = useCheckViewport(lastElementRef);
	useEffect(() => {
		if (isLoading) return;
		setIsLoading(true);
		setTimeout(() => {
			loadMore();
		}, 100);
	}, [isViewingLastElement]);

	return (
		<Card sx={{ overflow: 'auto' }}>
			{localFilters && (
				<Filter
					isChangingFilter={false}
					side={AnchorEnum.RIGHT}
					onChange={handleFilterChange}
					drawerHeadline={t('tickets:filter_switch_label')}
					textSearch={{
						filterKey: 'searchTerm',
						placeholder: t('tickets:filter_search_title'),
						defaultValue: localFilters?.searchTerm
					}}
					filters={[
						{
							filterType: FilterTypeEnum.CHIP,
							filterKey: 'customer',
							placeholder: t('tickets:label_customer'),
							items: customerList?.data
								?.filter((c) => c.casaviTenantId)
								?.map((customer) => ({
									label: customer.name,
									value: customer.id
								})),
							defaultValue: localFilters?.customerIds?.split(',') || []
						},
						{
							filterType: FilterTypeEnum.CHIP,
							filterKey: 'property',
							placeholder: t('tickets:label_property'),
							items: propertyList?.data?.map((property) => ({
								label: property.name,
								value: property.id
							})),
							defaultValue: localFilters?.propertyIds?.split(',') || []
						},
						{
							filterType: FilterTypeEnum.CHIP,
							filterKey: 'status',
							placeholder: t('tickets:label_status'),
							items: Object.entries(mapStatusToLabelTicket).map((e) => {
								return { value: e[0], label: t(e[1]) };
							}),
							defaultValue: localFilters?.statuses?.split(',') || []
						},
						{
							filterType: FilterTypeEnum.CHIP,
							filterKey: 'priority',
							placeholder: t('tickets:label_priority'),
							items: PRIORITIES.map((p) => {
								return { value: p.value, label: t(p.label) };
							}),
							defaultValue: localFilters?.priorities?.split(',') || []
						}
					]}
				/>
			)}
			{!ticketList?.length ? (
				<Alert severity="info" sx={{ mt: 2 }}>
					{t('tickets:list_none_here')}
				</Alert>
			) : (
				<TableCtx
					headers={[
						t('tickets:label_title'),
						t('tickets:label_createdAt'),
						t('tickets:label_status'),
						t('tickets:label_priority'),
						t('tickets:label_property_manager'),
						t('tickets:label_property'),
						t('tickets:label_attachment')
					]}
				>
					{ticketList?.map((ticket) => (
						<TableRowOrCard
							sx={{
								cursor: 'pointer',
								'&:hover': { backgroundColor: theme?.palette?.secondaryShades4p }
							}}
							onClick={() => openTicket(ticket.id)}
							key={ticket.id}
						>
							<TableCell>
								<Typography variant="h6">{ticket.title}</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body2">
									{dateTime(ticket.createdAt).toFormat('dd.MM.yyyy HH:mm')}
								</Typography>
							</TableCell>
							<TableCell>
								{ticket.status ? (
									<Chip
										color={t(mapTicketStatusToColor[ticket.status])}
										size="small"
										sx={{ borderRadius: '4px', minWidth: theme.spacing(11) }}
										label={t(mapStatusToLabelTicket[ticket.status])?.toUpperCase() ?? ticket.status}
									>
										{ticket.status}
									</Chip>
								) : (
									''
								)}
							</TableCell>
							<TableCell>
								<Typography variant="body2">
									<PriorityTag priority={ticket.priority} />
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1">{ticket?.Tenant?.name}</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1">{ticket?.Community?.name}</Typography>
							</TableCell>
							<TableCell>
								<div>
									<Image
										width={isMobile ? '100%' : 100}
										src={ticket?.Attachments[0]?.temporaryUrl || '/static/images/CardMedia.png'}
									/>
								</div>
							</TableCell>
						</TableRowOrCard>
					))}
				</TableCtx>
			)}
			{ticketList?.length > 0 && existMoreTickets && !isLoading && <div ref={lastElementRef}></div>}
			{isLoading && <LoadingScreen />}
		</Card>
	);
};
