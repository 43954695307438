import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../reducers';
import { closeDeletionDialog, deleteUser } from './actions';
import { getUserName } from './utils';
import { ButtonColor } from '../../../mui-custom/Button/PrimaryButton';
import CustomConfirmDialog from '../../../mui-custom/ConfirmDialog/ConfirmDialog';

export const AdminUserDeletionDialogV2 = () => {
	const { open, user } = useSelector((state: State) => state.admin.users.deletionDialog);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	return (
		<CustomConfirmDialog
			open={open}
			title={t('admin:user_deletion_dialog_title')}
			text={user ? t('admin:user_deletion_dialog_content', { name: getUserName(user) }) : ''}
			onClose={() => dispatch(closeDeletionDialog())}
			primaryAction={{
				children: t('admin:user_deletion_dialog_cta_button'),
				onClick: () => dispatch(deleteUser(user)),
				color: ButtonColor.Destructive
			}}
			secondaryAction={{
				children: t('general:cta_cancel')
			}}
		/>
	);
};
