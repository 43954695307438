import React, { ReactNode, PropsWithChildren, MouseEventHandler } from 'react';
import Card from '@mui/material/Card';
import { styled, ThemeProvider } from '@mui/material/styles';
import { Stack, Typography, useTheme } from '@mui/material';
import { ButtonColor } from '../../mui-custom/Button/SecondaryButton';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';
import { useCreateMemoTheme } from '../../../app/theme';
import { useMobile } from '../../hooks/useBreakpoints';
import { ListedCard } from '../../mui-custom/ListedCard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { HelpCenterTile } from './HelpCenterTile';
import { ConnectionRequestTile } from './ConnectionRequestTile';

const BACKGROUND_COLOR = '#E1E1E1';
const BASE_TILE_WIDTH_PX = 292;

interface DashboardTileOrListedCardProps {
	header?: ReactNode;
	media?: ReactNode;
	text?: ReactNode;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	buttonText?: string;
	shouldUseMobileBreakpoint?: boolean;
}

export const DashboardTileOrListedCard = ({
	header,
	media,
	text,
	onClick,
	buttonText,
	shouldUseMobileBreakpoint = true,
	children
}: PropsWithChildren<DashboardTileOrListedCardProps>) => {
	const isMobile = useMobile();
	const theme = useTheme();
	const BASE_TILE_HEIGHT_PX = 280;
	if (isMobile && shouldUseMobileBreakpoint) {
		return (
			<ListedCard
				headline={header}
				thumbnail={media}
				text={text}
				icon={<ChevronRightIcon />}
				onClick={onClick}
			/>
		);
	}
	return (
		<Card
			sx={{
				width: BASE_TILE_WIDTH_PX,
				height: BASE_TILE_HEIGHT_PX,
				padding: theme.spacing(2),
				paddingBottom: theme.spacing(1)
			}}
		>
			{children ? (
				children
			) : (
				<Stack sx={{ height: '100%' }} alignItems="flex-start" justifyContent="space-between">
					<Typography
						sx={{
							width: BASE_TILE_WIDTH_PX - parseInt(theme.spacing(2), 10) * 2,
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							overflow: 'hidden'
						}}
						variant="h6"
						color={theme.palette.text.primary}
					>
						{header}
					</Typography>
					{media}
					<Typography
						sx={{ overflow: 'auto' }}
						variant="body1"
						color={theme.palette.text.secondary}
					>
						{text}
					</Typography>
					<ActionBar
						alternate={{
							children: buttonText,
							color: ButtonColor.Creative,
							onClick
						}}
					/>
				</Stack>
			)}
		</Card>
	);
};

const DashboardContainer = styled('div')(({ theme }) => ({
	background: BACKGROUND_COLOR,
	width: '100%',
	padding: theme.spacing(1),
	overflowY: 'auto'
}));

const FlexContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'flex-start',
	gap: theme.spacing(1)
}));

export const HomeApp = () => {
	const designV2Theme = useCreateMemoTheme();
	return (
		<ThemeProvider theme={designV2Theme}>
			<DashboardContainer data-testid="home_screen_container">
				<FlexContainer>
					<ConnectionRequestTile />
					<DashboardTileOrListedCard shouldUseMobileBreakpoint={false}>
						<HelpCenterTile />
					</DashboardTileOrListedCard>
				</FlexContainer>
			</DashboardContainer>
		</ThemeProvider>
	);
};
