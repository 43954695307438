import i18next from 'i18next';
import { UIAccount, UIEmployee, UIOrderStatus, UIUser } from '@api/v1';
import { api } from '../../lib/api';

export function getStatusLabel(status: UIOrderStatus, t: i18next.TFunction): string {
	switch (status) {
		case 'OPEN':
			return t('orders:status_OPEN');
		case 'IN_PROGRESS':
			return t('orders:status_IN_PROGRESS');
		case 'FINISHED':
			return t('orders:status_FINISHED');
		case 'DECLINED':
			return t('orders:status_DECLINED');
		default:
			return null;
	}
}

export const searchEmployees = (search = '') => {
	return api.get<UIEmployee[]>('/api/v1/employees', { params: { search } }).then((data) => {
		return data;
	});
};

export const getAvailableOrderCredits = (data: Partial<UIUser> | Partial<UIAccount>) => {
	return data.orderCreditsPlan + data.orderCreditsAddon - data.usedOrderCredits;
};
