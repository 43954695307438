import { Dialog } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import * as React from 'react';
import { EmployeeForm } from './Form';

interface EmployeeDialogProps {
	open: DialogProps['open'];
	onClose: DialogProps['onClose'];
}

export const EmployeeCreationDialog = ({ open, onClose }: EmployeeDialogProps) => {
	return (
		<Dialog open={open}>
			<EmployeeForm onClose={onClose} />
		</Dialog>
	);
};
