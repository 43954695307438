import * as React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Tooltip
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../reducers';
import { useTranslation } from 'react-i18next';
import { closeUpgradeDialog } from './actions';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { Link } from 'react-router-dom';
import { usePermission } from '../acl/permissions';
import { OwnerExclusivePermission } from '../../../server/permissions/permission';
import { Feature, useFeature } from '../acl/features';

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(0, 4)
	},
	link: {
		textDecoration: 'none'
	},
	buttonContainer: {
		justifyContent: 'space-between'
	},
	titleContainer: {
		color: theme.palette.error.main,
		textAlign: 'center'
	}
}));

export function UpgradeCustomerDialog(props: {}) {
	const { t } = useTranslation();
	const { isUpgradeDialogOpen } = useSelector((state: State) => state.customers);

	const classes = useStyles(props);

	const hasAccountView = usePermission(OwnerExclusivePermission.VIEW_ACCOUNT);
	const hasChargebeeCheckout = useFeature(Feature.ChargebeeCheckout);

	const dispatch = useDispatch();
	return (
		<Dialog open={isUpgradeDialogOpen} maxWidth="xs">
			<DialogTitle className={classes.titleContainer}>
				<ErrorIcon fontSize="large" />
				<br />
				{t('customers:upgrade_dialog_header')}
			</DialogTitle>
			<DialogContent>
				<Typography
					variant="body2"
					dangerouslySetInnerHTML={{
						__html: t('customers:upgrade_dialog_content')
					}}
				/>
			</DialogContent>
			<DialogActions className={classes.buttonContainer}>
				<Button onClick={() => dispatch(closeUpgradeDialog())} color="primary">
					{t('customers:upgrade_dialog_later')}
				</Button>
				{hasChargebeeCheckout ? (
					hasAccountView ? (
						<Link to="/app/admin/account?action=upgrade" className={classes.link}>
							<Button variant="contained" color="primary">
								{t('admin:licenses_upgrade_button')}
							</Button>
						</Link>
					) : (
						<Tooltip title={t('admin:contact_account_owner')}>
							<span>
								<Button variant="contained" color="primary" disabled>
									{t('admin:licenses_upgrade_button')}
								</Button>
							</span>
						</Tooltip>
					)
				) : null}
			</DialogActions>
		</Dialog>
	);
}
