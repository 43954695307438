import { Button, CardActions, CardContent, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { State } from '../reducers';
import {
	checkUrlOnChoosePasswordPage,
	choosePassword,
	setPassword,
	setPasswordConfirm
} from './actions';

interface ChoosePasswordProps extends RouteComponentProps<{}> {}

export const ChoosePassword = ({ location }: ChoosePasswordProps) => {
	const { password, passwordConfirm, fromActivation, accountName, emailAddress } = useSelector(
		(state: State) => state.auth.choosePassword
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkUrlOnChoosePasswordPage(location.search));
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			if (value !== password) {
				return false;
			}
			return true;
		});
	});
	const onSubmit = () => {
		dispatch(choosePassword());
	};
	const { t } = useTranslation();
	const formRef = useRef('form');

	return (
		<ValidatorForm ref={formRef} onSubmit={onSubmit} onError={(errors) => console.log(errors)}>
			<CardContent key="content">
				{fromActivation ? (
					<div>
						<Typography variant="h5">{t('choose_password:user_invitation_header')}</Typography>
						<Typography>
							{t('choose_password:user_invitation_description', {
								accountName
							})}
						</Typography>
						<br />
						<TextField
							name="email"
							label={t('general:email_address')}
							value={emailAddress}
							onChange={() => null}
							fullWidth
							disabled
							variant="standard"
						/>
					</div>
				) : null}
				<TextValidator
					name="password"
					label={t('general:password')}
					onChange={(e) => dispatch(setPassword(e.target.value))}
					type="password"
					value={password}
					validators={['passwordLength', 'required']}
					errorMessages={[t('validation:password_length'), t('general:required_message')]}
					fullWidth
					margin="normal"
					variant="standard"
				/>
				<TextValidator
					name="passwordConfirm"
					label={t('general:repeat_password')}
					onChange={(e) => dispatch(setPasswordConfirm(e.target.value))}
					type="password"
					value={passwordConfirm}
					validators={['isPasswordMatch', 'required']}
					errorMessages={[t('validation:password_confirm'), t('general:required_message')]}
					fullWidth
					margin="normal"
					variant="standard"
				/>
			</CardContent>
			<CardActions key="actions">
				<Button variant="contained" type="submit">
					{fromActivation
						? t('choose_password:user_invitation_button')
						: t('general:reset_password')}
				</Button>
			</CardActions>
		</ValidatorForm>
	);
};
