import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	DialogContentText,
	InputLabel,
	MenuItem,
	FormControl,
	Select
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateCustomerIntegrationIds } from './actions';
import { CredentialsModal } from './CredentialsModal';

export enum IntegrationPartner {
	ASSFINET = 'ASSFINET',
	OASIS = 'OASIS',
	TECHEM = 'TECHEM'
}

export const CustomerEditDialog = (props) => {
	const { open, customer, closeModal } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [selectedIntegration, setSelectedIntegration] = React.useState(
		customer?.assfinetId
			? IntegrationPartner.ASSFINET
			: customer?.oasisId
			? IntegrationPartner.OASIS
			: customer?.techemId
			? IntegrationPartner.TECHEM
			: ''
	);
	const [isIdModified, setIsIdModified] = React.useState(false);
	const [integrationIds, setIntegrationIds] = React.useState({
		[IntegrationPartner.ASSFINET]: customer?.assfinetId || '',
		[IntegrationPartner.OASIS]: customer?.oasisId || '',
		[IntegrationPartner.TECHEM]: customer?.techemId || ''
	});

	const handleChangeId = (event) => {
		setIntegrationIds({ ...integrationIds, [selectedIntegration]: event.target.value });
	};

	const handleSave = () => {
		dispatch(
			updateCustomerIntegrationIds(customer.accountId, customer.id, {
				assfinetId: integrationIds?.[IntegrationPartner.ASSFINET] || null,
				oasisId: integrationIds?.[IntegrationPartner.OASIS] || null,
				techemId: integrationIds?.[IntegrationPartner.TECHEM] || null
			})
		);
		closeModal();
	};

	const handleClose = () => {
		setSelectedIntegration('');
		setIntegrationIds({
			[IntegrationPartner.ASSFINET]: '',
			[IntegrationPartner.OASIS]: '',
			[IntegrationPartner.TECHEM]: ''
		});
		setIsIdModified(false);
		closeModal();
	};

	React.useEffect(() => {
		setIsIdModified(
			!(
				integrationIds[IntegrationPartner.ASSFINET] === customer?.assfinetId &&
				integrationIds[IntegrationPartner.OASIS] === customer?.oasisId &&
				integrationIds[IntegrationPartner.TECHEM] === customer?.techemId
			)
		);
	}, [
		integrationIds[IntegrationPartner.ASSFINET],
		integrationIds[IntegrationPartner.OASIS],
		integrationIds[IntegrationPartner.TECHEM]
	]);

	return (
		<div>
			<Dialog open={open} aria-labelledby="form-dialog-title" onClose={closeModal}>
				<DialogTitle id="form-dialog-title">{t('sysadmin:customer_edit_modal_header')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('sysadmin:edit_customer_help')}</DialogContentText>
					<FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
						<InputLabel>{t('sysadmin:integration_partner')}</InputLabel>
						<Select
							value={selectedIntegration}
							onChange={(event) => setSelectedIntegration(event.target.value)}
							label={t('sysadmin:integration_partner')}
						>
							<MenuItem value={IntegrationPartner.ASSFINET}>{t('sysadmin:assfinet')}</MenuItem>
							<MenuItem value={IntegrationPartner.OASIS}>{t('sysadmin:oasis')}</MenuItem>
							<MenuItem value={IntegrationPartner.TECHEM}>{t('sysadmin:techem')}</MenuItem>
						</Select>
					</FormControl>
					<TextField
						margin="dense"
						id="integration-id"
						label={t('sysadmin:user_id')}
						value={integrationIds[selectedIntegration]}
						onChange={handleChangeId}
						fullWidth
						variant="standard"
					/>
					{selectedIntegration ? (
						<CredentialsModal selectedIntegration={selectedIntegration} customer={customer} />
					) : null}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary" variant="text">
						{t('sysadmin:restrictions_dialog_cancel')}
					</Button>
					<Button onClick={handleSave} color="primary" disabled={!isIdModified}>
						{t('sysadmin:restrictions_dialog_save')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
