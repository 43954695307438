
			/*
				Prevent script name from being obfuscated by build process
				add Chargebee to script list by adding script tag
			*/
			(() => {
				// create new html element
				const n = document.createElement('script');
				n.type = 'text/javascript';
				n.async = !0;
				n.src = 'https://js.chargebee.com/v2/chargebee.js';
				// add element to list of scripts
				const a = document.getElementsByTagName('script')[0];
				a.parentNode.insertBefore(n, a);
			})();
		