import { ImageListItemBar, IconButton, LinearProgress, Paper, Theme, Stack } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
import * as React from 'react';
import { Image } from '../Image';
import { DropzoneFile, OnRemoveCallback } from './types';
import { hasFailed, isUploaded } from './validation';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'relative',
		width: theme.spacing(20),
		height: theme.spacing(15),
		background: theme.palette.grey['200'],
		cursor: 'default'
	},
	image: {
		position: 'absolute',
		width: theme.spacing(20),
		height: theme.spacing(15),
		objectFit: 'cover'
	},
	topBar: {
		background:
			'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
		color: 'white'
	},
	caption: {
		padding: theme.spacing(1),
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		background: 'rgba(0,0,0,0.5)',
		color: 'white'
	},
	remove: {
		position: 'absolute',
		right: 0,
		background: 'radial-gradient(rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 50%)',
		color: 'white',
		borderRadius: '50%'
	},
	progress: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0
	},
	progressBar: {
		transition: theme.transitions.create('background')
	},
	progressBarSuccess: {
		background: theme.palette.success.main
	},
	progressBarFailure: {
		background: theme.palette.error.main
	},
	transparent: {
		background: 'transparent'
	}
}));

interface ThumbnailProps {
	file: DropzoneFile;
	onRemove: OnRemoveCallback;
}

export const Thumbnail = ({ file, onRemove }: ThumbnailProps) => {
	const handleOnClick = () => {
		URL.revokeObjectURL(file.preview);
		onRemove(file.tempId);
	};

	const classes = useStyles();
	return (
		<Paper elevation={1} className={classes.root} square onClick={(e) => e.stopPropagation()}>
			{file.fileType === 'application/pdf' ? (
				<Stack direction="row" alignItems="center" spacing={2} className={classes.caption}>
					<iframe
						src={file.preview}
						className={classes.topBar}
						style={{ width: '9rem', height: '7rem', objectFit: 'cover' }}
						onLoad={() => {
							URL.revokeObjectURL(file.preview);
						}}
					/>
					<IconButton sx={{ zIndex: 1 }} color="inherit" onClick={handleOnClick} size="large">
						<RemoveIcon />
					</IconButton>
				</Stack>
			) : (
				<>
					{file.preview ? <Image src={file.preview} className={classes.image} /> : null}
					<ImageListItemBar
						className={classes.topBar}
						titlePosition="top"
						actionIcon={
							<IconButton sx={{ zIndex: 1 }} color="inherit" onClick={handleOnClick} size="large">
								<RemoveIcon />
							</IconButton>
						}
						actionPosition="right"
					/>
				</>
			)}
			<ImageListItemBar title={file.fileName} />
			<LinearProgress
				classes={{
					root: classes.progress,
					colorPrimary: classes.transparent,
					barColorPrimary: cx(classes.progressBar, {
						[classes.progressBarSuccess]: isUploaded(file),
						[classes.progressBarFailure]: hasFailed(file)
					})
				}}
				variant="determinate"
				value={file.progress}
			/>
		</Paper>
	);
};
