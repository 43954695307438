import { Badge, Card, CircularProgress, Grid, IconButton, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AttachFile, Block as BlockIcon, Send as SendIcon } from '@mui/icons-material';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { containsFailedFiles, isReadyToUpload } from '../../../components/Dropzone/validation';
import { isWhiteSpaceOnly } from '../../../lib/validator';
import { State } from '../../reducers';
import * as actions from '../actions';
import { DropzoneDialog } from './DropzoneDialog';

const useStyles = makeStyles((theme) => ({
	layout: {
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: theme.spacing(4),
		'@media print': {
			display: 'none'
		},
		position: 'sticky',
		borderRadius: 0,
		borderLeft: 'none',
		borderRight: 'none',
		zIndex: 1,
		top: 0,
		bottom: 0
	},
	label: {
		marginLeft: theme.spacing(2),
		fontSize: '0.75rem',
		color: '#888888'
	},
	badge: {
		transition: theme.transitions.create('background-color')
	},
	badgeSuccess: {
		backgroundColor: theme.palette.success.main
	},
	iconBadge: {
		fontSize: 20
	},
	textField: {
		flex: '1 1 100%',
		width: 'unset',
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1)
	},
	commentContainer: {
		display: 'flex',
		alignItems: 'center'
	}
}));

export function CommentCompose({ name = '' }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles({});
	const { text, files, fileDialogOpen } = useSelector((state: State) => ({
		text: state.offerRequests.commentDrafts[state.offerRequests.single.offerRequest.id] || '',
		files: state.offerRequests.single.commentCompose.files,
		fileDialogOpen: state.offerRequests.single.commentCompose.fileDialogOpen
	}));
	const isUploaded = isReadyToUpload(files);
	const uploadFailed = containsFailedFiles(files);
	const isValid = isUploaded && !isWhiteSpaceOnly(text);
	const {
		changeCommentText: onTextChange,
		openCommentFileDialog: openDialog,
		closeCommentFileDialog: closeDialog,
		addCommentFile: onAddFile,
		changeCommentFile: onChangeFile,
		removeCommentFile: onRemoveFile,
		postComment: onCreate,
		removeAllCommentFiles: removeAllFiles
	} = actions;
	useEffect(() => {
		dispatch(removeAllFiles());
	}, []);
	const badgeContent: React.ReactNode = (() => {
		if (uploadFailed) return <BlockIcon className={classes.iconBadge} />;
		if (!isUploaded) return <CircularProgress color="inherit" size={16} />;
		return files.length;
	})();
	return (
		<>
			<label className={classes.label}>{t('offers:label_comment', { name })}</label>
			<Card className={classes.layout} variant="outlined">
				<Grid container direction="column" alignItems="stretch" justifyContent="center">
					<Grid item xs={12} className={classes.commentContainer}>
						<IconButton
							id="offer_details_button_file_upload"
							color="primary"
							onClick={() => dispatch(openDialog())}
							size="large"
						>
							{files.length ? (
								<Badge
									badgeContent={badgeContent}
									classes={{
										colorPrimary: cx(classes.badge, {
											[classes.badgeSuccess]: isUploaded
										})
									}}
									color={uploadFailed ? 'error' : 'primary'}
								>
									<AttachFile />
								</Badge>
							) : (
								<AttachFile />
							)}
						</IconButton>
						<TextField
							id="offer_details_field_comment"
							className={classes.textField}
							placeholder={t('offers:comment_placeholder')}
							multiline
							value={text}
							onChange={(e) => dispatch(onTextChange(e.target.value))}
							variant="standard"
						/>
						<IconButton
							id="offer_details_button_submit_comment"
							color="primary"
							disabled={!isValid}
							onClick={() => dispatch(onCreate(text))}
							size="large"
						>
							<SendIcon />
						</IconButton>
					</Grid>
				</Grid>
			</Card>
			<DropzoneDialog
				open={fileDialogOpen}
				onClose={() => dispatch(closeDialog())}
				title={t('general:attachments_title')}
				dropzoneProps={{
					text: t('general:attachments_upload'),
					files,
					onDrop: (file) => dispatch(onAddFile(file)),
					onChangeFile: (file, data) => dispatch(onChangeFile(file, data)),
					onRemove: (file) => dispatch(onRemoveFile(file))
				}}
			/>
		</>
	);
}
