import React, { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { Card, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';

export interface TableType {
	headers: string[];
	getHeader: () => string;
}

const TableContext = createContext<TableType>({ headers: null, getHeader: null });
TableContext.displayName = 'TableContext';

export type TableCtxProps = Pick<TableType, 'headers'> & {
	children: React.ReactNode;
};
export function TableCtx({ headers, children }: TableCtxProps): JSX.Element {
	const isMobile = useMobile();
	const columnId = useRef(0);
	useEffect(() => {
		columnId.current = 0;
	});
	const value = useMemo(
		() => ({
			headers,
			getHeader: () => headers[columnId.current++ % headers.length]
		}),
		[]
	);
	return (
		<TableContext.Provider value={value}>
			<Table>
				{!isMobile && (
					<TableHead>
						<TableRow>
							{headers.map((header) => (
								<TableCell key={header}>{header}</TableCell>
							))}
						</TableRow>
					</TableHead>
				)}
				<TableBody>{children}</TableBody>
			</Table>
		</TableContext.Provider>
	);
}

export function useTable() {
	const { headers, getHeader } = useContext(TableContext);
	if (headers) return { headers, getHeader };
	throw new Error('useTable must be used within a TableCtx to get the headers');
}

export interface TableCtxCellProps {
	children?: React.ReactElement | string;
	key?: number;
	sx?: object;
}

export function TableCtxCell({ children, sx }: TableCtxCellProps) {
	const { getHeader } = useTable();
	const isMobile = useMobile();
	const header = useMemo(() => getHeader(), []);
	const { palette } = useTheme();

	const placeHolderFromHeader = <span style={{ color: palette.text.secondary }}>{header}</span>;

	let childNode: typeof children;
	if (!isMobile) childNode = children;
	else if (typeof children === 'string') childNode = children || placeHolderFromHeader;
	else childNode = children?.props?.children ? children : placeHolderFromHeader;

	return (
		<TableCell
			component="th"
			scope="row"
			sx={isMobile ? { borderColor: 'transparent', p: 0, ...sx } : sx}
		>
			{childNode}
		</TableCell>
	);
}

export interface TableRowOrCardProps {
	children: React.ReactElement[];
	[key: string]: unknown;
}

export function TableRowOrCard({ children, ...restProps }: TableRowOrCardProps) {
	const isMobile = useMobile();

	return isMobile ? (
		<Card sx={{ m: 1 }}>
			<TableRow
				{...restProps}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					p: 1,
					'> *:first-of-type': { fontSize: '.95rem' }
				}}
			>
				{!!children &&
					React.Children.map(children, (TableCell) => <TableCtxCell {...TableCell.props} />)}
			</TableRow>
		</Card>
	) : (
		<TableRow {...restProps}>{children}</TableRow>
	);
}

export type TableRowOrCard = typeof TableRowOrCard;

// eslint-disable-next-line import/no-default-export
export default TableRowOrCard;
