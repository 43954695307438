import React, { useEffect, useState } from 'react';
import {
	Dialog,
	TextField,
	Grid,
	Typography,
	IconButton,
	InputAdornment,
	FormControlLabel,
	Checkbox
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { BFile, CreateOrderBody, UICustomer, UIProperty } from '@api/v1';
import {
	closeAddDialog,
	createOrder,
	addOrderFile,
	changeOrderFile,
	removeOrderFile,
	removeAllOrderFiles
} from './actions';
import { loadCustomers } from '../customers/actions';
import { loadProperties } from '../properties/actions';
import { DateDialog } from '../../../app/components/DateTime';
import { MaterialDropzone } from '../../components/Dropzone';
import moment from 'moment-with-locales-es6';
import { orderDialogUseStyles } from './single/orderDialogStyles';
import { getProperties, getRelayCustomers } from '../selectors';
import Page from '../../mui-custom/Page/Page';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';

const initialState: CreateOrderBody = {
	customer: {
		id: 0,
		name: '',
		emailAddress: ''
	},
	property: {
		id: 0,
		name: '',
		status: '',
		statusUpdatedAt: '',
		address: {
			streetAddress: '',
			postalCode: '',
			locality: '',
			country: ''
		},
		customer: { name: '', id: -1 },
		createdAt: '',
		internalId: '',
		insurances: []
	},
	contact: {
		name: '',
		emailAddress: '',
		telephone: ''
	},
	title: '',
	dueDate: '',
	isInternal: false,
	files: [],
	followUpDate: ''
};

export const OrderAddPage = (props) => {
	const { open, files } = useSelector((state: State) => state.orders.addDialog);
	const { customers } = useSelector(getRelayCustomers);
	const { properties } = useSelector(getProperties);
	const { t } = useTranslation();
	const classes = orderDialogUseStyles(props);

	const [order, setOrder] = useState<CreateOrderBody>(initialState);
	const [isDueDateDialogOpen, setIsDueDateDialogOpen] = useState<boolean>(false);
	const [isFollowUpDateDialogOpen, setIsFollowUpDateDialogOpen] = useState<boolean>(false);

	const [inputValueCustomer, setInputValueCustomer] = React.useState('');
	const [inputValueProperty, setInputValueProperty] = React.useState('');

	const dispatch = useDispatch();

	const handleSubmit = (_ev) => dispatch(createOrder(order));
	const handleGoBack = (_ev) => {
		dispatch(closeAddDialog());
		props.backToListPage();
	};

	const handleChangeFile = (file, data) => {
		dispatch(changeOrderFile(file, data));
		if (data.progress === 100) {
			const newAttachment: BFile = {
				s3Key: data.s3Key,
				thumbnailS3Key: data.thumbnailS3Key,
				fileName: data.fileName,
				fileType: data.fileType,
				tempId: file
			};
			setOrder({
				...order,
				files: [...order.files, newAttachment]
			});
		}
	};

	const handleRemoveFile = (file) => {
		dispatch(removeOrderFile(file));
		const filteredFiles = order.files.filter((item) => item.tempId !== file);
		setOrder({ ...order, files: filteredFiles });
	};

	useEffect(() => {
		if (open === false) return setOrder(initialState);

		dispatch(removeAllOrderFiles());
		if (isEmpty(customers)) dispatch(loadCustomers());
		if (isEmpty(properties)) dispatch(loadProperties());
	}, [open]);

	const customerFilter: (options: UICustomer[], filter) => UICustomer[] = createFilterOptions();
	const propertyFilter: (options: UIProperty[], filter) => UIProperty[] = createFilterOptions();

	if (!open) return null;
	return (
		<Dialog
			open={open}
			PaperComponent={ValidatorForm}
			PaperProps={{ onSubmit: handleSubmit }}
			sx={{ inset: '0 auto', position: 'relative', zIndex: 1100 }}
			maxWidth={false}
			disablePortal
			hideBackdrop
		>
			<Page
				style={{ maxWidth: '50rem' }}
				headline={t('orders:add_dialog_title')}
				actionBar={
					<ActionBar
						primary={{
							id: 'cta_create_send_continue_order_button',
							children: t('general:cta_send_order'),
							onClick: handleSubmit
						}}
						secondary={{
							children: t('general:cta_cancel'),
							onClick: handleGoBack
						}}
					/>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>{t('orders:add_dialog_customer_and_property')}</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Autocomplete
							classes={{ option: classes.autocompleteOption }}
							id="cta_new_order_add_dialog_customer_name_input_field"
							options={[...(customers || []), initialState.customer]}
							getOptionLabel={(customer: UICustomer) => customer.name}
							onChange={(_event, newValue: UICustomer) => {
								setOrder({
									...order,
									customer: newValue
								});
							}}
							isOptionEqualToValue={(option, value) => {
								return option.id === value.id;
							}}
							value={order.customer}
							inputValue={inputValueCustomer}
							onInputChange={(_event, value) => {
								setInputValueCustomer(value);
							}}
							renderInput={(params) => (
								<TextValidator
									{...params}
									fullWidth
									value={inputValueCustomer}
									label={t('orders:add_dialog_customer_name_required')}
									validators={['required']}
									errorMessages={[t('general:required_message')]}
									variant="outlined"
								/>
							)}
							renderOption={(props, option) => {
								if (option.name === '') {
									return (
										<li {...props}>
											<Link
												to="/app/customers_and_properties/customers"
												className={classes.addCustomerLink}
											>
												{t('orders:add_dialog_customer_add_new')}
											</Link>
										</li>
									);
								}
								return <li {...props}> {option.name} </li>;
							}}
							filterOptions={(options, params) => {
								const filtered = customerFilter(options, params);
								if (params.inputValue !== '') {
									filtered.push({
										name: '',
										emailAddress: ''
									});
								}
								return filtered;
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Autocomplete
							classes={{ option: classes.autocompleteOption }}
							id="cta_new_order_add_dialog_property_name_input_field"
							options={[
								...(properties || []),
								{
									...initialState.property,
									name: 'new-property'
								}
							]}
							getOptionLabel={(property: UIProperty) => property.name}
							onChange={(_event, newValue: UIProperty) => {
								setOrder({
									...order,
									property: newValue
								});
							}}
							isOptionEqualToValue={(option, value) => {
								return option.id === value.id;
							}}
							value={order.property}
							inputValue={inputValueProperty}
							onInputChange={(_event, value) => {
								setInputValueProperty(value);
							}}
							renderInput={(params) => (
								<TextValidator
									{...params}
									fullWidth
									label={t('orders:add_dialog_property_name_required')}
									validators={['required']}
									errorMessages={[t('general:required_message')]}
									value={inputValueProperty}
									variant="outlined"
								/>
							)}
							renderOption={(props, option) => {
								if (option.name === 'new-property') {
									return (
										<li {...props}>
											<Link
												to="/app/customers_and_properties/properties"
												className={classes.addCustomerLink}
											>
												{t('orders:add_dialog_property_add_new')}
											</Link>
										</li>
									);
								}
								return <li {...props}>{option.name}</li>;
							}}
							filterOptions={(options, params) => {
								const filtered = propertyFilter(options, params);
								if (params.inputValue !== '') {
									filtered.push({
										...initialState.property,
										name: 'new-property'
									});
								}
								return filtered;
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography>{t('orders:add_dialog_contact_label')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							fullWidth
							id="cta_new_order_add_dialog_contact_name_input_field"
							label={t('orders:add_dialog_contact_name')}
							onChange={(e) =>
								setOrder({
									...order,
									contact: {
										...order.contact,
										name: e.target.value
									}
								})
							}
							value={order.contact.name}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							fullWidth
							id="cta_new_order_add_dialog_contact_email_input_field"
							label={t('orders:add_dialog_contact_email')}
							onChange={(e) =>
								setOrder({
									...order,
									contact: {
										...order.contact,
										emailAddress: e.target.value
									}
								})
							}
							value={order.contact.emailAddress}
							validators={['isEmail']}
							errorMessages={[t('orders:dialog_email_validation_error_message')]}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							fullWidth
							id="cta_new_order_add_dialog_contact_tel_input_field"
							label={t('orders:add_dialog_contact_tel')}
							onChange={(e) =>
								setOrder({
									...order,
									contact: {
										...order.contact,
										telephone: e.target.value
									}
								})
							}
							value={order.contact.telephone}
							validators={['isEmptyOrValidPhoneNumber']}
							errorMessages={[t('orders:dialog_phone_validation_error_message')]}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography>{t('orders:add_dialog_description_label')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							fullWidth
							id="cta_new_order_add_dialog_create_title_input_field"
							label={t('orders:add_dialog_create_title')}
							onChange={(e) =>
								setOrder({
									...order,
									title: e.target.value
								})
							}
							value={order.title}
							validators={['required']}
							errorMessages={[t('general:required_message')]}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="cta_new_order_add_dialog_create_details_input_field"
							multiline
							rows={1}
							label={t('orders:add_dialog_create_details')}
							onChange={(e) =>
								setOrder({
									...order,
									details: e.target.value
								})
							}
							value={order.details || ''}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							fullWidth
							id="cta_new_order_add_dialog_create_external_id_input_field"
							label={t('orders:add_dialog_create_external_id')}
							onChange={(e) =>
								setOrder({
									...order,
									externalId: e.target.value
								})
							}
							value={order.externalId || ''}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextValidator
							fullWidth
							id="cta_new_order_add_dialog_create_internal_id_input_field"
							label={t('orders:add_dialog_create_internal_id')}
							onChange={(e) =>
								setOrder({
									...order,
									internalId: e.target.value
								})
							}
							value={order.internalId || ''}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6} sx={{ mr: 'auto' }}>
						<Grid container spacing={1}>
							<Grid item xs>
								<TextField
									fullWidth
									id="cta_new_order_add_dialog_create_due_date_input_field"
									label={t('orders:add_dialog_create_due_date')}
									onClick={() => setIsDueDateDialogOpen(true)}
									value={order.dueDate ? moment(order.dueDate).format('DD.MM.YYYY') : ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton size="large">
													<CalendarIcon />
												</IconButton>
											</InputAdornment>
										)
									}}
									variant="outlined"
								/>
							</Grid>
							{order.dueDate && (
								<Grid container item xs={1} justifyContent="center">
									<IconButton
										className={classes.dateDeleteButton}
										onClick={() => setOrder({ ...order, dueDate: '' })}
										size="large"
									>
										<CloseIcon fontSize="small" color="primary" />
									</IconButton>
								</Grid>
							)}
						</Grid>
						<DateDialog
							open={isDueDateDialogOpen}
							onClose={() => setIsDueDateDialogOpen(false)}
							onSubmit={(date) => {
								setIsDueDateDialogOpen(false);
								setOrder({
									...order,
									dueDate: date.toISOString()
								});
							}}
							initialValue={moment(order.dueDate || Date.now())}
							dialogTitle={t('orders:due_date_dialog_header')}
							submitLabel={t('orders:due_date_dialog_button_save')}
							disablePast
						/>
					</Grid>
					<Grid item xs={12} sm={6} sx={{ mr: 'auto' }}>
						<Grid container spacing={1}>
							<Grid item xs>
								<TextField
									fullWidth
									id="cta_new_order_add_dialog_create_follow_up_date_input_field"
									label={t('orders:add_dialog_create_follow_up_date')}
									onClick={() => setIsFollowUpDateDialogOpen(true)}
									value={order.followUpDate ? moment(order.followUpDate).format('DD.MM.YYYY') : ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton size="large">
													<CalendarIcon />
												</IconButton>
											</InputAdornment>
										)
									}}
									variant="outlined"
								/>
							</Grid>
							{order.followUpDate && (
								<Grid container item xs={1} justifyContent="center">
									<IconButton
										className={classes.dateDeleteButton}
										onClick={() => setOrder({ ...order, followUpDate: '' })}
										size="large"
									>
										<CloseIcon fontSize="small" color="primary" />
									</IconButton>
								</Grid>
							)}
						</Grid>
						<DateDialog
							open={isFollowUpDateDialogOpen}
							onClose={() => setIsFollowUpDateDialogOpen(false)}
							onSubmit={(date) => {
								setIsFollowUpDateDialogOpen(false);
								setOrder({
									...order,
									followUpDate: date.toISOString()
								});
							}}
							initialValue={moment(order.followUpDate || Date.now())}
							dialogTitle={t('orders:follow_up_date_dialog_header')}
							submitLabel={t('orders:follow_up_date_dialog_button_save')}
							disablePast
						/>
					</Grid>
					<Grid item sm={12}>
						<FormControlLabel
							control={
								<Checkbox
									disableRipple
									id="cta_new_order_add_dialog_internal_switch_button"
									checked={order.isInternal}
									onChange={(e) =>
										setOrder({
											...order,
											isInternal: e.target.checked
										})
									}
									color="primary"
								/>
							}
							label={
								<Typography variant="body1">
									{t('orders:add_dialog_internal_switch_label')}
								</Typography>
							}
							labelPlacement="end"
						/>
					</Grid>
					<Grid item xs={12}>
						<MaterialDropzone
							id="create_order_attachment_dropzone"
							text={t('orders:add_dialog_upload')}
							onDrop={(file) => dispatch(addOrderFile(file))}
							onRemove={handleRemoveFile}
							onChangeFile={handleChangeFile}
							files={files}
						/>
					</Grid>
				</Grid>
			</Page>
		</Dialog>
	);
};
