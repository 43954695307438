import {
	AccountPatchBody,
	ChargebeeSubscription,
	CreateCompanyLogoBody,
	PaymentSource,
	UIAccount,
	UIExternalProfile,
	UploadToTempResults
} from '@api/v1';
import i18next from 'i18next';
import { createStandardAction } from 'typesafe-actions';
import { api } from '../../../lib/api';
import { createApiAction } from '../../../redux/createApiAction';
import { showNotification } from '../../actions';
import { State } from '../../reducers';
import { ActiveRestrictionsCount } from '@api/v1';
import { i18n } from '../../../../app/i18n';
import { isEmpty } from 'lodash';
import { accept } from '../../../../app/core/orders/actions';

export const loadAccountUsedOrderCredits = createApiAction('admin_account/get_used_credits', () =>
	api.get('/api/v1/account/used_credits')
);
export const loadAccountDetails = createApiAction('admin_account/get_details', (_, dispatch) =>
	api.get<UIAccount>('/api/v1/account').then((account) => {
		/* check if usedCreditsUpdatedAt is in the current month, if not patch/reset usedOrderCredits of this month to 0 */
		dispatch(loadAccountUsedOrderCredits());
		return account;
	})
);
export const getActiveSubscription = createApiAction('admin_account/get_active_subscription', () =>
	api.get<ChargebeeSubscription>('/api/v1/chargebee/active_subscription')
);

export const getActiveRestrictions = createApiAction('admin_account/get_active_restrictions', () =>
	api.get<ActiveRestrictionsCount>('/api/v1/account/active_restrictions')
);

export const buyAdditionalOrder = createApiAction(
	'admin_account/buy_additional_order',
	(_, dispatch) =>
		api
			.post('/api/v1/chargebee/buy_additional_order')
			.then((payload) => {
				dispatch(showNotification(i18next.t('general:buy_additional_order_successful'), 'success'));
				dispatch(accept());
				return payload;
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			})
);

export const saveAccountDetails = createApiAction(
	'admin_account/save_details',
	async (a: void, dispatch, getState: () => State) =>
		api
			.patch<AccountPatchBody, UIAccount>('/api/v1/account', getState().admin.account.dialog.fields)
			.then((account) => {
				dispatch(showNotification(i18next.t('general:changes_successful'), 'success'));
				return account;
			})
);
export const openAccountEditDialog = createStandardAction(
	'admin_account/open_edit_dialog'
)<UIAccount>();
export const closeAccountEditDialog = createStandardAction('admin_account/close_edit_dialog')();
export const setName = createStandardAction('admin_account/set_name')<string>();
export const setStreet = createStandardAction('admin_account/set_street')<string>();
export const setPostalCode = createStandardAction('admin_account/set_postal_code')<string>();
export const setLocality = createStandardAction('admin_account/set_locality')<string>();
export const setCountry = createStandardAction('admin_account/set_country')<string>();
export const loadPaymentSources = createApiAction('admin_account_payment/get_payment_sources', () =>
	api.get<PaymentSource[]>('/api/v1/chargebee/payment_sources')
);

export const openPaymentSourceDialog = () => (dispatch) => {
	let chargebeeInstance;
	if (!chargebeeInstance) {
		chargebeeInstance = Chargebee.init({
			site: import.meta.env.MODE === 'production' ? 'relay' : 'relay-test'
		});
	}
	chargebeeInstance.setPortalSession(() => api.get('/api/v1/chargebee/portal_session'));
	chargebeeInstance.createChargebeePortal().open(
		{
			paymentSourceUpdate(result) {
				if (result === 'error') {
					return;
				}
				dispatch(loadPaymentSources());
				dispatch(showNotification(i18next.t('general:changes_successful'), 'success'));
			}
		},
		{
			sectionType: Chargebee.getPortalSections().EDIT_PAYMENT_SOURCE
		}
	);
};

export const saveExternalProfileDetails = createApiAction(
	'admin_account/save_external_profile',
	async (
		{
			externalProfile,
			tmpImageData
		}: { externalProfile: UIExternalProfile; tmpImageData?: UploadToTempResults },
		dispatch,
		getState: () => State
	) =>
		api
			.post<
				UIExternalProfile,
				{ id: string; geoModuleHasEror: boolean; externalProfile: UIExternalProfile }
			>(`/api/v1/account/${getState().auth.account.id}/external-profile`, externalProfile)
			.then((response) => {
				if (!isEmpty(tmpImageData)) {
					dispatch(
						addExternalProfilLogo({
							s3Key: tmpImageData.s3Key
						})
					);
				}
				if (response.geoModuleHasEror) {
					dispatch(
						showNotification(i18n.t('admin:external_profile_error_message_geo_module'), 'info')
					);
				} else {
					dispatch(showNotification(i18n.t('admin:external_profile_success_message'), 'success'));
					return response.externalProfile;
				}
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			})
);

export const addExternalProfilLogo = createApiAction(
	'admin_account/add_company_logo',
	async (reqBody: CreateCompanyLogoBody, dispatch, getState: () => State) =>
		api
			.post<CreateCompanyLogoBody, { url: string }>(
				`/api/v1/account/${getState().auth.account.id}/external-profile/company-logo`,
				reqBody
			)
			.then((response) => {
				dispatch(
					showNotification(
						i18n.t('admin:external_profile_avatar_upload_success_message'),
						'success'
					)
				);
				return response.url;
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			})
);

export const removeExternalProfilLogo = createApiAction(
	'admin_account/remove_company_logo',
	async (a: void, dispatch, getState: () => State) =>
		api
			.delete(`/api/v1/account/${getState().auth.account.id}/external-profile/company-logo`)
			.then(() => {
				dispatch(
					showNotification(
						i18n.t('admin:external_profile_avatar_upload_success_message'),
						'success'
					)
				);
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			})
);

export const deactivateAccount = createApiAction(
	'admin_account/deactivate_account',
	async (a: void, dispatch, getState: () => State) =>
		api
			.post('/api/v1/account/deactivate')
			.then(() => {
				dispatch(showNotification(i18next.t('general:account_deactivated_success'), 'success'));
				document
					.querySelector('#layoutFix > div')
					.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
				dispatch(loadAccountDetails());
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			})
);

export const reactivateAccount = createApiAction(
	'admin_account/reactivate_account',
	async (a: void, dispatch, getState: () => State) =>
		api
			.post('/api/v1/account/reactivate')
			.then(async () => {
				await dispatch(loadAccountDetails());
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:default_error'), 'error'));
				throw err;
			})
);

export const setPaywallOpen = createStandardAction('admin_account/set_paywall_open')();
export const setPaywallClose = createStandardAction('admin_account/set_paywall_close')();
export const setPaywallForcedOpen = createStandardAction('admin_account/set_paywall_forced_open')();
