import { State } from './reducers';
import { PropertyOrigin } from '../../server/properties/browser.types';
import { CustomerOrigin } from '../../server/customers/browser.types';

const { Casavi, AccountCreation } = CustomerOrigin;

export const getRelayCustomers = (state: State) => ({
	customers: state.customers.list?.data?.filter((customer) => {
		if (customer.origin === Casavi || customer.origin === AccountCreation) {
			return false;
		}
		return true;
	})
});

export const getProperties = (state: State) => ({
	properties: state.properties.list?.data?.filter(
		(property) => property.origin !== PropertyOrigin.AccountCreation
	)
});
