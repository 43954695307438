import { api } from '../../../lib/api';
import { createAsyncConstants } from '../../../lib/createConstants';
import { UIRawUser, UIRawUserList } from '@api/v1';
import React from 'react';
import { Trans } from 'react-i18next';
import { createApiAction } from '../../../redux/createApiAction';
import { createStandardAction, createAction } from 'typesafe-actions';

export const asyncConstants = createAsyncConstants(['GET_USERS', 'DEACTIVATE_USER'], {
	prefix: 'ADMIN_USERS'
});

export const loadUsers = createApiAction('users/list', async () =>
	api.get<UIRawUserList>('/api/v1/admin/users')
);

export const openInvitationDialog = createStandardAction('users/open_invitation_dialog')<
	UIRawUser
>();
export const closeInvitationDialog = createAction('users/close_invitation_dialog');

export const inviteUser = createApiAction(
	'users/invite',
	async (user: UIRawUser, dispatch) => {
		return api.patch(`/api/v1/admin/users/${user.id}`, { active: true }).then(() => {
			dispatch(loadUsers());
		});
	},
	{
		errorMessage: React.createElement(Trans, {
			i18nKey: 'admin:user_invitation_error_limit_exceeded',
			components: [
				React.createElement('a', { href: 'mailto:support@relay.immo' }, 'support@relay.immo')
			]
		})
	}
);

export const openDeactivationDialog = createStandardAction('users/open_deactivation_dialog')<
	UIRawUser
>();
export const closeDeactivationDialog = createAction('users/close_deactivation_dialog');

export const deactivateUser = createApiAction(
	'users/deactive',
	async (user: UIRawUser, dispatch) => {
		return api.patch(`/api/v1/admin/users/${user.id}`, { active: false }).then(() => {
			dispatch(loadUsers());
		});
	}
);

export const openDeletionDialog = createStandardAction('users/open_deletion_dialog')<UIRawUser>();
export const closeDeletionDialog = createAction('users/close_deletion_dialog');

export const deleteUser = createApiAction('users/deactive', async (user: UIRawUser, dispatch) => {
	return api.delete(`/api/v1/admin/users/${user.id}`).then(() => {
		dispatch(loadUsers());
	});
});
