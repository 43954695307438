import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { AppBar, IconButton, Slide, Stack, Typography, useTheme } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import Info from '@mui/icons-material/Info';

import { PrimaryButton } from '../mui-custom/Button';
import { resendConfirmEmail } from './actions';


const PADDING = 6;
export const BANNER_HEIGHT = `${40 + PADDING * 2}px`;

const useStyles = makeStyles((theme) => ({
	link: {
		textDecoration: 'underline',
		cursor: 'pointer',
		alignSelf: 'center'
	},
	banner: {
		'background-color': theme.palette.primary.dark,
		padding: `${PADDING}px`,
		'@media print': {
			display: 'none'
		}
	},
	warning: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.error.contrastText
	},
	info: {
		backgroundColor: theme.palette.info.main,
		color: theme.palette.info.contrastText,
		transitionProperty: 'margin',
		transitionDuration: '0.5s',
		alignItems: 'center'
	},
	closeButton: {
		color: theme.palette.common.white,
		float: 'right',
		padding: `${PADDING}px`
	},
	infoIcon: {
		alignSelf: 'center'
	}
}));

export function ConfirmEmailBanner({ email }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	return (
		<AppBar position="sticky" className={classes.banner}>
			<Typography align="center" color="inherit" variant="body2">
				{t('registration:banner_verify_email_address', {
					email
				})}
				&nbsp;<br></br>
				<a className={classes.link} onClick={() => dispatch(resendConfirmEmail())}>
					{t('registration:banner_resend_confirm_email_link')}
				</a>
			</Typography>
		</AppBar>
	);
}

export function SysAdminBanner() {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<AppBar position="sticky" className={`${classes.banner} ${classes.warning}`}>
			<Typography align="center" color="inherit" variant="h4">
				{t('sysadmin:logged_in_as_sysadmin_warning')}
			</Typography>
		</AppBar>
	);
}

export function PricingV2InfoBanner() {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();
	const [hideBanner, setHideBanner] = useState(false);
	const { palette } = useTheme();

	const navigateToSubscription = () => {
		history?.push('/app/settings/subscription');
	};

	return (
		<Slide
			direction="down"
			in={!hideBanner}
			mountOnEnter
			unmountOnExit
			timeout={{ enter: 200, exit: 1000 }}
		>
			<AppBar
				position="sticky"
				style={{ backgroundColor: palette.primary.main, flexDirection: 'row' }}
				className={`${classes.banner} ${classes.info}`}
			>
				<Stack direction="row" spacing={1} justifyContent="center" sx={{ flex: 1 }}>
					<Info className={classes.infoIcon} />
					<Typography alignSelf="center" variant="body2">
						{t('general:pricing_v2_info_banner_message')}
					</Typography>
						{/* Designer should change color to light token, temp solution '#8FB9FF' */}
						<PrimaryButton
							onClick={navigateToSubscription}
							style={{ backgroundColor: 'white', color: palette.info.main }}
							size="small"
						>
							{t('general:pricing_v2_info_banner_button')}
						</PrimaryButton>
				</Stack>
				<IconButton
					aria-label="close"
					size="small"
					className={classes.closeButton}
					onClick={() => setHideBanner(true)}
				>
					<GridCloseIcon />
				</IconButton>
			</AppBar>
		</Slide>
	);
}
export function InTrialBanner({ activePlanUntil }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const { palette } = useTheme();

	return (
		<AppBar
			position="sticky"
			className={`${classes.banner} ${classes.info}`}
			style={{ backgroundColor: palette.primary.main, flexDirection: 'row' }}
		>
			<Stack direction="row" spacing={1} justifyContent="center" sx={{ flex: 1 }}>
				<Info className={classes.infoIcon} />
				<Typography alignSelf="center" variant="body2">
					{t('general:in_trial_banner_message', {
						trialDaysLeft: Math.ceil(
							(new Date(activePlanUntil).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
						)
					})}
				</Typography>
				<Link to="/app/settings/subscription" className={classes.link}>
					<PrimaryButton
						style={{
							backgroundColor: 'white',
							color: palette.info.dark
						}}
						size="small"
					>
						{t('general:in_trial_banner_button')}
					</PrimaryButton>
				</Link>
			</Stack>
		</AppBar>
	);
}
