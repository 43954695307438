import {
	CircularProgress,
	IconButton,
	Snackbar,
	SnackbarContent,
	Theme,
	Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReactDom from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideNotification } from './actions';
import { State } from './reducers';

const useStyles = makeStyles((theme: Theme) => ({
	error: {
		background: theme.palette.error.main,
		color: theme.palette.error.contrastText,
		'& a': {
			color: 'inherit'
		}
	},
	success: {
		background: theme.palette.success.main,
		color: theme.palette.success.contrastText
	},
	info: {
		background: theme.palette.primary.main,
		color: theme.palette.error.contrastText
	},
	snackbarContent: {
		display: 'inline-flex'
	},
	snackbarRoot: {
		zIndex: 2147483648 // zIndex needs to be this high in order to always be on top
	},
	circularProgress: {
		marginLeft: theme.spacing(2)
	}
}));

export const Notification = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const onClose = (_event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		dispatch(hideNotification());
	};

	const { open, variant, message, noAutoHide } = useSelector((state: State) => state?.notification);
	return ReactDom.createPortal(
		<Snackbar
			open={open}
			classes={{ root: classes.snackbarRoot }}
			autoHideDuration={noAutoHide ? null : 4000}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			onClose={onClose}
		>
			<SnackbarContent
				className={classes[variant]}
				message={
					<div className={classes.snackbarContent}>
						{noAutoHide ? (
							<React.Fragment>
								{message}
								<CircularProgress
									className={classes.circularProgress}
									color="secondary"
									size={20}
								/>
							</React.Fragment>
						) : (
							<Typography variant="body2">{message}</Typography>
						)}
					</div>
				}
				action={
					!noAutoHide && (
						<div>
							<IconButton
								key="close"
								aria-label="Close"
								color="inherit"
								onClick={() => dispatch(hideNotification())}
								size="large"
							>
								<CloseIcon />
							</IconButton>
						</div>
					)
				}
			/>
		</Snackbar>,
		document.querySelector('body')
	);
};
