import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(3, 0),
		padding: theme.spacing(1, 3),
		borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.primary.light}`,
		backgroundColor: theme.palette.grey[200],
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}));

export function Blockquote(
	props: React.DetailedHTMLProps<React.BlockquoteHTMLAttributes<HTMLElement>, HTMLElement>
) {
	const classes = useStyles({});
	return <blockquote {...props} className={clsx(classes.root, props.className)} />;
}
