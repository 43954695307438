import { constants, asyncConstants } from './actions';
import { combineReducers } from 'redux';

export interface EmployeeCreationDialogState {
	email: string;
	firstName: string;
	lastName: string;
	telephone: string;
}

export const reducers = combineReducers<EmployeeCreationDialogState>({
	email: (state = '', action) => {
		switch (action.type) {
			case constants.SET_EMAIL:
				return action.payload;
			case asyncConstants.HANDLE_SUBMIT.FULFILLED:
				return '';
			default:
				return state;
		}
	},
	firstName: (state = '', action) => {
		switch (action.type) {
			case constants.SET_FIRSTNAME:
				return action.payload;
			case constants.PREFILL_EMPLOYEE_CREATION_FORM:
				return action.payload.firstName || '';
			case asyncConstants.HANDLE_SUBMIT.FULFILLED:
				return '';
			default:
				return state;
		}
	},
	lastName: (state = '', action) => {
		switch (action.type) {
			case constants.SET_LASTNAME:
				return action.payload;
			case constants.PREFILL_EMPLOYEE_CREATION_FORM:
				return action.payload.lastName || '';
			case asyncConstants.HANDLE_SUBMIT.FULFILLED:
				return '';
			default:
				return state;
		}
	},
	telephone: (state = '', action) => {
		switch (action.type) {
			case constants.SET_TELEPHONE:
				return action.payload;
			case asyncConstants.HANDLE_SUBMIT.FULFILLED:
				return '';
			default:
				return state;
		}
	}
});
