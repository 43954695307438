import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	Typography,
	MenuItem
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { closeAddDialog, createProperty } from './actions';
import { loadCustomers } from '../customers/actions';
import { Trans, useTranslation } from 'react-i18next';
import { CreatePropertyBody } from '@api/v1';

const useStyles = makeStyles((theme) => ({
	dialogActions: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(3)
	}
}));

const initialState: CreatePropertyBody = {
	name: '',
	address: {
		streetAddress: '',
		locality: '',
		postalCode: '',
		country: 'DE'
	},
	origin: 'relay',
	customerId: 0,
	status: 'ACCEPTED'
};

export const AddDialog = (props) => {
	const { open, customers = [] } = useSelector((state: State) => {
		return {
			...state.properties.addDialog,
			customers: state.customers.list?.data?.filter((customer) => customer.origin !== 'casavi')
		};
	});
	const { t } = useTranslation();
	const classes = useStyles(props);

	const [property, setProperty] = useState<CreatePropertyBody>(initialState);

	const dispatch = useDispatch();

	const handleSubmit = (_ev) => {
		dispatch(createProperty(property));
	};

	const handleSelectChange = (ev) => {
		setProperty({ ...property, customerId: ev.target.value });
	};

	useEffect(() => {
		dispatch(loadCustomers());

		// reset
		if (!open && property.name) {
			setProperty(initialState);
		}
	}, [open]);

	return (
		<Dialog open={open}>
			<ValidatorForm onSubmit={handleSubmit}>
				<br />
				<DialogTitle>
					{t('properties:add_dialog_title')}
					<br />
					<Typography variant="caption">{t('properties:add_dialog_title_label')}</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						<Grid item sm={12}>
							<TextValidator
								variant="standard"
								fullWidth
								label={t('properties:add_dialog_internal_id')}
								onChange={(e) => setProperty({ ...property, internalId: e.target.value })}
								value={property.internalId}
								defaultValue=""
							/>
						</Grid>
						<Grid item sm={12}>
							<TextValidator
								variant="standard"
								fullWidth
								label={t('properties:add_dialog_name')}
								onChange={(e) => setProperty({ ...property, name: e.target.value })}
								value={property.name}
								validators={['required']}
								errorMessages={[t('general:required_message')]}
							/>
						</Grid>
						<Grid item sm={12}>
							<TextValidator
								variant="standard"
								fullWidth
								label={t('properties:add_dialog_street')}
								onChange={(e) =>
									setProperty({
										...property,
										address: {
											...property.address,
											streetAddress: e.target.value
										}
									})
								}
								value={property.address.streetAddress}
								validators={['required']}
								errorMessages={[t('general:required_message')]}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextValidator
								variant="standard"
								fullWidth
								label={t('properties:add_dialog_postal_code')}
								onChange={(e) =>
									setProperty({
										...property,
										address: { ...property.address, postalCode: e.target.value }
									})
								}
								value={property.address.postalCode}
								validators={['required']}
								errorMessages={[t('general:required_message')]}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextValidator
								variant="standard"
								fullWidth
								label={t('properties:add_dialog_locality')}
								onChange={(e) =>
									setProperty({
										...property,
										address: { ...property.address, locality: e.target.value }
									})
								}
								value={property.address.locality}
								validators={['required']}
								errorMessages={[t('general:required_message')]}
							/>
						</Grid>
						<Grid item sm={12}>
							<SelectValidator
								variant="standard"
								onChange={handleSelectChange}
								validators={['required']}
								errorMessages={[t('general:required_message')]}
								value={property.customerId}
								type="number"
								fullWidth
							>
								<MenuItem value={0} disabled>
									{t('properties:add_dialog_customer')}
								</MenuItem>
								{customers?.map((customer) => {
									return (
										<MenuItem value={customer.id} key={customer.id}>
											{customer.name}
										</MenuItem>
									);
								})}
							</SelectValidator>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className={classes.dialogActions}>
					<Button color="secondary" variant="text" onClick={() => dispatch(closeAddDialog())}>
						<Trans i18nKey="properties:edit_dialog_cancel" />
					</Button>
					<Button color="primary" type="submit">
						<Trans i18nKey="properties:edit_dialog_save" />
					</Button>
				</DialogActions>
			</ValidatorForm>
		</Dialog>
	);
};
