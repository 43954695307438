import React from 'react';
import { Typography, useTheme } from '@mui/material';

export const TextGroupText = ({ children }) => {
	const theme = useTheme();
	return (
		<Typography sx={{ color: theme.palette.text.secondary }} variant="body2">
			{children}
		</Typography>
	);
};
