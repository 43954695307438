import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	InputAdornment,
	IconButton,
	Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
	addSubmitDialogFile,
	removeSubmitDialogFile,
	changeSubmitDialogFile,
	submit,
	removeAllSubmitDialogFiles
} from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialDropzone } from '../../../components/Dropzone';
import { State } from '../../reducers';
import { DateDialog } from '../../../components/DateTime';
import CalendarToday from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import EuroIcon from '@mui/icons-material/Euro';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { pendingFiles } from '../../../lib/uploadHelper';

const styles = makeStyles((theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
}));

export function SubmitDialog(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = styles(props);
	const [offerDescription, setOfferDescription] = useState('');
	const [price, setPrice] = useState('');
	const [executionDate, setExecutionDate] = useState('');
	const [isExecutionDateDialogOpen, setIsExecutionDateDialogOpen] = useState(false);
	const { files } = useSelector((state: State) => state.offerRequests.single.submitDialog);

	const onResetExecutionDate = useCallback((ev: SyntheticEvent) => {
		setExecutionDate('');
		ev.stopPropagation();
	}, []);

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

	return (
		<Dialog open={props.open}>
			<DialogTitle>
				{t('offers:dialog_submit_title')}
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={props.onClose}
					size="large"
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<ValidatorForm
				ref={(form) =>
					form?.isFormValid(true).then((isValid) => {
						if (isValid) {
							setIsSubmitDisabled(false);
						}
					})
				}
				onSubmit={() => {
					dispatch(submit({ offerDescription, price: parseFloat(price), executionDate, files }));
					dispatch(removeAllSubmitDialogFiles());
					props.onClose();
				}}
				onError={(errors) => {
					setIsSubmitDisabled(true);
					console.log(errors);
				}}
			>
				<DialogContent>
					<Typography variant="caption" color="textSecondary">
						{t('offers:dialog_submit_title_text')}
					</Typography>
					<br />
					<br />
					<TextValidator
						multiline
						variant="filled"
						rows={7}
						label={`${t('offers:dialog_submit_field_description')} *`}
						name="offer-submit-description"
						value={offerDescription}
						id="offer_submit_dialog_field_description"
						onChange={(e) => setOfferDescription(e.target.value)}
						validators={['maxStringLength:1500', 'required']}
						errorMessages={[
							t('validation:required_max_length', { maxLength: '1500' }),
							t('validation:required')
						]}
						fullWidth
					/>
					<br />
					<br />
					<Grid container spacing={1} justifyContent="space-between">
						<Grid item xs={12} sm={4}>
							<TextValidator
								name="offer-submit-price"
								label={`${t('offers:dialog_submit_field_gross_price')} *`}
								value={price}
								onChange={(e) => setPrice(e.target.value)}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<EuroIcon color="action" />
										</InputAdornment>
									)
								}}
								validators={['maxStringLength:10', 'isPrice', 'required']}
								errorMessages={[
									t('validation:required_max_length', { maxLength: '10' }),
									t('validation:price_format'),
									t('validation:required')
								]}
								variant="standard"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextValidator
								name="offer-submit-date"
								fullWidth
								label={`${t('offers:dialog_submit_field_execution_date')} *`}
								onClick={() => setIsExecutionDateDialogOpen(true)}
								value={executionDate ? moment(executionDate).format('DD.MM.YYYY') : ''}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{executionDate && (
												<IconButton onClick={onResetExecutionDate} size="large">
													<CloseIcon fontSize="small" color="primary" />
												</IconButton>
											)}
											<IconButton size="large">
												<CalendarToday />
											</IconButton>
										</InputAdornment>
									)
								}}
								variant="standard"
								validators={['required']}
								errorMessages={[t('validation:required')]}
							/>
							<DateDialog
								open={isExecutionDateDialogOpen}
								onClose={() => setIsExecutionDateDialogOpen(false)}
								onSubmit={(date) => {
									setIsExecutionDateDialogOpen(false);
									date.startOf('day');
									setExecutionDate(date.toISOString());
								}}
								initialValue={moment(executionDate || Date.now())}
								dialogTitle={t('offers:execution_date_dialog_header')}
								submitLabel={t('offers:execution_date_dialog_button_save')}
								disablePast
							/>
						</Grid>
					</Grid>

					<br />
					<br />
					<MaterialDropzone
						text={t('offers:dialog_finish_upload')}
						onDrop={(file) => dispatch(addSubmitDialogFile(file))}
						onRemove={(file) => dispatch(removeSubmitDialogFile(file))}
						onChangeFile={(file, data) => dispatch(changeSubmitDialogFile(file, data))}
						files={files}
					/>
					<br />
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="secondary" onClick={props.onClose}>
						{t('general:cta_cancel')}
					</Button>
					<Button
						id="offer_submit_dialog_button_submit"
						color="primary"
						type="submit"
						disabled={!!(isSubmitDisabled || pendingFiles(files))}
					>
						{t('offers:cta_submit')}
					</Button>
				</DialogActions>
			</ValidatorForm>
		</Dialog>
	);
}
