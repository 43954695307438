import { UIPropertiesStatistics, UIProperty } from '@api/v1';
import { combineReducers } from 'redux';
import { asyncConstants, constants, loadProperties } from './actions';

export interface PropertiesState {
	list: {
		data: UIProperty[];
		metaData: {
			maxAcceptedCount: number;
			currentAcceptedCount: number;
			canAcceptMore: boolean;
			lastId: number;
			hasMore: boolean;
			isFetching: boolean;
		};
	};
	acceptingDialog: PropertiesAcceptingDialogState;
	rejectingDialog: PropertiesRejectingDialogState;
	acceptAllDialog: PropertiesAcceptAllDialogState;
	editDialog: UIPropertyEditDialogState;
	addDialog: PropertyAddDialogState;
	statistics: UIPropertiesStatistics;
}

export interface PropertiesRejectingDialogState {
	open: boolean;
	property: UIProperty;
}
export interface PropertiesAcceptingDialogState {
	open: boolean;
	property: UIProperty;
}
export interface PropertiesAcceptAllDialogState {
	open: boolean;
}

export interface UIPropertyEditDialogState {
	open: boolean;
	property: UIProperty;
}
export interface PropertyAddDialogState {
	open: boolean;
}

export const PropertiesReducers = combineReducers<PropertiesState>({
	list: (state = null, action) => {
		switch (action.type) {
			case asyncConstants.GET_PROPERTIES.FULFILLED:
				return action.payload;
			case asyncConstants.GET_MORE_PROPERTIES.FULFILLED:
				return {
					...action.payload,
					data: [...state.data, ...action.payload.data]
				};
			case constants.SET_IS_LOADING:
				return { ...state, metaData: { ...state.metaData, isFetching: true } };
			case asyncConstants.REJECT_PROPERTY.FULFILLED:
			case asyncConstants.ACCEPT_PROPERTY.FULFILLED:
				return {
					...state,
					data: state.data.map((property) => {
						if (property.id === action.payload.id) {
							return action.payload;
						} else {
							return { ...property };
						}
					})
				};
			case constants.CLEAR:
				return {
					metaData: { maxAcceptedCount: 0, currentAcceptedCount: 0 }
				};
			default:
				return state;
		}
	},
	rejectingDialog: combineReducers<PropertiesRejectingDialogState>({
		open: (state = false, action) => {
			switch (action.type) {
				case constants.OPEN_REJECTING_DIALOG:
					return true;
				case constants.CLOSE_REJECTING_DIALOG:
					return false;
				case asyncConstants.REJECT_PROPERTY.FULFILLED:
					return false;
				default:
					return state;
			}
		},
		property: (state = null, action) => {
			switch (action.type) {
				case constants.OPEN_REJECTING_DIALOG:
					return action.payload;
				default:
					return state;
			}
		}
	}),
	acceptingDialog: combineReducers<PropertiesAcceptingDialogState>({
		open: (state = false, action) => {
			switch (action.type) {
				case constants.OPEN_ACCEPTING_DIALOG:
					return true;
				case constants.CLOSE_ACCEPTING_DIALOG:
					return false;
				case asyncConstants.ACCEPT_PROPERTY.FULFILLED:
					return false;
				default:
					return state;
			}
		},
		property: (state = null, action) => {
			switch (action.type) {
				case constants.OPEN_ACCEPTING_DIALOG:
					return action.payload;
				default:
					return state;
			}
		}
	}),
	acceptAllDialog: combineReducers<PropertiesAcceptAllDialogState>({
		open: (state = false, action) => {
			switch (action.type) {
				case constants.OPEN_ACCEPT_ALL_DIALOG:
					return true;
				case constants.CLOSE_ACCEPT_ALL_DIALOG:
					return false;
				case asyncConstants.ACCEPT_ALL_PROPERTIES.FULFILLED:
					return false;
				default:
					return state;
			}
		}
	}),
	editDialog: combineReducers<UIPropertyEditDialogState>({
		open: (state = false, action) => {
			switch (action.type) {
				case asyncConstants.EDIT_PROPERTY.FULFILLED:
				case constants.CLOSE_EDIT_DIALOG:
					return false;
				case constants.OPEN_EDIT_DIALOG:
					return true;
				default:
					return state;
			}
		},
		property: (state = null, action) => {
			switch (action.type) {
				case asyncConstants.GET_PROPERTIES.FULFILLED:
				case constants.OPEN_EDIT_DIALOG:
					return action.payload;
				case constants.SET_INTERNAL_ID:
					return { ...state, internalId: action.payload };
				default:
					return state;
			}
		}
	}),
	addDialog: combineReducers<PropertyAddDialogState>({
		open: (state = false, action) => {
			switch (action.type) {
				case constants.CLOSE_ADD_DIALOG:
					return false;
				case constants.OPEN_ADD_DIALOG:
					return true;
				default:
					return state;
			}
		}
	}),
	statistics: (state = {}, action) => {
		switch (action.type) {
			case asyncConstants.FETCH_STATISTICS.FULFILLED:
				return action.payload;
			default:
				return state;
		}
	}
});
