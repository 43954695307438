import { i18n } from '../i18n';
import { api } from '../lib/api';
import { createConstants } from '../lib/createConstants';

export type NotificationVariant = 'info' | 'success' | 'error';

export const constants = createConstants([
	'SHOW_NOTIFICATION',
	'HIDE_NOTIFICATION',
	'RESEND_CONFIRM_EMAIL'
]);

export const hideNotification = () => ({
	type: constants.HIDE_NOTIFICATION
});

export const showNotification = (
	message: React.ReactNode,
	variant: NotificationVariant,
	noAutoHide?: boolean
) => (dispatch) => {
	if (noAutoHide) {
		dispatch(hideNotification());
	}

	setTimeout(
		() =>
			dispatch({
				type: constants.SHOW_NOTIFICATION,
				payload: {
					message,
					variant,
					noAutoHide
				}
			}),
		200
	);
};

export const resendConfirmEmail = () => (dispatch) =>
	dispatch({
		type: constants.RESEND_CONFIRM_EMAIL,
		payload: api
			.post('/api/v1/user/actions/send_email_address_confirmation_email')
			.then((data) => {
				dispatch(showNotification(i18n.t('registration:resend_confirm_email'), 'success'));
				return data;
			})
			.catch((err) => {
				dispatch(showNotification(i18n.t('general:generic_error'), 'error'));
				throw err;
			})
	});
