import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers';
import { closeRejectingDialog, rejectProperty } from './actions';

export const RejectingDialog = () => {
	const { property, open } = useSelector((state: State) => state?.properties?.rejectingDialog);
	const dispatch = useDispatch();
	return (
		<Dialog open={open}>
			<DialogTitle>
				<Trans i18nKey="properties:rejecting_dialog_header" />
			</DialogTitle>
			<DialogContent>
				<Trans
					i18nKey="properties:rejecting_dialog_content"
					values={{
						propertyName: property ? property.name : '',
						customerName: property ? property.customer.name : ''
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => dispatch(closeRejectingDialog())}>
					<Trans i18nKey="general:cta_cancel" />
				</Button>
				<Button color="primary" onClick={() => dispatch(rejectProperty())}>
					<Trans i18nKey="properties:action_button_reject" />
				</Button>
			</DialogActions>
		</Dialog>
	);
};
