import { UIDocument } from '@api/v1';
import { combineReducers } from 'redux';
import { handleAddFile, handleChangeFile, handleRemoveFile } from '../../components/Dropzone';
import { DropzoneFile } from '../../components/Dropzone/types';
import {
	constants,
	openCreationDialog,
	closeCreationDialog,
	changeTitle,
	changeDescription,
	changeProperty,
	createDocument,
	asyncConstants
} from './actions';
import { createReducer } from '../../redux/createReducer';
import { MetaList } from 'server/browser.types';

export interface UIBrowserLazyLoadingMetaData {
	lastId: number;
	hasMore: boolean;
}

export interface UILazyLoadingMetaData extends UIBrowserLazyLoadingMetaData {
	isFetching: boolean;
}

interface UIDocumentList extends MetaList<UIDocument, UILazyLoadingMetaData> {}

export interface DocumentState {
	list: UIDocumentList;
	creationDialog: DocumentCreationDialogState;
}

interface DocumentCreationDialogState {
	open: boolean;
	title: string;
	description: string;
	property: {
		key: string;
		label: string;
	};
	files: DropzoneFile[];
}

export const reducers = combineReducers<DocumentState>({
	list: (state = null, action) => {
		switch (action.type) {
			case asyncConstants.FETCH.FULFILLED:
				return action.payload;
			case constants.SET_IS_FETCHING:
				return { ...state, metaData: { ...state.metaData, isFetching: true } };
			case asyncConstants.FETCH_MORE.FULFILLED:
				return {
					...action.payload,
					data: [...state.data, ...action.payload.data]
				};
			default:
				return state;
		}
	},
	creationDialog: combineReducers<DocumentCreationDialogState>({
		open: createReducer(false)
			.handleAction(openCreationDialog, () => true)
			.handleAction(closeCreationDialog, () => false)
			.handleAction(createDocument.success, () => false),
		title: createReducer('')
			.handleAction(changeTitle, (state, action) => action.payload)
			.handleAction(createDocument.success, () => ''),
		description: createReducer('')
			.handleAction(changeDescription, (state, action) => action.payload)
			.handleAction(createDocument.success, () => ''),
		property: createReducer<{ key: string; label: string }>(null)
			.handleAction(changeProperty, (state, action) => action.payload)
			.handleAction(createDocument.success, () => null),
		files: createReducer<DropzoneFile[]>([])
			.handleAnyAction(constants.ADD_FILE_TO_CREATION_DIALOG, handleAddFile)
			.handleAnyAction(constants.CHANGE_FILE_IN_CREATION_DIALOG, handleChangeFile)
			.handleAnyAction(constants.REMOVE_FILE_FROM_CREATION_DIALOG, handleRemoveFile)
			.handleAction(createDocument.success, () => [])
	})
});
