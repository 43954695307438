import { BUser, UIUser } from '@api/v1';
import { State } from 'app/core/reducers';
import _ from 'lodash';
import { PermissionType } from 'server/permissions/permission';
import { showNotification } from '../../core/actions';
import { setAuthEmailAddress, setEmailConfirmed } from '../../core/auth/actions';
import { api } from '../../lib/api';
import { createConstants } from '../../lib/createConstants';
import { UIUserState } from '../auth/reducers';

export const constants = createConstants([
	'HANDLE_SUBMIT',
	'SET_FIRSTNAME',
	'SET_LASTTNAME',
	'SET_TELEPHONE',
	'PREFILL_PROFILE_EDIT_FORM',
	'CHECK_EMAIL',
	'SET_EMAIL_ADDRESS',
	'SUBMIT_EMAIL_SETTINGS',
	'SET_EMAIL_PERMISSIONS',
	'FETCH_RATINGS'
]);

export const prefillProfileEditForm = (payload: { user: UIUserState }) => ({
	type: constants.PREFILL_PROFILE_EDIT_FORM,
	payload
});

export const setFirstName = (firstName: string) => ({
	type: constants.SET_FIRSTNAME,
	payload: firstName
});

export const setLastName = (lasttName: string) => ({
	type: constants.SET_LASTTNAME,
	payload: lasttName
});

export const setTelephone = (telephone: string) => ({
	type: constants.SET_TELEPHONE,
	payload: telephone
});

export const setEmailAddress = (emailAddress: string) => (dispatch) =>
	dispatch({
		type: constants.SET_EMAIL_ADDRESS,
		payload: emailAddress
	});

export const handleSubmit = () => (dispatch, getState: () => State) => {
	let userDataToPatch = _.pick(getState().profileEdit, [
		'firstName',
		'lastName',
		'telephone',
		'email'
	]);

	if (getState().auth.user.email === getState().profileEdit.email) {
		userDataToPatch = _.omit(userDataToPatch, 'email');
	} else {
		dispatch(setAuthEmailAddress(userDataToPatch.email));
	}

	return dispatch({
		type: constants.HANDLE_SUBMIT,
		payload: api.patch<BUser, UIUser>('/api/v1/user', userDataToPatch).then((body) => {
			const { emailConfirmed } = body;
			try {
				dispatch(setEmailConfirmed(emailConfirmed));
				dispatch(showNotification('Profil wurde erfolgreich gespeichert.', 'success'));
				return body;
			} catch (err) {
				dispatch(showNotification('Profil konnte nicht bearbeitet werden.', 'error'));
			}
		})
	});
};

export const setEmailPermissions = (payload: { [key in PermissionType]?: boolean }) => ({
	type: constants.SET_EMAIL_PERMISSIONS,
	payload
});

export const checkEmail = (email: string) => ({
	type: constants.CHECK_EMAIL,
	payload: api.get(
		`/api/v1/registration/actions/check_email_address?email=${encodeURIComponent(email)}`
	)
});

export const submitEmailSettings =
	(permissions: { [key in PermissionType]?: boolean }) => (dispatch) => {
		dispatch(setEmailPermissions(permissions));
		return {
			type: constants.SUBMIT_EMAIL_SETTINGS,
			payload: api
				.post('/api/v1/roles', {
					name: 'custom',
					permissions: Object.keys(permissions).filter((key) => permissions[key])
				})
				.then(
					() =>
						dispatch(
							showNotification(
								'E-Mail Benachrichtigungen wurde erfolgreich gespeichert.',
								'success'
							)
						),
					() =>
						dispatch(
							showNotification('E-Mail Benachrichtigungen konnte nicht bearbeitet werden.', 'error')
						)
				)
		};
	};

export const fetchRatings = () => (dispatch) =>
	dispatch({
		type: constants.FETCH_RATINGS,
		payload: api
			.get('/api/v1/ratings')
			.catch((error) => dispatch(showNotification('Error: No rating found! ' + error, 'error')))
	});
