import { UIAppointment } from '@api/v1';
import { Card, CardHeader, CardContent, Hidden, Typography, Chip, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import InfoIcon from '@mui/icons-material/Info';
import * as React from 'react';
import { Avatar } from '../../components/Avatar';
import { dateTime } from '../../lib/dateHelper';
import { useTranslation } from 'react-i18next';
import { getFirstLetterOfCompanyForIcon } from '../../lib/nameHelper';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2)
	},
	content: {
		display: 'flex'
	},
	title: {
		flex: '1 1 100%'
	},
	icon: {
		height: '100%',
		width: '1rem',
		borderColor: 'transparent'
	}
}));

export const AppointmentListItem: React.FC<{ item: UIAppointment }> = ({ item: appointment }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { title, customer, property, createdAt, dateStart, dateEnd, description } = appointment;
	const date = dateTime(dateStart).toFormat('dd.MM.yyyy');
	const endDate = dateTime(dateEnd).toFormat('dd.MM.yyyy');
	const isSameDay = date === endDate;
	return (
		<Card className={classes.root}>
			<CardHeader
				title={customer.name}
				subheader={property?.name || '\u200A'}
				avatar={<Avatar>{getFirstLetterOfCompanyForIcon(customer)}</Avatar>}
				action={
					<Hidden smDown>
						<Typography variant="body2">{dateTime(createdAt).toFormat('T')}</Typography>
					</Hidden>
				}
			/>
			<CardContent>
				<Box sx={{ ml: 8 }}>
					<Box className={classes.content}>
						<Chip icon={<DateRangeIcon />} variant="outlined" className={classes.icon} />
						<Typography className={classes.title}>
							{t('appointments:list_item_content', {
								title,
								propertyName: property?.name,
								date: dateTime(dateStart).toFormat('dd.MM.yyyy'),
								timeStart: dateTime(dateStart).toFormat('HH:mm'),
								timeEnd: dateTime(dateEnd).toFormat(isSameDay ? 'HH:mm' : 'dd.MM.yyyy HH:mm')
							})}
						</Typography>
					</Box>
					<Box className={classes.content}>
						<Chip icon={<InfoIcon />} variant="outlined" className={classes.icon} />
						<Typography className={classes.title}>{description}</Typography>
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};
