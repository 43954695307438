import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LoadingScreen } from '../../components/LoadingScreen';
import { State } from '../../core/reducers';
import { getUsers } from './actions';
import { Trans } from 'react-i18next';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		padding: theme.spacing(2),
		overflow: 'auto'
	},
	paper: {
		width: '100%',
		overflowX: 'auto'
	},
	link: {
		color: theme.palette.primary.main
	}
}));
export const UsersApp = (props) => {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const users = useSelector((state: State) => state.sysadmin.users.usersList);

	useEffect(() => {
		dispatch(getUsers());
	}, []);
	if (users) {
		return (
			<div className={classes.root}>
				<Paper className={classes.paper} style={{ height: '100%', width: '100%' }}>
					<DataGrid
						rows={users}
						columns={columns}
						components={{
							Toolbar: GridToolbar
						}}
						disableSelectionOnClick
					/>
				</Paper>
			</div>
		);
	}
	return <LoadingScreen />;
};
const columns = [
	{
		field: 'id',
		headerName: Trans({ i18nKey: 'sysadmin:users_id' }),
		editable: false,
		resizable: true,
		valueFormatter: ({ value }) => {
			return `${value}`;
		},
		renderCell: (params) => {
			return params.value ? (
				<Link to={`/sysadmin/app/accounts/${params.row.accountId}`}>{params.value}</Link>
			) : (
				''
			);
		}
	},
	{
		field: 'account',
		headerName: Trans({ i18nKey: 'sysadmin:users_account' }),
		editable: false,
		resizable: true
	},
	{
		field: 'email',
		headerName: Trans({ i18nKey: 'sysadmin:users_email' }),
		editable: false,
		width: 200
	},
	{
		field: 'name',
		headerName: Trans({ i18nKey: 'sysadmin:users_name' }),
		editable: false,
		width: 200,
		valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`
	},
	{
		field: 'telephone',
		headerName: Trans({ i18nKey: 'sysadmin:users_telephone' }),
		editable: false,
		width: 200
	}
];
