import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialDropzone } from '../../components/Dropzone';
import { DropzoneFile } from '../../components/Dropzone/types';
import { map, isObject } from 'lodash';
import { useHistory } from 'react-router';
import { State } from '../reducers';
import { LoadingScreen } from '../../../app/components/LoadingScreen';
import { checkToken, createOrderReply } from './actions';
import { CheckCircle } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
	displayBlock: {
		'@media print': {
			display: 'block'
		}
	},
	logo: {
		margin: `0px auto ${theme.spacing(4)} auto`,
		display: 'block'
	},
	paper: {
		padding: theme.spacing(8)
	},
	successIcon: {
		marginRight: theme.spacing(1),
		verticalAlign: 'sub'
	}
}));

interface InitialState {
	text: string;
	files: DropzoneFile[];
}

const initialState = {
	text: '',
	files: []
};

export const OrderReplyApp = (props) => {
	const { account } = useSelector((state: State) => state.orderReply);
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles(props);
	const [reply, setReply] = useState<InitialState>(initialState);
	const [hasFiguredOut, setFigureOut] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	useEffect(() => {
		dispatch(
			checkToken((err, data) => {
				if (err || !isObject(data)) {
					history.replace('/');
				}
				setFigureOut(true);
			})
		);
	}, []);

	const onSubmit = () => {
		dispatch(
			createOrderReply(reply, () => {
				setIsSuccess(true);
			})
		);
	};

	return hasFiguredOut ? (
		<Container maxWidth="sm">
			<Grid container className={classes.displayBlock} justifyContent="center">
				<Grid item xs={12}>
					<Paper elevation={4} className={classes.paper}>
						<ValidatorForm onSubmit={onSubmit}>
							<img
								id="navigation_logo"
								className={classes.logo}
								src="./../../static/images/relay-logo.svg"
								alt="relay logo"
								width={200}
							/>
							<Typography variant="h6">
								{t('orderReply:reply_to_headline', {
									serviceProviderName: account?.name
								})}
							</Typography>
							<br />
							{isSuccess ? (
								<Typography variant="subtitle1">
									<CheckCircle className={classes.successIcon} />
									{t('orderReply:reply_successful')}
								</Typography>
							) : (
								<>
									<TextValidator
										fullWidth
										multiline
										variant="outlined"
										rows={8}
										style={{ display: 'block' }}
										label={t('orderReply:value_label')}
										onChange={(e) =>
											setReply({
												...reply,
												text: e.target.value
											})
										}
										value={reply.text || ''}
										validators={['required']}
										errorMessages={[t('general:required_message')]}
									/>
									<br />
									<MaterialDropzone
										text={t('orderReply:attachment_placeholder')}
										onDrop={(file: DropzoneFile) => {
											setReply({
												...reply,
												files: [...reply.files, file]
											});
										}}
										onRemove={(tempId: string) => {
											setReply({
												...reply,
												files: reply.files.filter((f: DropzoneFile) => tempId !== f.tempId)
											});
										}}
										onChangeFile={(tempId, data) => {
											setReply({
												...reply,
												files: map(reply.files, function (file) {
													if (file.tempId === tempId) {
														return { tempId, ...data };
													}
													return file;
												})
											});
										}}
										files={reply.files}
									/>
									<br />
									<Button color="primary" variant="contained" fullWidth type="submit" size="large">
										{t('general:cta_answer')}
									</Button>
								</>
							)}
						</ValidatorForm>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	) : (
		<LoadingScreen />
	);
};
