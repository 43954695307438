import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { LoadingScreen } from '../components/LoadingScreen';
import { SysadminApp } from '../sysadmin/App';
import { checkToken, getNotifications } from './auth/actions';
import { LoginApp } from './auth/App';
import { OrderReplyApp } from './order-reply/App';
import { General } from './General';
import { Notification } from './Notification';
import { RegistrationApp } from './registration/App';
import { State } from './reducers';
import { loadActiveFeatures } from './acl/actions';

const NoMatch = () => <div>404</div>;

export const CoreApp = () => {
	const [hasFiguredOut, setHasFiguredOut] = useState<boolean>(false);
	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch('/');
	const dispatch = useDispatch();
	const { features: activeFeatures } = useSelector((state: State) => state.acl);

	useEffect(() => {
		const { pathname, search } = location;
		if (pathname.includes('/sysadmin') || pathname.includes('/order-reply')) {
			setHasFiguredOut(true);
			return;
		}
		if (hasFiguredOut) return;
		dispatch(
			checkToken((err, data) => {
				if (err || !_.isObject(data)) {
					if (pathname.includes('/app')) {
						history.replace('/login', {
							nextPath: pathname + search
						});
					} else if (match.isExact) {
						history.replace('/login');
					}
				} else if (match.isExact) {
					history.replace('/app');
				}
				if (!err && _.isObject(data)) {
					dispatch(getNotifications());
				}
				setHasFiguredOut(true);
			})
		);
		if (activeFeatures?.length < 1) dispatch(loadActiveFeatures());
	}, [location, activeFeatures]);

	if (hasFiguredOut) {
		return [
			<Switch key={0}>
				<Route path="/app" component={General} />
				<Route path="/order-reply" component={OrderReplyApp} />
				<Route path="/sysadmin" component={SysadminApp} />
				<Route path="/registration" component={RegistrationApp} />
				<Route path="/" component={LoginApp} />
				<Route component={NoMatch} />
			</Switch>,
			<Notification key={1} />
		];
	}
	return <LoadingScreen />;
};
