import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Property } from '../../server/db/models/Property';
import { ResponseCustomer } from '../../server/customers/browser.types';
import { UITicketTypes } from '../core/tickets/reducers';

export type DropdownItemValue = Property | ResponseCustomer | UITicketTypes;

export interface DropdownProp<T = DropdownItemValue> {
	label: string;
	values: T[];
	control: [T, React.Dispatch<React.SetStateAction<T>>];
	filterFields?: string[];
	disabled?: boolean;
}

export const Dropdown = ({
	label,
	values,
	control,
	filterFields,
	disabled = false
}: DropdownProp) => {
	const [state, setState] = control;
	const valuesNames = {};
	values?.forEach((v) => {
		valuesNames[v.id] = v.name;
	});
	const checkFilterFields = (v: DropdownItemValue) => !!filterFields?.find((field) => !v[field]);
	return (
		<TextField
			select
			fullWidth
			disabled={!values?.length || disabled}
			label={label}
			value={state.id}
			onChange={(e) =>
				setState({ id: e.target.value as unknown as number, name: valuesNames[e.target.value] })
			}
		>
			{values?.length > 0 &&
				values.map((v) => (
					<MenuItem key={v.id} value={v.id} disabled={checkFilterFields(v)}>
						{v.name}
					</MenuItem>
				))}
		</TextField>
	);
};
